import api from './APIInterceptor'

export default class AccountService {
  static permissions() {
    return api.get('/me/permissions')
  }

  static currentUserDetails() {
    return api.get('/me')
  }

  static updateCurrentUser(values) {
    return api.patch('/me', values)
  }

  static changePassword(values) {
    return api.patch('/me/password', values)
  }

  static currentUserCoworkers() {
    return api.get('/me/company/users')
  }

  static async uploadUserAvatar(avatar) {
    const formData = new FormData()
    formData.append('avatar', avatar)
    return api.post('/me/avatar', formData)
  }

  static async uploadEmployeeAvatar({ id, file }) {
    const formData = new FormData()
    formData.append('avatar', file)
    return api.post(`/me/company/users/${id}/avatar`, formData)
  }

  static sendContactForm(values) {
    return api.post('/contact', values)
  }
}
