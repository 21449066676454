import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { difference, last } from 'ramda'
import snakeCase from 'lodash.snakecase'
import { renameKeysWith } from 'ramda-adjunct'
import api from './APIInterceptor'

class LaravelEchoService {
  constructor() {
    //temporary - client: Pusher - as in documentation is not working
    if (typeof window === 'undefined') return

    this.activeChannels = []
    this.userId = null
    this.connected = true
    this.pusher = new Pusher(process.env.GATSBY_LARAVEL_ECHO_APP_NAME, {
      key: process.env.GATSBY_LARAVEL_ECHO_APP_NAME,
      wsHost: process.env.GATSBY_LARAVEL_ECHO_WSHOST,
      wssPort: process.env.GATSBY_LARAVEL_ECHO_WSSPORT,
      enableStats: false,
      authorizer: channel => ({
        authorize: (socketId, callback) => {
          api
            .post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name
            })
            .then(response => {
              callback(false, renameKeysWith(snakeCase, response.data))
            })
            .catch(error => {
              callback(true, error)
            })
        }
      })
    })

    this.echo = new Echo({
      broadcaster: 'pusher',
      client: this.pusher
    })
  }

  listenToChatEvents(userId, callback = console.log) {
    if (typeof window === 'undefined') return

    this.echo
      .private(`chat.${userId}`)
      .listen('.message.sent', payload => callback('.message.sent', payload))
      .listen('.offer.reserved', payload =>
        callback('.offer.reserved', payload)
      )
      .listen('.offer.deleted', payload => callback('.offer.deleted', payload))
      .listen('.offer.finished', payload =>
        callback('.offer.finished', payload)
      )
      .listen('.offer.handler.changed', payload =>
        callback('.offer.handler.changed', payload)
      )
      .listen('.offer.bid', payload => callback('.offer.bid', payload))
      .listen('.offer.reservation.accepted', payload =>
        callback('.offer.reservation.accepted', payload)
      )
      .listen('.offer.reservation.cancelled', payload =>
        callback('.offer.reservation.cancelled', payload)
      )
      .listen('.offer.reservation.queue.place-changed', payload =>
        callback('.offer.reservation.queue.place-changed', payload)
      )
      .listen('.offer.reservation.rejected', payload =>
        callback('.offer.reservation.rejected', payload)
      )
  }

  listenToNotificationEvents(userId, callback = console.log) {
    if (typeof window === 'undefined') return
    this.userId = userId

    this.echo
      .private(`notifications.${userId}`)
      .listen('.notification.sent', payload =>
        callback('.notification.sent', payload)
      )
  }

  listenToGlobalExchangeCounters(callback = console.log) {
    if (typeof window === 'undefined') return

    this.echo
      .private('exchange.counters')
      .listen('.offer.created', payload => callback('.offer.created', payload))
      .listen('.offer.deleted', payload => callback('.offer.deleted', payload))
  }

  listenToExchangeTabs({
    exchangeType,
    userId,
    tabsIds = [],
    callback = console.log
  }) {
    if (typeof window === 'undefined') return
    this.userId = userId

    const newActiveChannels = tabsIds.map(
      id => `users.${userId}.${exchangeType}.tabs.${id}`
    )
    const leaveChannels = difference(this.activeChannels, newActiveChannels)
    const listenChannels = difference(newActiveChannels, this.activeChannels)
    this.activeChannels = newActiveChannels

    leaveChannels.map(channel => this.echo.leave(channel))

    listenChannels.map(channel => {
      const tabId = last(channel.split('.'))
      this.echo
        .private(channel)
        .listen('.offer.created', payload =>
          callback(tabId, '.offer.created', payload)
        )
        .listen('.offer.refreshed', payload =>
          callback(tabId, '.offer.refreshed', payload)
        )
        .listen('.offer.deleted', payload =>
          callback(tabId, '.offer.deleted', payload)
        )
    })
  }

  listenToChatUserPresence({ initCallback, joiningCallback, leavingCallback }) {
    if (typeof window === 'undefined') return
    this.echo
      .join('chat.online')
      .here(initCallback)
      .joining(joiningCallback)
      .leaving(leavingCallback)
  }

  leaveChatPresenceChannel() {
    if (typeof window === 'undefined') return

    this.echo.leave('chat.online')
  }

  leaveAllChannels() {
    const that = this
    this.activeChannels.map(channel => this.echo.leave(channel))
    this.echo.leave('chat.online')
    this.echo.leave('exchange.counters')
    this.echo.leave(`notifications.${this.userId}`)
    this.echo.leave(`chat.${this.userId}`)

    setTimeout(() => {
      that.echo.disconnect()
      that.connected = false
    }, 1500)
  }
}

const Singleton = (function() {
  let instance
  return {
    getInstance() {
      if (!instance || !instance.connected) {
        instance = new LaravelEchoService()
      }
      return instance
    }
  }
})()

export default Singleton
//export default new LaravelEchoService()
