import {
  call,
  put,
  select,
  takeLatest,
  fork,
  takeEvery
} from '@redux-saga/core/effects'
import { map, prop } from 'ramda'
import { createRoutine } from 'redux-saga-routines'
import { navigate } from 'gatsby-plugin-intl'
import {
  selectMyFinishedOffersFreeCarrierFiltersFormatted,
  selectMyOffersFreeCarrierFiltersFormatted,
  selectMyOngoingFreeCarrierOffers,
  selectSelectedFreeCarrierOffers
} from 'src/features/myOffers/duck/selectors'
import OfferService from 'src/services/OfferService'
import ExchangeService from 'src/services/ExchangeService'
import {
  refreshFreeCarrierOfferRoutine,
  makeFreeCarrierOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-free-carriers'
import { fillFormUsingTemplateFreeCarriers } from 'src/features/offers/duck/actions'
import { normalizeToOfferTemplateFreeCarriers } from 'src/features/offers/duck/normalizers'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import {
  acceptFreeCarrierReservationRoutine,
  rejectFreeCarrierReservationRoutine
} from 'src/features/reservations/duck/actions'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import TrackingService from 'src/services/TrackingService'

export const getMyOngoingFreeCarrierOffersRoutine = createRoutine(
  'GET_MY_ONGOING_FREE_CARRIER_OFFERS'
)
export const getMyFinishedFreeCarrierOffersRoutine = createRoutine(
  'GET_MY_FINISHED_FREE_CARRIER_OFFERS'
)
export const setMyFreeCarrierFiltersRoutine = createRoutine(
  'SET_MY_FREE_CARRIER_FILTERS'
)
export const setMyFinishedFreeCarrierFiltersRoutine = createRoutine(
  'SET_MY_FINISHED_FREE_CARRIER_FILTERS'
)
export const setSelectedFreeCarrierOfferRoutine = createRoutine(
  'SELECT_FREE_CARRIER_OFFER'
)
export const selectAllFreeCarrierOffersRoutine = createRoutine(
  'SELECT_ALL_FREE_CARRIER_OFFERS'
)
export const clearSelectedFreeCarrierOffersRoutine = createRoutine(
  'CLEAR_SELECTED_FREE_CARRIER_OFFERS'
)
export const deleteMultipleFreeCarrierOffersRoutine = createRoutine(
  'DELETE_MULTIPLE_FREE_CARRIER_OFFERS'
)
export const deleteFreeCarrierOfferRoutine = createRoutine(
  'DELETE_FREE_CARRIER_OFFER'
)
export const restoreFreeCarrierOfferRoutine = createRoutine(
  'RESTORE_FREE_CARRIER_OFFER'
)
export const showOngoingFreeCarrierOfferReservationsRoutine = createRoutine(
  'SHOW_ONGOING_FREE_CARRIER_OFFER_RESERVATIONS'
)
export const showFinishedFreeCarrierOfferReservationsRoutine = createRoutine(
  'SHOW_FINISHED_FREE_CARRIER_OFFER_RESERVATIONS'
)
export const copyMyOfferToFreeCarrierFormRoutine = createRoutine(
  'COPY_MY_OFFER_TO_FREE_CARRIER_FORM'
)
export const createFreeCarrierOfferTrackingRoutine = createRoutine(
  'CREATE_FREE_CARRIER_OFFER_TRACKING'
)

//ACTIONS

export function* getMyOngoingFreeCarrierOffers() {
  yield put(getMyOngoingFreeCarrierOffersRoutine.request())
  try {
    const filters = yield select(selectMyOffersFreeCarrierFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyOngoingFreeCarrierOffers, filters)
    yield put(getMyOngoingFreeCarrierOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
  }
}

function* getMyFinishedFreeCarrierOffers() {
  yield put(getMyFinishedFreeCarrierOffersRoutine.request())
  try {
    const filters = yield select(
      selectMyFinishedOffersFreeCarrierFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyFinishedFreeCarrierOffers, filters)
    yield put(getMyFinishedFreeCarrierOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
  }
}

function* showOngoingFreeCarrierOfferReservations({ payload }) {
  yield put(showOngoingFreeCarrierOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      OfferService.showOngoingFreeCarrierOfferReservations,
      payload.id
    )
    yield put(showOngoingFreeCarrierOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* showFinishedFreeCarrierOfferReservations({ payload }) {
  yield put(showFinishedFreeCarrierOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      OfferService.showFinishedFreeCarrierOfferReservations,
      payload.id
    )
    yield put(showFinishedFreeCarrierOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* setMyFreeCarrierFilters({ payload }) {
  yield put(setMyFreeCarrierFiltersRoutine.success(payload))
}

function* setMyFinishedFreeCarrierFilters({ payload }) {
  yield put(setMyFinishedFreeCarrierFiltersRoutine.success(payload))
}

function* setSelectedOffer({ payload }) {
  yield put(setSelectedFreeCarrierOfferRoutine.success(payload))
}

function* selectAllOffers() {
  const offers = yield select(selectMyOngoingFreeCarrierOffers)
  const ids = map(prop('id'), offers)
  yield put(selectAllFreeCarrierOffersRoutine.success({ ids }))
}

function* clearSelectedOffers() {
  yield put(clearSelectedFreeCarrierOffersRoutine.success())
}

function* deleteMultipleFreeCarrierOffers() {
  yield put(deleteMultipleFreeCarrierOffersRoutine.request())
  try {
    const ids = yield select(selectSelectedFreeCarrierOffers)
    yield call(OfferService.deleteMultipleFreeCarrierOffers, { ids })
    yield put(deleteMultipleFreeCarrierOffersRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOffersSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deleteMultipleFreeCarrierOffersRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* deleteFreeCarrierOffer({ payload }) {
  yield put(deleteFreeCarrierOfferRoutine.request())
  try {
    yield call(OfferService.deleteFreeCarrierOffer, payload.id, {
      reason: payload.reason
    })
    yield put(deleteFreeCarrierOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOneOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deleteFreeCarrierOfferRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* restoreFreeCarrierOffer({ payload }) {
  yield put(restoreFreeCarrierOfferRoutine.request())
  try {
    yield call(OfferService.restoreFreeCarrierOffer, payload.id)
    yield put(restoreFreeCarrierOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.restoreOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(restoreFreeCarrierOfferRoutine.failure())
    console.log(e)
  }
}

function* copyMyOfferToFreeCarrierForm({ payload }) {
  const { data } = yield call(ExchangeService.showFreeCarrierOffer, {
    id: payload.offer.id
  })
  const normalized = normalizeToOfferTemplateFreeCarriers(data)

  yield call(fillFormUsingTemplateFreeCarriers, {
    payload: { template: normalized }
  })
  navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_FREE_CARRIERS}`)
}

export function* createFreeCarrierOfferTracking({ payload }) {
  yield put(createFreeCarrierOfferTrackingRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.createFreeCarriersOfferTracking,
      payload.id
    )
    yield put(createFreeCarrierOfferTrackingRoutine.success(data))
  } catch (e) {
    yield put(createFreeCarrierOfferTrackingRoutine.fulfill())
  }
}

//WATCHERS

function* getMyOngoingFreeCarrierOffersRoutineWatcher() {
  yield takeLatest(
    [
      setMyFreeCarrierFiltersRoutine.SUCCESS,
      getMyOngoingFreeCarrierOffersRoutine.TRIGGER,
      deleteMultipleFreeCarrierOffersRoutine.SUCCESS,
      deleteFreeCarrierOfferRoutine.SUCCESS,
      refreshFreeCarrierOfferRoutine.SUCCESS,
      makeFreeCarrierOfferPublicRoutine.SUCCESS,
      restoreFreeCarrierOfferRoutine.SUCCESS,
      rejectFreeCarrierReservationRoutine.SUCCESS
    ],
    getMyOngoingFreeCarrierOffers
  )
}

function* getMyFinishedFreeCarrierOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyFinishedFreeCarrierOffersRoutine.TRIGGER,
      setMyFinishedFreeCarrierFiltersRoutine.SUCCESS,
      acceptFreeCarrierReservationRoutine.SUCCESS,
      rejectFreeCarrierReservationRoutine.SUCCESS,
      restoreFreeCarrierOfferRoutine.SUCCESS,
      deleteFreeCarrierOfferRoutine.SUCCESS
    ],
    getMyFinishedFreeCarrierOffers
  )
}

function* setMyFreeCarrierFiltersRoutineWatcher() {
  yield takeLatest(
    setMyFreeCarrierFiltersRoutine.TRIGGER,
    setMyFreeCarrierFilters
  )
}

function* setMyFinishedFreeCarrierFiltersRoutineWatcher() {
  yield takeLatest(
    setMyFinishedFreeCarrierFiltersRoutine.TRIGGER,
    setMyFinishedFreeCarrierFilters
  )
}

function* setSelectedOfferRoutineWatcher() {
  yield takeLatest(setSelectedFreeCarrierOfferRoutine.TRIGGER, setSelectedOffer)
}

function* selectAllOffersRoutineWatcher() {
  yield takeLatest(selectAllFreeCarrierOffersRoutine.TRIGGER, selectAllOffers)
}
function* clearSelectedOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyOngoingFreeCarrierOffersRoutine.SUCCESS,
      clearSelectedFreeCarrierOffersRoutine.TRIGGER
    ],
    clearSelectedOffers
  )
}

function* deleteMultipleFreeCarrierOffersRoutineWatcher() {
  yield takeLatest(
    deleteMultipleFreeCarrierOffersRoutine.TRIGGER,
    deleteMultipleFreeCarrierOffers
  )
}

function* deleteFreeCarrierOfferRoutineWatcher() {
  yield takeLatest(
    deleteFreeCarrierOfferRoutine.TRIGGER,
    deleteFreeCarrierOffer
  )
}

function* restoreFreeCarrierOfferRoutineWatcher() {
  yield takeLatest(
    restoreFreeCarrierOfferRoutine.TRIGGER,
    restoreFreeCarrierOffer
  )
}

function* showOngoingFreeCarrierOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showOngoingFreeCarrierOfferReservationsRoutine.TRIGGER,
    showOngoingFreeCarrierOfferReservations
  )
}

function* showFinishedFreeCarrierOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showFinishedFreeCarrierOfferReservationsRoutine.TRIGGER,
    showFinishedFreeCarrierOfferReservations
  )
}

function* copyMyOfferToFreeCarrierFormRoutineWatcher() {
  yield takeLatest(
    copyMyOfferToFreeCarrierFormRoutine.TRIGGER,
    copyMyOfferToFreeCarrierForm
  )
}

function* createFreeCarrierOfferTrackingRoutineWatcher() {
  yield takeLatest(
    createFreeCarrierOfferTrackingRoutine.TRIGGER,
    createFreeCarrierOfferTracking
  )
}

export const myFreeCarrierOffersSagas = [
  fork(getMyOngoingFreeCarrierOffersRoutineWatcher),
  fork(setMyFreeCarrierFiltersRoutineWatcher),
  fork(setSelectedOfferRoutineWatcher),
  fork(deleteMultipleFreeCarrierOffersRoutineWatcher),
  fork(selectAllOffersRoutineWatcher),
  fork(clearSelectedOffersRoutineWatcher),
  fork(deleteFreeCarrierOfferRoutineWatcher),
  fork(getMyFinishedFreeCarrierOffersRoutineWatcher),
  fork(setMyFinishedFreeCarrierFiltersRoutineWatcher),
  fork(restoreFreeCarrierOfferRoutineWatcher),
  fork(showOngoingFreeCarrierOfferReservationsRoutineWatcher),
  fork(copyMyOfferToFreeCarrierFormRoutineWatcher),
  fork(showFinishedFreeCarrierOfferReservationsRoutineWatcher),
  fork(createFreeCarrierOfferTrackingRoutineWatcher)
]
