import { find, pathOr, propEq, propIs, propOr, pipe } from 'ramda'
import {
  selectMyFinishedExportOffers,
  selectMyFinishedFreeCarrierOffers,
  selectMyFinishedImportOffers,
  selectMyFinishedPostImportOffers
} from 'src/features/myOffers/duck/selectors'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import { API_STATES } from 'src/ducks/consts'

export const selectOffer = state => state.debtCollection.selectedOffer
export const selectDebtCollectionOfferId = state =>
  state.debtCollection.selectedOffer.id
export const selectAdditionalInfo = state => state.debtCollection.additionalInfo
export const selectFiles = state => state.debtCollection.files
export const selectDebtCollectionConfirmation = state =>
  state.debtCollection.confirmation
export const selectDebtCollectionErrorMessage = state =>
  state.debtCollection.errorMessage

export const selectIsLoading = state =>
  state.debtCollection.state === API_STATES.IN_PROGRESS

export const selectDebtor = state => {
  const { id, offerType } = selectOffer(state)

  const selectMyFinishedOffers = {
    [STOCKS_TYPES.POST_IMPORT]: selectMyFinishedPostImportOffers,
    [STOCKS_TYPES.CARGO_EXPORT]: selectMyFinishedExportOffers,
    [STOCKS_TYPES.CARGO_IMPORT]: selectMyFinishedImportOffers,
    [STOCKS_TYPES.FREE_CARRIERS]: selectMyFinishedFreeCarrierOffers
  }[offerType]

  return pipe(
    selectMyFinishedOffers,
    find(propEq('id', id)),
    propOr([], 'reservations'),
    find(propIs(String, 'acceptedAt')),
    pathOr(null, ['offeror', 'company'])
  )(state)
}
