import { pipe } from 'ramda'
import moment from 'moment'
import {
  OrderDetails,
  OrderEdit,
  OrderListItem
} from 'src/features/orders/duck/records'
import {
  filterEmptyStrings,
  filterNullValues,
  getDateFromIsoString,
  getIsoStringFromDateAndTime,
  getTimeFromIsoString
} from 'src/utils/helpers'
import {
  AdrClass,
  Company,
  Container,
  Contractor,
  Currency,
  Shipowner
} from 'src/ducks/records'
import { TRACKING_OFFER_TYPES } from 'src/features/tracking/duck/consts'
import {
  normalizeExportOfferDetails,
  normalizeFreeCarrierOfferDetails,
  normalizeImportOfferDetails,
  normalizePostImportOfferDetails
} from 'src/features/stocks/ducks/normalizers'
import { DATE_SHORT_FORMAT_WITH_TIME } from 'src/ducks/consts'

export const normalizeOrdersList = arr =>
  arr.map(
    el =>
      new OrderListItem({
        ...el,
        loadingUnloadingDateTime: el.loadingUnloadingDateTime
          ? moment(el.loadingUnloadingDateTime).format(
              DATE_SHORT_FORMAT_WITH_TIME
            )
          : null,
        container: Container({ ...el.container }),
        shipowner: Shipowner({ ...el.shipowner }),
        carrierCompany: Company({ ...el.carrierCompany }),
        forwarderCompany: Company({ ...el.forwarderCompany })
      })
  )

export const normalizeOrderDetails = obj => {
  const offer = {
    [TRACKING_OFFER_TYPES.EXPORT]: normalizeExportOfferDetails(obj.offer),
    [TRACKING_OFFER_TYPES.IMPORT]: normalizeImportOfferDetails(obj.offer),
    [TRACKING_OFFER_TYPES.FREE_CARRIER]: normalizeFreeCarrierOfferDetails(
      obj.offer
    ),
    [TRACKING_OFFER_TYPES.POST_IMPORT]: normalizePostImportOfferDetails(
      obj.offer
    )
  }[obj.offerType]

  return OrderDetails({
    ...obj,
    loadingUnloadingDate: getDateFromIsoString(obj.loadingUnloadingDateTime),
    loadingUnloadingTime: getTimeFromIsoString(obj.loadingUnloadingDateTime),
    container: Container({
      ...obj.container
    }),
    shipowner: Shipowner({
      ...obj.shipowner
    }),
    forwarderUser: Contractor({
      ...obj.forwarderUser
    }),
    carrierUser: Contractor({
      ...obj.carrierUser
    }),
    price: Currency({ ...obj.price }),
    adrClass: AdrClass({ ...obj.adrClass }),
    offer
  })
}

export const normalizeOrderEditForm = obj =>
  OrderEdit({
    ...obj,
    price: obj.price?.float,
    loadingUnloadingDate: getDateFromIsoString(obj.loadingUnloadingDateTime),
    loadingUnloadingTime: getTimeFromIsoString(obj.loadingUnloadingDateTime)
  })

export const normalizeOrderEditFormForSave = obj =>
  pipe(
    filterNullValues,
    filterEmptyStrings
  )({
    ...obj,
    loadingUnloadingDateTime: getIsoStringFromDateAndTime(
      obj.loadingUnloadingDate,
      obj.loadingUnloadingTime
    )
  })
