import { combineReducers } from 'redux'
import { all } from '@redux-saga/core/effects'

import { globalSagas } from 'src/ducks/actions'
import {
  dictionariesReducer,
  exchangeCountersReducer,
  snackbarReducer,
  euFinancingDialogReducer
} from 'src/ducks/reducers'
import {
  forgotPasswordReducer,
  loginReducer,
  registerReducer
} from 'src/features/auth/duck/reducers'
import { authSagas, logoutUserRoutine } from 'src/features/auth/duck/actions'

import {
  currentCompanyReducer,
  addUserReducer,
  editUserReducer,
  accountReducer,
  contactFormReducer
} from 'src/features/account/duck/reducers'
import {
  exportReducer,
  importReducer,
  postImportReducer,
  freeCarriersReducer
} from 'src/features/stocks/ducks/reducers'

import { exchangeExportSagas } from 'src/features/stocks/ducks/actions-export'
import { exchangeImportSagas } from 'src/features/stocks/ducks/actions-import'
import { exchangePostImportSagas } from 'src/features/stocks/ducks/actions-post-import'
import { exchangeFreeCarriersSagas } from 'src/features/stocks/ducks/actions-free-carriers'

import { accountSagas } from 'src/features/account/duck/actions'

import { offersSagas } from 'src/features/offers/duck/actions'
import {
  offerFormImportReducer,
  offerFormExportReducer,
  offerFormPostImportReducer,
  offerFormFreeCarriersReducer,
  offerFormsScaffoldsReducer,
  offerFormContractReducer
} from 'src/features/offers/duck/reducers'

import { reservationsSagas } from 'src/features/reservations/duck/actions'
import { myReservationsReducer } from 'src/features/reservations/duck/reducers'
import { myOffersReducer } from 'src/features/myOffers/duck/reducers'
import { myExportOffersSagas } from 'src/features/myOffers/duck/actions-export'
import { myImportOffersSagas } from 'src/features/myOffers/duck/actions-import'
import { myPostImportOffersSagas } from 'src/features/myOffers/duck/actions-post-import'
import { myFreeCarrierOffersSagas } from 'src/features/myOffers/duck/actions-free-carrier'
import { companiesSagas } from 'src/features/companies/duck/actions'

import { messagesSagas } from 'src/features/messages/duck/actions'
import { messagesReducer } from 'src/features/messages/duck/reducers'
import { notificationsSagas } from 'src/features/notifications/duck/actions'
import { notificationsReducer } from 'src/features/notifications/duck/reducers'
import { companiesReducer } from 'src/features/companies/duck/reducers'
import { termsOfServiceSagas } from 'src/features/termsOfService/ducks/actions'
import { termsOfServiceReducer } from 'src/features/termsOfService/ducks/reducer'
import { debtCollectionReducer } from 'src/features/debtCollection/duck/reducers'
import { debtCollectionSagas } from 'src/features/debtCollection/duck/actions'
import { contractSagas } from 'src/features/contracts/duck/actions'
import { contractsReducer } from 'src/features/contracts/duck/reducers'
import { trackingSagas } from 'src/features/tracking/duck/actions'
import { trackingReducer } from 'src/features/tracking/duck/reducers'
import { ordersReducer } from 'src/features/orders/duck/reducers'
import { ordersSagas } from 'src/features/orders/duck/actions'
import { invoicesReducer } from 'src/features/invoices/duck/reducers'
import { invoicesSagas } from 'src/features/invoices/duck/actions'

const appReducer = combineReducers({
  dictionaries: dictionariesReducer,
  login: loginReducer,
  register: registerReducer,
  forgotPassword: forgotPasswordReducer,
  account: accountReducer,
  currentCompany: currentCompanyReducer,
  myOffers: myOffersReducer,
  myReservations: myReservationsReducer,
  addUserForm: addUserReducer,
  editUserForm: editUserReducer,
  stockExport: exportReducer,
  stockImport: importReducer,
  stockPostImport: postImportReducer,
  stockFreeCarriers: freeCarriersReducer,
  offerFormImport: offerFormImportReducer,
  offerFormExport: offerFormExportReducer,
  offerFormPostImport: offerFormPostImportReducer,
  offerFormFreeCarriers: offerFormFreeCarriersReducer,
  offerFormContracts: offerFormContractReducer,
  offerFormsScaffolds: offerFormsScaffoldsReducer,
  exchangeCounters: exchangeCountersReducer,
  snackbar: snackbarReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  contactForm: contactFormReducer,
  companies: companiesReducer,
  termsOfService: termsOfServiceReducer,
  debtCollection: debtCollectionReducer,
  contracts: contractsReducer,
  tracking: trackingReducer,
  orders: ordersReducer,
  invoices: invoicesReducer,
  euFinancingDialog: euFinancingDialogReducer
})

/**
 * Reset redux store on logout
 * see: https://stackoverflow.com/a/35641992
 * @param state
 * @param action
 * @returns {undefined|*}
 */
export default (state, action) => {
  if (action.type === logoutUserRoutine.SUCCESS) {
    // eslint-disable-next-line
    state = undefined
  }
  return appReducer(state, action)
}

export function* rootSaga() {
  yield all([
    ...globalSagas,
    ...authSagas,
    ...accountSagas,
    ...exchangeExportSagas,
    ...exchangeImportSagas,
    ...exchangePostImportSagas,
    ...exchangeFreeCarriersSagas,
    ...offersSagas,
    ...myExportOffersSagas,
    ...myImportOffersSagas,
    ...myPostImportOffersSagas,
    ...myFreeCarrierOffersSagas,
    ...reservationsSagas,
    ...messagesSagas,
    ...notificationsSagas,
    ...companiesSagas,
    ...termsOfServiceSagas,
    ...trackingSagas,
    ...debtCollectionSagas,
    ...contractSagas,
    ...ordersSagas,
    ...invoicesSagas
  ])
}
