import { Record } from 'immutable'
import {
  API_STATES,
  COUNTRIES,
  OFFERS_DEFAULT_LIMIT,
  OFFERS_DEFAULT_PAGE
} from 'src/ducks/consts'
import { StockStates } from 'src/features/stocks/ducks/records'
import { Contractor, Currency, Pagination } from 'src/ducks/records'
import {
  INVOICE_LANGUAGES,
  INVOICE_NUMBERING_SCHEME,
  SAFT_NAMES_ARRAY,
  TAX_RATES
} from 'src/features/invoices/duck/consts'
import { OrderListItem } from 'src/features/orders/duck/records'
import { reduce } from 'ramda'
import moment from 'moment'

export const Payment = Record({
  id: null,
  userId: null,
  currency: '',
  amount: Currency(),
  paidAt: ''
})

export const Saft = Record(
  reduce(
    (acc, saftName) => ({ ...acc, [saftName]: false }),
    {},
    SAFT_NAMES_ARRAY
  )
)

export const InvoicesSettings = Record({
  state: API_STATES.PRISTINE,
  errorMessage: null,
  companyId: null,
  numberingScheme: INVOICE_NUMBERING_SCHEME.MONTHLY,
  issuePlace: '',
  paymentBankAccount: '',
  paymentBankName: ''
})

export const InvoicesFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: OFFERS_DEFAULT_LIMIT,
  paymentStatus: null
})

export const Invoices = Record({
  state: StockStates(),
  errorMessage: null,
  invoices: [],
  filters: InvoicesFilters(),
  pagination: Pagination(),
  total: ''
})

export const InvoiceItem = Record({
  id: null,
  invoiceId: null,
  description: '',
  qty: 1,
  unit: '',
  currency: '',
  unitPrice: Currency(),
  subtotal: Currency(),
  taxRate: TAX_RATES.PERCENT23,
  tax: Currency(),
  total: Currency(),
  gtuCode: '',
  pkwiuCode: ''
})

export const AddRecipientForm = Record({
  recipientCompanyName: '',
  recipientCompanyAddress: '',
  recipientCompanyAddressLine2: '',
  recipientCompanyPostalCode: '',
  recipientCompanyCity: '',
  recipientCompanyCountryCode: COUNTRIES.PL,
  recipientCompanyTaxId: '',
  recipientCompanyEmail: ''
})

export const Invoice = Record({
  addRecipientForm: AddRecipientForm(),
  additionalInfo: '',
  amountDue: Currency(),
  amountPaid: Currency(),
  currency: '',
  canCreateCorrectiveInvoice: false,
  correctiveInvoices: [],
  correctiveNotes: [],
  canCreateCorrectiveNote: false,
  correctiveNotesCount: 0,
  dueDate: moment().add(7, 'days'),
  id: null,
  isSplitPayment: false,
  isCorrectiveInvoice: false,
  issueDate: moment(),
  issuePlace: '',
  issuer: Contractor(),
  issuerCompanyAddress: '',
  issuerCompanyCity: '',
  issuerCompanyCountryCode: '',
  issuerCompanyEmail: '',
  issuerCompanyId: null,
  issuerCompanyName: '',
  issuerCompanyPostalCode: '',
  issuerCompanyTaxId: null,
  issuerId: null,
  items: [InvoiceItem()],
  invoice: { id: null, number: '' },
  lastCorrectiveInvoice: {
    id: null,
    invoiceId: null,
    issueDate: '',
    number: ''
  },
  number: '',
  numberPostfix: '/',
  numberingScheme: INVOICE_NUMBERING_SCHEME.MONTHLY,
  paymentBankAccount: '',
  paymentBankName: '',
  paymentMethod: '',
  payments: [],
  pdfLang: INVOICE_LANGUAGES.PL,
  recipientCompanyAddress: '',
  recipientCompanyAddressLine2: '',
  recipientCompanyCity: '',
  recipientCompanyCountryCode: COUNTRIES.PL,
  recipientCompanyEmail: '',
  recipientCompanyId: null,
  recipientCompanyName: '',
  recipientCompanyPostalCode: '',
  recipientCompanyTaxId: '',
  recipientSearchResults: [],
  saft: Saft(),
  sellDate: moment(),
  state: API_STATES.PRISTINE,
  recipientSearchState: API_STATES.PRISTINE,
  subtotal: Currency(),
  tax: Currency(),
  total: Currency(),
  transportationOrder: OrderListItem(),
  transportationOrderId: null
})

export const CorrectiveNote = Record({
  state: API_STATES.PRISTINE,
  id: null,
  invoiceId: null,
  issuerId: null,
  issuerCompanyId: null,
  recipientCompanyId: null,
  number: '',
  issueDate: '',
  originalContent: '',
  correctedContent: '',
  additionalInfo: '',
  invoice: Invoice(),
  issuer: Contractor()
})

export const CorrectiveInvoice = Record({
  state: API_STATES.PRISTINE,
  id: null,
  invoiceId: null,
  issuerId: null,
  issuerCompanyId: null,
  recipientCompanyId: null,
  transportationOrderId: null,
  isCorrectiveInvoice: true,
  number: '',
  issuePlace: '',
  issueDate: '',
  dueDate: null,
  correctionReason: '',
  currency: '',
  subtotal: Currency(),
  tax: Currency(),
  total: Currency(),
  amountDue: Currency(),
  amountPaid: Currency(),
  paymentMethod: 'transfer',
  paymentBankAccount: '',
  paymentBankName: '',
  isSplitPayment: false,
  saft: Saft(),
  additionalInfo: '',
  invoiceTotalAfterCorrection: Currency(),
  availableCorrectionReasons: [],
  items: [],
  invoice: new Invoice(),
  issuer: Contractor(),
  payments: []
})
