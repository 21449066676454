export const selectForgotPasswordStatus = state => state.forgotPassword.state
export const selectForgotPasswordError = state =>
  state.forgotPassword.errorMessage
export const selectForgotPasswordSentSuccessfully = state =>
  state.forgotPassword.sentSuccessfully
export const selectPasswordChangedSuccessfully = state =>
  state.forgotPassword.changedSuccessfully
export const selectRegistrationData = state => state.register
export const selectLoginData = state => state.login
export const selectRegisterUserForm = state => state.register
