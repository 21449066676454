import { Record } from 'immutable'
import { API_STATES } from 'src/ducks/consts'

export const Cta = Record({
  title: '',
  url: ''
})

export const Notification = Record({
  id: null,
  subject: '',
  code: '',
  type: null,
  createdAt: '',
  marked: false,
  readAt: null,
  offer: null,
  coworkerName: '',
  body: null,
  cta: Cta(),
  transportationOrder: null,
  invoice: null
})

export const OfferDetails = Record({
  referenceNumber: null,
  id: null,
  from: '',
  fromDate: '',
  startDate: '',
  endDate: '',
  to: '',
  toDate: '',
  container: '',
  handlerCompany: '',
  handlerName: '',
  shipowner: '',
  driver: '',
  driverPhone: ''
})

export const InvoiceDetails = Record({
  id: null,
  number: '',
  transportationOrderNumber: null,
  details: {
    issuer: {
      company: '',
      name: '',
      taxId: ''
    },
    total: ''
  }
})

export const Settings = Record({
  code: null,
  label: '',
  databaseChannel: false,
  mailChannel: false,
  mobilePushChannel: false
})

export const NotificationGroupSettings = Record({
  label: '',
  type: '',
  values: []
})

export const Notifications = Record({
  listState: API_STATES.PRISTINE,
  unreadCount: 0,
  listOpened: false,
  notifications: [],
  notificationsSticky: [],
  page: 1,
  limit: 20,
  allFetched: false,
  settings: []
})
