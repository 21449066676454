import {
  pipe,
  when,
  includes,
  not,
  evolve,
  reject,
  isNil,
  omit,
  keys,
  map,
  sum,
  pluck
} from 'ramda'
import { renameKeysWith, isEmptyArray, isArray } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import { formatFilterKey, joinLocationsIdsWithRange } from 'src/utils/helpers'
import {
  normalizeExportOfferTimelineData,
  normalizeFreeCarrierOfferTimelineData,
  normalizeImportOfferTimelineData,
  normalizePostImportOfferTimelineData
} from 'src/features/stocks/ducks/normalizers'

//EXPORT
export const selectExportStock = state => state.stockExport
export const selectExportOffers = state => state.stockExport.offers
export const selectExportOfferDetails = state => state.stockExport.offerDetails
export const selectIsExportDrawerOpen = state => state.stockExport.isDrawerOpen
export const selectExportFilters = state => state.stockExport.filters
export const selectExportOfferDetailsId = state =>
  state.stockExport.offerDetails.id
export const selectExportPagination = state => state.stockExport.pagination
export const selectExportErrorMessage = state => state.stockExport.errorMessage
export const selectExportButtonsState = state => state.stockExport.state.buttons

//IMPORT
export const selectImportStock = state => state.stockImport
export const selectImportOffers = state => state.stockImport.offers
export const selectImportOfferDetails = state => state.stockImport.offerDetails
export const selectIsImportDrawerOpen = state => state.stockImport.isDrawerOpen
export const selectImportFilters = state => state.stockImport.filters
export const selectImportOfferDetailsId = state =>
  state.stockImport.offerDetails.id
export const selectImportPagination = state => state.stockImport.pagination
export const selectImportErrorMessage = state => state.stockImport.errorMessage
export const selectImportButtonsState = state => state.stockImport.state.buttons

//POSTIMPORT
export const selectPostImportStock = state => state.stockPostImport
export const selectPostImportOffers = state => state.stockPostImport.offers
export const selectPostImportOfferDetails = state =>
  state.stockPostImport.offerDetails
export const selectIsPostImportDrawerOpen = state =>
  state.stockPostImport.isDrawerOpen
export const selectPostImportFilters = state => state.stockPostImport.filters
export const selectPostImportOfferDetailsId = state =>
  state.stockPostImport.offerDetails.id
export const selectPostImportPagination = state =>
  state.stockPostImport.pagination
export const selectPostImportErrorMessage = state =>
  state.stockPostImport.errorMessage
export const selectPostImportButtonsState = state =>
  state.stockPostImport.state.buttons

//FREECARRIERS
export const selectFreeCarrierStock = state => state.stockFreeCarriers
export const selectFreeCarriersOffers = state => state.stockFreeCarriers.offers
export const selectFreeCarrierOfferDetails = state =>
  state.stockFreeCarriers.offerDetails
export const selectIsFreeCarrierDrawerOpen = state =>
  state.stockFreeCarriers.isDrawerOpen
export const selectFreeCarrierFilters = state => state.stockFreeCarriers.filters
export const selectFreeCarriersOfferDetailsId = state =>
  state.stockFreeCarriers.offerDetails.id
export const selectFreeCarrierPagination = state =>
  state.stockFreeCarriers.pagination
export const selectFreeCarrierErrorMessage = state =>
  state.stockFreeCarriers.errorMessage
export const selectFreeCarriersButtonsState = state =>
  state.stockFreeCarriers.state.buttons

export const selectExportOfferTimelineData = state => {
  const offerDetails = selectExportOfferDetails(state)
  return normalizeExportOfferTimelineData(offerDetails)
}

export const selectImportOfferTimelineData = state => {
  const offerDetails = selectImportOfferDetails(state)
  return normalizeImportOfferTimelineData(offerDetails)
}

export const selectFreeCarrierOfferTimelineData = state => {
  const offerDetails = selectFreeCarrierOfferDetails(state)
  return normalizeFreeCarrierOfferTimelineData(offerDetails)
}

export const selectPostImportOfferTimelineData = state => {
  const offerDetails = selectPostImportOfferDetails(state)
  return normalizePostImportOfferTimelineData(offerDetails)
}

export const selectExportFiltersFormatted = state => {
  const filters = selectExportFilters(state)
  const omittedValues = ['sort', 'page', 'limit', 'tab_name', 'tab_id']

  return pipe(
    evolve({
      loadingLocation: joinLocationsIdsWithRange,
      returnLocation: joinLocationsIdsWithRange,
      company: pluck('id')
    }),
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectImportFiltersFormatted = state => {
  const filters = selectImportFilters(state)
  const omittedValues = ['sort', 'page', 'limit', 'tab_name', 'tab_id']

  return pipe(
    evolve({
      pickupLocation: joinLocationsIdsWithRange,
      unloadingLocation: joinLocationsIdsWithRange,
      company: pluck('id')
    }),
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectPostImportFiltersFormatted = state => {
  const filters = selectPostImportFilters(state)
  const omittedValues = ['sort', 'page', 'limit', 'tab_name', 'tab_id']

  return pipe(
    evolve({
      availabilityLocation: joinLocationsIdsWithRange,
      destinationLocation: joinLocationsIdsWithRange,
      company: pluck('id')
    }),
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectFreeCarrierFiltersFormatted = state => {
  const filters = selectFreeCarrierFilters(state)
  const omittedValues = ['sort', 'page', 'limit', 'tab_name', 'tab_id']

  return pipe(
    evolve({
      availabilityLocation: joinLocationsIdsWithRange,
      destinationLocation: joinLocationsIdsWithRange,
      company: pluck('id')
    }),
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

const selectActiveNumber = filters =>
  pipe(
    omit(['sort', 'page', 'limit', 'tabName']),
    reject(isNil),
    reject(isEmptyArray),
    keys,
    map(key => (isArray(filters[key]) ? filters[key].length : 1)),
    sum
  )(filters)

export const selectExportFiltersActiveNumber = state => {
  const filters = selectExportFilters(state).toJS()
  return selectActiveNumber(filters)
}

export const selectImportFiltersActiveNumber = state => {
  const filters = selectImportFilters(state).toJS()
  return selectActiveNumber(filters)
}

export const selectFreeCarriersFiltersActiveNumber = state => {
  const filters = selectFreeCarrierFilters(state).toJS()
  return selectActiveNumber(filters)
}

export const selectPostImportFiltersActiveNumber = state => {
  const filters = selectPostImportFilters(state).toJS()
  return selectActiveNumber(filters)
}

export const selectExportFiltersTabsList = state => state.stockExport.tabs

export const selectImportFiltersTabsList = state => state.stockImport.tabs

export const selectFreeCarriersFiltersTabsList = state =>
  state.stockFreeCarriers.tabs

export const selectPostImportFiltersTabsList = state =>
  state.stockPostImport.tabs

export const selectExportActiveTab = state => state.stockExport.activeTab

export const selectImportActiveTab = state => state.stockImport.activeTab

export const selectFreeCarriersActiveTab = state =>
  state.stockFreeCarriers.activeTab

export const selectPostImportActiveTab = state =>
  state.stockPostImport.activeTab

export const selectExportTabsData = state => state.stockExport.tabsData

export const selectImportTabsData = state => state.stockImport.tabsData

export const selectPostImportTabsData = state => state.stockPostImport.tabsData

export const selectFreeCarriersTabsData = state =>
  state.stockFreeCarriers.tabsData

export const selectExportInfoBoxOffersNumber = state =>
  state.stockExport.infoBoxOffersNumber

export const selectImportInfoBoxOffersNumber = state =>
  state.stockImport.infoBoxOffersNumber

export const selectPostImportInfoBoxOffersNumber = state =>
  state.stockPostImport.infoBoxOffersNumber

export const selectFreeCarriersInfoBoxOffersNumber = state =>
  state.stockFreeCarriers.infoBoxOffersNumber
