import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, select, takeLatest } from '@redux-saga/core/effects'
import CompanyService from 'src/services/CompanyService'
import {
  selectCorrectiveInvoiceAddForm,
  selectInvoiceAddForm,
  selectInvoiceSettings,
  selectInvoicesIssuedFiltersFormatted,
  selectInvoicesReceivedFiltersFormatted
} from 'src/features/invoices/duck/selectors'
import { drop, evolve, map, pick, pipe, prop } from 'ramda'
import {
  displayResponseErrorMessage,
  filterNullValues
} from 'src/utils/helpers'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import InvoiceService from 'src/services/InvoiceService'
import {
  normalizeCorrectiveInvoiceForSave,
  normalizeInvoiceForOrder,
  normalizeInvoiceForSave,
  normalizeInvoiceItemForSave,
  normalizeMarkAsPaidValues
} from 'src/features/invoices/duck/normalizers'
import { navigate } from 'gatsby-plugin-intl'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import FileDownload from 'js-file-download'
import { getFilenameFromHeaders } from 'src/features/invoices/duck/helpers'

export const showMyCompanyInvoiceSettingsRoutine = createRoutine(
  'SHOW_MY_COMPANY_INVOICE_SETTINGS'
)

export const getInvoicesListIssuedRoutine = createRoutine(
  'GET_INVOICES_LIST_ISSUED'
)

export const getInvoicesListReceivedRoutine = createRoutine(
  'GET_INVOICES_LIST_RECEIVED'
)

export const setInvoicesIssuedFiltersRoutine = createRoutine(
  'SET_INVOICES_ISSUED_FILTERS'
)

export const setInvoicesReceivedFiltersRoutine = createRoutine(
  'SET_INVOICES_RECEIVED_FILTERS'
)

export const saveMyCompanyInvoiceSettingsRoutine = createRoutine(
  'SAVE_MY_COMPANY_INVOICE_SETTINGS'
)

export const setInvoiceSettingsValueRoutine = createRoutine(
  'SET_INVOICE_SETTINGS_VALUE'
)

export const prepareInvoiceRoutine = createRoutine('PREPARE_INVOICE')

export const setInvoiceEditFormValueRoutine = createRoutine(
  'SET_INVOICE_EDIT_FORM_VALUE'
)

export const createInvoiceRoutine = createRoutine('CREATE_INVOICE')

export const recalculateInvoiceRoutine = createRoutine('RECALCULATE_INVOICE')

export const regenerateInvoiceNumberRoutine = createRoutine(
  'REGENERATE_INVOICE_NUMBER'
)

export const searchForInvoiceRecipientRoutine = createRoutine(
  'SEARCH_FOR_INVOICE_RECIPIENT'
)

export const clearRecipientRoutine = createRoutine('CLEAR_RECIPIENT')

export const setInvoiceMultipleFormValuesRoutine = createRoutine(
  'SET_INVOICE_MULTIPLE_FORM_VALUES'
)

export const previewInvoiceRoutine = createRoutine('PREVIEW_INVOICE')

export const markAsPaidRoutine = createRoutine('MARK_AS_PAID')

export const showInvoiceDetailsRoutine = createRoutine('SHOW_INVOICE_DETAILS')

export const clearInvoiceDetailsRoutine = createRoutine('CLEAR_INVOICE_DETAILS')

export const deletePaymentRoutine = createRoutine('DELETE_PAYMENT')

export const clearRecipientSearchRoutine = createRoutine(
  'CLEAR_RECIPIENT_SEARCH'
)
export const prepareInvoiceForTransportationOrderRoutine = createRoutine(
  'PREPARE_INVOICE_FOR_TRANSPORTATION_ORDER'
)

export const clearAddInvoiceFormRoutine = createRoutine(
  'CLEAR_ADD_INVOICE_FORM'
)

export const createCorrectionNoteRoutine = createRoutine(
  'CREATE_CORRECTION_NOTE'
)

export const showCorrectionNoteRoutine = createRoutine('SHOW_CORRECTION_NOTE')

export const downloadNotePdfRoutine = createRoutine('DOWNLOAD_NOTE_PDF')

export const showCorrectiveInvoiceDetailsRoutine = createRoutine(
  'SHOW_CORRECTIVE_INVOICE_DETAILS'
)

export const clearCorrectiveInvoiceDetailsRoutine = createRoutine(
  'CLEAR_CORRECTIVE_INVOICE_DETAILS'
)

export const clearAddCorrectiveInvoiceFormRoutine = createRoutine(
  'CLEAR_ADD_CORRECTIVE_INVOICE_FORM'
)

export const prepareCorrectiveInvoiceRoutine = createRoutine(
  'PREPARE_CORRECTIVE_INVOICE'
)

export const setCorrectiveInvoiceEditFormValueRoutine = createRoutine(
  'SET_CORRECTIVE_INVOICE_EDIT_FORM_VALUE'
)

export const recalculateCorrectiveInvoiceRoutine = createRoutine(
  'RECALCULATE_CORRECTIVE_INVOICE'
)

export const createCorrectiveInvoiceRoutine = createRoutine(
  'CREATE_CORRECTIVE_INVOICE'
)

export const markCorrectiveAsPaidRoutine = createRoutine(
  'MARK_CORRECTIVE_AS_PAID_ROUTINE'
)

export const previewCorrectiveInvoiceRoutine = createRoutine(
  'PREVIEW_CORRECTIVE_INVOICE'
)

export const downloadCorrectiveInvoiceRoutine = createRoutine(
  'DOWNLOAD_CORRECTIVE_INVOICE'
)

export const deletePaymentFromCorrectiveInvoiceRoutine = createRoutine(
  'DELETE_PAYMENT_FROM_CORRECTIVE_INVOICE'
)

function* deletePaymentFromCorrectiveInvoice({ payload }) {
  yield put(deletePaymentFromCorrectiveInvoiceRoutine.request())
  try {
    const { data } = yield call(
      InvoiceService.deletePaymentFromCorrectiveInvoice,
      payload.invoiceId,
      payload.paymentId
    )
    yield put(deletePaymentFromCorrectiveInvoiceRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.removedSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(deletePaymentFromCorrectiveInvoiceRoutine.failure(err))
  }
}

function* downloadCorrectiveInvoice({ payload }) {
  yield put(downloadInvoicePdfRoutine.request())
  try {
    window.open(
      `${process.env.GATSBY_API_URL}/me/invoices/corrective-invoices/${payload}/pdf`
    )
    yield put(downloadInvoicePdfRoutine.success())
  } catch (err) {
    yield put(downloadInvoicePdfRoutine.failure(err))
  }
}

function* previewCorrectiveInvoice() {
  yield put(previewCorrectiveInvoiceRoutine.request())
  const payload = yield select(selectCorrectiveInvoiceAddForm)
  const payloadFormatted = normalizeCorrectiveInvoiceForSave(payload.toJS())
  try {
    const { data, headers } = yield call(
      InvoiceService.previewCorrectiveInvoice,
      payload.invoiceId,
      payloadFormatted
    )
    const filename = `preview_${getFilenameFromHeaders(headers)}`
    FileDownload(data, filename)
    yield put(previewCorrectiveInvoiceRoutine.success(data))
  } catch (err) {
    yield put(previewCorrectiveInvoiceRoutine.failure(err))
  }
}

function* createCorrectiveInvoice() {
  yield put(createCorrectiveInvoiceRoutine.request())
  const payload = yield select(selectCorrectiveInvoiceAddForm)

  const payloadFormatted = normalizeCorrectiveInvoiceForSave(payload.toJS())

  try {
    const { data } = yield call(
      InvoiceService.createCorrectiveInvoice,
      payload.invoiceId,
      payloadFormatted
    )

    yield put(createCorrectiveInvoiceRoutine.success(data))
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`)
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'invoices.createdSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(createCorrectiveInvoiceRoutine.failure(err))
    yield call(displayResponseErrorMessage, err, 'alert', true)
  }
}

function* recalculateCorrectiveInvoice() {
  yield put(recalculateCorrectiveInvoiceRoutine.request())
  const invoice = yield select(selectCorrectiveInvoiceAddForm)
  const currency = prop('currency', invoice)
  const items = map(normalizeInvoiceItemForSave, invoice.items)
  try {
    const { data } = yield call(
      InvoiceService.recalculateCorrectiveInvoice,
      invoice.invoiceId,
      {
        currency,
        items
      }
    )
    yield put(recalculateCorrectiveInvoiceRoutine.success(data))
  } catch (err) {
    yield put(recalculateCorrectiveInvoiceRoutine.failure(err))
  }
}

function* setCorrectiveInvoiceEditFormValue({ payload }) {
  yield put(setCorrectiveInvoiceEditFormValueRoutine.request())
  try {
    yield put(setCorrectiveInvoiceEditFormValueRoutine.success(payload))
  } catch (err) {
    yield put(setCorrectiveInvoiceEditFormValueRoutine.failure(err))
  }
}
function* prepareCorrectiveInvoice({ payload }) {
  yield put(prepareCorrectiveInvoiceRoutine.request())
  try {
    const { data } = yield call(
      InvoiceService.prepareCorrectiveInvoice,
      payload.id
    )
    yield put(prepareCorrectiveInvoiceRoutine.success(data))
  } catch (err) {
    yield put(prepareCorrectiveInvoiceRoutine.failure(err))
  }
}

function* clearAddCorrectiveInvoiceForm() {
  yield put(clearAddCorrectiveInvoiceFormRoutine.request())
  try {
    yield put(clearAddCorrectiveInvoiceFormRoutine.success())
  } catch (err) {
    yield put(clearAddCorrectiveInvoiceFormRoutine.failure(err))
  }
}

function* clearCorrectiveInvoiceDetails() {
  yield put(clearCorrectiveInvoiceDetailsRoutine.request())
  try {
    yield put(clearCorrectiveInvoiceDetailsRoutine.success())
  } catch (err) {
    yield put(clearCorrectiveInvoiceDetailsRoutine.failure(err))
  }
}

function* showCorrectiveInvoiceDetails({ payload }) {
  yield put(showCorrectiveInvoiceDetailsRoutine.request())
  try {
    const { data } = yield call(
      InvoiceService.showCorrectiveInvoiceDetails,
      payload
    )
    yield put(showCorrectiveInvoiceDetailsRoutine.success(data))
  } catch (err) {
    yield put(showCorrectiveInvoiceDetailsRoutine.failure(err))
  }
}

function* markCorrectiveAsPaid({ payload }) {
  yield put(markCorrectiveAsPaidRoutine.request())
  const values = normalizeMarkAsPaidValues(payload.values)
  try {
    const { data } = yield call(
      InvoiceService.markCorrectiveAsPaid,
      payload.id,
      values
    )
    yield put(markCorrectiveAsPaidRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.updatedSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(markCorrectiveAsPaidRoutine.failure(err))
  }
}

function* downloadNotePdf({ payload }) {
  yield put(downloadNotePdfRoutine.request())
  try {
    window.open(
      `${process.env.GATSBY_API_URL}/me/invoices/corrective-notes/${payload.id}/pdf`
    )
    yield put(downloadNotePdfRoutine.success())
  } catch (err) {
    yield put(downloadNotePdfRoutine.failure(err))
  }
}

function* showCorrectionNote({ payload }) {
  yield put(showCorrectionNoteRoutine.request())
  try {
    const { data } = yield call(InvoiceService.showCorrectionNote, payload.id)
    yield put(showCorrectionNoteRoutine.success(data))
  } catch (err) {
    yield put(showCorrectionNoteRoutine.failure(err))
  }
}

function* createCorrectionNote({ payload }) {
  yield put(createCorrectionNoteRoutine.request())
  try {
    const { data } = yield call(
      InvoiceService.createCorrectionNote,
      payload.invoiceId,
      payload.values
    )
    yield put(createCorrectionNoteRoutine.success(data))
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`)
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'invoices.createdSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(createCorrectionNoteRoutine.failure(err))
  }
}

function* clearAddInvoiceForm() {
  yield put(clearAddInvoiceFormRoutine.request())
  try {
    yield put(clearAddInvoiceFormRoutine.success())
  } catch (err) {
    yield put(clearAddInvoiceFormRoutine.failure(err))
  }
}

function* prepareInvoiceForTransportationOrder({ payload }) {
  yield put(prepareInvoiceForTransportationOrderRoutine.request())
  try {
    const { data } = yield call(
      InvoiceService.prepareInvoiceForTransportationOrder,
      payload.transportationOrderId
    )
    yield put(prepareInvoiceForTransportationOrderRoutine.success(data))
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES_ADD}`)
  } catch (err) {
    yield put(prepareInvoiceForTransportationOrderRoutine.failure(err))
  }
}

function* clearRecipientSearch() {
  yield put(clearRecipientSearchRoutine.request())
  try {
    yield put(clearRecipientSearchRoutine.success())
  } catch (err) {
    yield put(clearRecipientSearchRoutine.failure(err))
  }
}

function* deletePayment({ payload }) {
  yield put(deletePaymentRoutine.request())
  try {
    const { data } = yield call(
      InvoiceService.deletePaymentFromInvoice,
      payload.invoiceId,
      payload.paymentId
    )
    yield put(deletePaymentRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.removedSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(deletePaymentRoutine.failure(err))
  }
}

function* clearInvoiceDetails() {
  yield put(clearInvoiceDetailsRoutine.request())
  try {
    yield put(clearInvoiceDetailsRoutine.success())
  } catch (err) {
    yield put(clearInvoiceDetailsRoutine.failure(err))
  }
}

function* showInvoiceDetails({ payload }) {
  yield put(showInvoiceDetailsRoutine.request())
  try {
    const { data } = yield call(InvoiceService.showInvoiceDetails, payload)
    yield put(showInvoiceDetailsRoutine.success(data))
  } catch (err) {
    yield put(showInvoiceDetailsRoutine.failure(err))
  }
}

function* markAsPaid({ payload }) {
  yield put(markAsPaidRoutine.request())
  const values = normalizeMarkAsPaidValues(payload.values)
  try {
    const { data } = yield call(InvoiceService.markAsPaid, payload.id, values)
    yield put(markAsPaidRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.updatedSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(markAsPaidRoutine.failure(err))
    yield call(displayResponseErrorMessage, err)
  }
}

function* previewInvoice() {
  yield put(previewInvoiceRoutine.request())
  const payload = yield select(selectInvoiceAddForm)
  const payloadFormatted = normalizeInvoiceForSave(payload.toJS())
  try {
    const { data, headers } = yield call(
      InvoiceService.previewInvoice,
      payloadFormatted
    )
    const filename = `preview_${getFilenameFromHeaders(headers)}`
    FileDownload(data, filename)
    yield put(previewInvoiceRoutine.success(data))
  } catch (err) {
    yield put(previewInvoiceRoutine.failure(err))
  }
}

export const downloadInvoicePdfRoutine = createRoutine('DOWNLOAD_INVOICE_PDF')

function* setInvoiceMultipleFormValues({ payload }) {
  yield put(setInvoiceMultipleFormValuesRoutine.success(payload))
}

function* clearRecipient() {
  yield put(clearRecipientRoutine.success())
}

function* searchForInvoiceRecipient({ payload }) {
  yield put(searchForInvoiceRecipientRoutine.request())
  try {
    const { data } = yield call(
      InvoiceService.searchForInvoiceRecipient,
      payload
    )
    yield put(searchForInvoiceRecipientRoutine.success(data))
  } catch (err) {
    yield put(searchForInvoiceRecipientRoutine.failure(err))
  }
}

function* regenerateInvoiceNumber() {
  yield put(regenerateInvoiceNumberRoutine.request())
  try {
    const invoice = yield select(selectInvoiceAddForm)
    const payload = pipe(
      pick(['numberingScheme', 'numberPostfix']),
      evolve({ numberPostfix: drop(1) })
    )(invoice)
    const { data } = yield call(InvoiceService.regenerateInvoiceNumber, payload)
    yield put(regenerateInvoiceNumberRoutine.success(data))
  } catch (err) {
    yield put(regenerateInvoiceNumberRoutine.failure(err))
  }
}

function* recalculateInvoice() {
  yield put(recalculateInvoiceRoutine.request())
  const invoice = yield select(selectInvoiceAddForm)
  const currency = prop('currency', invoice)
  const items = map(normalizeInvoiceItemForSave, invoice.items)
  try {
    const { data } = yield call(InvoiceService.recalculateInvoice, {
      currency,
      items
    })
    yield put(recalculateInvoiceRoutine.success(data))
  } catch (err) {
    yield put(recalculateInvoiceRoutine.failure(err))
  }
}

function* createInvoice() {
  yield put(createInvoiceRoutine.request())
  const payload = yield select(selectInvoiceAddForm)
  const invoiceForOrder = !!payload.transportationOrderId

  const payloadFormatted = invoiceForOrder
    ? normalizeInvoiceForOrder(payload.toJS())
    : normalizeInvoiceForSave(payload.toJS())

  const endpoint = invoiceForOrder
    ? InvoiceService.createInvoiceForTransportationOrder
    : InvoiceService.createInvoice
  try {
    const { data } = yield call(endpoint, payloadFormatted)

    yield put(createInvoiceRoutine.success(data))
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.INVOICES}`)
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'invoices.createdSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(createInvoiceRoutine.failure(err))
    yield call(displayResponseErrorMessage, err, 'alert', true)
  }
}

function* setInvoiceEditFormValue({ payload }) {
  yield put(setInvoiceEditFormValueRoutine.request())
  try {
    yield put(setInvoiceEditFormValueRoutine.success(payload))
    payload.onSuccess && payload.onSuccess()
  } catch (err) {
    yield put(setInvoiceEditFormValueRoutine.failure(err))
  }
}

function* prepareInvoice() {
  yield put(prepareInvoiceRoutine.request())
  try {
    const { data } = yield call(InvoiceService.prepareInvoice)
    yield put(prepareInvoiceRoutine.success(data))
  } catch (err) {
    yield put(prepareInvoiceRoutine.failure(err))
  }
}

function* setInvoiceSettingsValue({ payload }) {
  yield put(setInvoiceSettingsValueRoutine.request())
  try {
    yield put(setInvoiceSettingsValueRoutine.success(payload))
  } catch (err) {
    yield put(setInvoiceSettingsValueRoutine.failure(err))
  }
}

function* saveMyCompanyInvoiceSettings({ payload }) {
  yield put(saveMyCompanyInvoiceSettingsRoutine.request())
  const settings = yield select(selectInvoiceSettings)
  const values = pipe(
    pick([
      'issuePlace',
      'numberingScheme',
      'paymentBankAccount',
      'paymentBankName'
    ]),
    filterNullValues
  )(settings)
  try {
    const { data } = yield call(
      CompanyService.updateMyCompanyInvoiceSettings,
      values
    )
    yield put(saveMyCompanyInvoiceSettingsRoutine.success(data))
    payload.meta?.onSuccess()
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'invoices.savedSuccessfully'
        })
      }
    })
  } catch (err) {
    yield put(saveMyCompanyInvoiceSettingsRoutine.failure(err))
  }
}

function* setInvoicesReceivedFilters({ payload }) {
  yield put(setInvoicesReceivedFiltersRoutine.request())
  try {
    yield put(setInvoicesReceivedFiltersRoutine.success(payload))
  } catch (err) {
    yield put(setInvoicesReceivedFiltersRoutine.failure(err))
  }
}

function* setInvoicesIssuedFilters({ payload }) {
  yield put(setInvoicesIssuedFiltersRoutine.request())
  try {
    yield put(setInvoicesIssuedFiltersRoutine.success(payload))
  } catch (err) {
    yield put(setInvoicesIssuedFiltersRoutine.failure(err))
  }
}

function* getInvoicesListReceived() {
  yield put(getInvoicesListReceivedRoutine.request())
  try {
    const filters = yield select(selectInvoicesReceivedFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(InvoiceService.showReceivedInvoices, filters)
    yield put(getInvoicesListReceivedRoutine.success({ data, meta }))
  } catch (err) {
    yield put(getInvoicesListReceivedRoutine.failure(err))
  }
}

function* getInvoicesListIssued() {
  yield put(getInvoicesListIssuedRoutine.request())
  try {
    const filters = yield select(selectInvoicesIssuedFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(InvoiceService.showIssuedInvoices, filters)
    yield put(getInvoicesListIssuedRoutine.success({ data, meta }))
  } catch (err) {
    yield put(getInvoicesListIssuedRoutine.failure(err))
  }
}

function* showMyCompanyInvoiceSettings() {
  yield put(showMyCompanyInvoiceSettingsRoutine.request())
  try {
    const { data } = yield call(CompanyService.getMyCompanyInvoiceSettings)
    yield put(showMyCompanyInvoiceSettingsRoutine.success(data))
  } catch (err) {
    yield put(showMyCompanyInvoiceSettingsRoutine.failure(err))
  }
}

function* downloadInvoicePdf({ payload }) {
  yield put(downloadInvoicePdfRoutine.request())
  try {
    window.open(`${process.env.GATSBY_API_URL}/me/invoices/${payload}/pdf`)
    yield put(downloadInvoicePdfRoutine.success())
  } catch (err) {
    yield put(downloadInvoicePdfRoutine.failure(err))
  }
}

export function* showMyCompanyInvoiceSettingsRoutineWatcher() {
  yield takeLatest(
    showMyCompanyInvoiceSettingsRoutine.TRIGGER,
    showMyCompanyInvoiceSettings
  )
}

export function* getInvoicesListIssuedRoutineWatcher() {
  yield takeLatest(
    [
      getInvoicesListIssuedRoutine.TRIGGER,
      markAsPaidRoutine.SUCCESS,
      markCorrectiveAsPaidRoutine.SUCCESS,
      setInvoicesIssuedFiltersRoutine.SUCCESS
    ],
    getInvoicesListIssued
  )
}

export function* getInvoicesListReceivedRoutineWatcher() {
  yield takeLatest(
    [
      getInvoicesListReceivedRoutine.TRIGGER,
      setInvoicesReceivedFiltersRoutine.SUCCESS
    ],
    getInvoicesListReceived
  )
}

export function* setInvoicesIssuedFiltersRoutineWatcher() {
  yield takeLatest(
    setInvoicesIssuedFiltersRoutine.TRIGGER,
    setInvoicesIssuedFilters
  )
}

export function* setInvoicesReceivedFiltersRoutineWatcher() {
  yield takeLatest(
    setInvoicesReceivedFiltersRoutine.TRIGGER,
    setInvoicesReceivedFilters
  )
}

export function* saveMyCompanyInvoiceSettingsRoutineWatcher() {
  yield takeLatest(
    saveMyCompanyInvoiceSettingsRoutine.TRIGGER,
    saveMyCompanyInvoiceSettings
  )
}

export function* setInvoiceSettingsValueRoutineWatcher() {
  yield takeLatest(
    setInvoiceSettingsValueRoutine.TRIGGER,
    setInvoiceSettingsValue
  )
}

export function* prepareInvoiceRoutineWatcher() {
  yield takeLatest(prepareInvoiceRoutine.TRIGGER, prepareInvoice)
}

export function* setInvoiceEditFormValueRoutineWatcher() {
  yield takeLatest(
    setInvoiceEditFormValueRoutine.TRIGGER,
    setInvoiceEditFormValue
  )
}

export function* createInvoiceRoutineWatcher() {
  yield takeLatest(createInvoiceRoutine.TRIGGER, createInvoice)
}

export function* recalculateInvoiceRoutineWatcher() {
  yield takeLatest(recalculateInvoiceRoutine.TRIGGER, recalculateInvoice)
}

export function* regenerateInvoiceNumberRoutineWatcher() {
  yield takeLatest(
    regenerateInvoiceNumberRoutine.TRIGGER,
    regenerateInvoiceNumber
  )
}

export function* searchForInvoiceRecipientRoutineWatcher() {
  yield takeLatest(
    searchForInvoiceRecipientRoutine.TRIGGER,
    searchForInvoiceRecipient
  )
}

export function* clearRecipientRoutineWatcher() {
  yield takeLatest(clearRecipientRoutine.TRIGGER, clearRecipient)
}

export function* setInvoiceMultipleFormValuesRoutineWatcher() {
  yield takeLatest(
    setInvoiceMultipleFormValuesRoutine.TRIGGER,
    setInvoiceMultipleFormValues
  )
}

export function* previewInvoiceRoutineWatcher() {
  yield takeLatest(previewInvoiceRoutine.TRIGGER, previewInvoice)
}

export function* downloadInvoicePdfRoutineWatcher() {
  yield takeLatest(downloadInvoicePdfRoutine.TRIGGER, downloadInvoicePdf)
}

export function* markAsPaidRoutineWatcher() {
  yield takeLatest(markAsPaidRoutine.TRIGGER, markAsPaid)
}

export function* showInvoiceDetailsRoutineWatcher() {
  yield takeLatest(showInvoiceDetailsRoutine.TRIGGER, showInvoiceDetails)
}

export function* clearInvoiceDetailsRoutineWatcher() {
  yield takeLatest(clearInvoiceDetailsRoutine.TRIGGER, clearInvoiceDetails)
}

export function* deletePaymentRoutineWatcher() {
  yield takeLatest(deletePaymentRoutine.TRIGGER, deletePayment)
}

export function* clearRecipientSearchRoutineWatcher() {
  yield takeLatest(clearRecipientSearchRoutine.TRIGGER, clearRecipientSearch)
}

export function* prepareInvoiceForTransportationOrderRoutineWatcher() {
  yield takeLatest(
    prepareInvoiceForTransportationOrderRoutine.TRIGGER,
    prepareInvoiceForTransportationOrder
  )
}

export function* clearAddInvoiceFormRoutineWatcher() {
  yield takeLatest(clearAddInvoiceFormRoutine.TRIGGER, clearAddInvoiceForm)
}

export function* createCorrectionNoteRoutineWatcher() {
  yield takeLatest(createCorrectionNoteRoutine.TRIGGER, createCorrectionNote)
}

export function* showCorrectionNoteRoutineWatcher() {
  yield takeLatest(showCorrectionNoteRoutine.TRIGGER, showCorrectionNote)
}

export function* downloadNotePdfRoutineWatcher() {
  yield takeLatest(downloadNotePdfRoutine.TRIGGER, downloadNotePdf)
}

export function* markCorrectiveAsPaidRoutineWatcher() {
  yield takeLatest(markCorrectiveAsPaidRoutine.TRIGGER, markCorrectiveAsPaid)
}

export function* showCorrectiveInvoiceDetailsRoutineWatcher() {
  yield takeLatest(
    showCorrectiveInvoiceDetailsRoutine.TRIGGER,
    showCorrectiveInvoiceDetails
  )
}

export function* clearCorrectiveInvoiceDetailsRoutineWatcher() {
  yield takeLatest(
    clearCorrectiveInvoiceDetailsRoutine.TRIGGER,
    clearCorrectiveInvoiceDetails
  )
}

export function* clearAddCorrectiveInvoiceFormRoutineWatcher() {
  yield takeLatest(
    clearAddCorrectiveInvoiceFormRoutine.TRIGGER,
    clearAddCorrectiveInvoiceForm
  )
}

export function* prepareCorrectiveInvoiceRoutineWatcher() {
  yield takeLatest(
    prepareCorrectiveInvoiceRoutine.TRIGGER,
    prepareCorrectiveInvoice
  )
}

export function* setCorrectiveInvoiceEditFormValueRoutineWatcher() {
  yield takeLatest(
    setCorrectiveInvoiceEditFormValueRoutine.TRIGGER,
    setCorrectiveInvoiceEditFormValue
  )
}

export function* recalculateCorrectiveInvoiceRoutineWatcher() {
  yield takeLatest(
    recalculateCorrectiveInvoiceRoutine.TRIGGER,
    recalculateCorrectiveInvoice
  )
}

export function* createCorrectiveInvoiceRoutineWatcher() {
  yield takeLatest(
    createCorrectiveInvoiceRoutine.TRIGGER,
    createCorrectiveInvoice
  )
}

export function* previewCorrectiveInvoiceRoutineWatcher() {
  yield takeLatest(
    previewCorrectiveInvoiceRoutine.TRIGGER,
    previewCorrectiveInvoice
  )
}

export function* downloadCorrectiveInvoiceRoutineWatcher() {
  yield takeLatest(
    downloadCorrectiveInvoiceRoutine.TRIGGER,
    downloadCorrectiveInvoice
  )
}

export function* deletePaymentFromCorrectiveInvoiceRoutineWatcher() {
  yield takeLatest(
    deletePaymentFromCorrectiveInvoiceRoutine.TRIGGER,
    deletePaymentFromCorrectiveInvoice
  )
}

export const invoicesSagas = [
  fork(showMyCompanyInvoiceSettingsRoutineWatcher),
  fork(getInvoicesListIssuedRoutineWatcher),
  fork(getInvoicesListReceivedRoutineWatcher),
  fork(setInvoicesIssuedFiltersRoutineWatcher),
  fork(setInvoicesReceivedFiltersRoutineWatcher),
  fork(saveMyCompanyInvoiceSettingsRoutineWatcher),
  fork(setInvoiceSettingsValueRoutineWatcher),
  fork(prepareInvoiceRoutineWatcher),
  fork(setInvoiceEditFormValueRoutineWatcher),
  fork(createInvoiceRoutineWatcher),
  fork(recalculateInvoiceRoutineWatcher),
  fork(regenerateInvoiceNumberRoutineWatcher),
  fork(searchForInvoiceRecipientRoutineWatcher),
  fork(clearRecipientRoutineWatcher),
  fork(setInvoiceMultipleFormValuesRoutineWatcher),
  fork(previewInvoiceRoutineWatcher),
  fork(downloadInvoicePdfRoutineWatcher),
  fork(markAsPaidRoutineWatcher),
  fork(showInvoiceDetailsRoutineWatcher),
  fork(clearInvoiceDetailsRoutineWatcher),
  fork(deletePaymentRoutineWatcher),
  fork(clearRecipientSearchRoutineWatcher),
  fork(prepareInvoiceForTransportationOrderRoutineWatcher),
  fork(clearAddInvoiceFormRoutineWatcher),
  fork(createCorrectionNoteRoutineWatcher),
  fork(showCorrectionNoteRoutineWatcher),
  fork(downloadNotePdfRoutineWatcher),
  fork(createCorrectiveInvoiceRoutineWatcher),
  fork(recalculateCorrectiveInvoiceRoutineWatcher),
  fork(setCorrectiveInvoiceEditFormValueRoutineWatcher),
  fork(prepareCorrectiveInvoiceRoutineWatcher),
  fork(clearAddCorrectiveInvoiceFormRoutineWatcher),
  fork(clearCorrectiveInvoiceDetailsRoutineWatcher),
  fork(showCorrectiveInvoiceDetailsRoutineWatcher),
  fork(markCorrectiveAsPaidRoutineWatcher),
  fork(previewCorrectiveInvoiceRoutineWatcher),
  fork(downloadCorrectiveInvoiceRoutineWatcher),
  fork(deletePaymentFromCorrectiveInvoiceRoutineWatcher)
]
