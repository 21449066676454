import {
  call,
  put,
  select,
  takeLatest,
  fork,
  takeEvery
} from '@redux-saga/core/effects'
import { map, prop } from 'ramda'
import { createRoutine } from 'redux-saga-routines'
import { navigate } from 'gatsby-plugin-intl'
import {
  selectMyFinishedOffersImportFiltersFormatted,
  selectMyOffersImportFiltersFormatted,
  selectMyOngoingImportOffers,
  selectSelectedImportOffers
} from 'src/features/myOffers/duck/selectors'
import OfferService from 'src/services/OfferService'
import ExchangeService from 'src/services/ExchangeService'
import {
  refreshImportOfferRoutine,
  makeImportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-import'
import { fillFormUsingTemplateImport } from 'src/features/offers/duck/actions'
import { normalizeToOfferTemplateImport } from 'src/features/offers/duck/normalizers'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import {
  acceptImportReservationRoutine,
  rejectImportReservationRoutine
} from 'src/features/reservations/duck/actions'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import TrackingService from 'src/services/TrackingService'

export const getMyOngoingImportOffersRoutine = createRoutine(
  'GET_MY_ONGOING_IMPORT_OFFERS'
)
export const getMyFinishedImportOffersRoutine = createRoutine(
  'GET_MY_FINISHED_IMPORT_OFFERS'
)
export const setMyImportFiltersRoutine = createRoutine('SET_MY_IMPORT_FILTERS')
export const setMyFinishedImportFiltersRoutine = createRoutine(
  'SET_MY_FINISHED_IMPORT_FILTERS'
)
export const setSelectedImportOfferRoutine = createRoutine(
  'SELECT_IMPORT_OFFER'
)
export const selectAllImportOffersRoutine = createRoutine(
  'SELECT_ALL_IMPORT_OFFERS'
)
export const clearSelectedImportOffersRoutine = createRoutine(
  'CLEAR_SELECTED_IMPORT_OFFERS'
)
export const deleteMultipleImportOffersRoutine = createRoutine(
  'DELETE_MULTIPLE_IMPORT_OFFERS'
)
export const deleteImportOfferRoutine = createRoutine('DELETE_IMPORT_OFFER')
export const restoreImportOfferRoutine = createRoutine('RESTORE_IMPORT_OFFER')
export const showOngoingImportOfferReservationsRoutine = createRoutine(
  'SHOW_ONGOING_IMPORT_OFFER_RESERVATIONS'
)
export const showFinishedImportOfferReservationsRoutine = createRoutine(
  'SHOW_FINISHED_IMPORT_OFFER_RESERVATIONS'
)
export const copyMyOfferToImportFormRoutine = createRoutine(
  'COPY_MY_OFFER_TO_IMPORT_FORM'
)

export const createImportOfferTrackingRoutine = createRoutine(
  'CREATE_IMPORT_OFFER_TRACKING'
)

//ACTIONS

export function* getMyOngoingImportOffers() {
  yield put(getMyOngoingImportOffersRoutine.request())
  try {
    const filters = yield select(selectMyOffersImportFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyOngoingImportOffers, filters)
    yield put(getMyOngoingImportOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
  }
}

function* getMyFinishedImportOffers() {
  yield put(getMyFinishedImportOffersRoutine.request())
  try {
    const filters = yield select(selectMyFinishedOffersImportFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyFinishedImportOffers, filters)
    yield put(getMyFinishedImportOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
  }
}

function* showOngoingImportOfferReservations({ payload }) {
  yield put(showOngoingImportOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      OfferService.showOngoingImportOfferReservations,
      payload.id
    )
    yield put(showOngoingImportOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* showFinishedImportOfferReservations({ payload }) {
  yield put(showFinishedImportOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      OfferService.showFinishedImportOfferReservations,
      payload.id
    )
    yield put(showFinishedImportOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* setMyImportFilters({ payload }) {
  yield put(setMyImportFiltersRoutine.success(payload))
}

function* setMyFinishedImportFilters({ payload }) {
  yield put(setMyFinishedImportFiltersRoutine.success(payload))
}

function* setSelectedOffer({ payload }) {
  yield put(setSelectedImportOfferRoutine.success(payload))
}

function* selectAllOffers() {
  const offers = yield select(selectMyOngoingImportOffers)
  const ids = map(prop('id'), offers)
  yield put(selectAllImportOffersRoutine.success({ ids }))
}

function* clearSelectedOffers() {
  yield put(clearSelectedImportOffersRoutine.success())
}

function* deleteMultipleImportOffers() {
  yield put(deleteMultipleImportOffersRoutine.request())
  try {
    const ids = yield select(selectSelectedImportOffers)
    yield call(OfferService.deleteMultipleImportOffers, { ids })
    yield put(deleteMultipleImportOffersRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOffersSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deleteMultipleImportOffersRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* deleteImportOffer({ payload }) {
  yield put(deleteImportOfferRoutine.request())
  try {
    yield call(OfferService.deleteImportOffer, payload.id, {
      reason: payload.reason
    })
    yield put(deleteImportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOneOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deleteImportOfferRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* restoreImportOffer({ payload }) {
  yield put(restoreImportOfferRoutine.request())
  try {
    yield call(OfferService.restoreImportOffer, payload.id)
    yield put(restoreImportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.restoreOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(restoreImportOfferRoutine.failure())
    console.log(e)
  }
}

function* copyMyOfferToImportForm({ payload }) {
  const { data } = yield call(ExchangeService.showImportOffer, {
    id: payload.offer.id
  })
  const normalized = normalizeToOfferTemplateImport(data)

  yield call(fillFormUsingTemplateImport, { payload: { template: normalized } })
  navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_IMPORT}`)
}

export function* createImportOfferTracking({ payload }) {
  yield put(createImportOfferTrackingRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.createImportOfferTracking,
      payload.id
    )
    yield put(createImportOfferTrackingRoutine.success(data))
  } catch (e) {
    yield put(createImportOfferTrackingRoutine.fulfill())
  }
}

//WATCHERS

function* getMyOngoingImportOffersRoutineWatcher() {
  yield takeLatest(
    [
      setMyImportFiltersRoutine.SUCCESS,
      getMyOngoingImportOffersRoutine.TRIGGER,
      deleteMultipleImportOffersRoutine.SUCCESS,
      deleteImportOfferRoutine.SUCCESS,
      refreshImportOfferRoutine.SUCCESS,
      makeImportOfferPublicRoutine.SUCCESS,
      restoreImportOfferRoutine.SUCCESS,
      rejectImportReservationRoutine.SUCCESS
    ],
    getMyOngoingImportOffers
  )
}

function* getMyFinishedImportOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyFinishedImportOffersRoutine.TRIGGER,
      setMyFinishedImportFiltersRoutine.SUCCESS,
      acceptImportReservationRoutine.SUCCESS,
      rejectImportReservationRoutine.SUCCESS,
      restoreImportOfferRoutine.SUCCESS,
      deleteImportOfferRoutine.SUCCESS
    ],
    getMyFinishedImportOffers
  )
}

function* setMyImportFiltersRoutineWatcher() {
  yield takeLatest(setMyImportFiltersRoutine.TRIGGER, setMyImportFilters)
}

function* setMyFinishedImportFiltersRoutineWatcher() {
  yield takeLatest(
    setMyFinishedImportFiltersRoutine.TRIGGER,
    setMyFinishedImportFilters
  )
}

function* setSelectedOfferRoutineWatcher() {
  yield takeLatest(setSelectedImportOfferRoutine.TRIGGER, setSelectedOffer)
}

function* selectAllOffersRoutineWatcher() {
  yield takeLatest(selectAllImportOffersRoutine.TRIGGER, selectAllOffers)
}
function* clearSelectedOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyOngoingImportOffersRoutine.SUCCESS,
      clearSelectedImportOffersRoutine.TRIGGER
    ],
    clearSelectedOffers
  )
}

function* deleteMultipleImportOffersRoutineWatcher() {
  yield takeLatest(
    deleteMultipleImportOffersRoutine.TRIGGER,
    deleteMultipleImportOffers
  )
}

function* deleteImportOfferRoutineWatcher() {
  yield takeLatest(deleteImportOfferRoutine.TRIGGER, deleteImportOffer)
}

function* restoreImportOfferRoutineWatcher() {
  yield takeLatest(restoreImportOfferRoutine.TRIGGER, restoreImportOffer)
}

function* showOngoingImportOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showOngoingImportOfferReservationsRoutine.TRIGGER,
    showOngoingImportOfferReservations
  )
}

function* showFinishedImportOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showFinishedImportOfferReservationsRoutine.TRIGGER,
    showFinishedImportOfferReservations
  )
}

function* copyMyOfferToImportFormRoutineWatcher() {
  yield takeLatest(
    copyMyOfferToImportFormRoutine.TRIGGER,
    copyMyOfferToImportForm
  )
}

function* createImportOfferTrackingRoutineWatcher() {
  yield takeLatest(
    createImportOfferTrackingRoutine.TRIGGER,
    createImportOfferTracking
  )
}

export const myImportOffersSagas = [
  fork(getMyOngoingImportOffersRoutineWatcher),
  fork(setMyImportFiltersRoutineWatcher),
  fork(setSelectedOfferRoutineWatcher),
  fork(deleteMultipleImportOffersRoutineWatcher),
  fork(selectAllOffersRoutineWatcher),
  fork(clearSelectedOffersRoutineWatcher),
  fork(deleteImportOfferRoutineWatcher),
  fork(getMyFinishedImportOffersRoutineWatcher),
  fork(setMyFinishedImportFiltersRoutineWatcher),
  fork(restoreImportOfferRoutineWatcher),
  fork(showOngoingImportOfferReservationsRoutineWatcher),
  fork(copyMyOfferToImportFormRoutineWatcher),
  fork(showFinishedImportOfferReservationsRoutineWatcher),
  fork(createImportOfferTrackingRoutineWatcher)
]
