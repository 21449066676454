import { Company } from 'src/features/companies/duck/records'
import { REQUIRED_DOCUMENTS_TYPES } from 'src/features/account/duck/consts'
import { concat, filter, includes, map, not, pipe, prop } from 'ramda'
import { Image } from 'src/features/account/duck/records'

export const normalizeCompanies = companies =>
  companies.map(company => new Company(company))

export const normalizeCompany = obj => {
  const requiredDocuments = REQUIRED_DOCUMENTS_TYPES[obj.businessProfile]
  const currentTypes = map(prop(['type']), obj.documents)

  const documents = pipe(
    filter(a => not(includes(a, currentTypes))),
    map(doc => ({
      type: doc,
      url: null,
      validFrom: null,
      validTo: null
    })),
    concat(obj.documents)
  )(requiredDocuments)

  return new Company({
    ...obj,
    logo: Image({ ...obj.logo }),
    documents
  })
}
