import { combineReducers } from 'redux'
import {
  OrderDetails,
  OrderEdit,
  Orders,
  OrdersFilters
} from 'src/features/orders/duck/records'
import {
  prepareOrderRoutine,
  setOrderEditFormValueRoutine,
  createOrderRoutine,
  clearEditOrderFormRoutine,
  getOrdersListIssuedRoutine,
  setOrdersIssuedFiltersRoutine,
  getOrdersListReceivedRoutine,
  setOrdersReceivedFiltersRoutine,
  getOrderDetailsRoutine,
  acceptOrderRoutine,
  rejectOrderRoutine,
  downloadOrderPdfRoutine,
  clearOrderDetailsRoutine,
  prefillOrderFormRoutine
} from 'src/features/orders/duck/actions'
import {
  normalizeOrderDetails,
  normalizeOrderEditForm,
  normalizeOrdersList
} from 'src/features/orders/duck/normalizers'
import { API_STATES } from 'src/ducks/consts'
import { Pagination } from 'src/ducks/records'

const ordersIssuedReducer = (state = new Orders(), action) => {
  switch (action.type) {
    case getOrdersListIssuedRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getOrdersListIssuedRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getOrdersListIssuedRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getOrdersListIssuedRoutine.SUCCESS:
      return state
        .set('orders', normalizeOrdersList(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setOrdersIssuedFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new OrdersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const ordersReceivedReducer = (state = new Orders(), action) => {
  switch (action.type) {
    case getOrdersListReceivedRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getOrdersListReceivedRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getOrdersListReceivedRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getOrdersListReceivedRoutine.SUCCESS:
      return state
        .set('orders', normalizeOrdersList(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setOrdersReceivedFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new OrdersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const orderDetailsReducer = (state = new OrderDetails(), action) => {
  switch (action.type) {
    case getOrderDetailsRoutine.REQUEST:
    case downloadOrderPdfRoutine.REQUEST:
    case acceptOrderRoutine.REQUEST:
    case rejectOrderRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getOrderDetailsRoutine.FAILURE:
    case downloadOrderPdfRoutine.FAILURE:
    case acceptOrderRoutine.FAILURE:
    case rejectOrderRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case getOrderDetailsRoutine.FULFILL:
    case downloadOrderPdfRoutine.FULFILL:
    case acceptOrderRoutine.FULFILL:
    case rejectOrderRoutine.FULFILL:
    case downloadOrderPdfRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE)
    case getOrderDetailsRoutine.SUCCESS:
    case prepareOrderRoutine.SUCCESS:
    case acceptOrderRoutine.SUCCESS:
    case rejectOrderRoutine.SUCCESS:
    case prefillOrderFormRoutine.SUCCESS:
      return normalizeOrderDetails(action.payload).set('state', API_STATES.DONE)
    case clearEditOrderFormRoutine.SUCCESS:
    case clearOrderDetailsRoutine.SUCCESS:
      return new OrderDetails()
    default:
      return state
  }
}

const orderEditReducer = (state = new OrderEdit(), action) => {
  switch (action.type) {
    case createOrderRoutine.REQUEST:
    case prepareOrderRoutine.REQUEST:
    case prefillOrderFormRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createOrderRoutine.FAILURE:
    case prepareOrderRoutine.FAILURE:
    case prefillOrderFormRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case createOrderRoutine.FULFILL:
    case prepareOrderRoutine.FULFILL:
    case prefillOrderFormRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case createOrderRoutine.SUCCESS:
    case prefillOrderFormRoutine.SUCCESS:
      return normalizeOrderEditForm(action.payload).set(
        'state',
        API_STATES.DONE
      )
    case clearEditOrderFormRoutine.SUCCESS:
      return new OrderEdit()
    case setOrderEditFormValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case prepareOrderRoutine.SUCCESS:
      return normalizeOrderEditForm(action.payload).set(
        'state',
        API_STATES.DONE
      )
    default:
      return state
  }
}

export const ordersReducer = combineReducers({
  issued: ordersIssuedReducer,
  received: ordersReceivedReducer,
  details: orderDetailsReducer,
  edit: orderEditReducer
})
