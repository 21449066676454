import { API_STATES } from 'src/ducks/consts'
import { Notifications } from './records'
import {
  setNotificationsListOpenedRoutine,
  getUnseenNotificationsCountRoutine,
  getNotificationsListRoutine,
  getStickyNotificationsListRoutine,
  clearNotificationsRoutine,
  markNotificationAsReadRoutine,
  getNotificationsSettingsRoutine,
  updateNotificationSettingRoutine
} from './actions'
import {
  normalizeNotificationsList,
  normalizeNotificationsSettingsList
} from './normalizers'

export const notificationsReducer = (state = new Notifications(), action) => {
  switch (action.type) {
    case setNotificationsListOpenedRoutine.SUCCESS:
      return state.set('listOpened', action.payload.opened)
    case getUnseenNotificationsCountRoutine.SUCCESS:
      return state.set('unreadCount', action.payload.all)
    case getNotificationsListRoutine.REQUEST:
      return state.set('listState', API_STATES.IN_PROGRESS)
    case getNotificationsListRoutine.FAILURE:
    case getNotificationsListRoutine.FULFILL:
      return state.set('listState', API_STATES.DONE)
    case getNotificationsListRoutine.SUCCESS:
      return state
        .update('notifications', list =>
          action.payload.append
            ? list.concat(normalizeNotificationsList(action.payload.data))
            : normalizeNotificationsList(action.payload.data)
        )
        .set(
          'page',
          action.payload.meta.currentPage + 1 > action.payload.meta.lastPage
            ? action.payload.meta.lastPage
            : action.payload.meta.currentPage + 1
        )
        .set(
          'allFetched',
          action.payload.meta.currentPage === action.payload.meta.lastPage
        )
        .set('listState', API_STATES.DONE)
    case getStickyNotificationsListRoutine.SUCCESS:
      return state.set(
        'notificationsSticky',
        normalizeNotificationsList(action.payload)
      )
    case markNotificationAsReadRoutine.SUCCESS:
      return state.update('notifications', list =>
        list.map(n =>
          n.id === action.payload.id ? n.set('readAt', Date.now()) : n
        )
      )
    case getNotificationsSettingsRoutine.REQUEST:
      return state.set('listState', API_STATES.IN_PROGRESS)
    case getNotificationsSettingsRoutine.SUCCESS:
      return state
        .set(
          'settings',
          normalizeNotificationsSettingsList(action.payload.data)
        )
        .set('listState', API_STATES.DONE)
    case getNotificationsSettingsRoutine.FAILURE:
      return state.set('listState', API_STATES.FAILURE)
    case updateNotificationSettingRoutine.REQUEST:
      return state.update('settings', settings =>
        settings.map(s =>
          s.update('values', v =>
            v.map(val =>
              val.code === action.payload.code ? action.payload : val
            )
          )
        )
      )
    case clearNotificationsRoutine.SUCCESS:
      return state.set('page', 1).set('allFetched', false) //reset page to initial one
    default:
      return state
  }
}
