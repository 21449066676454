import { map, pipe, values } from 'ramda'
import translate from 'src/intl/translate'

export const API_STATES = {
  PRISTINE: 'pristine',
  IN_PROGRESS: 'in-progress',
  DONE: 'done',
  ERROR: 'error'
}
const parseToOptions = obj =>
  pipe(
    values,
    map(value => ({ name: value, value }))
  )(obj)

export const parseToOptionsTranslated = (obj, intl, path) =>
  pipe(
    values,
    map(value => ({
      name: intl.formatMessage({ id: `${path}.${value}` }),
      value
    }))
  )(obj)

export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user'
}

export const SUBSCRIPTION_TYPES = {
  CARRIER_BASIC: 'carrier_basic',
  CARRIER_PRO: 'carrier_pro',
  FORWARDER_BASIC: 'forwarder_basic',
  FORWARDER_PRO: 'forwarder_pro',
  CARRIER_FORWARDER_BASIC: 'carrier_forwarder_basic',
  CARRIER_FORWARDER_PRO: 'carrier_forwarder_pro'
}

export const LEAD_SOURCE_OPTIONS = {
  INTERNET_OR_SELF_SEARCH: 'internet_or_self_search',
  FRIEND_RECOMMENDATION: 'friend_recommendation',
  FACEBOOK_OR_LINKEDIN_OR_ADVERT: 'facebook_or_linkedin_or_advert',
  AFFILIATE_PROGRAM: 'affiliate_program',
  OTHER: 'other'
}

export const COMPANY_PROFILES = {
  CARRIER: 'carrier',
  FORWARDER: 'forwarder',
  CARRIER_FORWARDER: 'carrier_forwarder'
}

export const COUNTRIES = {
  AL: 'AL',
  AD: 'AD',
  AT: 'AT',
  BE: 'BE',
  BY: 'BY',
  BA: 'BA',
  BG: 'BG',
  HR: 'HR',
  CY: 'CY',
  ME: 'ME',
  CZ: 'CZ',
  DK: 'DK',
  EE: 'EE',
  FI: 'FI',
  FR: 'FR',
  GI: 'GI',
  GR: 'GR',
  GE: 'GE',
  ES: 'ES',
  NL: 'NL',
  IE: 'IE',
  IS: 'IS',
  KZ: 'KZ',
  LI: 'LI',
  LT: 'LT',
  LU: 'LU',
  LV: 'LV',
  MK: 'MK',
  MT: 'MT',
  MD: 'MD',
  MC: 'MC',
  DE: 'DE',
  NO: 'NO',
  PL: 'PL',
  PT: 'PT',
  RU: 'RU',
  RO: 'RO',
  SM: 'SM',
  RS: 'RS',
  SK: 'SK',
  SI: 'SI',
  CH: 'CH',
  SE: 'SE',
  TR: 'TR',
  UA: 'UA',
  VA: 'VA',
  HU: 'HU',
  GB: 'GB',
  IT: 'IT'
}

export const COUNTRIES_OPTIONS = values(
  map(
    country => ({
      value: country,
      label: translate().formatMessage({
        id: `countries.${country}`
      })
    }),
    COUNTRIES
  )
)

export const BOOLEAN = {
  YES: true,
  NO: false
}

const LANGS = {
  PL: 'pl',
  EN: 'en',
  DE: 'de'
}

//TODO: tp be replaced with translated options
export const LANGS_OPTIONS = parseToOptions(LANGS)

export const COMMUNICATOR_IM = {
  gg: 'gg',
  skype: 'skype',
  tlen: 'tlen',
  ym: 'ym',
  icq: 'icq',
  jabber: 'jabber',
  msn: 'msn',
  gtalk: 'gtalk'
}

export const COMMUNICATOR_IM_OPTIONS = parseToOptions(COMMUNICATOR_IM)

export const OFFERS_DEFAULT_PAGE = '1'

export const OFFERS_DEFAULT_LIMIT = '30'

export const OFFERS_DEFAULT_SORTING = '-added_to_listing_at'

export const ITEMS_PER_PAGE_VARIANTS = [10, 30, 50, 100]

export const DATE_API_FORMAT = 'YYYY-MM-DD'

export const DATE_FORMAT = 'DD.MM.YYYY'

export const DATE_SHORT_FORMAT = 'DD.MM'

export const DATE_SHORT_FORMAT_WITH_TIME = 'DD.MM, HH:mm'

export const DATE_LONG_FORMAT = 'DD.MM.YYYY, H:mm'

export const DATE_LONG_FORMAT_WITH_WEEKDAY = 'dddd, DD.MM.YYYY, H:mm'

export const TIME_FORMAT = 'HH:mm'

export const ERROR_DELAY_TIME = 8000

export const IMPERSONATE_SESSION_KEY = 'impersonate'

export const REQUIRED_DOCUMENT_TYPE = 'carrier_license'

export const LOCATION_TYPES = {
  CITY: ['city'],
  TERMINAL: [
    'sea_terminal',
    'land_terminal',
    'intermodal_terminal',
    'railway_terminal'
  ]
}

export const MAX_IMAGE_SIZE_BYTES = 512000

export const MAX_FILE_SIZE_BYTES = 10485760

export const MAX_FILE_SIZE_BYTES_FOR_TRACKING = 50485760

export const EXCHANGE_TYPES = {
  EXPORT: 'export',
  IMPORT: 'import',
  POST_IMPORT_CONTAINERS: 'post_import_containers',
  FREE_CARRIERS: 'free_carriers',
  CONTRACTS: 'contracts'
}

export const EXCHANGE_EVENT_TYPES = {
  OFFER_CREATED: '.offer.created',
  OFFER_ADDED: '.offer.added',
  OFFER_REFRESHED: '.offer.refreshed',
  OFFER_DELETED: '.offer.deleted'
}

export const EXCHANGE_API_URL_PREFIXES = {
  EXPORT: 'export-cargo',
  IMPORT: 'import-cargo',
  POST_IMPORT_CONTAINERS: 'post-import-containers',
  FREE_CARRIERS: 'free-carriers',
  CONTRACTS: 'contracts'
}

export const OFFERS_TABLE_CLEANER_INTERVAL = 60 * 1000 // 1 minute

export const MAX_CHARACTERS_INPUT_REASON = 200

export const MAX_RESERVATIONS_COUNT_FOR_QUEUE = 3

export const CURRENCIES = {
  PLN: 'PLN',
  GBP: 'GBP',
  EUR: 'EUR'
}

export const MIN_BID = {
  [CURRENCIES.PLN]: 50,
  [CURRENCIES.GBP]: 10,
  [CURRENCIES.EUR]: 10
}

export const MIN_TAXID_LENGTH = 5
