import {
  AdrClass,
  CargoType,
  Container,
  Contractor,
  Currency,
  Location,
  Shipowner
} from 'src/ducks/records'
import {
  AcceptedReservation,
  Contract,
  ContractReservation,
  Transportation
} from 'src/features/contracts/duck/records'
import { DATE_FORMAT } from 'src/ducks/consts'
import moment from 'moment'
import { addPostalCodeToName } from 'src/utils/helpers'

const normalizeTransportation = transportation =>
  new Transportation({
    ...transportation,
    originLocation: Location(
      addPostalCodeToName(transportation.originLocation)
    ),
    destinationLocation: Location(
      addPostalCodeToName(transportation.destinationLocation)
    ),
    returnLocation: Location(
      addPostalCodeToName(transportation.returnLocation)
    ),
    customsClearanceLocation: Location({
      ...transportation.customsClearanceLocation
    }),
    container: Container({ ...transportation.container }),
    shipowner: Shipowner({ ...transportation.shipowner }),
    cargoType: CargoType({ ...transportation.cargoType }),
    adrClass: AdrClass({ ...transportation.adrClass })
  })

export const normalizeContractOfferDetails = offer =>
  new Contract({
    ...offer,
    price: Currency({ ...offer.reservationPrice }),
    issuer: Contractor({ ...offer.issuer }),
    acceptedReservation: AcceptedReservation({ ...offer.acceptedReservation }),
    handler: Contractor({ ...offer.handler }),
    startDateFormatted: moment(offer.startDate).format(DATE_FORMAT),
    endDateFormatted: moment(offer.endDate).format(DATE_FORMAT),
    transportations: offer.transportations.map(transportation =>
      normalizeTransportation(transportation)
    )
  })

export const normalizeContractOffers = arr =>
  arr.map(v => normalizeContractOfferDetails({ ...v }))

export const normalizeContractReservations = arr =>
  arr.map(
    reservation =>
      new ContractReservation({
        ...reservation,
        price: Currency({ ...reservation.price }),
        offer: normalizeContractOfferDetails({ ...reservation.offer }),
        offeror: Contractor({ ...reservation.offeror })
      })
  )
