import { includes, not, pipe, when } from 'ramda'
import { renameKeysWith } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import { formatFilterKey } from 'src/utils/helpers'

//EXPORT ONGOING
export const selectExportOngoingReservations = state =>
  state.myReservations.exportOngoing
export const selectMyExportOngoingReservations = state =>
  state.myReservations.exportOngoing.reservations

export const selectMyReservationsExportOngoingFilters = state =>
  state.myReservations.exportOngoing.filters

export const selectMyReservationsExportOngoingPagination = state =>
  state.myReservations.exportOngoing.pagination

export const selectMyReservationsExportOngoingErrorMessage = state =>
  state.myReservations.exportOngoing.errorMessage

export const selectMyReservationsExportOngoingFiltersFormatted = state => {
  const filters = selectMyReservationsExportOngoingFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//EXPORT FINISHED
export const selectExportFinishedReservations = state =>
  state.myReservations.exportFinished
export const selectMyExportFinishedReservations = state =>
  state.myReservations.exportFinished.reservations

export const selectMyReservationsExportFinishedFilters = state =>
  state.myReservations.exportFinished.filters

export const selectMyReservationsExportFinishedPagination = state =>
  state.myReservations.exportFinished.pagination

export const selectMyReservationsExportFinishedErrorMessage = state =>
  state.myReservations.exportFinished.errorMessage

export const selectMyReservationsExportFinishedFiltersFormatted = state => {
  const filters = selectMyReservationsExportFinishedFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//IMPORT ONGOING
export const selectImportReservationsOngoing = state =>
  state.myReservations.importOngoing
export const selectMyImportOngoingReservations = state =>
  state.myReservations.importOngoing.reservations

export const selectMyReservationsImportOngoingFilters = state =>
  state.myReservations.importOngoing.filters

export const selectMyReservationsImportOngoingPagination = state =>
  state.myReservations.importOngoing.pagination

export const selectMyReservationsImportOngoingErrorMessage = state =>
  state.myReservations.importOngoing.errorMessage

export const selectMyReservationsImportOngoingFiltersFormatted = state => {
  const filters = selectMyReservationsImportOngoingFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//IMPORT FINISHED
export const selectImportFinishedReservations = state =>
  state.myReservations.importFinished
export const selectMyImportFinishedReservations = state =>
  state.myReservations.importFinished.reservations

export const selectMyReservationsImportFinishedFilters = state =>
  state.myReservations.importFinished.filters

export const selectMyReservationsImportFinishedPagination = state =>
  state.myReservations.importFinished.pagination

export const selectMyReservationsImportFinishedErrorMessage = state =>
  state.myReservations.importFinished.errorMessage

export const selectMyReservationsImportFinishedFiltersFormatted = state => {
  const filters = selectMyReservationsImportFinishedFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//POST IMPORT ONGOING
export const selectPostImportOngoingReservations = state =>
  state.myReservations.postImportOngoing

export const selectMyPostImportOngoingReservations = state =>
  state.myReservations.postImportOngoing.reservations

export const selectMyReservationsPostImportOngoingFilters = state =>
  state.myReservations.postImportOngoing.filters

export const selectMyReservationsPostImportOngoingPagination = state =>
  state.myReservations.postImportOngoing.pagination

export const selectMyReservationsPostImportOngoingErrorMessage = state =>
  state.myReservations.postImportOngoing.errorMessage

export const selectMyReservationsPostImportOngoingFiltersFormatted = state => {
  const filters = selectMyReservationsPostImportOngoingFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//POST IMPORT FINISHED
export const selectPostImportFinishedReservations = state =>
  state.myReservations.postImportFinished
export const selectMyPostImportFinishedReservations = state =>
  state.myReservations.postImportFinished.reservations

export const selectMyReservationsPostImportFinishedFilters = state =>
  state.myReservations.postImportFinished.filters

export const selectMyReservationsPostImportFinishedPagination = state =>
  state.myReservations.postImportFinished.pagination

export const selectMyReservationsPostImportFinishedErrorMessage = state =>
  state.myReservations.postImportFinished.errorMessage

export const selectMyReservationsPostImportFinishedFiltersFormatted = state => {
  const filters = selectMyReservationsPostImportFinishedFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//FREE CARRIER ONGOING
export const selectFreeCarrierOngoingReservations = state =>
  state.myReservations.freeCarrierOngoing

export const selectMyFreeCarrierOngoingReservations = state =>
  state.myReservations.freeCarrierOngoing.reservations

export const selectMyReservationsFreeCarrierOngoingFilters = state =>
  state.myReservations.freeCarrierOngoing.filters

export const selectMyReservationsFreeCarrierOngoingPagination = state =>
  state.myReservations.freeCarrierOngoing.pagination

export const selectMyReservationsFreeCarrierOngoingErrorMessage = state =>
  state.myReservations.freeCarrierOngoing.errorMessage

export const selectMyReservationsFreeCarrierOngoingFiltersFormatted = state => {
  const filters = selectMyReservationsFreeCarrierOngoingFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//FREE CARRIER FINISHED
export const selectFreeCarrierFinishedReservationsGeneral = state =>
  state.myReservations.freeCarrierFinished
export const selectFreeCarrierFinishedReservations = state =>
  state.myReservations.freeCarrierFinished.reservations
export const selectMyFreeCarrierFinishedReservations = state =>
  state.myReservations.freeCarrierFinished.reservations

export const selectMyReservationsFreeCarrierFinishedFilters = state =>
  state.myReservations.freeCarrierFinished.filters

export const selectMyReservationsFreeCarrierFinishedPagination = state =>
  state.myReservations.freeCarrierFinished.pagination

export const selectMyReservationsFreeCarrierFinishedErrorMessage = state =>
  state.myReservations.freeCarrierFinished.errorMessage

export const selectMyReservationsFreeCarrierFinishedFiltersFormatted = state => {
  const filters = selectMyReservationsFreeCarrierFinishedFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}
