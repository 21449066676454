import { keys, forEach, omit } from 'ramda'
import api from './APIInterceptor'

export default class CompanyService {
  static currentCompanyDetails() {
    return api.get('/me/company')
  }

  static updateCurrentCompany(values) {
    return api.patch('/me/company', values)
  }

  static showCurrentCompanyUsers() {
    return api.get('/me/company/users')
  }

  static async addNewCompanyUser(values) {
    const formData = new FormData()
    forEach(
      key => formData.append(key, values[key]),
      keys(omit(['avatar'], values))
    )
    if (values.avatar && values.avatar.type === 'blob') {
      const avatar = await fetch(values.avatar.url).then(r => r.blob())
      formData.append('avatar', avatar)
    }

    return api.post('/me/company/users', formData)
  }

  //TODO: refactor edit user and add user
  static async editCompanyUser(values) {
    return api.patch(`/me/company/users/${values.id}`, values)
  }

  static deleteCompanyUser(id) {
    return api.delete(`/me/company/users/${id}`)
  }

  static deactivateCompanyUser(id) {
    return api.get(`/me/company/users/${id}/deactivate`)
  }

  static searchCompany(taxId) {
    return api.post('company/search', { taxId })
  }

  static showCompany(id) {
    return api.get(`company/${id}`)
  }

  static getWhitelistedCompanies() {
    return api.get('/me/company/whitelist')
  }

  static getBlacklistedCompanies() {
    return api.get('/me/company/blacklist')
  }

  static addCompanyToWhitelist(companyId) {
    return api.patch('/me/company/whitelist', { companyId })
  }

  static addCompanyToBlacklist(companyId) {
    return api.patch('/me/company/blacklist', { companyId })
  }

  static deleteCompanyFromWhitelist(companyId) {
    return api.delete(`/me/company/whitelist/${companyId}`)
  }

  static deleteCompanyFromBlacklist(companyId) {
    return api.delete(`/me/company/blacklist/${companyId}`)
  }

  static getMyCompanyDrivers() {
    return api.get('/me/company/drivers')
  }

  static getMyCompanyInvoiceSettings() {
    return api.get('/me/company/invoices/settings')
  }

  static updateMyCompanyInvoiceSettings(values) {
    return api.post('/me/company/invoices/settings', values)
  }
}
