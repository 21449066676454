import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
  spawn,
  take
} from '@redux-saga/core/effects'
import { eventChannel } from 'redux-saga'
import { head, pluck } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import ExchangeService from 'src/services/ExchangeService'
import DictionariesService from 'src/services/DictionariesService'
import LaravelEchoService from 'src/services/LaravelEchoService'
import {
  ERROR_DELAY_TIME,
  EXCHANGE_TYPES,
  EXCHANGE_EVENT_TYPES
} from 'src/ducks/consts'
import { selectDictionaries } from 'src/ducks/selectors'
import { selectCurrentUserId } from 'src/features/account/duck/selectors'
import {
  selectPostImportFiltersTabsList,
  selectPostImportActiveTab,
  selectPostImportFilters,
  selectPostImportFiltersFormatted,
  selectPostImportTabsData
} from 'src/features/stocks/ducks/selectors'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import {
  displayResponseErrorMessage,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'
import { normalizeToLocationsIds, normalizeToCompaniesIds } from './normalizers'

export const getPostImportOffersRoutine = createRoutine(
  'GET_POST_IMPORT_OFFERS'
)
export const clearPostImportOffersRoutine = createRoutine(
  'CLEAR_POST_IMPORT_OFFERS'
)
export const cleanPostImportOffersDetailsRoutine = createRoutine(
  'CLEAN_POST_IMPORT_OFFERS_DETAILS'
)
export const showPostImportOfferDetailsRoutine = createRoutine(
  'SHOW_POST_IMPORT_OFFER_DETAILS'
)
export const setPostImportDrawerRoutine = createRoutine(
  'SET_POST_IMPORT_DRAWER'
)
export const setPostImportFiltersRoutine = createRoutine(
  'SET_POST_IMPORT_FILTERS'
)
export const updatePostImportOfferRoutine = createRoutine(
  'UPDATE_POST_IMPORT_OFFER'
)
export const reservePostImportOfferRoutine = createRoutine(
  'RESERVE_POST_IMPORT_OFFER'
)

export const bidPostImportOfferRoutine = createRoutine('BID_POST_IMPORT_OFFER')
export const refreshPostImportOfferRoutine = createRoutine(
  'REFRESH_POST_IMPORT_OFFER'
)
export const makePostImportOfferPublicRoutine = createRoutine(
  'MAKE_POST_IMPORT_OFFER_PUBLIC'
)

export const clearPostImportFiltersRoutine = createRoutine(
  'CLEAR_POST_IMPORT_FILTERS'
)
export const getPostImportFilterTabsListRoutine = createRoutine(
  'GET_POST_IMPORT_FILTER_TABS_LIST'
)
export const createPostImportFilterTabRoutine = createRoutine(
  'CREATE_POST_IMPORT_FILTER_TAB'
)
export const removePostImportFilterTabRoutine = createRoutine(
  'REMOVE_POST_IMPORT_FILTER_TAB'
)
export const setPostImportActiveTabRoutine = createRoutine(
  'SET_POST_IMPORT_ACTIVE_TAB'
)

export const POST_IMPORT_TAB_EVENT_OFFER_CREATED =
  'POST_IMPORT_TAB_EVENT_OFFER_CREATED'
export const POST_IMPORT_TAB_EVENT_OFFER_REFRESHED =
  'POST_IMPORT_TAB_EVENT_OFFER_REFRESHED'
export const POST_IMPORT_TAB_EVENT_OFFER_DELETED =
  'POST_IMPORT_TAB_EVENT_OFFER_DELETED'
export const POST_IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA =
  'POST_IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA'

export const postImportOffersCleanerRoutine = createRoutine(
  'POST_IMPORT_OFFERS_CLEANER'
)
//actions

function* getPostImportOffers() {
  yield put(getPostImportOffersRoutine.request())

  try {
    const activeTab = yield select(selectPostImportActiveTab)

    //case when initially loads
    if (isFalsy(activeTab)) {
      yield call(getPostImportFilterTabsList)
      const tabs = yield select(selectPostImportFiltersTabsList)
      yield call(setPostImportActiveTab, { payload: { id: head(tabs).id } })
      return
    }

    const tabs = yield select(selectPostImportFiltersTabsList)
    const filters = yield select(selectPostImportFiltersFormatted)

    const data = yield call(
      ExchangeService.getPostImportOffersListByTab,
      isFalsy(activeTab) ? head(tabs).id : activeTab,
      filters
    )

    yield put(getPostImportOffersRoutine.success(data))
    yield call(getPostImportFilterTabsList)
  } catch (e) {
    yield put(getPostImportOffersRoutine.failure())
    console.log(e)
  }
}

function* clearPostImportOffers() {
  yield put(clearPostImportOffersRoutine.success())
}

function* cleanPostImportOffersDetails() {
  yield put(cleanPostImportOffersDetailsRoutine.success())
}

function* showPostImportOfferDetails({ payload }) {
  yield put(showPostImportOfferDetailsRoutine.request())
  try {
    const { data } = yield call(ExchangeService.showPostImportOffer, payload)
    yield put(showPostImportOfferDetailsRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(showPostImportOfferDetailsRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(showPostImportOfferDetailsRoutine.fulfill())
  }
}

function* setPostImportDrawer({ payload }) {
  yield put(setPostImportDrawerRoutine.success(payload))
}

function* setPostImportFilters({ payload }) {
  const dictionaries = yield select(selectDictionaries)

  yield put(setPostImportFiltersRoutine.success({ ...payload, dictionaries }))
  yield* getPostImportOffers()
}

function* clearPostImportFilters() {
  yield put(clearPostImportFiltersRoutine.success())
  yield* getPostImportOffers()
}

function* updatePostImportOffer({ payload }) {
  yield put(updatePostImportOfferRoutine.request())
  try {
    const { data } = yield call(
      ExchangeService.updatePostImportOffer,
      payload.id,
      payload.values
    )
    yield put(updatePostImportOfferRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(updatePostImportOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(updatePostImportOfferRoutine.fulfill())
  }
}

function* reservePostImportOffer({ payload }) {
  yield put(reservePostImportOfferRoutine.request())
  try {
    yield call(ExchangeService.reservePostImportOffer, payload.id)
    yield put(reservePostImportOfferRoutine.success())
    yield* showPostImportOfferDetails({ payload })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(reservePostImportOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(reservePostImportOfferRoutine.fulfill())
  }
}

function* bidPostImportOffer({ payload }) {
  yield put(bidPostImportOfferRoutine.request())
  try {
    yield call(ExchangeService.bidPostImportOffer, payload.id, {
      price: payload.price
    })
    yield put(bidPostImportOfferRoutine.success())
    yield call(showPostImportOfferDetails, { payload: { id: payload.id } })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'makeABid.successMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(bidPostImportOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(bidPostImportOfferRoutine.fulfill())
  }
}

function* refreshPostImportOffer({ payload }) {
  yield put(refreshPostImportOfferRoutine.request())
  try {
    yield call(ExchangeService.refreshPostImportOffer, payload.id)
    yield put(refreshPostImportOfferRoutine.success())
    yield* showPostImportOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.refreshOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(refreshPostImportOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(refreshPostImportOfferRoutine.fulfill())
  }
}

function* makePostImportOfferPublic({ payload }) {
  yield put(makePostImportOfferPublicRoutine.request())
  try {
    yield call(ExchangeService.makePostImportOfferPublic, payload.id)
    yield put(makePostImportOfferPublicRoutine.success())
    yield* showPostImportOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.makeOfferPublicSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(makePostImportOfferPublicRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(makePostImportOfferPublicRoutine.fulfill())
  }
}

function listenToExchangeTabEvents(data) {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToExchangeTabs({
      exchangeType: data.exchangeType,
      userId: data.userId,
      tabsIds: data.tabsIds,
      callback: (tabId, event, payload) => {
        switch (event) {
          case EXCHANGE_EVENT_TYPES.OFFER_CREATED:
            return emitter({
              type: POST_IMPORT_TAB_EVENT_OFFER_CREATED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_REFRESHED:
            return emitter({
              type: POST_IMPORT_TAB_EVENT_OFFER_REFRESHED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_DELETED:
            return emitter({
              type: POST_IMPORT_TAB_EVENT_OFFER_DELETED,
              tabId,
              payload
            })
          default:
            //handle information about not handled type
            return null
        }
      }
    })
    // unsubscribe function
    return () => {
      // do whatever to interrupt the socket communication here
    }
  })
}

export function* getPostImportFilterTabsList() {
  try {
    const tabs = yield call(ExchangeService.getPostImportFilterTabsList)
    const locationsIds = normalizeToLocationsIds(tabs.data)
    const companiesIds = normalizeToCompaniesIds(tabs.data)

    const locations = yield call(DictionariesService.locations, {
      filter: {
        id: locationsIds
      }
    })
    const companies = yield call(DictionariesService.companies, {
      filter: {
        id: companiesIds
      }
    })

    yield put(
      getPostImportFilterTabsListRoutine.success({
        data: tabs.data,
        locations: locations.data,
        companies: companies.data
      })
    )

    //listen to tabs events
    const userId = yield select(selectCurrentUserId)
    yield spawn(listenToExchangeTabEventsWatcher, {
      exchangeType: EXCHANGE_TYPES.POST_IMPORT_CONTAINERS,
      userId,
      tabsIds: pluck('id')(tabs.data)
    })
  } catch (e) {
    console.log(e)
  }
}

function* createPostImportFilterTab({ payload }) {
  try {
    const filters = yield select(selectPostImportFilters)
    const activeTab = yield select(selectPostImportActiveTab)
    const tabs = yield select(selectPostImportFiltersTabsList)
    const activeTabData = head(tabs.filter(v => v.id === activeTab))

    const { data } = yield call(ExchangeService.createPostImportFilterTab, {
      name: payload.empty ? null : filters.tabName
    })
    yield put(
      createPostImportFilterTabRoutine.success({
        data,
        currentFilters: payload.empty ? null : activeTabData.currentFilters
      })
    )
    //set active new tab

    yield call(setPostImportActiveTab, { payload: { id: data.id } })
  } catch (e) {
    console.log(e)
  }
}

function* removePostImportFilterTab({ payload }) {
  try {
    yield call(ExchangeService.removePostImportFilterTab, payload.id)
    yield put(removePostImportFilterTabRoutine.success({ id: payload.id }))
    //after removal set active tab to first one
    const tabs = yield select(selectPostImportFiltersTabsList)
    yield call(setPostImportActiveTab, { payload: { id: head(tabs).id } })
  } catch (e) {
    console.log(e)
  }
}

function* setPostImportActiveTab({ payload }) {
  try {
    const tabs = yield select(selectPostImportFiltersTabsList)
    const tab = head(tabs.filter(v => v.id === payload.id))
    const tabsData = yield select(selectPostImportTabsData)

    yield put(
      setPostImportActiveTabRoutine.success({
        activeTab: tab.id,
        filters: tab.currentFilters
      })
    )
    yield call(getPostImportOffers)

    //if tab has live data stored move them to offers list
    if (!isFalsy(tabsData[tab.id])) {
      yield put({
        type: POST_IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
        payload: { tabId: tab.id, tabData: tabsData[tab.id] }
      })
    }
  } catch (e) {
    console.log(e)
  }
}

function* postImportOffersCleaner() {
  yield put(postImportOffersCleanerRoutine.success())
}

//watchers

function* getPostImportOffersRoutineWatcher() {
  yield takeLatest(getPostImportOffersRoutine.TRIGGER, getPostImportOffers)
}

function* clearPostImportOffersRoutineWatcher() {
  yield takeLatest(clearPostImportOffersRoutine.TRIGGER, clearPostImportOffers)
}

function* cleanPostImportOffersDetailsRoutineWatcher() {
  yield takeLatest(
    cleanPostImportOffersDetailsRoutine.TRIGGER,
    cleanPostImportOffersDetails
  )
}

function* showPostImportOfferDetailsRoutineWatcher() {
  yield takeLatest(
    showPostImportOfferDetailsRoutine.TRIGGER,
    showPostImportOfferDetails
  )
}

function* setPostImportDrawerRoutineWatcher() {
  yield takeLatest(setPostImportDrawerRoutine.TRIGGER, setPostImportDrawer)
}

function* setPostImportFiltersRoutineWatcher() {
  yield takeLatest(setPostImportFiltersRoutine.TRIGGER, setPostImportFilters)
}

function* updatePostImportOfferRoutineWatcher() {
  yield takeLatest(updatePostImportOfferRoutine.TRIGGER, updatePostImportOffer)
}

function* reservePostImportOfferRoutineWatcher() {
  yield takeLatest(
    reservePostImportOfferRoutine.TRIGGER,
    reservePostImportOffer
  )
}

function* bidPostImportOfferRoutineWatcher() {
  yield takeLatest(bidPostImportOfferRoutine.TRIGGER, bidPostImportOffer)
}

function* refreshPostImportOfferRoutineWatcher() {
  yield takeLatest(
    refreshPostImportOfferRoutine.TRIGGER,
    refreshPostImportOffer
  )
}

function* makePostImportOfferPublicRoutineWatcher() {
  yield takeLatest(
    makePostImportOfferPublicRoutine.TRIGGER,
    makePostImportOfferPublic
  )
}

function* clearPostImportFiltersRoutineWatcher() {
  yield takeLatest(
    clearPostImportFiltersRoutine.TRIGGER,
    clearPostImportFilters
  )
}

function* getPostImportFilterTabsListRoutineWatcher() {
  yield takeLatest(
    getPostImportFilterTabsListRoutine.TRIGGER,
    getPostImportFilterTabsList
  )
}

function* createPostImportFilterTabRoutineWatcher() {
  yield takeLatest(
    createPostImportFilterTabRoutine.TRIGGER,
    createPostImportFilterTab
  )
}

function* removePostImportFilterTabRoutineWatcher() {
  yield takeLatest(
    removePostImportFilterTabRoutine.TRIGGER,
    removePostImportFilterTab
  )
}

function* setPostImportActiveTabRoutineWatcher() {
  yield takeLatest(
    setPostImportActiveTabRoutine.TRIGGER,
    setPostImportActiveTab
  )
}

function* listenToExchangeTabEventsWatcher(payload) {
  const channel = yield call(listenToExchangeTabEvents, payload)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* postImportOffersCleanerRoutineWatcher() {
  yield takeLatest(
    postImportOffersCleanerRoutine.TRIGGER,
    postImportOffersCleaner
  )
}

export const exchangePostImportSagas = [
  fork(getPostImportOffersRoutineWatcher),
  fork(clearPostImportOffersRoutineWatcher),
  fork(cleanPostImportOffersDetailsRoutineWatcher),
  fork(showPostImportOfferDetailsRoutineWatcher),
  fork(setPostImportDrawerRoutineWatcher),
  fork(setPostImportFiltersRoutineWatcher),
  fork(updatePostImportOfferRoutineWatcher),
  fork(reservePostImportOfferRoutineWatcher),
  fork(refreshPostImportOfferRoutineWatcher),
  fork(clearPostImportFiltersRoutineWatcher),
  fork(getPostImportFilterTabsListRoutineWatcher),
  fork(createPostImportFilterTabRoutineWatcher),
  fork(removePostImportFilterTabRoutineWatcher),
  fork(setPostImportActiveTabRoutineWatcher),
  fork(postImportOffersCleanerRoutineWatcher),
  fork(bidPostImportOfferRoutineWatcher),
  fork(makePostImportOfferPublicRoutineWatcher)
]
