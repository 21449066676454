import { API_STATES } from 'src/ducks/consts'
import { Registration, Login, ForgotPassword } from './records'
import {
  loginUserRoutine,
  setRegistrationValueRoutine,
  setNewPasswordRoutine,
  passwordResetRequestRoutine,
  registerUserRoutine
} from './actions'

export const loginReducer = (state = new Login(), action) => {
  switch (action.type) {
    case loginUserRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS).set('errorMessage', '')
    case loginUserRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE)
    case loginUserRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', action.payload.message)
    default:
      return state
  }
}

export const registerReducer = (state = new Registration(), action) => {
  switch (action.type) {
    case setRegistrationValueRoutine.SUCCESS:
      return state.set(action.payload.name, action.payload.value)
    case registerUserRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case registerUserRoutine.SUCCESS:
      return new Registration().set('state', API_STATES.DONE)
    case registerUserRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case registerUserRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    default:
      return state
  }
}

export const forgotPasswordReducer = (state = new ForgotPassword(), action) => {
  switch (action.type) {
    case passwordResetRequestRoutine.REQUEST:
      return state
        .set('sentSuccessfully', false)
        .set('state', API_STATES.IN_PROGRESS)
    case setNewPasswordRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
        .set('changedSuccessfully', false)
    case setRegistrationValueRoutine.SUCCESS:
      return state.set(action.payload.name, action.payload.value)
    case passwordResetRequestRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE).set('sentSuccessfully', true)
    case setNewPasswordRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('changedSuccessfully', true)
    case passwordResetRequestRoutine.FAILURE:
      return state
        .set('sentSuccessfully', false)
        .set('errorMessage', action.payload.message)
        .set('state', API_STATES.DONE)
    case setNewPasswordRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .set('state', API_STATES.DONE)
    case passwordResetRequestRoutine.FULFILL:
    case setNewPasswordRoutine.FULFILL:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', null)
        .set('sentSuccessfully', false)
        .set('changedSuccessfully', false)
    default:
      return state
  }
}
