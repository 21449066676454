import snakeCase from 'lodash.snakecase'
import { renameKeysWith } from 'ramda-adjunct'
import { LOCATION_TYPES } from 'src/ducks/consts'
import { includes } from 'ramda'
import { removePrefixFromGtuCodeValue } from 'src/features/invoices/duck/helpers'
import { Dictionaries, DictionaryEntry, ExchangeCounters } from './records'

export const normalizeDictionaries = ({
  state,
  containers,
  containerSemiTrailers,
  shipowners,
  cargoTypes,
  adrClasses,
  contactCategories,
  gtuCodes
}) =>
  new Dictionaries({
    ...state.toJS(),
    containers: containers.data.map(
      v => new DictionaryEntry({ label: v.name, value: v.id })
    ),
    containerSemiTrailers: containerSemiTrailers.data.map(
      v => new DictionaryEntry({ label: v.name, value: v.id })
    ),
    shipowners: shipowners.data.map(
      v => new DictionaryEntry({ label: v.name, value: v.id })
    ),
    cargoTypes: cargoTypes.data.map(
      v => new DictionaryEntry({ label: v.name, value: v.id })
    ),
    adrClasses: adrClasses.data.map(
      v => new DictionaryEntry({ label: `${v.class}. ${v.name}`, value: v.id })
    ),
    contactCategories: contactCategories.data.map(
      v => new DictionaryEntry({ label: v.name, value: v.id })
    ),
    gtuCodes: gtuCodes.data.map(
      v =>
        new DictionaryEntry({
          label: `${removePrefixFromGtuCodeValue(v.code)} - ${v.name}`,
          value: v.code
        })
    )
  })

export const normalizeLocations = locations =>
  locations.map(
    v =>
      new DictionaryEntry({
        label: includes(v.type, LOCATION_TYPES.TERMINAL)
          ? `${v.countryCode}, ${v.name}`
          : `${v.countryCode}, ${v.name}, ${v.postalCode}`,
        value: v.id
      })
  )
export const normalizeCompanies = companies =>
  companies.map(v => new DictionaryEntry({ label: `${v.name}`, value: v.id }))

export const normalizeExchangeCounters = obj =>
  new ExchangeCounters({
    ...renameKeysWith(snakeCase)(obj)
  })
