import { createIntl, createIntlCache } from 'react-intl'
import flatten from 'flat'
import { LANGUAGE_KEYS } from 'src/utils/lang'
import appRegistry from 'src/services/AppRegistry'

import pl from './pl.json'
import en from './en.json'
import de from './de.json'

export const DEFAULT_LANG = 'pl'
const cache = createIntlCache()

const intl = {
  pl: () =>
    createIntl(
      {
        locale: 'pl',
        messages: flatten(pl)
      },
      cache
    ),
  de: () =>
    createIntl(
      {
        locale: 'de',
        messages: flatten(de)
      },
      cache
    ),
  en: () =>
    createIntl(
      {
        locale: 'en',
        messages: flatten(en)
      },
      cache
    )
}

export default () => {
  const currentLang =
    appRegistry.storage.get('gatsby-intl-language') || DEFAULT_LANG
  return LANGUAGE_KEYS.includes(currentLang)
    ? intl[currentLang]()
    : intl[DEFAULT_LANG]()
}
