import { Record } from 'immutable'
import { DEFAULT_LANG } from 'src/intl/translate'
import { API_STATES, USER_ROLES } from 'src/ducks/consts'

export const Image = Record({
  url: null,
  type: null
})

export const ChangePassword = Record({
  state: API_STATES.PRISTINE,
  changedSuccessfully: false,
  errorMessage: null
})

export const UpdateMyCompany = Record({
  state: API_STATES.PRISTINE,
  changedSuccessfully: false,
  errorMessage: null
})

export const User = Record({
  state: API_STATES.PRISTINE,
  avatar: Image(),
  avatarSize: 0,
  companyId: null,
  contactInfo: '',
  createdAt: null,
  email: '',
  errorMessage: null,
  firstName: '',
  id: null,
  isActive: false,
  lang: DEFAULT_LANG,
  lastName: '',
  newPassword: '',
  password: '',
  phoneNumber: '',
  role: USER_ROLES.USER,
  username: ''
})

export const MyCompany = Record({
  id: null,
  name: '',
  email: '',
  address: '',
  addressNote: '',
  city: '',
  postalCode: '',
  countryCode: '',
  phoneNumber: '',
  taxId: '',
  createdAt: '',
  symbol: '',
  homepageUrl: '',
  status: null,
  businessProfile: '',
  logo: Image(),
  documents: [],
  users: [],
  state: API_STATES.PRISTINE,
  changedSuccessfully: false,
  errorMessage: null
})

export const CurrentUser = Record({
  state: API_STATES.PRISTINE,
  avatar: Image(),
  company: MyCompany(),
  companyId: null,
  contactInfo: '',
  createdAt: null,
  email: '',
  firstName: '',
  id: null,
  isActive: '',
  lang: DEFAULT_LANG,
  lastName: '',
  phoneNumber: '',
  username: '',
  role: 'user',
  isChatMuted: false,
  termsOfServiceAcceptedAt: null
})

export const Account = Record({
  permissions: {},
  currentUser: CurrentUser(),
  coworkers: [],
  changePassword: ChangePassword(),
  updateMyCompany: UpdateMyCompany(),
  updateUser: UpdateMyCompany(),
  avatarState: API_STATES.PRISTINE
})

export const ContactForm = Record({
  category: '',
  subject: '',
  message: '',
  state: API_STATES.PRISTINE,
  sendSuccessfully: false,
  errorMessage: null
})
