import { last, dropLast, assoc, dissoc } from 'ramda'
import { isArray } from 'ramda-adjunct'
import { API_STATES } from 'src/ducks/consts'
import { Messages, Conversation } from './records'
import {
  setMessagesListOpenedRoutine,
  setConversationOpenedRoutine,
  sendMessageRoutine,
  bootstrapConversationRoutine,
  getConversationMessagesRoutine,
  clearConversationRoutine,
  getLastContextualMessagesListRoutine,
  getLastPersonalMessagesListRoutine,
  getUnreadMessagesCountRoutine,
  searchForUserConversationsRoutine,
  clearLastMessagesListsRoutine,
  toggleConversationNotificationsRoutine,
  getConversationContextRoutine,
  getContextAvailableActionsRoutine,
  MESSAGE_EVENT_USER_MESSAGE_SENT,
  MESSAGE_EVENT_SYSTEM_MESSAGE_SENT,
  CHAT_PRESENCE_CHANNEL_LEAVE,
  CHAT_PRESENCE_CHANNEL_INIT,
  CHAT_PRESENCE_CHANNEL_JOIN
} from './actions'
import { normalizeReceivedMessage } from './normalizers'

export const messagesReducer = (state = new Messages(), action) => {
  switch (action.type) {
    case setMessagesListOpenedRoutine.SUCCESS:
      return state.set('listOpened', action.payload.opened)
    case setConversationOpenedRoutine.SUCCESS:
      return state
        .set('conversation', new Conversation({ ...action.payload }))
        .set('conversationOpened', action.payload.conversationOpened)
    case sendMessageRoutine.SUCCESS:
      return state
        .setIn(
          ['conversation', 'conversationId'],
          action.payload.data.conversationId
        )
        .updateIn(['conversation', 'messages'], list => {
          const lastGroup = last(list)
          return isArray(lastGroup)
            ? [...dropLast(1, list), lastGroup.concat(action.payload.message)]
            : list.concat([[action.payload.message]])
        })
    case bootstrapConversationRoutine.SUCCESS:
      return state
        .setIn(
          ['conversation', 'conversationId'],
          action.payload.conversationId
        )
        .setIn(['conversation', 'interlocutor'], action.payload.interlocutor)
        .setIn(
          ['conversation', 'isConversationMuted'],
          action.payload.isConversationMuted
        )
        .setIn(['conversation', 'isAvailable'], action.payload.isAvailable)
    case getConversationMessagesRoutine.REQUEST:
      return state.set('conversationState', API_STATES.IN_PROGRESS)
    case getConversationMessagesRoutine.SUCCESS:
      return state
        .setIn(
          ['conversation', 'messages'],
          [...action.payload.data, ...state.conversation.messages]
        )
        .set('conversationState', API_STATES.DONE)
        .setIn(
          ['lastRequest', action.payload.conversationId],
          action.payload.data
        )

    case getLastContextualMessagesListRoutine.REQUEST:
      return state.set('listState', API_STATES.IN_PROGRESS)
    case getLastContextualMessagesListRoutine.SUCCESS:
      return state
        .update('contextualMessages', list =>
          action.payload.append
            ? list.concat(action.payload.data)
            : action.payload.data
        )
        .set(
          'contextualMessagesPage',
          action.payload.meta.currentPage + 1 > action.payload.meta.lastPage
            ? action.payload.meta.lastPage
            : action.payload.meta.currentPage + 1
        )
        .set(
          'contextualMessagesAllFetched',
          action.payload.meta.currentPage === action.payload.meta.lastPage
        )
        .set('listState', API_STATES.DONE)

    case getLastPersonalMessagesListRoutine.REQUEST:
      return state.set('listState', API_STATES.IN_PROGRESS)
    case getLastPersonalMessagesListRoutine.SUCCESS:
      return state
        .update('personalMessages', list =>
          action.payload.append
            ? list.concat(action.payload.data)
            : action.payload.data
        )
        .set(
          'personalMessagesPage',
          action.payload.meta.currentPage + 1 > action.payload.meta.lastPage
            ? action.payload.meta.lastPage
            : action.payload.meta.currentPage + 1
        )
        .set(
          'personalMessagesAllFetched',
          action.payload.meta.currentPage === action.payload.meta.lastPage
        )
        .set('listState', API_STATES.DONE)
    case getLastPersonalMessagesListRoutine.FULFILL:
      return state.set('listState', API_STATES.DONE)
    case getLastContextualMessagesListRoutine.FULFILL:
      return state.set('listState', API_STATES.DONE)

    case MESSAGE_EVENT_USER_MESSAGE_SENT:
      return state.update('conversation', conversation =>
        conversation.conversationId === action.payload.conversation_id
          ? conversation.update('messages', list => {
              const lastGroup = last(list)
              return isArray(lastGroup)
                ? [
                    ...dropLast(1, list),
                    lastGroup.concat(normalizeReceivedMessage(action.payload))
                  ]
                : list.concat([[normalizeReceivedMessage(action.payload)]])
            })
          : conversation
      )
    case MESSAGE_EVENT_SYSTEM_MESSAGE_SENT:
      return state.update('conversation', conversation =>
        conversation.conversationId === action.payload.conversation_id
          ? conversation.update('messages', list => {
              const lastGroup = last(list)
              return isArray(lastGroup)
                ? [
                    ...dropLast(1, list),
                    lastGroup.concat(normalizeReceivedMessage(action.payload))
                  ]
                : list.concat([[normalizeReceivedMessage(action.payload)]])
            })
          : conversation
      )
    case getConversationContextRoutine.REQUEST:
      return state.set('conversationState', API_STATES.IN_PROGRESS)
    case getConversationContextRoutine.SUCCESS:
      return state
        .setIn(['conversation', 'context'], action.payload.data)
        .set('conversationState', API_STATES.DONE)

    case getUnreadMessagesCountRoutine.SUCCESS:
      return state
        .set('allUnreadMessagesCount', action.payload.all)
        .set('contextualMessagesCount', action.payload.contextual)
        .set('personalMessagesCount', action.payload.personal)

    case searchForUserConversationsRoutine.TRIGGER:
      return state
        .set('personalSearchResultsAllFetched', false)
        .set('contextualSearchResultsAllFetched', false)
    case searchForUserConversationsRoutine.REQUEST:
      return state.set('listState', API_STATES.IN_PROGRESS)
    case searchForUserConversationsRoutine.SUCCESS:
      return state
        .update(action.payload.field, current =>
          action.payload.append
            ? current.concat(action.payload.data)
            : action.payload.data
        )
        .set(
          action.payload.pageValueName,
          action.payload.meta.currentPage + 1 > action.payload.meta.lastPage
            ? action.payload.meta.lastPage
            : action.payload.meta.currentPage + 1
        )
        .set(
          action.payload.allFetchedValueName,
          action.payload.meta.currentPage === action.payload.meta.lastPage
        )
        .set('listState', API_STATES.DONE)
    case searchForUserConversationsRoutine.FAILURE:
      return state.set('listState', API_STATES.DONE)
    case toggleConversationNotificationsRoutine.SUCCESS:
      return state.setIn(
        ['conversation', 'isConversationMuted'],
        action.payload
      )
    case clearConversationRoutine.SUCCESS:
      return state
        .set('conversation', new Conversation())
        .set('conversationOpened', false)
    case CHAT_PRESENCE_CHANNEL_INIT:
      const userIds = action.payload.users.reduce((acc, val) => {
        acc[val.id] = true
        return acc
      }, {})
      return state.set('onlineUsers', userIds)
    case CHAT_PRESENCE_CHANNEL_JOIN:
      return state.update('onlineUsers', users =>
        assoc(action.payload.user.id, true, users)
      )
    case CHAT_PRESENCE_CHANNEL_LEAVE:
      return state.update('onlineUsers', users =>
        dissoc(action.payload.user.id, users)
      )
    case getContextAvailableActionsRoutine.REQUEST:
      return state.setIn(
        ['conversation', 'contextState'],
        API_STATES.IN_PROGRESS
      )
    case getContextAvailableActionsRoutine.FAILURE:
      return state.setIn(['conversation', 'contextState'], API_STATES.DONE)
    case getContextAvailableActionsRoutine.SUCCESS:
      return state
        .setIn(['conversation', 'contextActions'], action.payload)
        .setIn(['conversation', 'contextState'], API_STATES.DONE)
    case clearLastMessagesListsRoutine.SUCCESS:
      return state
        .set('personalMessagesPage', 1)
        .set('contextualMessagesPage', 1)
        .set('personalSearchResultsPage', 1)
        .set('contextualSearchResultsPage', 1)
        .set('personalMessagesAllFetched', false)
        .set('contextualMessagesAllFetched', false)
        .set('personalSearchResultsAllFetched', false)
        .set('contextualSearchResultsAllFetched', false)
    default:
      return state
  }
}
