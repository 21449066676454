import { TRACKING_OFFER_TYPES } from 'src/features/tracking/duck/consts'
import {
  normalizeExportOfferTimelineData,
  normalizeFreeCarrierOfferTimelineData,
  normalizeImportOfferTimelineData,
  normalizePostImportOfferTimelineData
} from 'src/features/stocks/ducks/normalizers'
import { includes, not, pipe, when } from 'ramda'
import { renameKeysWith } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import { formatFilterKey } from 'src/utils/helpers'

export const selectOrderDetails = state => state.orders.details
export const selectOrderDetailsOffer = state => state.orders.details.offer
export const selectOrderEditForm = state => state.orders.edit

export const selectOrderTimelineData = state => {
  const offer = selectOrderDetailsOffer(state)

  return {
    [TRACKING_OFFER_TYPES.EXPORT]: normalizeExportOfferTimelineData(offer),
    [TRACKING_OFFER_TYPES.IMPORT]: normalizeImportOfferTimelineData(offer),
    [TRACKING_OFFER_TYPES.FREE_CARRIER]: normalizeFreeCarrierOfferTimelineData(
      offer
    ),
    [TRACKING_OFFER_TYPES.POST_IMPORT]: normalizePostImportOfferTimelineData(
      offer
    )
  }[offer.offerContextType]
}

//Orders issued
export const selectOrdersIssuedRecords = state => state.orders.issued
export const selectOrdersIssued = state => state.orders.issued.orders
export const selectOrdersIssuedFilters = state => state.orders.issued.filters
export const selectOrdersIssuedPagination = state =>
  state.orders.issued.pagination
export const selectOrdersIssuedButtonsState = state =>
  state.orders.issued.state.buttons
export const selectOrdersIssuedFiltersFormatted = state => {
  const filters = state.orders.issued.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//Orders received
export const selectOrdersReceivedRecords = state => state.orders.received
export const selectOrdersReceived = state => state.orders.received.orders
export const selectOrdersReceivedFilters = state =>
  state.orders.received.filters
export const selectOrdersReceivedPagination = state =>
  state.orders.received.pagination
export const selectOrdersReceivedButtonsState = state =>
  state.orders.received.state.buttons
export const selectOrdersReceivedFiltersFormatted = state => {
  const filters = state.orders.received.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}
