import { reduce, findIndex, propEq, propOr } from 'ramda'
import {
  Dictionaries,
  ExchangeCounters,
  Snackbar,
  EUFinancingDialog
} from './records'
import {
  initDictionariesRoutine,
  appendLocationsRoutine,
  fetchLocationsRoutine,
  fetchCompaniesRoutine,
  GLOBAL_EXCHANGE_COUNTERS_OFFER_CREATED,
  GLOBAL_EXCHANGE_COUNTERS_OFFER_DELETED,
  getExchangeOffersCountersRoutine,
  setSnackbarValuesRoutine
} from './actions'
import {
  normalizeDictionaries,
  normalizeLocations,
  normalizeCompanies,
  normalizeExchangeCounters
} from './normalizers'

export const dictionariesReducer = (state = new Dictionaries(), action) => {
  switch (action.type) {
    case initDictionariesRoutine.SUCCESS:
      return normalizeDictionaries({
        ...action.payload,
        state
      })
    case fetchLocationsRoutine.SUCCESS:
      return state.setIn(
        ['locations', action.payload.dictionaryName, 'values'],
        normalizeLocations(action.payload.data)
      )
    case appendLocationsRoutine.SUCCESS:
      const normalized = normalizeLocations(action.payload.data)
      return state.updateIn(
        ['locations', action.payload.dictionaryName, 'values'],
        list =>
          reduce(
            (acc, fetchedEntry) =>
              findIndex(propEq('value', fetchedEntry.value))(acc) === -1
                ? acc.concat([fetchedEntry])
                : acc,
            list,
            normalized
          )
      )
    case fetchCompaniesRoutine.SUCCESS:
      return state.setIn(
        ['companies', 'values'],
        normalizeCompanies(action.payload.data)
      )
    default:
      return state
  }
}

export const exchangeCountersReducer = (
  state = new ExchangeCounters(),
  action
) => {
  switch (action.type) {
    case getExchangeOffersCountersRoutine.SUCCESS:
      return normalizeExchangeCounters(action.payload)
    case GLOBAL_EXCHANGE_COUNTERS_OFFER_CREATED:
      return state.update(action.payload.type, v => v + 1)
    case GLOBAL_EXCHANGE_COUNTERS_OFFER_DELETED:
      return state.update(action.payload.type, v => (v === 0 ? 0 : v - 1))
    default:
      return state
  }
}

export const snackbarReducer = (state = new Snackbar(), action) => {
  switch (action.type) {
    case setSnackbarValuesRoutine.SUCCESS:
      const open = propOr(true, 'open', action.payload)
      const type = propOr('success', 'type', action.payload)
      return state
        .set('message', action.payload.message)
        .set('title', action.payload.title)
        .set('type', type)
        .set('open', open)
    case setSnackbarValuesRoutine.FULFILL:
      return state
        .set('message', '')
        .set('type', 'success')
        .set('open', false)
    default:
      return state
  }
}

export const euFinancingDialogReducer = (
  state = new EUFinancingDialog(),
  action
) => {
  switch (action.type) {
    case 'SET_EU_FINANCING_DIALOG_OPEN':
      return state.set('open', true)
    case 'SET_EU_FINANCING_DIALOG_CLOSED':
      return state.set('open', false)
    default:
      return state
  }
}
