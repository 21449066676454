import {
  call,
  put,
  select,
  takeLatest,
  fork,
  takeEvery
} from '@redux-saga/core/effects'
import { map, prop } from 'ramda'
import { createRoutine } from 'redux-saga-routines'
import { navigate } from 'gatsby-plugin-intl'
import {
  selectMyFinishedOffersPostImportFiltersFormatted,
  selectMyOffersPostImportFiltersFormatted,
  selectMyOngoingPostImportOffers,
  selectSelectedPostImportOffers
} from 'src/features/myOffers/duck/selectors'
import OfferService from 'src/services/OfferService'
import ExchangeService from 'src/services/ExchangeService'
import {
  refreshPostImportOfferRoutine,
  makePostImportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-post-import'
import { fillFormUsingTemplatePostImport } from 'src/features/offers/duck/actions'
import { normalizeToOfferTemplatePostImport } from 'src/features/offers/duck/normalizers'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import {
  acceptPostImportReservationRoutine,
  rejectPostImportReservationRoutine
} from 'src/features/reservations/duck/actions'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import TrackingService from 'src/services/TrackingService'

export const getMyOngoingPostImportOffersRoutine = createRoutine(
  'GET_MY_ONGOING_POST_IMPORT_OFFERS'
)
export const getMyFinishedPostImportOffersRoutine = createRoutine(
  'GET_MY_FINISHED_POST_IMPORT_OFFERS'
)
export const setMyPostImportFiltersRoutine = createRoutine(
  'SET_MY_POST_IMPORT_FILTERS'
)
export const setMyFinishedPostImportFiltersRoutine = createRoutine(
  'SET_MY_FINISHED_POST_IMPORT_FILTERS'
)
export const setSelectedPostImportOfferRoutine = createRoutine(
  'SELECT_POST_IMPORT_OFFER'
)
export const selectAllPostImportOffersRoutine = createRoutine(
  'SELECT_ALL_POST_IMPORT_OFFERS'
)
export const clearSelectedPostImportOffersRoutine = createRoutine(
  'CLEAR_SELECTED_POST_IMPORT_OFFERS'
)
export const deleteMultiplePostImportOffersRoutine = createRoutine(
  'DELETE_MULTIPLE_POST_IMPORT_OFFERS'
)
export const deletePostImportOfferRoutine = createRoutine(
  'DELETE_POST_IMPORT_OFFER'
)
export const restorePostImportOfferRoutine = createRoutine(
  'RESTORE_POST_IMPORT_OFFER'
)
export const showOngoingPostImportOfferReservationsRoutine = createRoutine(
  'SHOW_ONGOING_POST_IMPORT_OFFER_RESERVATIONS'
)
export const showFinishedPostImportOfferReservationsRoutine = createRoutine(
  'SHOW_FINISHED_POST_IMPORT_OFFER_RESERVATIONS'
)
export const copyMyOfferToPostImportFormRoutine = createRoutine(
  'COPY_MY_OFFER_TO_POST_IMPORT_FORM'
)
export const createPostImportOfferTrackingRoutine = createRoutine(
  'CREATE_POST_IMPORT_OFFER_TRACKING'
)

//ACTIONS

export function* getMyOngoingPostImportOffers() {
  yield put(getMyOngoingPostImportOffersRoutine.request())
  try {
    const filters = yield select(selectMyOffersPostImportFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyOngoingPostImportOffers, filters)
    yield put(getMyOngoingPostImportOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
  }
}

function* getMyFinishedPostImportOffers() {
  yield put(getMyFinishedPostImportOffersRoutine.request())
  try {
    const filters = yield select(
      selectMyFinishedOffersPostImportFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyFinishedPostImportOffers, filters)
    yield put(getMyFinishedPostImportOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
  }
}

function* showOngoingPostImportOfferReservations({ payload }) {
  yield put(showOngoingPostImportOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      OfferService.showOngoingPostImportOfferReservations,
      payload.id
    )
    yield put(showOngoingPostImportOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* showFinishedPostImportOfferReservations({ payload }) {
  yield put(showFinishedPostImportOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      OfferService.showFinishedPostImportOfferReservations,
      payload.id
    )
    yield put(showFinishedPostImportOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* setMyPostImportFilters({ payload }) {
  yield put(setMyPostImportFiltersRoutine.success(payload))
}

function* setMyFinishedPostImportFilters({ payload }) {
  yield put(setMyFinishedPostImportFiltersRoutine.success(payload))
}

function* setSelectedOffer({ payload }) {
  yield put(setSelectedPostImportOfferRoutine.success(payload))
}

function* selectAllOffers() {
  const offers = yield select(selectMyOngoingPostImportOffers)
  const ids = map(prop('id'), offers)
  yield put(selectAllPostImportOffersRoutine.success({ ids }))
}

function* clearSelectedOffers() {
  yield put(clearSelectedPostImportOffersRoutine.success())
}

function* deleteMultiplePostImportOffers() {
  yield put(deleteMultiplePostImportOffersRoutine.request())
  try {
    const ids = yield select(selectSelectedPostImportOffers)
    yield call(OfferService.deleteMultiplePostImportOffers, { ids })
    yield put(deleteMultiplePostImportOffersRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOffersSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deleteMultiplePostImportOffersRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* deletePostImportOffer({ payload }) {
  yield put(deletePostImportOfferRoutine.request())
  try {
    yield call(OfferService.deletePostImportOffer, payload.id, {
      reason: payload.reason
    })
    yield put(deletePostImportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOneOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deletePostImportOfferRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* restorePostImportOffer({ payload }) {
  yield put(restorePostImportOfferRoutine.request())
  try {
    yield call(OfferService.restorePostImportOffer, payload.id)
    yield put(restorePostImportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.restoreOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(restorePostImportOfferRoutine.failure())
    console.log(e)
  }
}

function* copyMyOfferToPostImportForm({ payload }) {
  const { data } = yield call(ExchangeService.showPostImportOffer, {
    id: payload.offer.id
  })
  const normalized = normalizeToOfferTemplatePostImport(data)

  yield call(fillFormUsingTemplatePostImport, {
    payload: { template: normalized }
  })
  navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_POST_IMPORT}`)
}

export function* createPostImportOfferTracking({ payload }) {
  yield put(createPostImportOfferTrackingRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.createPostImportOfferTracking,
      payload.id
    )
    yield put(createPostImportOfferTrackingRoutine.success(data))
  } catch (e) {
    yield put(createPostImportOfferTrackingRoutine.fulfill())
  }
}

//WATCHERS

function* getMyOngoingPostImportOffersRoutineWatcher() {
  yield takeLatest(
    [
      setMyPostImportFiltersRoutine.SUCCESS,
      getMyOngoingPostImportOffersRoutine.TRIGGER,
      deleteMultiplePostImportOffersRoutine.SUCCESS,
      deletePostImportOfferRoutine.SUCCESS,
      refreshPostImportOfferRoutine.SUCCESS,
      makePostImportOfferPublicRoutine.SUCCESS,
      rejectPostImportReservationRoutine.SUCCESS,
      restorePostImportOfferRoutine.SUCCESS
    ],
    getMyOngoingPostImportOffers
  )
}

function* getMyFinishedPostImportOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyFinishedPostImportOffersRoutine.TRIGGER,
      setMyFinishedPostImportFiltersRoutine.SUCCESS,
      acceptPostImportReservationRoutine.SUCCESS,
      rejectPostImportReservationRoutine.SUCCESS,
      deletePostImportOfferRoutine.SUCCESS,
      restorePostImportOfferRoutine.SUCCESS
    ],
    getMyFinishedPostImportOffers
  )
}

function* setMyPostImportFiltersRoutineWatcher() {
  yield takeLatest(
    setMyPostImportFiltersRoutine.TRIGGER,
    setMyPostImportFilters
  )
}

function* setMyFinishedPostImportFiltersRoutineWatcher() {
  yield takeLatest(
    setMyFinishedPostImportFiltersRoutine.TRIGGER,
    setMyFinishedPostImportFilters
  )
}

function* setSelectedOfferRoutineWatcher() {
  yield takeLatest(setSelectedPostImportOfferRoutine.TRIGGER, setSelectedOffer)
}

function* selectAllOffersRoutineWatcher() {
  yield takeLatest(selectAllPostImportOffersRoutine.TRIGGER, selectAllOffers)
}
function* clearSelectedOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyOngoingPostImportOffersRoutine.SUCCESS,
      clearSelectedPostImportOffersRoutine.TRIGGER
    ],
    clearSelectedOffers
  )
}

function* deleteMultiplePostImportOffersRoutineWatcher() {
  yield takeLatest(
    deleteMultiplePostImportOffersRoutine.TRIGGER,
    deleteMultiplePostImportOffers
  )
}

function* deletePostImportOfferRoutineWatcher() {
  yield takeLatest(deletePostImportOfferRoutine.TRIGGER, deletePostImportOffer)
}

function* restorePostImportOfferRoutineWatcher() {
  yield takeLatest(
    restorePostImportOfferRoutine.TRIGGER,
    restorePostImportOffer
  )
}

function* showOngoingPostImportOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showOngoingPostImportOfferReservationsRoutine.TRIGGER,
    showOngoingPostImportOfferReservations
  )
}

function* showFinishedPostImportOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showFinishedPostImportOfferReservationsRoutine.TRIGGER,
    showFinishedPostImportOfferReservations
  )
}

function* copyMyOfferToPostImportFormRoutineWatcher() {
  yield takeLatest(
    copyMyOfferToPostImportFormRoutine.TRIGGER,
    copyMyOfferToPostImportForm
  )
}

function* createPostImportOfferTrackingRoutineWatcher() {
  yield takeLatest(
    createPostImportOfferTrackingRoutine.TRIGGER,
    createPostImportOfferTracking
  )
}

export const myPostImportOffersSagas = [
  fork(getMyOngoingPostImportOffersRoutineWatcher),
  fork(setMyPostImportFiltersRoutineWatcher),
  fork(setSelectedOfferRoutineWatcher),
  fork(deleteMultiplePostImportOffersRoutineWatcher),
  fork(selectAllOffersRoutineWatcher),
  fork(clearSelectedOffersRoutineWatcher),
  fork(deletePostImportOfferRoutineWatcher),
  fork(getMyFinishedPostImportOffersRoutineWatcher),
  fork(setMyFinishedPostImportFiltersRoutineWatcher),
  fork(restorePostImportOfferRoutineWatcher),
  fork(showOngoingPostImportOfferReservationsRoutineWatcher),
  fork(copyMyOfferToPostImportFormRoutineWatcher),
  fork(showFinishedPostImportOfferReservationsRoutineWatcher),
  fork(createPostImportOfferTrackingRoutineWatcher)
]
