import React from 'react'
import styled from 'styled-components'

import MUILocalOffer from '@material-ui/icons/LocalOfferOutlined'
import MUITextSms from '@material-ui/icons/TextsmsOutlined'
import MUIGavel from '@material-ui/icons/Gavel'
import MUIBookmarks from '@material-ui/icons/BookmarksOutlined'
import MUIAttachMoney from '@material-ui/icons/AttachMoney'
import MUIDateRange from '@material-ui/icons/DateRangeOutlined'
import MUIPriorityHigh from '@material-ui/icons/PriorityHigh'
import CustomIcon from 'src/components/CustomIcon'

const Offer = styled(MUILocalOffer)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1rem !important;
`

const MessageIcon = styled(MUITextSms)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1rem !important;
`

const ReservationIcon = styled(MUIBookmarks)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1rem !important;
`

const MoneyIcon = styled(MUIAttachMoney)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1rem !important;
`

const AuctionOfferIcon = styled(MUIGavel)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1rem !important;
`
const TimeIcon = styled(MUIDateRange)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1rem !important;
`

const DefaultIcon = styled(MUIPriorityHigh)`
  display: inline-block;
  color: inherit;
  width: ${({ size }) => size}px;
  position: relative;
  z-index: 20;
  font-size: 1rem !important;
`

const TrackingIcon = styled(CustomIcon)`
  display: inline-block;
  color: inherit;
  margin-top: 18px;
  position: relative;
  z-index: 20;
`

const OrderIcon = styled(CustomIcon)`
  display: inline-block;
  color: inherit;
  margin-top: 18px;
  position: relative;
  z-index: 20;
`

const InvoiceIcon = styled(CustomIcon)`
  display: inline-block;
  color: inherit;
  margin-top: 18px;
  position: relative;
  z-index: 20;
`

export default {
  offer: <Offer size={25} />,
  auction: <AuctionOfferIcon size={25} />,
  message: <MessageIcon size={25} />,
  reservation: <ReservationIcon size={25} />,
  money: <MoneyIcon size={25} />,
  time: <TimeIcon size={25} />,
  default: <DefaultIcon size={25} />,
  tracking: <TrackingIcon name='tracking' size={19} />,
  order: <OrderIcon name='order' size={19} />,
  invoice: <InvoiceIcon name='invoice' size={19} />
}
