import { Record } from 'immutable'
import {
  API_STATES,
  OFFERS_DEFAULT_LIMIT,
  OFFERS_DEFAULT_PAGE
} from 'src/ducks/consts'
import { ExportOffer, StockStates } from 'src/features/stocks/ducks/records'
import {
  Currency,
  Pagination,
  Container,
  Shipowner,
  Contractor,
  Company,
  AdrClass
} from 'src/ducks/records'

export const OrdersFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: OFFERS_DEFAULT_LIMIT,
  onlyMe: 0,
  status: null
})

export const Orders = Record({
  state: StockStates(),
  errorMessage: null,
  orders: [],
  filters: OrdersFilters(),
  pagination: Pagination(),
  total: ''
})

export const OrderListItem = Record({
  id: null,
  offerType: '',
  offerId: null,
  forwarderCompanyId: null,
  carrierCompanyId: null,
  issuerId: null,
  referenceNumber: '',
  transportationType: '',
  containerId: null,
  shipownerId: null,
  loadingUnloadingAddress: '',
  loadingUnloadingCompanyName: '',
  loadingUnloadingDateTime: '',
  createdAt: '',
  acceptedAt: '',
  rejectedAt: '',
  container: Container(),
  shipowner: Shipowner(),
  carrierCompany: Company(),
  forwarderCompany: Company()
})

export const OrderDetails = Record({
  acceptedAt: null,
  additionalInfo: '',
  adrClass: AdrClass(),
  adrClassId: null,
  adrClassUn: null,
  availableCustomsClearanceTypes: null,
  booking: '',
  cargoName: '',
  carrierCompanyId: null,
  carrierUserId: null,
  conditions: '',
  containerId: null,
  containerNumber: '',
  containerSemiTrailerRegistrationPlate: '',
  createdAt: null,
  currency: '',
  customsClearanceAddress: '',
  customsClearanceType: '',
  driverIdentityCardNumber: '',
  driverName: '',
  forwarderCompanyId: '',
  forwarderUserId: null,
  id: null,
  issuePlace: '',
  issuerId: null,
  loadingUnloadingAddress: null,
  loadingUnloadingCompanyName: '',
  loadingUnloadingDateTime: null,
  loadingUnloadingTime: null,
  loadingUnloadingDate: null,
  offerId: null,
  offerType: '',
  pickUpAddress: '',
  pickUpRef: '',
  pin: '',
  referenceNumber: '',
  rejectedAt: null,
  rejectionReason: '',
  returnAddress: '',
  returnRef: '',
  sealNumber: '',
  shipownerId: null,
  state: API_STATES.PRISTINE,
  transportationType: '',
  updatedAt: null,
  vehicleRegistrationPlate: '',
  weight: '',
  carrierUser: Contractor(),
  container: Container(),
  forwarderUser: Contractor(),
  offer: ExportOffer(),
  price: Currency(),
  shipowner: Shipowner()
})

export const OrderEdit = Record({
  state: API_STATES.PRISTINE,
  offerType: '',
  offerId: null,
  transportationType: '',
  containerId: null,
  shipownerId: null,
  containerNumber: '',
  weight: '',
  sealNumber: '',
  pickUpAddress: '',
  pin: '',
  pickUpRef: '',
  returnAddress: '',
  booking: '',
  returnRef: '',
  loadingUnloadingAddress: '',
  loadingUnloadingDateTime: '',
  loadingUnloadingDate: '',
  loadingUnloadingTime: '',
  loadingUnloadingCompanyName: '',
  customsClearanceType: '',
  customsClearanceAddress: '',
  driverName: '',
  driverIdentityCardNumber: '',
  vehicleRegistrationPlate: '',
  containerSemiTrailerRegistrationPlate: '',
  price: '',
  currency: '',
  cargoName: '',
  adrClassId: '',
  adrClassUn: '',
  issuePlace: '',
  additionalInfo: '',
  conditions: ''
})
