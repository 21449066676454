import axios from 'axios'
import applyConverters from 'axios-case-converter'
import { setupCache } from 'axios-cache-adapter'
import { navigate } from 'gatsby-plugin-intl'
import qs from 'qs'
import { pathOr, values, flatten, join, pipe, path } from 'ramda'
import routes from 'src/utils/routes'
import { store } from 'src/ducks/storeProvider'
import { getCurrentUserDetailsRoutine } from 'src/features/account/duck/actions'
import translate from 'src/intl/translate'
import { SAFT_NAMES_ARRAY } from 'src/features/invoices/duck/consts'
import { setSnackbarValuesRoutine } from 'src/ducks/actions'

const cache = setupCache({
  maxAge: 0,
  key: req => {
    return req.url + JSON.stringify(req.params) + req.headers['Accept-Language'];
  },
  exclude: { query: false }
})

// const cache = setupCache({
//   maxAge: 0,
//   exclude: { query: false }
// })

const settings = {
  baseURL: process.env.GATSBY_API_URL,
  withCredentials: true, //used to send cookies to API
  adapter: cache.adapter,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

// prevent lowercase saft obj keys while creating invoice
const caseConverterOptions = {
  preservedKeys: SAFT_NAMES_ARRAY
}

const API = applyConverters(axios.create(settings), caseConverterOptions)

API.interceptors.response.use(
  successRes =>
    pathOr(false, ['data', 'data'], successRes)
      ? {
          ...successRes,
          data: successRes.data.data,
          response: successRes.data
        }
      : { ...successRes, data: successRes.data },
  async ({ response }) => {
    if (response?.status === 401) {
      APIInterceptor.redirectToAuthorize()
    }
    if (response?.data.code === 40301) {
      //40301 error = terms of service not accepted
      //get userDetails for termsOfServiceAcceptedAt flag
      store.dispatch(getCurrentUserDetailsRoutine())
    }
    if (response.data.code === 42200) {
      const message = pathOr(
        translate().formatMessage({
          id: 'common.errorMessage'
        }),
        ['data', 'message'],
        response
      )

      const messageDescription = pipe(
        path(['data', 'errors']),
        values,
        flatten,
        join('\n')
      )(response)

      store.dispatch(
        setSnackbarValuesRoutine({
          title: message,
          message: messageDescription,
          type: 'alert'
        })
      )
    }
    return Promise.reject(response)
  }
)

export default class APIInterceptor {
  static mergeConfig(config = {}) {
    return {
      ...config,
      headers: {
        'Accept-Language': translate().locale,
        ...config.headers
      }
    }
  }

  static get(url, config) {
    return API.get(url, this.mergeConfig(config))
  }

  static post(url, data, config) {
    return API.post(url, data, this.mergeConfig(config))
  }

  static put(url, data, config) {
    return API.put(url, data, this.mergeConfig(config))
  }

  static patch(url, data, config) {
    return API.patch(url, data, this.mergeConfig(config))
  }

  static delete(url, data, config) {
    return API.delete(url, data, this.mergeConfig(config))
  }

  static redirectToAuthorize() {
    const { redirect } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })

    const removeLangFromUrl = url =>
      url.replace(/\/pl\/|\/de\/|\/en\/|\/ru\//, '/')

    //remove sign in page from redirect flow
    if (window.location.pathname.includes('/login')) return

    if (redirect) {
      navigate(`${routes.SIGN_IN}?redirect=${removeLangFromUrl(redirect)}`)
    } else {
      navigate(
        `${routes.SIGN_IN}?redirect=${removeLangFromUrl(
          window.location.pathname
        )}&${window.location.search.replace('?', '')}`
      )
    }
  }
}
