import {
  reduce,
  assocPath,
  prop,
  filter,
  pipe,
  includes,
  not,
  map,
  concat,
  pick
} from 'ramda'
import { filterEmptyStrings, filterNullValues } from 'src/utils/helpers'
import { REQUIRED_DOCUMENTS_TYPES } from 'src/features/account/duck/consts'
import { CurrentUser, User, Image, MyCompany } from './records'

export const normalizeUserPermissions = arr =>
  reduce((acc, item) => assocPath(item.split('.'), true, acc), {}, arr)

export const normalizeCurrentUserData = obj =>
  new CurrentUser({
    ...obj,
    company: MyCompany({ ...obj.company }),
    avatar: Image({ ...obj.avatar })
  })

export const normalizeCoworkersData = arr =>
  arr.map(
    coworker =>
      new User({
        ...coworker,
        avatar: Image({ ...coworker.avatar })
      })
  )

export const normalizeMyCompany = obj => {
  const requiredDocuments = REQUIRED_DOCUMENTS_TYPES[obj.businessProfile]
  const currentTypes = map(prop(['type']), obj.documents)

  const documents = pipe(
    filter(a => not(includes(a, currentTypes))),
    map(doc => ({
      type: doc,
      url: null,
      validFrom: null,
      validTo: null
    })),
    concat(obj.documents)
  )(requiredDocuments)

  return new MyCompany({
    ...obj,
    logo: Image({ ...obj.logo }),
    documents
  })
}

export const normalizeAddUserForm = pipe(
  pick([
    'avatar',
    'contactInfo',
    'email',
    'firstName',
    'lang',
    'lastName',
    'password',
    'phoneNumber',
    'role',
    'username'
  ]),
  filterEmptyStrings,
  filterNullValues
)

export const normalizeEditUserForm = pipe(
  pick([
    'contactInfo',
    'email',
    'id',
    'lang',
    'password',
    'phoneNumber',
    'role',
    'username',
    'firstName',
    'lastName'
  ]),
  filterEmptyStrings,
  filterNullValues
)

export const normalizeContactForm = pipe(
  pick(['message', 'subject', 'category']),
  filterEmptyStrings,
  filterNullValues
)
