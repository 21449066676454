import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  takeLatest,
  select,
  takeLeading
} from '@redux-saga/core/effects'
import qs from 'qs'
import { pick, pipe, prop, propOr, toLower } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import { changeLocale, navigate } from 'gatsby-plugin-intl'
import appRegistry from 'src/services/AppRegistry'
import AccountService from 'src/services/AccountService'
import { ERROR_DELAY_TIME, IMPERSONATE_SESSION_KEY } from 'src/ducks/consts'
import translate, { DEFAULT_LANG } from 'src/intl/translate'
import {
  filterEmptyStrings,
  filterNullValues,
  formatHomepageUrl,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'
import CompanyService from 'src/services/CompanyService'
import {
  selectAddUserForm,
  selectContactForm,
  selectEditUserForm
} from 'src/features/account/duck/selectors'
import routes from 'src/utils/routes'
import { MENU_ITEMS, MY_COMPANY_TABS } from 'src/features/account/duck/consts'
import {
  normalizeAddUserForm,
  normalizeContactForm,
  normalizeEditUserForm
} from 'src/features/account/duck/normalizers'

import { setSnackbarValues } from 'src/ducks/actions'
//routines

import { initDictionaries } from 'src/ducks/actions'

export const setCurrentCompanyValueRoutine = createRoutine(
  'SET_CURRENT_COMPANY_VALUE'
)
export const setAddUserFormRoutine = createRoutine('SET_ADD_USER_FORM_VALUE')
export const setEditUserFormRoutine = createRoutine('SET_EDIT_USER_FORM_VALUE')
export const prefillEditUserFormRoutine = createRoutine(
  'PREFILL_EDIT_USER_FORM_VALUE'
)
export const clearAddUserFormRoutine = createRoutine('CLEAR_ADD_USER_FORM')
export const clearEditUserFormRoutine = createRoutine('CLEAR_EDIT_USER_FORM')
export const getUserPermissionsRoutine = createRoutine('GET_USER_PERMISSIONS')
export const getCurrentUserDetailsRoutine = createRoutine(
  'GET_CURRENT_USER_DETAILS'
)
export const clearUserPermissionsRoutine = createRoutine(
  'CLEAR_USER_PERMISSIONS'
)

export const getCurrentUserCoworkersRoutine = createRoutine(
  'GET_CURRENT_USER_COWORKERS'
)

export const updateCurrentUserRoutine = createRoutine('UPDATE_CURRENT_USER')

export const updateCurrentUserLanguageRoutine = createRoutine(
  'UPDATE_CURRENT_USER_LANGUAGE'
)

export const changeCurrentUserPasswordRoutine = createRoutine(
  'CHANGE_CURRENT_USER_PASSWORD'
)

export const updateCurrentCompanyRoutine = createRoutine(
  'UPDATE_CURRENT_COMPANY'
)

export const getCurrentCompanyDetailsRoutine = createRoutine(
  'GET_CURRENT_COMPANY_DETAILS'
)

export const addCurrentCompanyUserRoutine = createRoutine(
  'ADD_CURRENT_COMPANY_USER'
)

export const editCurrentCompanyUserRoutine = createRoutine(
  'EDIT_CURRENT_COMPANY_USER'
)

export const uploadEmployeeAvatarRoutine = createRoutine(
  'UPLOAD_EMPLOYEE_USER_AVATAR'
)

export const uploadUserAvatarRoutine = createRoutine('UPLOAD_USER_AVATAR')

export const deleteCompanyUserRoutine = createRoutine('DELETE_COMPANY_USER')

export const deactivateCompanyUserRoutine = createRoutine(
  'DEACTIVATE_COMPANY_USER'
)

export const toggleChatNotificationsRoutine = createRoutine(
  'TOGGLE_CHAT_NOTIFICATIONS'
)

export const sendContactFormRoutine = createRoutine('SEND_CONTACT_FORM')

export const setContactFormValueRoutine = createRoutine(
  'SET_CONTACT_FORM_VALUE'
)

//actions

function* setCurrentCompanyValue({ payload }) {
  yield put(setCurrentCompanyValueRoutine.success(payload))
}

function* setAddUserFormValue({ payload }) {
  yield put(setAddUserFormRoutine.success(payload))
}

function* setEditUserFormValue({ payload }) {
  yield put(setEditUserFormRoutine.success(payload))
}

function* prefillEditUserForm({ payload }) {
  yield put(prefillEditUserFormRoutine.success(payload))
}

export function* getCurrentUserDetails() {
  yield put(getCurrentUserDetailsRoutine.request())
  try {
    const { data } = yield call(AccountService.currentUserDetails)
    yield put(getCurrentUserDetailsRoutine.success({ data }))
    //set the language to the one saved in the profile
    const language = toLower(propOr(DEFAULT_LANG, 'lang', data))

    changeLocale(language)

  } catch (e) {
    yield put(getCurrentUserDetailsRoutine.failure())
    console.log(e)
  }
}

function* getCurrentCompanyDetails() {
  yield put(getCurrentCompanyDetailsRoutine.request())
  try {
    const { data } = yield call(CompanyService.currentCompanyDetails)
    yield put(getCurrentCompanyDetailsRoutine.success({ data }))
  } catch (e) {
    yield put(getCurrentCompanyDetailsRoutine.failure())
    console.log(e)
  }
}

function* updateCurrentUser({ payload }) {
  yield put(updateCurrentUserRoutine.request())
  try {
    const values = pipe(
      pick(['phoneNumber', 'contactInfo', 'firstName', 'lastName']),
      filterNullValues
    )(payload)
    const { data } = yield call(AccountService.updateCurrentUser, values)
    yield put(updateCurrentUserRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(updateCurrentUserRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(updateCurrentUserRoutine.fulfill())
  }
}

function* addCurrentCompanyUser() {
  yield put(addCurrentCompanyUserRoutine.request())
  try {
    const payload = yield select(selectAddUserForm)
    const { data } = yield call(
      CompanyService.addNewCompanyUser,
      normalizeAddUserForm(payload.toJS())
    )
    yield put(addCurrentCompanyUserRoutine.success({ data }))
    navigate(`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`, {
      state: {
        activeTab: MY_COMPANY_TABS.EMPLOYEES
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(addCurrentCompanyUserRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(addCurrentCompanyUserRoutine.fulfill())
  }
}

function* editCurrentCompanyUser() {
  yield put(editCurrentCompanyUserRoutine.request())
  try {
    const payload = yield select(selectEditUserForm)
    const { data } = yield call(
      CompanyService.editCompanyUser,
      normalizeEditUserForm(payload.toJS())
    )
    navigate(`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`, {
      state: {
        activeTab: MY_COMPANY_TABS.EMPLOYEES
      }
    })
    yield put(editCurrentCompanyUserRoutine.success({ data }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.updatedSuccessfully'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(editCurrentCompanyUserRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        })
      }
    })
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(editCurrentCompanyUserRoutine.fulfill())
  }
}

function* deleteCompanyUser() {
  yield put(deleteCompanyUserRoutine.request())
  try {
    const payload = yield select(selectEditUserForm)
    const id = prop('id', payload.toJS())
    const { data } = yield call(CompanyService.deleteCompanyUser, id)
    yield put(deleteCompanyUserRoutine.success({ data }))
    navigate(`${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`, {
      state: {
        activeTab: MY_COMPANY_TABS.EMPLOYEES
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(deleteCompanyUserRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(deleteCompanyUserRoutine.fulfill())
  }
}

function* deactivateCompanyUser() {
  yield put(deactivateCompanyUserRoutine.request())
  try {
    const payload = yield select(selectEditUserForm)
    const { data } = yield call(
      CompanyService.deactivateCompanyUser,
      payload.id
    )
    yield put(deactivateCompanyUserRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(deactivateCompanyUserRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(deactivateCompanyUserRoutine.fulfill())
  }
}

function* uploadUserAvatar({ payload }) {
  yield put(uploadUserAvatarRoutine.request())
  try {
    const { data } = yield call(AccountService.uploadUserAvatar, payload)
    yield put(uploadUserAvatarRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(uploadUserAvatarRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(uploadUserAvatarRoutine.fulfill())
  }
}

function* uploadEmployeeAvatar({ payload }) {
  yield put(uploadEmployeeAvatarRoutine.request())
  try {
    const { data } = yield call(AccountService.uploadEmployeeAvatar, payload)
    yield put(uploadEmployeeAvatarRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(uploadEmployeeAvatarRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(uploadEmployeeAvatarRoutine.fulfill())
  }
}

function* updateCurrentUserLanguage({ payload }) {
  yield put(updateCurrentUserLanguageRoutine.request())

  try {
    const { data } = yield call(AccountService.updateCurrentUser, payload)
    yield put(updateCurrentUserLanguageRoutine.success({ data }))
    const language = toLower(propOr(DEFAULT_LANG, 'lang', data))

    changeLocale(language)

  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(updateCurrentUserLanguageRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(updateCurrentUserLanguageRoutine.fulfill())
  }
}

function* changeCurrentUserPassword({ payload }) {
  yield put(changeCurrentUserPasswordRoutine.request())
  try {
    const { data } = yield call(AccountService.changePassword, payload)
    yield put(changeCurrentUserPasswordRoutine.success({ data }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forgottenPassword.passwordChanged'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(changeCurrentUserPasswordRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forgottenPassword.passwordChangedError'
        }),
        type: 'alert'
      }
    })
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(changeCurrentUserPasswordRoutine.fulfill())
  }
}

function* updateCurrentCompany({ payload }) {
  yield put(updateCurrentCompanyRoutine.request())
  const filteredValues = pipe(filterEmptyStrings, filterNullValues)(payload)
  const homepageUrl = formatHomepageUrl(propOr(null, 'homepageUrl', payload))
  const values = {
    ...filteredValues,
    homepageUrl
  }
  try {
    const { data } = yield call(CompanyService.updateCurrentCompany, values)
    yield put(updateCurrentCompanyRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(updateCurrentCompanyRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(updateCurrentCompanyRoutine.fulfill())
  }
}

export function* getUserPermissions() {
  try {
    const { data } = yield call(AccountService.permissions)
    yield put(getUserPermissionsRoutine.success({ data }))
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    //search for ?impersonate=1 to check if active impersonalization takes place
    appRegistry.session.set(
      IMPERSONATE_SESSION_KEY,
      !isFalsy(params.impersonate) ? true : null
    )
  } catch (e) {
    console.log(e)
  }
}

function* clearUserPermissions() {
  yield put(clearUserPermissionsRoutine.success())
}

function* clearEditUserForm() {
  yield put(clearEditUserFormRoutine.success())
}

function* clearAddUserForm() {
  yield put(clearAddUserFormRoutine.success())
}

function* getCurrentUserCoworkers() {
  try {
    const { data } = yield call(CompanyService.showCurrentCompanyUsers)
    yield put(getCurrentUserCoworkersRoutine.success({ data }))
  } catch (e) {
    console.log(e)
  }
}

export function* toggleChatNotifications({ payload }) {
  yield put(toggleChatNotificationsRoutine.success(payload))
}

function* setContactFormValue({ payload }) {
  yield put(setContactFormValueRoutine.success(payload))
}

function* sendContactForm() {
  yield put(sendContactFormRoutine.request())
  try {
    const payload = yield select(selectContactForm)
    yield call(
      AccountService.sendContactForm,
      normalizeContactForm(payload.toJS())
    )
    yield put(sendContactFormRoutine.success())
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(sendContactFormRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(sendContactFormRoutine.fulfill())
  }
}

//watchers

function* setCurrentCompanyValueWatcher() {
  yield takeLatest(
    setCurrentCompanyValueRoutine.TRIGGER,
    setCurrentCompanyValue
  )
}

function* setAddUserFormValueWatcher() {
  yield takeLatest(setAddUserFormRoutine.TRIGGER, setAddUserFormValue)
}

function* setEditUserFormValueWatcher() {
  yield takeLatest(setEditUserFormRoutine.TRIGGER, setEditUserFormValue)
}

function* prefillEditUserFormWatcher() {
  yield takeLatest(prefillEditUserFormRoutine.TRIGGER, prefillEditUserForm)
}

function* getUserPermissionsWatcher() {
  yield takeLatest(getUserPermissionsRoutine.TRIGGER, getUserPermissions)
}

function* clearUserPermissionsWatcher() {
  yield takeLatest(clearUserPermissionsRoutine.TRIGGER, clearUserPermissions)
}

function* getCurrentUserDetailsWatcher() {
  yield takeLeading(getCurrentUserDetailsRoutine.TRIGGER, getCurrentUserDetails)
}

function* getCurrentUserCoworkersWatcher() {
  yield takeLatest(
    [
      getCurrentUserCoworkersRoutine.TRIGGER,
      uploadEmployeeAvatarRoutine.SUCCESS
    ],
    getCurrentUserCoworkers
  )
}

function* updateCurrentUserWatcher() {
  yield takeLatest(updateCurrentUserRoutine.TRIGGER, updateCurrentUser)
}

function* updateCurrentUserLanguageWatcher() {
  yield takeLatest(
    updateCurrentUserLanguageRoutine.TRIGGER,
    updateCurrentUserLanguage
  )
}

function* changeCurrentUserPasswordWatcher() {
  yield takeLatest(
    changeCurrentUserPasswordRoutine.TRIGGER,
    changeCurrentUserPassword
  )
}

function* updateCurrentCompanyWatcher() {
  yield takeLatest(updateCurrentCompanyRoutine.TRIGGER, updateCurrentCompany)
}

function* getCurrentCompanyDetailsWatcher() {
  yield takeLatest(
    getCurrentCompanyDetailsRoutine.TRIGGER,
    getCurrentCompanyDetails
  )
}

function* uploadUserAvatarWatcher() {
  yield takeLatest(uploadUserAvatarRoutine.TRIGGER, uploadUserAvatar)
}

function* uploadEmployeeAvatarWatcher() {
  yield takeLatest(uploadEmployeeAvatarRoutine.TRIGGER, uploadEmployeeAvatar)
}

function* addCurrentCompanyUserWatcher() {
  yield takeLatest(addCurrentCompanyUserRoutine.TRIGGER, addCurrentCompanyUser)
}

function* editCurrentCompanyUserWatcher() {
  yield takeLatest(
    editCurrentCompanyUserRoutine.TRIGGER,
    editCurrentCompanyUser
  )
}

function* deactivateCompanyUserWatcher() {
  yield takeLatest(deactivateCompanyUserRoutine.TRIGGER, deactivateCompanyUser)
}

function* deleteCompanyUserWatcher() {
  yield takeLatest(deleteCompanyUserRoutine.TRIGGER, deleteCompanyUser)
}

function* clearEditUserFormWatcher() {
  yield takeLatest(clearEditUserFormRoutine.TRIGGER, clearEditUserForm)
}

function* clearAddUserFormWatcher() {
  yield takeLatest(clearAddUserFormRoutine.TRIGGER, clearAddUserForm)
}

function* setContactFormValueWatcher() {
  yield takeLatest(setContactFormValueRoutine.TRIGGER, setContactFormValue)
}

function* sendContactFormWatcher() {
  yield takeLatest(sendContactFormRoutine.TRIGGER, sendContactForm)
}

function* toggleChatNotificationsWatcher() {
  yield takeLatest(
    toggleChatNotificationsRoutine.TRIGGER,
    toggleChatNotifications
  )
}

export const accountSagas = [
  fork(setCurrentCompanyValueWatcher),
  fork(setAddUserFormValueWatcher),
  fork(setEditUserFormValueWatcher),
  fork(getUserPermissionsWatcher),
  fork(clearUserPermissionsWatcher),
  fork(getCurrentUserDetailsWatcher),
  fork(getCurrentUserCoworkersWatcher),
  fork(updateCurrentUserWatcher),
  fork(updateCurrentUserLanguageWatcher),
  fork(changeCurrentUserPasswordWatcher),
  fork(updateCurrentCompanyWatcher),
  fork(getCurrentCompanyDetailsWatcher),
  fork(uploadUserAvatarWatcher),
  fork(addCurrentCompanyUserWatcher),
  fork(editCurrentCompanyUserWatcher),
  fork(deactivateCompanyUserWatcher),
  fork(deleteCompanyUserWatcher),
  fork(clearAddUserFormWatcher),
  fork(clearEditUserFormWatcher),
  fork(prefillEditUserFormWatcher),
  fork(uploadEmployeeAvatarWatcher),
  fork(toggleChatNotificationsWatcher),
  fork(sendContactFormWatcher),
  fork(setContactFormValueWatcher)
]
