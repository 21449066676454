import { API_STATES } from 'src/ducks/consts'
import { DebtCollection } from 'src/features/debtCollection/duck/records'

import { normalizeDebtConfirmation } from 'src/features/debtCollection/duck/normalizers'
import {
  createDebtCollectionExportRoutine,
  selectOfferForDebtCollectionRoutine,
  setAdditionalInfoRoutine,
  setDebtCollectionFilesRoutine,
  clearDebtCollectionRoutine,
  createDebtCollectionPostImportRoutine,
  createDebtCollectionFreeCarriersRoutine,
  createDebtCollectionImportRoutine
} from './actions'

export const debtCollectionReducer = (state = new DebtCollection(), action) => {
  switch (action.type) {
    case createDebtCollectionExportRoutine.REQUEST:
    case createDebtCollectionImportRoutine.REQUEST:
    case createDebtCollectionPostImportRoutine.REQUEST:
    case createDebtCollectionFreeCarriersRoutine.REQUEST:
    case selectOfferForDebtCollectionRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createDebtCollectionExportRoutine.SUCCESS:
    case createDebtCollectionImportRoutine.SUCCESS:
    case createDebtCollectionPostImportRoutine.SUCCESS:
    case createDebtCollectionFreeCarriersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('confirmation', normalizeDebtConfirmation(action.payload.data))
    case createDebtCollectionExportRoutine.FAILURE:
    case createDebtCollectionImportRoutine.FAILURE:
    case createDebtCollectionPostImportRoutine.FAILURE:
    case createDebtCollectionFreeCarriersRoutine.FAILURE:
    case selectOfferForDebtCollectionRoutine.FAILURE:
      return state
        .set('state', API_STATES.ERROR)
        .set('errorMessage', action.payload.message)
    case createDebtCollectionExportRoutine.FULFILL:
    case createDebtCollectionImportRoutine.FULFILL:
    case createDebtCollectionPostImportRoutine.FULFILL:
    case createDebtCollectionFreeCarriersRoutine.FULFILL:
    case selectOfferForDebtCollectionRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case selectOfferForDebtCollectionRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', null)
        .set('selectedOffer', action.payload)
    case setAdditionalInfoRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', null)
        .set('additionalInfo', action.payload)
    case setDebtCollectionFilesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', null)
        .set('files', action.payload)
    case clearDebtCollectionRoutine.SUCCESS:
      return new DebtCollection()
    default:
      return state
  }
}
