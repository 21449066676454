import { pathOr, head, pathEq, keys, propOr, isNil, groupBy } from 'ramda'
import moment from 'moment'
import { renameKeysWith } from 'ramda-adjunct'
import camelCase from 'lodash.camelcase'
import { addPostalCodeToName } from 'src/utils/helpers'
import {
  Interlocutor,
  Message,
  LastMessage,
  FoundUser,
  Context
} from './records'

export const normalizeToInterlocutor = user =>
  new Interlocutor({
    ...user,
    avatar: pathOr('', ['avatar', 'url'], user),
    companyName: pathOr('', ['company', 'name'], user),
    companyId: pathOr(null, ['company', 'id'], user)
  })

export const normalizeMessagesList = (list = [], currentUserId) => {
  const mapped = list.map(
    message =>
      new Message({
        ...message,
        text: message.message,
        avatar: pathOr('', ['sender', 'avatar', 'url'], message),
        userName: `${pathOr('', ['sender', 'firstName'], message)} ${pathOr(
          '',
          ['sender', 'lastName'],
          message
        )}`,
        isInterlocutorMessage:
          !pathEq(['sender'], null, message) &&
          !pathEq(['sender', 'id'], currentUserId, message),
        isSystemMessage: pathEq(['sender'], null, message)
      })
  )
  const grouped = groupBy(
    elem => elem.createdAt.split(':')[0],
    mapped.reverse()
  )
  return keys(grouped)
    .sort()
    .map(key => grouped[key])
}

const toCamelCaseDeep = obj => {
  const normalized = renameKeysWith(camelCase)(obj)
  return keys(normalized).reduce((acc, key) => {
    //parse nested objects
    acc[key] =
      typeof normalized[key] === 'object' && !(normalized[key] instanceof Array)
        ? renameKeysWith(camelCase)(normalized[key])
        : normalized[key]
    return acc
  }, {})
}

export const normalizeReceivedMessage = message => {
  const normalized = toCamelCaseDeep(message)
  return new Message({
    text: normalized.message,
    avatar: pathOr('', ['sender', 'avatar', 'url'], normalized),
    userName: `${pathOr('', ['sender', 'firstName'], normalized)} ${pathOr(
      '',
      ['sender', 'lastName'],
      normalized
    )}`,
    isInterlocutorMessage: !pathEq(['sender'], null, message),
    isSystemMessage: pathEq(['sender'], null, message)
  })
}

export const normalizeSentMessage = text =>
  new Message({ text, createdAt: moment().format() })

const normalizeContextDate = str => moment(str).format('DD.MM')

export const normalizeContext = (contextType, context) => {
  switch (contextType) {
    case 'export_cargo_offer':
      return new Context({
        ...context,
        from: addPostalCodeToName(context.loadingLocation)?.name,
        fromDate: normalizeContextDate(context.loadingDate),
        fromTime: context.loadingTime,
        to: addPostalCodeToName(context.returnLocation)?.name,
        toDate: normalizeContextDate(context.returnDate),
        toTime: context.returnTime,
        container: pathOr('', ['container', 'name'], context),
        shipowner: pathOr('', ['shipowner', 'name'], context),
        belongsToTheCurrentCompany:
          pathOr(null, ['issuer', 'companyId'], context) ===
          context.currentUserCompanyId,
        contextType
      })
    case 'import_cargo_offer':
      return new Context({
        ...context,
        from: addPostalCodeToName(context.pickupLocation)?.name,
        fromDate: normalizeContextDate(context.pickupDate),
        fromTime: context.pickupTime,
        to: addPostalCodeToName(context.unloadingLocation)?.name,
        toDate: normalizeContextDate(context.unloadingDate),
        toTime: context.unloadingTime,
        container: pathOr('', ['container', 'name'], context),
        shipowner: pathOr('', ['shipowner', 'name'], context),
        belongsToTheCurrentCompany:
          pathOr(null, ['issuer', 'companyId'], context) ===
          context.currentUserCompanyId,
        contextType
      })
    case 'post_import_container_offer':
      return new Context({
        ...context,
        from: addPostalCodeToName(context.availabilityLocation)?.name,
        fromDate: normalizeContextDate(context.availabilityDate),
        fromTime: context.availabilityTime,
        to: addPostalCodeToName(context.destinationLocation)?.name,
        container: pathOr('', ['container', 'name'], context),
        shipowner: pathOr('', ['shipowner', 'name'], context),
        belongsToTheCurrentCompany:
          pathOr(null, ['issuer', 'companyId'], context) ===
          context.currentUserCompanyId,
        contextType
      })
    case 'free_carrier_offer':
      return new Context({
        ...context,
        from: addPostalCodeToName(context.availabilityLocation)?.name,
        fromDate: normalizeContextDate(context.availabilityDate),
        fromTime: context.availabilityTime,
        to: addPostalCodeToName(context.destinationLocation)?.name,
        container: pathOr('', ['container', 'name'], context),
        shipowner: pathOr('', ['shipowner', 'name'], context),
        belongsToTheCurrentCompany:
          pathOr(null, ['issuer', 'companyId'], context) ===
          context.currentUserCompanyId,
        contextType
      })
    default:
      return new Context()
  }
}

export const normalizeLastMessagesList = ({
  list,
  currentUserId,
  messagesType
}) =>
  list.map(val => {
    const interlocutor = head(
      pathOr([], ['participants'], val).filter(v => v.id !== currentUserId)
    )
    const isInterlocutorMessage = !pathEq(
      ['lastMessage', 'senderId'],
      currentUserId,
      val
    )

    const isSystemMessage = pathEq(['lastMessage', 'sender'], null, val)

    return new LastMessage({
      ...val,
      interlocutorId: interlocutor.id,
      avatar: pathOr('', ['avatar', 'url'], interlocutor),
      text: pathOr('', ['lastMessage', 'message'], val),
      userName: `${pathOr('', ['firstName'], interlocutor)} ${pathOr(
        '',
        ['lastName'],
        interlocutor
      )}`,
      companyName: pathOr('Company name', ['company', 'name'], interlocutor),
      isInterlocutorMessage: isInterlocutorMessage && !isSystemMessage,
      //message is mark as seen if last message was send by current user or by system
      messageSeen:
        !isInterlocutorMessage || isSystemMessage
          ? true
          : !pathEq(['lastMessage', 'seenAt'], null, val),
      messageSeenAt: pathOr(null, ['lastMessage', 'seenAt'], val),
      messageType: messagesType,
      createdAt: moment(pathOr('', ['lastMessageCreatedAt'], val)).calendar(
        null,
        {
          sameDay: 'HH:mm',
          nextDay: 'HH:mm',
          lastDay: 'DD.MM',
          lastWeek: 'DD.MM',
          sameElse: 'DD.MM'
        }
      ),
      context: !isNil(val.context)
        ? normalizeContext(val.contextType, {
            ...val.context,
            companyName: pathOr(
              'Company name',
              ['company', 'name'],
              interlocutor
            )
          })
        : null
    })
  })

export const normalizeFoundUsers = (arr = []) =>
  arr.map(
    user =>
      new FoundUser({
        ...user,
        userName: `${propOr('', 'firstName', user)} ${propOr(
          '',
          'lastName',
          user
        )}`,
        companyName: pathOr('', ['company', 'name'], user),
        avatar: pathOr('', ['avatar', 'url'], user)
      })
  )
