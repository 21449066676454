import {
  call,
  put,
  takeLatest,
  fork,
  select,
  delay,
  takeEvery
} from '@redux-saga/core/effects'
import { createRoutine } from 'redux-saga-routines'
import ContractService from 'src/services/ContractService'
import {
  selectContractsFilters,
  selectMyOffersContractsFiltersFormatted,
  selectMyOffersFinishedContractsFiltersFormatted,
  selectMyReservationsContractsFiltersFormatted,
  selectMyReservationsFinishedContractsFiltersFormatted
} from 'src/features/contracts/duck/selectors'
import { ERROR_DELAY_TIME } from 'src/ducks/consts'
import ReservationService from 'src/services/ReservationService'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import { getErrorMessageFromApiResponse } from 'src/utils/helpers'

export const getContractOffersListRoutine = createRoutine(
  'GET_CONTRACT_OFFERS_LIST'
)

export const setContractDrawerRoutine = createRoutine('SET_CONTRACT_DRAWER')

export const getContractOfferRoutine = createRoutine('GET_CONTRACT_OFFER')

export const deleteContractOfferRoutine = createRoutine('DELETE_CONTRACT_OFFER')

export const updateContractOfferRoutine = createRoutine('UPDATE_CONTRACT_OFFER')

export const getMyOngoingContractOffersRoutine = createRoutine(
  'GET_MY_ONGOING_CONTRACT_OFFERS'
)
export const getMyFinishedContractOffersRoutine = createRoutine(
  'GET_MY_FINISHED_CONTRACT_OFFERS'
)

export const getMyFinishedContractReservationsRoutine = createRoutine(
  'GET_MY_FINISHED_CONTRACT_RESERVATIONS'
)
export const getMyOngoingContractReservationsRoutine = createRoutine(
  'GET_MY_ONGOING_CONTRACT_RESERVATIONS'
)
export const reserveContractOfferRoutine = createRoutine(
  'RESERVE_CONTRACT_OFFER'
)
export const showFinishedContractOfferReservationsRoutine = createRoutine(
  'SHOW_FINISHED_CONTRACT_OFFER_RESERVATIONS'
)
export const showOngoingContractOfferReservationsRoutine = createRoutine(
  'SHOW_ONGOING_CONTRACT_OFFER_RESERVATIONS'
)
export const cancelContractReservationRoutine = createRoutine(
  'CANCEL_CONTRACT_RESERVATION'
)
export const acceptContractReservationRoutine = createRoutine(
  'ACCEPT_CONTRACT_RESERVATION'
)

export const setContractOffersFiltersRoutine = createRoutine(
  'SET_CONTRACT_OFFERS_FILTERS'
)
export const setMyOngoingContractOffersFiltersRoutine = createRoutine(
  'SET_MY_ONGOING_CONTRACT_OFFERS_FILTERS'
)
export const setMyFinishedContractOffersFiltersRoutine = createRoutine(
  'SET_MY_FINISHED_CONTRACT_OFFERS_FILTERS'
)
export const setMyOngoingContractReservationsFiltersRoutine = createRoutine(
  'SET_MY_ONGOING_CONTRACT_RESERVATIONS_FILTERS'
)
export const setMyFinishedContractReservationsFiltersRoutine = createRoutine(
  'SET_MY_FINISHED_CONTRACT_RESERVATIONS_FILTERS'
)
export const cleanContractsDetailsRoutine = createRoutine(
  'CLEAN_CONTRACT_DETAILS'
)

//ACTIONS

function* getContractOffersList() {
  yield put(getContractOffersListRoutine.request())
  try {
    const filters = yield select(selectContractsFilters)
    const {
      response: { data, meta }
    } = yield call(ContractService.getContractOffersList, filters.toJS())
    yield put(getContractOffersListRoutine.success({ data, meta }))
  } catch (e) {
    yield put(getContractOffersListRoutine.failure())
    console.log(e)
  }
}

function* setContractOffersFilters({ payload }) {
  yield put(setContractOffersFiltersRoutine.request())
  try {
    yield put(setContractOffersFiltersRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}

function* setMyOngoingContractOffersFilters({ payload }) {
  yield put(setMyOngoingContractOffersFiltersRoutine.request())
  try {
    yield put(setMyOngoingContractOffersFiltersRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}

function* setMyFinishedContractOffersFilters({ payload }) {
  yield put(setMyFinishedContractOffersFiltersRoutine.request())
  try {
    yield put(setMyFinishedContractOffersFiltersRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}

function* setMyOngoingContractReservationsFilters({ payload }) {
  yield put(setMyOngoingContractReservationsFiltersRoutine.request())
  try {
    yield put(setMyOngoingContractReservationsFiltersRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}

function* setMyFinishedContractReservationsFilters({ payload }) {
  yield put(setMyFinishedContractReservationsFiltersRoutine.request())
  try {
    yield put(setMyFinishedContractReservationsFiltersRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}

function* getContractOffer({ payload }) {
  yield put(getContractOfferRoutine.request())
  try {
    const { data } = yield call(ContractService.getContractOffer, payload)
    yield put(getContractOfferRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getContractOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getContractOfferRoutine.fulfill())
  }
}

function* reserveContractOffer({ payload }) {
  yield put(reserveContractOfferRoutine.request())
  try {
    yield call(ContractService.reserveContractOffer, payload)
    yield put(reserveContractOfferRoutine.success())
    yield* getContractOffer({ payload })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(reserveContractOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(reserveContractOfferRoutine.fulfill())
  }
}

function* deleteContractOffer({ payload }) {
  yield put(deleteContractOfferRoutine.request())
  try {
    yield call(ContractService.deleteContractOffer, payload.id)
    yield put(deleteContractOfferRoutine.success())
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(deleteContractOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(deleteContractOfferRoutine.fulfill())
  }
}

function* getMyOngoingContractOffers() {
  yield put(getMyOngoingContractOffersRoutine.request())
  try {
    const filters = yield select(selectMyOffersContractsFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(ContractService.getMyOngoingContractOffers, filters)
    yield put(getMyOngoingContractOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyOngoingContractOffersRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyOngoingContractOffersRoutine.fulfill())
  }
}

function* getMyFinishedContractOffers() {
  yield put(getMyFinishedContractOffersRoutine.request())
  try {
    const filters = yield select(
      selectMyOffersFinishedContractsFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(ContractService.getMyFinishedContractOffers, filters)
    yield put(getMyFinishedContractOffersRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyFinishedContractOffersRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyFinishedContractOffersRoutine.fulfill())
  }
}

function* getMyOngoingContractReservations() {
  yield put(getMyOngoingContractReservationsRoutine.request())
  try {
    const filters = yield select(selectMyReservationsContractsFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(ContractService.getMyOngoingContractReservations, filters)
    yield put(getMyOngoingContractReservationsRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyOngoingContractReservationsRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyOngoingContractReservationsRoutine.fulfill())
  }
}

function* getMyFinishedContractReservations() {
  yield put(getMyFinishedContractReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsFinishedContractsFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(ContractService.getMyFinishedContractReservations, filters)
    yield put(getMyFinishedContractReservationsRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyFinishedContractReservationsRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyFinishedContractReservationsRoutine.fulfill())
  }
}

function* showOngoingContractOfferReservations({ payload }) {
  yield put(showOngoingContractOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.showOngoingContractOfferReservations,
      payload.id
    )
    yield put(showOngoingContractOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* showFinishedContractOfferReservations({ payload }) {
  yield put(showFinishedContractOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.showFinishedContractOfferReservations,
      payload.id
    )
    yield put(showFinishedContractOfferReservationsRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function* acceptContractReservation({ payload }) {
  yield put(acceptContractReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.acceptContractReservation,
      payload.offerId,
      payload.reservationId
    )
    if (payload.onSuccess) {
      payload.onSuccess()
    }
    yield put(acceptContractReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.acceptOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(acceptContractReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(acceptContractReservationRoutine.fulfill())
  }
}

function* cleanContractsDetails() {
  yield put(cleanContractsDetailsRoutine.success())
}

function* setContractDrawer({ payload }) {
  yield put(setContractDrawerRoutine.success(payload))
}

function* cancelContractReservation({ payload }) {
  yield put(cancelContractReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.cancelContractReservation,
      payload.offerId,
      payload.reservationId,
      payload.reason
    )
    yield put(cancelContractReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'reservations.cancelReservationSuccessMessage'
        }),
        type: 'success'
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(cancelContractReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(cancelContractReservationRoutine.fulfill())
  }
}

// WATCHERS

function* cleanContractsDetailsRoutineWatcher() {
  yield takeLatest(cleanContractsDetailsRoutine.TRIGGER, cleanContractsDetails)
}

function* getContractOffersListRoutineWatcher() {
  yield takeLatest(
    [
      getContractOffersListRoutine.TRIGGER,
      setContractOffersFiltersRoutine.SUCCESS
    ],
    getContractOffersList
  )
}

function* setContractOffersFiltersRoutineWatcher() {
  yield takeLatest(
    [setContractOffersFiltersRoutine.TRIGGER],
    setContractOffersFilters
  )
}

function* setMyOngoingContractOffersFiltersRoutineWatcher() {
  yield takeLatest(
    [setMyOngoingContractOffersFiltersRoutine.TRIGGER],
    setMyOngoingContractOffersFilters
  )
}

function* setMyFinishedContractOffersFiltersRoutineWatcher() {
  yield takeLatest(
    [setMyFinishedContractOffersFiltersRoutine.TRIGGER],
    setMyFinishedContractOffersFilters
  )
}

function* setMyOngoingContractReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    [setMyOngoingContractReservationsFiltersRoutine.TRIGGER],
    setMyOngoingContractReservationsFilters
  )
}

function* setMyFinishedContractReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    [setMyFinishedContractReservationsFiltersRoutine.TRIGGER],
    setMyFinishedContractReservationsFilters
  )
}

function* getContractOfferRoutineWatcher() {
  yield takeLatest([getContractOfferRoutine.TRIGGER], getContractOffer)
}

function* setContractDrawerRoutineWatcher() {
  yield takeLatest([setContractDrawerRoutine.TRIGGER], setContractDrawer)
}

function* reserveContractOfferRoutineWatcher() {
  yield takeLatest([reserveContractOfferRoutine.TRIGGER], reserveContractOffer)
}

function* deleteContractOfferRoutineWatcher() {
  yield takeLatest([deleteContractOfferRoutine.TRIGGER], deleteContractOffer)
}

function* getMyOngoingContractOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyOngoingContractOffersRoutine.TRIGGER,
      acceptContractReservationRoutine.SUCCESS,
      setMyOngoingContractOffersFiltersRoutine.SUCCESS,
      deleteContractOfferRoutine.SUCCESS
    ],
    getMyOngoingContractOffers
  )
}

function* getMyFinishedContractOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyFinishedContractOffersRoutine.TRIGGER,
      setMyFinishedContractOffersFiltersRoutine.SUCCESS,
      acceptContractReservationRoutine.SUCCESS,
      deleteContractOfferRoutine.SUCCESS
    ],
    getMyFinishedContractOffers
  )
}

function* getMyOngoingContractReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyOngoingContractReservationsRoutine.TRIGGER,
      setMyOngoingContractReservationsFiltersRoutine.SUCCESS,
      cancelContractReservationRoutine.SUCCESS
    ],
    getMyOngoingContractReservations
  )
}

function* getMyFinishedContractReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyFinishedContractReservationsRoutine.TRIGGER,
      setMyFinishedContractReservationsFiltersRoutine.SUCCESS,
      cancelContractReservationRoutine.SUCCESS
    ],
    getMyFinishedContractReservations
  )
}

function* showOngoingContractOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showOngoingContractOfferReservationsRoutine.TRIGGER,
    showOngoingContractOfferReservations
  )
}

function* showFinishedContractOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showFinishedContractOfferReservationsRoutine.TRIGGER,
    showFinishedContractOfferReservations
  )
}

function* acceptContractReservationRoutineWatcher() {
  yield takeLatest(
    acceptContractReservationRoutine.TRIGGER,
    acceptContractReservation
  )
}

function* cancelContractReservationRoutineWatcher() {
  yield takeLatest(
    cancelContractReservationRoutine.TRIGGER,
    cancelContractReservation
  )
}

export const contractSagas = [
  fork(cleanContractsDetailsRoutineWatcher),
  fork(getContractOffersListRoutineWatcher),
  fork(setContractOffersFiltersRoutineWatcher),
  fork(getContractOfferRoutineWatcher),
  fork(setContractDrawerRoutineWatcher),
  fork(reserveContractOfferRoutineWatcher),
  fork(getMyOngoingContractOffersRoutineWatcher),
  fork(getMyFinishedContractOffersRoutineWatcher),
  fork(showOngoingContractOfferReservationsRoutineWatcher),
  fork(showFinishedContractOfferReservationsRoutineWatcher),
  fork(acceptContractReservationRoutineWatcher),
  fork(setMyOngoingContractOffersFiltersRoutineWatcher),
  fork(setMyFinishedContractOffersFiltersRoutineWatcher),
  fork(getMyOngoingContractReservationsRoutineWatcher),
  fork(getMyFinishedContractReservationsRoutineWatcher),
  fork(setMyOngoingContractReservationsFiltersRoutineWatcher),
  fork(setMyFinishedContractReservationsFiltersRoutineWatcher),
  fork(cancelContractReservationRoutineWatcher),
  fork(deleteContractOfferRoutineWatcher)
]
