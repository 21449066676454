import { parseFiltersToQuery } from 'src/utils/helpers'
import api from './APIInterceptor'

export default class OrdersService {
  static getOrdersListIssued(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/transportation-orders/issued?${query}`)
  }

  static getOrdersListReceived(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/transportation-orders/received?${query}`)
  }

  static getOrderDetails(id) {
    return api.get(`/me/transportation-orders/${id}`)
  }

  static createOrder(values) {
    return api.post('/me/transportation-orders/', values)
  }

  static updateOrder(id, values) {
    return api.put(`/me/transportation-orders/${id}`, values)
  }

  static acceptOrder(id) {
    return api.patch(`/me/transportation-orders/${id}/accept`)
  }

  static rejectOrder(id, values) {
    return api.patch(`/me/transportation-orders/${id}/reject`, values)
  }

  static getOrderPdf(id) {
    return api.get(`/me/transportation-orders/${id}/pdf`)
  }
}
