import { map, propOr } from 'ramda'
import { Image } from 'src/features/account/duck/records'
import { DebtConfirmation } from 'src/features/debtCollection/duck/records'
import { Company } from 'src/features/companies/duck/records'

export const normalizeInputFiles = map(file => ({
  image: Image({
    url: URL.createObjectURL(file),
    type: 'blob'
  }),
  size: propOr(0, 'size', file),
  name: file.name
}))

export const normalizeDebtConfirmation = payload =>
  new DebtConfirmation({
    ...payload,
    debtor: new Company(payload.debtor),
    creditor: new Company(payload.creditor)
  })
