import { API_STATES } from 'src/ducks/consts'
import { Companies, Company } from './records'
import {
  searchCompanyRoutine,
  clearCompanySearchResultRoutine,
  clearCompanyPublicDetailsRoutine,
  showCompanyPublicDetailsRoutine,
  getAllMarkedCompaniesRoutine,
  getBlacklistedCompaniesRoutine,
  getWhitelistedCompaniesRoutine
} from './actions'
import { normalizeCompany, normalizeCompanies } from './normalizers'

export const companiesReducer = (state = new Companies(), action) => {
  switch (action.type) {
    case searchCompanyRoutine.REQUEST:
    case showCompanyPublicDetailsRoutine.REQUEST:
    case getAllMarkedCompaniesRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case searchCompanyRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', null)
        .set('searchResult', new Company(action.payload))
    case showCompanyPublicDetailsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('companyDetails', normalizeCompany(action.payload))
    case getAllMarkedCompaniesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('allMarkedCompanies', normalizeCompanies(action.payload))
    case getWhitelistedCompaniesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('whitelist', normalizeCompanies(action.payload))
    case getBlacklistedCompaniesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('blacklist', normalizeCompanies(action.payload))
    case clearCompanySearchResultRoutine.SUCCESS:
      return state
        .set('searchResult', new Company())
        .set('errorMessage', null)
        .set('state', API_STATES.PRISTINE)
    case clearCompanyPublicDetailsRoutine.SUCCESS:
      return state.set('companyDetails', new Company())
    case searchCompanyRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', action.payload.message)
        .set('searchResult', new Company())
    case showCompanyPublicDetailsRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', action.payload.message)
        .set('companyDetails', new Company())
    case searchCompanyRoutine.FULFILL:
      return state.set('state', API_STATES.DONE).set('errorMessage', null)
    case showCompanyPublicDetailsRoutine.FULFILL:
      return state.set('errorMessage', null)
    default:
      return state
  }
}
