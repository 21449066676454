import {
  ExportOffer,
  ImportOffer,
  FreeCarrierOffer,
  PostImportOffer,
  FilterTab,
  FilterTabData
} from 'src/features/stocks/ducks/records'
import {
  Company,
  Container,
  Contractor,
  Currency,
  Location,
  Shipowner,
  Document
} from 'src/ducks/records'

import {
  head,
  isNil,
  evolve,
  pipe,
  assoc,
  pathOr,
  flatten,
  keys,
  reverse,
  findIndex,
  propEq
} from 'ramda'
import moment from 'moment'
import {
  isEmptyArray,
  isEmptyString,
  isArray,
  isFalsy,
  renameKeysWith
} from 'ramda-adjunct'
import camelCase from 'lodash.camelcase'
import {
  removeMsFromTime,
  filterFalsyValues,
  addPostalCodeToName
} from 'src/utils/helpers'
import {
  ADR_OPTIONS,
  GENSET_OPTIONS,
  RESERVATION_STATUS_OPTIONS,
  VISIBILITY_OPTIONS
} from './consts'
import translate from 'src/intl/translate'

export const normalizeExportOfferDetails = offer =>
  new ExportOffer({
    ...offer,
    loadingDate: moment(offer.loadingDate).calendar(),
    loadingTime: removeMsFromTime(offer.loadingTime),
    returnDate: moment(offer.returnDate).calendar(),
    returnTime: removeMsFromTime(offer.returnTime),
    price: Currency({ ...offer.price }),
    container: Container({ ...offer.container }),
    issuer: Contractor({
      ...offer.issuer,
      company: Company({
        ...offer.issuer?.company,
        documents: pathOr(
          [],
          ['issuer', 'company', 'documents'],
          offer
        ).map(file => Document({ ...file }))
      })
    }),
    handler: Contractor({ ...offer.handler }),
    shipowner: Shipowner({ ...offer.shipowner }),
    loadingLocation: Location(addPostalCodeToName(offer.loadingLocation)),
    unloadingLocation: Location(addPostalCodeToName(offer.unloadingLocation)),
    customsClearanceLocation: Location(
      addPostalCodeToName(offer.customsClearanceLocation)
    ),
    returnLocation: Location(addPostalCodeToName(offer.returnLocation)),
    timeFromAdded: moment(offer.addedToListingAt).fromNow(),
    isOfferNew:
      moment(new Date().getTime()).diff(offer.addedToListingAt, 'minutes') < 1,

    isPrivate: offer.visibility === 'private',
    goPublicAt: isFalsy(offer.goPublicAt) ? null : moment(offer.goPublicAt)
  })

export const normalizeImportOfferDetails = offer =>
  new ImportOffer({
    ...offer,
    pickupDate: moment(offer.pickupDate).calendar(),
    pickupTime: removeMsFromTime(offer.pickupTime),
    unloadingDate: moment(offer.unloadingDate).calendar(),
    unloadingTime: removeMsFromTime(offer.unloadingTime),
    price: Currency({ ...offer.price }),
    container: Container({ ...offer.container }),
    issuer: Contractor({ ...offer.issuer }),
    handler: Contractor({ ...offer.handler }),
    shipowner: Shipowner({ ...offer.shipowner }),
    pickupLocation: Location(addPostalCodeToName(offer.pickupLocation)),
    unloadingLocation: Location(addPostalCodeToName(offer.unloadingLocation)),
    returnLocation: Location(addPostalCodeToName(offer.returnLocation)),
    customsClearanceLocation: Location(
      addPostalCodeToName(offer.customsClearanceLocation)
    ),
    timeFromAdded: moment(offer.addedToListingAt).fromNow(),
    isOfferNew:
      moment(new Date().getTime()).diff(offer.addedToListingAt, 'minutes') < 1,

    isPrivate: offer.visibility === 'private',
    goPublicAt: isFalsy(offer.goPublicAt) ? null : moment(offer.goPublicAt)
  })

export const normalizePostImportOfferDetails = offer =>
  new PostImportOffer({
    ...offer,
    availabilityDate: moment(offer.availabilityDate).calendar(),
    availabilityTime: removeMsFromTime(offer.availabilityTime),
    container: Container({ ...offer.container }),
    price: Currency({ ...offer.price }),
    issuer: Contractor({ ...offer.issuer }),
    handler: Contractor({ ...offer.handler }),
    shipowner: Shipowner({ ...offer.shipowner }),
    availabilityLocation: Location(
      addPostalCodeToName(offer.availabilityLocation)
    ),
    customsClearanceLocation: Location(
      addPostalCodeToName(offer.customsClearanceLocation)
    ),
    destinationLocation: Location(
      addPostalCodeToName(offer.destinationLocation)
    ),
    timeFromAdded: moment(offer.addedToListingAt).fromNow(),
    isOfferNew:
      moment(new Date().getTime()).diff(offer.addedToListingAt, 'minutes') < 1,

    isPrivate: offer.visibility === 'private',
    goPublicAt: isFalsy(offer.goPublicAt) ? null : moment(offer.goPublicAt)
  })

export const normalizeFreeCarrierOfferDetails = offer =>
  new FreeCarrierOffer({
    ...offer,
    availabilityDate: moment(offer.availabilityDate).calendar(),
    availabilityTime: removeMsFromTime(offer.availabilityTime),
    containerSemiTrailer: Container({ ...offer.containerSemiTrailer }),
    price: Currency({ ...offer.price }),
    issuer: Contractor({ ...offer.issuer }),
    handler: Contractor({ ...offer.handler }),
    availabilityLocation: Location(
      addPostalCodeToName(offer.availabilityLocation)
    ),
    destinationLocation: Location(
      addPostalCodeToName(offer.destinationLocation)
    ),
    customsClearanceLocation: Location(
      addPostalCodeToName(offer.customsClearanceLocation)
    ),
    timeFromAdded: moment(offer.addedToListingAt).fromNow(),
    isOfferNew:
      moment(new Date().getTime()).diff(offer.addedToListingAt, 'minutes') < 1,

    isPrivate: offer.visibility === 'private',
    goPublicAt: isFalsy(offer.goPublicAt) ? null : moment(offer.goPublicAt)
  })

export const normalizeExportOffers = arr =>
  arr.map(offer => normalizeExportOfferDetails(offer))

export const normalizeImportOffers = arr =>
  arr.map(offer => normalizeImportOfferDetails(offer))

export const normalizePostImportOffers = arr =>
  arr.map(offer => normalizePostImportOfferDetails(offer))

export const normalizeFreeCarriersOffers = arr =>
  arr.map(offer => normalizeFreeCarrierOfferDetails(offer))

const normalizeLocationName = (id, locations = []) => {
  //ids are received from API as `id|range`
  const option = head(locations.filter(v => v.id === head(id.split('|')))) || {}
  return `${option.countryCode}, ${option.name}, ${option.postalCode}`
}

//ids are received from API as `id|range`
const normalizeToLocation = (ids, locations) =>
  isArray(ids)
    ? ids.map(id => ({
        id: id.split('|')[0],
        name: normalizeLocationName(id, locations),
        range: isFalsy(id.split('|')[1]) ? 0 : parseInt(id.split('|')[1], 10)
      }))
    : [
        {
          id: ids.split('|')[0],
          name: normalizeLocationName(ids, locations),
          range: isFalsy(ids.split('|')[1])
            ? 0
            : parseInt(ids.split('|')[1], 10)
        }
      ]

const normalizeToValueArray = value => (isArray(value) ? value : [value])

const normalizeCompanyName = (id, companies = []) => {
  const option = head(companies.filter(v => v.id === id))
  return `${option.name}`
}

const normalizeToCompany = (ids, companies) =>
  isArray(ids)
    ? ids.map(id => ({ id, name: normalizeCompanyName(id, companies) }))
    : [{ id: ids, name: normalizeCompanyName(ids, companies) }]

export const normalizeFilterTabs = (tabs, locations, companies) =>
  tabs.map(tab => {
    const currentFilters = pipe(
      evolve({
        loadingLocation: ids => normalizeToLocation(ids, locations),
        returnLocation: ids => normalizeToLocation(ids, locations),
        pickupLocation: ids => normalizeToLocation(ids, locations),
        unloadingLocation: ids => normalizeToLocation(ids, locations),
        availabilityLocation: ids => normalizeToLocation(ids, locations),
        destinationLocation: ids => normalizeToLocation(ids, locations),
        container: normalizeToValueArray,
        shipowner: normalizeToValueArray,
        company: ids => normalizeToCompany(ids, companies),
        europeanEmissionStandard: value => parseInt(value, 10),
        containerSemiTrailer: normalizeToValueArray,
        adrClass: normalizeToValueArray,
        cargoType: normalizeToValueArray
      }),
      assoc('tabName', tab.name)
    )(tab.currentFilters)

    return new FilterTab({
      ...tab,
      currentFilters: isNil(tab.currentFilters) ? null : currentFilters
    })
  })

const getFirstLocationName = arr => (isEmptyArray(arr) ? null : head(arr).name)

const getFirstCompanyName = arr => (isEmptyArray(arr) ? null : head(arr).name)

const formatDateForTabName = date =>
  isNil(date) ? null : moment(date).format('DD.MM')

const formatEuropeanEmissionStandard = value =>
  isFalsy(value) ? null : `EURO ${value}`

const getDictionaryLabel = (id, dictionary = []) => {
  if (isNil(id)) return null
  const option = head(dictionary.filter(v => v.value === id))
  console.log('option', option)
  return !isFalsy(option)
    ? translate().formatMessage({ id: option.label })
    : null
}

const getReservationStatusDictionaryLabel = (id, dictionary = []) => {
  if (isNil(id)) return null
  const option = head(dictionary.filter(v => v.value === id))
  return !isFalsy(option)
    ? translate().formatMessage({ id: option.label })
    : null
}

const getVisibilityDictionaryLabel = (id, dictionary = []) => {
  if (isNil(id)) return null
  const option = head(dictionary.filter(v => v.value === id))
  return !isFalsy(option)
    ? translate().formatMessage({ id: option.label })
    : null
}

export const generateTabNameExport = (filters, dictionaries) => {
  const filtersRow = [
    getFirstLocationName(filters.loadingLocation),
    formatDateForTabName(filters.loadingDateFrom),
    formatDateForTabName(filters.loadingDateTo),
    getFirstLocationName(filters.returnLocation),
    formatDateForTabName(filters.returnDateFrom),
    formatDateForTabName(filters.returnDateTo),
    getDictionaryLabel(head(filters.container), dictionaries.containers),
    getDictionaryLabel(head(filters.shipowner), dictionaries.shipowners),
    getFirstCompanyName(filters.company),
    getDictionaryLabel(filters.adr, ADR_OPTIONS),
    getReservationStatusDictionaryLabel(
      filters.status,
      RESERVATION_STATUS_OPTIONS
    ),
    getVisibilityDictionaryLabel(filters.visibility, VISIBILITY_OPTIONS),
    getDictionaryLabel(head(filters.adrClass), dictionaries.adrClasses),
    formatEuropeanEmissionStandard(filters.europeanEmissionStandard),
    getDictionaryLabel(head(filters.cargoType), dictionaries.cargoTypes)
  ]

  const filterName = filterFalsyValues(filtersRow).join(', ')
  console.log(filterName)
  return isEmptyString(filterName) ? null : filterName
}

export const generateTabNameImport = (filters, dictionaries) => {
  const filtersRow = [
    getFirstLocationName(filters.pickupLocation),
    formatDateForTabName(filters.pickupDateFrom),
    formatDateForTabName(filters.pickupDateTo),
    getFirstLocationName(filters.unloadingLocation),
    formatDateForTabName(filters.unloadingDateFrom),
    formatDateForTabName(filters.unloadingDateTo),
    getDictionaryLabel(head(filters.container), dictionaries.containers),
    getDictionaryLabel(head(filters.shipowner), dictionaries.shipowners),
    getFirstCompanyName(filters.company),
    getDictionaryLabel(filters.adr, ADR_OPTIONS),
    getDictionaryLabel(filters.status, RESERVATION_STATUS_OPTIONS),
    getVisibilityDictionaryLabel(filters.visibility, VISIBILITY_OPTIONS),
    getDictionaryLabel(head(filters.adrClass), dictionaries.adrClasses),
    formatEuropeanEmissionStandard(filters.europeanEmissionStandard),
    getDictionaryLabel(head(filters.cargoType), dictionaries.cargoTypes)
  ]

  const filterName = filterFalsyValues(filtersRow).join(', ')
  return isEmptyString(filterName) ? null : filterName
}

export const generateTabNameFreeCarriers = (filters, dictionaries) => {
  const filtersRow = [
    getFirstLocationName(filters.availabilityLocation),
    formatDateForTabName(filters.availabilityDateFrom),
    formatDateForTabName(filters.availabilityDateTo),
    getFirstLocationName(filters.destinationLocation),
    getDictionaryLabel(
      head(filters.containerSemiTrailer),
      dictionaries.containerSemiTrailers
    ),
    getFirstCompanyName(filters.company),
    getDictionaryLabel(filters.adr, ADR_OPTIONS),
    getDictionaryLabel(filters.genset, GENSET_OPTIONS),
    getDictionaryLabel(filters.status, RESERVATION_STATUS_OPTIONS),
    getVisibilityDictionaryLabel(filters.visibility, VISIBILITY_OPTIONS),
    getDictionaryLabel(head(filters.adrClass), dictionaries.adrClasses),
    formatEuropeanEmissionStandard(filters.europeanEmissionStandard)
  ]

  const filterName = filterFalsyValues(filtersRow).join(', ')
  return isEmptyString(filterName) ? null : filterName
}

export const generateTabNamePostImport = (filters, dictionaries) => {
  const filtersRow = [
    getFirstLocationName(filters.availabilityLocation),
    formatDateForTabName(filters.availabilityDateFrom),
    formatDateForTabName(filters.availabilityDateTo),
    getFirstLocationName(filters.destinationLocation),
    getDictionaryLabel(head(filters.container), dictionaries.containers),
    getVisibilityDictionaryLabel(filters.visibility, VISIBILITY_OPTIONS),
    getDictionaryLabel(head(filters.adrClass), dictionaries.adrClasses),
    formatEuropeanEmissionStandard(filters.europeanEmissionStandard)
  ]

  const filterName = filterFalsyValues(filtersRow).join(', ')
  return isEmptyString(filterName) ? null : filterName
}

export const normalizeToLocationsIds = tabs => {
  const tabsLocations = tabs.map(tab => {
    const filtersLocations = [
      pathOr([], ['currentFilters', 'loadingLocation'], tab),
      pathOr([], ['currentFilters', 'returnLocation'], tab),
      pathOr([], ['currentFilters', 'pickupLocation'], tab),
      pathOr([], ['currentFilters', 'unloadingLocation'], tab),
      pathOr([], ['currentFilters', 'availabilityLocation'], tab),
      pathOr([], ['currentFilters', 'destinationLocation'], tab)
    ]
    return flatten(filtersLocations)
  })
  return flatten(tabsLocations)
}

export const normalizeToCompaniesIds = tabs => {
  const tabsCompanies = tabs.map(tab =>
    pathOr([], ['currentFilters', 'company'], tab)
  )
  return flatten(tabsCompanies)
}

const toCamelCaseDeep = obj => {
  const normalized = renameKeysWith(camelCase)(obj)
  return keys(normalized).reduce((acc, key) => {
    //parse nested objects
    acc[key] =
      typeof normalized[key] === 'object' && !(normalized[key] instanceof Array)
        ? renameKeysWith(camelCase)(normalized[key])
        : normalized[key]
    return acc
  }, {})
}
export const refreshExchangeAfterOfferBeingRefreshed = ({
  exchangeState,
  tabId,
  eventPayload,
  offerNormalizer
}) => {
  let state = exchangeState
  const tabExists = !isFalsy(exchangeState.tabsData[tabId])
  const tabsDataObject = exchangeState.tabsData
  const isActiveTab = exchangeState.activeTab === tabId
  const normalizedOffer = offerNormalizer(toCamelCaseDeep(eventPayload))

  if (isActiveTab) {
    //if current tab is active and current page is different than 1 then increment infoBox value
    if (state.pagination.currentPage !== 1) {
      return state.update('infoBoxOffersNumber', v => v + 1)
    }

    //if current tab is active and currentPage is equal to 1 then add it to the list
    const offerIndex = findIndex(propEq('id', normalizedOffer.id), state.offers)
    return state
      .update('offers', list =>
        list.map((listOffer, index) =>
          index === offerIndex
            ? listOffer
                .set('liveNewOfferAddedAt', new Date().getTime())
                .set('isOfferNew', true)
                .set('isOfferLiveNew', true)
            : listOffer
        )
      )
      .update('offers', list =>
        offerIndex === -1
          ? [
              normalizedOffer
                .set('liveNewOfferAddedAt', new Date().getTime())
                .set('isOfferNew', true)
                .set('isOfferLiveNew', true),
              ...list
            ]
          : list
      )
  }

  //if current tab is not active, store offer in tabsData
  tabsDataObject[tabId] = tabExists
    ? tabsDataObject[tabId]
        .update('counter', v => v + 1)
        .update('addedOffers', v => [
          offerNormalizer(toCamelCaseDeep(eventPayload)),
          ...v
        ])
    : new FilterTabData({
        counter: 1,
        addedOffers: [offerNormalizer(toCamelCaseDeep(eventPayload))]
      })

  return state
}

export const updateExchangeAfterNewLiveOfferCreatedEvent = ({
  exchangeState,
  tabId,
  eventPayload,
  offerNormalizer
}) => {
  let state = exchangeState
  const tabExists = !isFalsy(exchangeState.tabsData[tabId])
  const tabsDataObject = exchangeState.tabsData
  const isActiveTab = exchangeState.activeTab === tabId
  const normalizedOffer = offerNormalizer(toCamelCaseDeep(eventPayload))

  if (isActiveTab) {
    //if current tab is active and current page is different than 1 then increment infoBox value
    if (state.pagination.currentPage !== 1) {
      return state.update('infoBoxOffersNumber', v => v + 1)
    }

    //if current tab is active and currentPage is equal to 1 then add it to the list
    const offerIndex = findIndex(propEq('id', normalizedOffer.id), state.offers)
    return state
      .update('offers', list =>
        list.map((listOffer, index) =>
          index === offerIndex
            ? listOffer
                .set('liveNewOfferAddedAt', new Date().getTime())
                .set('isOfferNew', true)
                .set('isOfferLiveNew', true)
                .set('liveOfferRemovedAt', null)
            : listOffer
        )
      )
      .update('offers', list =>
        offerIndex === -1
          ? [
              normalizedOffer
                .set('liveNewOfferAddedAt', new Date().getTime())
                .set('isOfferNew', true)
                .set('isOfferLiveNew', true),
              ...list
            ]
          : list
      )
      .updateIn(['pagination', 'total'], v => v + 1)
      .setIn(
        ['offerDetails', 'deletedAt'],
        exchangeState.offerDetails.id === eventPayload.id
          ? null
          : exchangeState.offerDetails.deletedAt
      )
  }

  //if current tab is not active, store offer in tabsData
  tabsDataObject[tabId] = tabExists
    ? tabsDataObject[tabId]
        .update('counter', v => v + 1)
        .update('addedOffers', v => [
          offerNormalizer(toCamelCaseDeep(eventPayload)),
          ...v
        ])
    : new FilterTabData({
        counter: 1,
        addedOffers: [offerNormalizer(toCamelCaseDeep(eventPayload))]
      })

  return state
}

export const updateExchangeAfterSwitchToNewTabWithLiveData = ({
  exchangeState,
  tabData,
  tabId
}) => {
  let state = exchangeState
  //reverse live offers list to keep the order
  reverse(tabData.addedOffers).map(liveOffer => {
    const offerIndex = findIndex(propEq('id', liveOffer.id), state.offers)
    state = state
      .update('offers', list =>
        list.map((listOffer, index) =>
          index === offerIndex
            ? listOffer
                .set('liveNewOfferAddedAt', new Date().getTime())
                .set('isOfferNew', true)
                .set('isOfferLiveNew', true)
            : listOffer
        )
      )
      .update('offers', list =>
        offerIndex === -1
          ? [
              liveOffer
                .set('liveNewOfferAddedAt', new Date().getTime())
                .set('isOfferNew', true)
                .set('isOfferLiveNew', true),
              ...list
            ]
          : list
      )
  })

  state.tabsData[tabId] = state.tabsData[tabId]
    .set('addedOffers', [])
    .set('counter', 0)

  return state
}

//isOfferLiveNew - after 2 minutes mark offer as not new
//isOfferNew - after 1 minute mark offer as not new

export const updateOfferTimestamps = offers =>
  offers.map(offer =>
    offer
      .set('timeFromAdded', moment(offer.addedToListingAt).fromNow())
      .set(
        'isOfferLiveNew',
        !isNil(offer.liveNewOfferAddedAt)
          ? moment(new Date().getTime()).diff(
              offer.liveNewOfferAddedAt,
              'minutes'
            ) < 2
          : null
      )
      .set(
        'isOfferNew',
        !isNil(offer.addedToListingAt)
          ? moment(new Date().getTime()).diff(
              offer.addedToListingAt,
              'minutes'
            ) < 1
          : null
      )
  )

export const updateExchangeAfterOfferDeletedEvent = ({
  exchangeState,
  eventPayload,
  tabId
}) => {
  const isActiveTab = exchangeState.activeTab === tabId
  if (isActiveTab) {
    return exchangeState
      .update('offers', list =>
        list.map(listOffer =>
          listOffer.id === eventPayload.id
            ? listOffer.set('liveOfferRemovedAt', new Date().getTime())
            : listOffer
        )
      )
      .updateIn(['pagination', 'total'], v => (v === 0 ? 0 : v - 1))
      .setIn(
        ['offerDetails', 'deletedAt'],
        exchangeState.offerDetails.id === eventPayload.id
          ? new Date().getTime()
          : null
      )
  }

  return exchangeState
}

export const normalizeExportOfferTimelineData = offer => [
  {
    title: 'loadingLocation',
    location: offer.loadingLocation,
    date: offer.loadingDate,
    time: offer.loadingTime
  },
  {
    title: 'returnLocation',
    location: offer.returnLocation,
    date: offer.returnDate,
    time: offer.returnTime
  }
]

export const normalizeImportOfferTimelineData = offer => [
  {
    title: 'pickupLocation',
    location: offer.pickupLocation,
    date: offer.pickupDate,
    time: offer.pickupTime
  },
  {
    title: 'unloadingLocation',
    location: offer.unloadingLocation,
    date: offer.unloadingDate,
    time: offer.unloadingTime
  },
  {
    title: 'returnLocation',
    location: offer.returnLocation
  }
]

export const normalizeFreeCarrierOfferTimelineData = offer => [
  {
    title: 'availabilityLocation',
    location: offer.availabilityLocation,
    date: offer.availabilityDate,
    time: offer.availabilityTime
  },
  {
    title: 'destinationLocation',
    location: offer.destinationLocation
  }
]

export const normalizePostImportOfferTimelineData = offer => [
  {
    title: 'availabilityLocation',
    location: offer.availabilityLocation,
    date: offer.availabilityDate,
    time: offer.availabilityTime
  },
  {
    title: 'destinationLocation',
    location: offer.destinationLocation
  }
]

export const normalizeContractExportOfferTimelineData = offer => [
  {
    title: 'loadingLocation',
    location: offer.originLocation,
    count: offer.numberOfContainers
  },
  {
    title: 'returnLocation',
    location: offer.destinationLocation
  }
]

export const normalizeContractImportOfferTimelineData = offer => [
  {
    title: 'pickupLocation',
    location: offer.originLocation,
    count: offer.numberOfContainers
  },
  {
    title: 'unloadingLocation',
    location: offer.destinationLocation
  },
  {
    title: 'returnLocation',
    location: offer.returnLocation
  }
]
