import { API_STATES } from 'src/ducks/consts'

export const isNotificationsListOpened = state =>
  state.notifications.listOpened === true

export const selectNotificationsList = state =>
  state.notifications.notifications

export const selectStickyNotificationsList = state =>
  state.notifications.notificationsSticky

export const selectNotificationsUnreadCount = state =>
  state.notifications.unreadCount

export const selectNotificationsDetails = state => state.notifications

export const isNotificationListLoading = state =>
  state.notifications.listState === API_STATES.IN_PROGRESS

export const selectAllNotificationsFetched = state =>
  state.notifications.allFetched

export const selectNotificationsSettings = state => state.notifications.settings
