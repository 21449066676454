import { filterNullValues } from 'src/utils/helpers'
import qs from 'query-string'
import { renameKeysWith } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import api from './APIInterceptor'

export default class MessagesService {
  static findConversation(payload = {}) {
    return api.post('/chat/conversations/search', payload)
  }

  static startConversation(payload = {}) {
    return api.post('/chat/conversations', payload)
  }

  static getConversationContextActions(conversationId) {
    return api.get(`/chat/conversations/${conversationId}/actions`)
  }

  static getConversationContextReservation(conversationId) {
    return api.get(`/chat/conversations/${conversationId}/reservation`)
  }

  static sendMessage(payload = {}) {
    return api.post(
      `/chat/conversations${
        payload.conversationId ? `/${payload.conversationId}/messages` : ''
      }`,
      filterNullValues(payload)
    )
  }

  static getConversationMessages(conversationId, payload) {
    const parsedPayload = renameKeysWith(snakeCase, payload)
    const query = qs.stringify(parsedPayload, { skipNull: true })
    return api.get(`/chat/conversations/${conversationId}?${query}`)
  }

  static getConversationContext({ stock, offerId }) {
    return api.get(`/exchange/${stock}/offers/${offerId}`)
  }

  static getMessagesList(query = '') {
    return api.get(`/chat/conversations?${query}`)
  }

  static markConversationAsSeen(conversationId) {
    return api.patch(`/chat/conversations/${conversationId}/mark-as-seen`)
  }

  static markMessageAsSeen(conversationId, messageId) {
    return api.patch(
      `chat/conversations/${conversationId}/messages/${messageId}/mark-as-seen`
    )
  }

  static getUnreadMessagesCount() {
    return api.get('/chat/conversations/unread-counters')
  }

  static muteChat() {
    return api.patch('/chat/mute')
  }

  static unmuteChat() {
    return api.patch('/chat/unmute')
  }

  static muteConversation(conversationId) {
    return api.patch(`/chat/conversations/${conversationId}/mute`)
  }

  static unmuteConversation(conversationId) {
    return api.patch(`/chat/conversations/${conversationId}/unmute`)
  }
}
