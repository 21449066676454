import { createRoutine } from 'redux-saga-routines'
import {
  call,
  take,
  delay,
  fork,
  put,
  select,
  takeLatest,
  spawn
} from '@redux-saga/core/effects'
import { eventChannel } from 'redux-saga'
import { head, pluck, propOr } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import ExchangeService from 'src/services/ExchangeService'
import DictionariesService from 'src/services/DictionariesService'
import LaravelEchoService from 'src/services/LaravelEchoService'
import {
  ERROR_DELAY_TIME,
  EXCHANGE_TYPES,
  EXCHANGE_EVENT_TYPES
} from 'src/ducks/consts'
import { selectDictionaries } from 'src/ducks/selectors'
import { selectCurrentUserId } from 'src/features/account/duck/selectors'
import {
  selectExportFilters,
  selectExportFiltersFormatted,
  selectExportFiltersTabsList,
  selectExportActiveTab,
  selectExportTabsData
} from 'src/features/stocks/ducks/selectors'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import {
  displayResponseErrorMessage,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'
import { normalizeToLocationsIds, normalizeToCompaniesIds } from './normalizers'

export const cleanExportOffersDetailsRoutine = createRoutine(
  'CLEAN_EXPORT_OFFERS_DETAILS'
)
export const getExportOffersRoutine = createRoutine('GET_EXPORT_OFFERS')
export const clearExportOffersRoutine = createRoutine('CLEAR_EXPORT_OFFERS')
export const showExportOfferDetailsRoutine = createRoutine(
  'SHOW_EXPORT_OFFER_DETAILS'
)
export const setExportDrawerRoutine = createRoutine('SET_EXPORT_DRAWER')
export const setExportFiltersRoutine = createRoutine('SET_EXPORT_FILTERS')
export const updateExportOfferRoutine = createRoutine('UPDATE_EXPORT_OFFER')
export const reserveExportOfferRoutine = createRoutine('RESERVE_EXPORT_OFFER')
export const refreshExportOfferRoutine = createRoutine('REFRESH_EXPORT_OFFER')
export const makeExportOfferPublicRoutine = createRoutine(
  'MAKE_EXPORT_OFFER_PUBLIC'
)
export const clearExportFiltersRoutine = createRoutine('CLEAR_EXPORT_FILTERS')
export const getExportFilterTabsListRoutine = createRoutine(
  'GET_EXPORT_FILTER_TABS_LIST'
)
export const createExportFilterTabRoutine = createRoutine(
  'CREATE_EXPORT_FILTER_TAB'
)
export const removeExportFilterTabRoutine = createRoutine(
  'REMOVE_EXPORT_FILTER_TAB'
)
export const setExportActiveTabRoutine = createRoutine('SET_EXPORT_ACTIVE_TAB')

export const EXPORT_TAB_EVENT_OFFER_CREATED = 'EXPORT_TAB_EVENT_OFFER_CREATED'
export const EXPORT_TAB_EVENT_OFFER_REFRESHED =
  'EXPORT_TAB_EVENT_OFFER_REFRESHED'
export const EXPORT_TAB_EVENT_OFFER_DELETED = 'EXPORT_TAB_EVENT_OFFER_DELETED'
export const EXPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA =
  'EXPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA'
export const exportOffersCleanerRoutine = createRoutine('EXPORT_OFFERS_CLEANER')
export const bidExportOfferRoutine = createRoutine('BID_EXPORT_OFFER')

//actions

function* getExportOffers() {
  yield put(getExportOffersRoutine.request())
  try {
    const activeTab = yield select(selectExportActiveTab)

    //case when initially loads
    if (isFalsy(activeTab)) {
      yield call(getExportFilterTabsList)
      const tabs = yield select(selectExportFiltersTabsList)
      yield call(setExportActiveTab, {
        payload: { id: propOr('', 'id', head(tabs)) }
      })
      return
    }

    const tabs = yield select(selectExportFiltersTabsList)
    const filters = yield select(selectExportFiltersFormatted)

    const data = yield call(
      ExchangeService.getExportOffersListByTab,
      isFalsy(activeTab) ? head(tabs).id : activeTab,
      filters
    )

    yield put(getExportOffersRoutine.success(data))
    yield call(getExportFilterTabsList)
  } catch (e) {
    yield put(getExportOffersRoutine.failure())
    console.log(e)
  }
}

function* clearExportOffers() {
  yield put(clearExportOffersRoutine.success())
}

function* cleanExportOffersDetails() {
  yield put(cleanExportOffersDetailsRoutine.success())
}

function* showExportOfferDetails({ payload }) {
  yield put(showExportOfferDetailsRoutine.request())
  try {
    const { data } = yield call(ExchangeService.showExportOffer, payload)
    yield put(showExportOfferDetailsRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(showExportOfferDetailsRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(showExportOfferDetailsRoutine.fulfill())
  }
}

function* setExportDrawer({ payload }) {
  yield put(setExportDrawerRoutine.success(payload))
}

function* setExportFilters({ payload }) {
  const dictionaries = yield select(selectDictionaries)

  yield put(setExportFiltersRoutine.success({ ...payload, dictionaries }))
  yield* getExportOffers()
}

function* clearExportFilters() {
  yield put(clearExportFiltersRoutine.success())
  yield* getExportOffers()
}

function* updateExportOffer({ payload }) {
  yield put(updateExportOfferRoutine.request())
  try {
    const { data } = yield call(
      ExchangeService.updateExportOffer,
      payload.id,
      payload.values
    )
    yield put(updateExportOfferRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(updateExportOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(updateExportOfferRoutine.fulfill())
  }
}

function* reserveExportOffer({ payload }) {
  yield put(reserveExportOfferRoutine.request())
  try {
    yield call(ExchangeService.reserveExportOffer, payload.id)
    yield put(reserveExportOfferRoutine.success())
    yield* showExportOfferDetails({ payload })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(reserveExportOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(reserveExportOfferRoutine.fulfill())
  }
}

function* bidExportOffer({ payload }) {
  yield put(bidExportOfferRoutine.request())
  try {
    yield call(ExchangeService.bidExportOffer, payload.id, {
      price: payload.price
    })
    yield put(bidExportOfferRoutine.success())
    yield call(showExportOfferDetails, { payload: { id: payload.id } })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'makeABid.successMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(bidExportOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(bidExportOfferRoutine.fulfill())
  }
}

function* refreshExportOffer({ payload }) {
  yield put(refreshExportOfferRoutine.request())
  try {
    yield call(ExchangeService.refreshExportOffer, payload.id)
    yield put(refreshExportOfferRoutine.success())
    yield* showExportOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.refreshOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(refreshExportOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(refreshExportOfferRoutine.fulfill())
  }
}

function* makeExportOfferPublic({ payload }) {
  yield put(makeExportOfferPublicRoutine.request())
  try {
    yield call(ExchangeService.makeExportOfferPublic, payload.id)
    yield put(makeExportOfferPublicRoutine.success())
    yield* showExportOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.makeOfferPublicSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(makeExportOfferPublicRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(makeExportOfferPublicRoutine.fulfill())
  }
}

function listenToExchangeTabEvents(data) {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToExchangeTabs({
      exchangeType: data.exchangeType,
      userId: data.userId,
      tabsIds: data.tabsIds,
      callback: (tabId, event, payload) => {
        switch (event) {
          case EXCHANGE_EVENT_TYPES.OFFER_CREATED:
            return emitter({
              type: EXPORT_TAB_EVENT_OFFER_CREATED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_REFRESHED:
            return emitter({
              type: EXPORT_TAB_EVENT_OFFER_REFRESHED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_DELETED:
            return emitter({
              type: EXPORT_TAB_EVENT_OFFER_DELETED,
              tabId,
              payload
            })
          default:
            //handle information about not handled type
            return null
        }
      }
    })
    // unsubscribe function
    return () => {
      // do whatever to interrupt the socket communication here
    }
  })
}

export function* getExportFilterTabsList() {
  try {
    const tabs = yield call(ExchangeService.getExportFilterTabsList)
    const locationsIds = normalizeToLocationsIds(tabs.data)
    const companiesIds = normalizeToCompaniesIds(tabs.data)

    const locations = yield call(DictionariesService.locations, {
      filter: {
        id: locationsIds.map(id => head(id.split('|')))
      }
    })
    const companies = yield call(DictionariesService.companies, {
      filter: {
        id: companiesIds
      }
    })

    yield put(
      getExportFilterTabsListRoutine.success({
        data: tabs.data,
        locations: locations.data,
        companies: companies.data
      })
    )

    //listen to tabs events
    const userId = yield select(selectCurrentUserId)
    yield spawn(listenToExchangeTabEventsWatcher, {
      exchangeType: EXCHANGE_TYPES.EXPORT,
      userId,
      tabsIds: pluck('id')(tabs.data)
    })
  } catch (e) {
    console.log(e)
  }
}

function* createExportFilterTab({ payload }) {
  try {
    const filters = yield select(selectExportFilters)
    const activeTab = yield select(selectExportActiveTab)
    const tabs = yield select(selectExportFiltersTabsList)
    const activeTabData = head(tabs.filter(v => v.id === activeTab))

    const { data } = yield call(ExchangeService.createExportFilterTab, {
      name: payload.empty ? null : filters.tabName
    })

    yield put(
      createExportFilterTabRoutine.success({
        data,
        currentFilters: payload.empty ? null : activeTabData.currentFilters
      })
    )
    //set active new tab

    yield call(setExportActiveTab, { payload: { id: data.id } })
  } catch (e) {
    console.log(e)
  }
}

function* removeExportFilterTab({ payload }) {
  try {
    yield call(ExchangeService.removeExportFilterTab, payload.id)
    yield put(removeExportFilterTabRoutine.success({ id: payload.id }))
    //after removal set active tab to first one
    const tabs = yield select(selectExportFiltersTabsList)
    yield call(setExportActiveTab, { payload: { id: head(tabs).id } })
  } catch (e) {
    console.log(e)
  }
}

function* setExportActiveTab({ payload }) {
  try {
    const tabs = yield select(selectExportFiltersTabsList)
    const tab = head(tabs.filter(v => v.id === payload.id))
    const tabsData = yield select(selectExportTabsData)

    yield put(
      setExportActiveTabRoutine.success({
        activeTab: tab.id,
        filters: tab.currentFilters
      })
    )
    yield call(getExportOffers)

    //if tab has live data stored move them to offers list
    if (!isFalsy(tabsData[tab.id])) {
      yield put({
        type: EXPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
        payload: { tabId: tab.id, tabData: tabsData[tab.id] }
      })
    }
  } catch (e) {
    console.log(e)
  }
}

function* exportOffersCleaner() {
  yield put(exportOffersCleanerRoutine.success())
}

//watchers

function* getExportOffersRoutineWatcher() {
  yield takeLatest(getExportOffersRoutine.TRIGGER, getExportOffers)
}

function* cleanExportOffersDetailsRoutineWatcher() {
  yield takeLatest(
    cleanExportOffersDetailsRoutine.TRIGGER,
    cleanExportOffersDetails
  )
}

function* clearExportOffersRoutineWatcher() {
  yield takeLatest(clearExportOffersRoutine.TRIGGER, clearExportOffers)
}

function* showExportOfferDetailsRoutineWatcher() {
  yield takeLatest(
    showExportOfferDetailsRoutine.TRIGGER,
    showExportOfferDetails
  )
}

function* setExportDrawerRoutineWatcher() {
  yield takeLatest(setExportDrawerRoutine.TRIGGER, setExportDrawer)
}

function* setExportFiltersRoutineWatcher() {
  yield takeLatest(setExportFiltersRoutine.TRIGGER, setExportFilters)
}

function* updateExportOfferRoutineWatcher() {
  yield takeLatest(updateExportOfferRoutine.TRIGGER, updateExportOffer)
}

function* reserveExportOfferRoutineWatcher() {
  yield takeLatest(reserveExportOfferRoutine.TRIGGER, reserveExportOffer)
}

function* bidExportOfferRoutineWatcher() {
  yield takeLatest(bidExportOfferRoutine.TRIGGER, bidExportOffer)
}

function* refreshExportOfferRoutineWatcher() {
  yield takeLatest(refreshExportOfferRoutine.TRIGGER, refreshExportOffer)
}

function* makeExportOfferPublicRoutineWatcher() {
  yield takeLatest(makeExportOfferPublicRoutine.TRIGGER, makeExportOfferPublic)
}

function* clearExportFiltersRoutineWatcher() {
  yield takeLatest(clearExportFiltersRoutine.TRIGGER, clearExportFilters)
}

function* getExportFilterTabsListRoutineWatcher() {
  yield takeLatest(
    getExportFilterTabsListRoutine.TRIGGER,
    getExportFilterTabsList
  )
}

function* createExportFilterTabRoutineWatcher() {
  yield takeLatest(createExportFilterTabRoutine.TRIGGER, createExportFilterTab)
}

function* removeExportFilterTabRoutineWatcher() {
  yield takeLatest(removeExportFilterTabRoutine.TRIGGER, removeExportFilterTab)
}

function* setExportActiveTabRoutineWatcher() {
  yield takeLatest(setExportActiveTabRoutine.TRIGGER, setExportActiveTab)
}

function* listenToExchangeTabEventsWatcher(payload) {
  const channel = yield call(listenToExchangeTabEvents, payload)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* exportOffersCleanerRoutineWatcher() {
  yield takeLatest(exportOffersCleanerRoutine.TRIGGER, exportOffersCleaner)
}

export const exchangeExportSagas = [
  fork(getExportOffersRoutineWatcher),
  fork(clearExportOffersRoutineWatcher),
  fork(cleanExportOffersDetailsRoutineWatcher),
  fork(showExportOfferDetailsRoutineWatcher),
  fork(setExportDrawerRoutineWatcher),
  fork(setExportFiltersRoutineWatcher),
  fork(updateExportOfferRoutineWatcher),
  fork(reserveExportOfferRoutineWatcher),
  fork(refreshExportOfferRoutineWatcher),
  fork(clearExportFiltersRoutineWatcher),
  fork(getExportFilterTabsListRoutineWatcher),
  fork(createExportFilterTabRoutineWatcher),
  fork(removeExportFilterTabRoutineWatcher),
  fork(setExportActiveTabRoutineWatcher),
  fork(exportOffersCleanerRoutineWatcher),
  fork(bidExportOfferRoutineWatcher),
  fork(makeExportOfferPublicRoutineWatcher)
]
