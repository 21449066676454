import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  spawn,
  takeLatest
} from '@redux-saga/core/effects'
import { eventChannel } from 'redux-saga'
import { head, pluck } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import ExchangeService from 'src/services/ExchangeService'
import DictionariesService from 'src/services/DictionariesService'
import LaravelEchoService from 'src/services/LaravelEchoService'
import {
  ERROR_DELAY_TIME,
  EXCHANGE_TYPES,
  EXCHANGE_EVENT_TYPES
} from 'src/ducks/consts'
import { selectDictionaries } from 'src/ducks/selectors'
import { selectCurrentUserId } from 'src/features/account/duck/selectors'
import {
  selectImportActiveTab,
  selectImportFilters,
  selectImportFiltersFormatted,
  selectImportFiltersTabsList,
  selectImportTabsData
} from 'src/features/stocks/ducks/selectors'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import {
  displayResponseErrorMessage,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'
import { normalizeToLocationsIds, normalizeToCompaniesIds } from './normalizers'

export const getImportOffersRoutine = createRoutine('GET_IMPORT_OFFERS')
export const clearImportOffersRoutine = createRoutine('CLEAR_IMPORT_OFFERS')
export const cleanImportOffersDetailsRoutine = createRoutine(
  'CLEAN_IMPORT_OFFERS_DETAILS'
)
export const showImportOfferDetailsRoutine = createRoutine(
  'SHOW_IMPORT_OFFER_DETAILS'
)
export const setImportDrawerRoutine = createRoutine('SET_IMPORT_DRAWER')
export const setImportFiltersRoutine = createRoutine('SET_IMPORT_FILTERS')
export const updateImportOfferRoutine = createRoutine('UPDATE_IMPORT_OFFER')
export const reserveImportOfferRoutine = createRoutine('RESERVE_IMPORT_OFFER')
export const bidImportOfferRoutine = createRoutine('BID_IMPORT_OFFER')
export const refreshImportOfferRoutine = createRoutine('REFRESH_IMPORT_OFFER')
export const makeImportOfferPublicRoutine = createRoutine(
  'MAKE_IMPORT_OFFER_PUBLIC'
)
export const clearImportFiltersRoutine = createRoutine('CLEAR_IMPORT_FILTERS')
export const getImportFilterTabsListRoutine = createRoutine(
  'GET_IMPORT_FILTER_TABS_LIST'
)
export const createImportFilterTabRoutine = createRoutine(
  'CREATE_IMPORT_FILTER_TAB'
)
export const removeImportFilterTabRoutine = createRoutine(
  'REMOVE_IMPORT_FILTER_TAB'
)
export const setImportActiveTabRoutine = createRoutine('SET_IMPORT_ACTIVE_TAB')

export const IMPORT_TAB_EVENT_OFFER_CREATED = 'IMPORT_TAB_EVENT_OFFER_CREATED'
export const IMPORT_TAB_EVENT_OFFER_REFRESHED =
  'IMPORT_TAB_EVENT_OFFER_REFRESHED'
export const IMPORT_TAB_EVENT_OFFER_DELETED = 'IMPORT_TAB_EVENT_OFFER_DELETED'
export const IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA =
  'IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA'
export const importOffersCleanerRoutine = createRoutine('IMPORT_OFFERS_CLEANER')

//actions

function* getImportOffers() {
  yield put(getImportOffersRoutine.request())
  try {
    const activeTab = yield select(selectImportActiveTab)

    //case when initially loads
    if (isFalsy(activeTab)) {
      yield call(getImportFilterTabsList)
      const tabs = yield select(selectImportFiltersTabsList)
      yield call(setImportActiveTab, { payload: { id: head(tabs).id } })
      return
    }

    const tabs = yield select(selectImportFiltersTabsList)
    const filters = yield select(selectImportFiltersFormatted)

    const data = yield call(
      ExchangeService.getImportOffersListByTab,
      isFalsy(activeTab) ? head(tabs).id : activeTab,
      filters
    )

    yield put(getImportOffersRoutine.success(data))
    yield call(getImportFilterTabsList)
  } catch (e) {
    yield put(getImportOffersRoutine.failure())
    console.log(e)
  }
}

function* clearImportOffers() {
  yield put(clearImportOffersRoutine.success())
}

function* cleanImportOffersDetails() {
  yield put(cleanImportOffersDetailsRoutine.success())
}

function* showImportOfferDetails({ payload }) {
  yield put(showImportOfferDetailsRoutine.request())
  try {
    const { data } = yield call(ExchangeService.showImportOffer, payload)
    yield put(showImportOfferDetailsRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(showImportOfferDetailsRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(showImportOfferDetailsRoutine.fulfill())
  }
}

function* setImportDrawer({ payload }) {
  yield put(setImportDrawerRoutine.success(payload))
}

function* setImportFilters({ payload }) {
  const dictionaries = yield select(selectDictionaries)

  yield put(setImportFiltersRoutine.success({ ...payload, dictionaries }))
  yield* getImportOffers()
}

function* clearImportFilters() {
  yield put(clearImportFiltersRoutine.success())
  yield* getImportOffers()
}

function* updateImportOffer({ payload }) {
  yield put(updateImportOfferRoutine.request())
  try {
    const { data } = yield call(
      ExchangeService.updateImportOffer,
      payload.id,
      payload.values
    )
    yield put(updateImportOfferRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(updateImportOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(updateImportOfferRoutine.fulfill())
  }
}

function* reserveImportOffer({ payload }) {
  yield put(reserveImportOfferRoutine.request())
  try {
    yield call(ExchangeService.reserveImportOffer, payload.id)
    yield put(reserveImportOfferRoutine.success())
    yield* showImportOfferDetails({ payload })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(reserveImportOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(reserveImportOfferRoutine.fulfill())
  }
}

function* bidImportOffer({ payload }) {
  yield put(bidImportOfferRoutine.request())
  try {
    yield call(ExchangeService.bidImportOffer, payload.id, {
      price: payload.price
    })
    yield put(bidImportOfferRoutine.success())
    yield call(showImportOfferDetails, { payload: { id: payload.id } })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'makeABid.successMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(bidImportOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(bidImportOfferRoutine.fulfill())
  }
}

function* refreshImportOffer({ payload }) {
  yield put(refreshImportOfferRoutine.request())
  try {
    yield call(ExchangeService.refreshImportOffer, payload.id)
    yield put(refreshImportOfferRoutine.success())
    yield* showImportOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.refreshOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(refreshImportOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(refreshImportOfferRoutine.fulfill())
  }
}

function* makeImportOfferPublic({ payload }) {
  yield put(makeImportOfferPublicRoutine.request())
  try {
    yield call(ExchangeService.makeImportOfferPublic, payload.id)
    yield put(makeImportOfferPublicRoutine.success())
    yield* showImportOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.makeOfferPublicSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(makeImportOfferPublicRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(makeImportOfferPublicRoutine.fulfill())
  }
}

function listenToExchangeTabEvents(data) {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToExchangeTabs({
      exchangeType: data.exchangeType,
      userId: data.userId,
      tabsIds: data.tabsIds,
      callback: (tabId, event, payload) => {
        switch (event) {
          case EXCHANGE_EVENT_TYPES.OFFER_CREATED:
            return emitter({
              type: IMPORT_TAB_EVENT_OFFER_CREATED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_REFRESHED:
            return emitter({
              type: IMPORT_TAB_EVENT_OFFER_REFRESHED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_DELETED:
            return emitter({
              type: IMPORT_TAB_EVENT_OFFER_DELETED,
              tabId,
              payload
            })
          default:
            //handle information about not handled type
            return null
        }
      }
    })
    // unsubscribe function
    return () => {
      // do whatever to interrupt the socket communication here
    }
  })
}

export function* getImportFilterTabsList() {
  try {
    const tabs = yield call(ExchangeService.getImportFilterTabsList)
    const locationsIds = normalizeToLocationsIds(tabs.data)
    const companiesIds = normalizeToCompaniesIds(tabs.data)

    const locations = yield call(DictionariesService.locations, {
      filter: {
        id: locationsIds
      }
    })
    const companies = yield call(DictionariesService.companies, {
      filter: {
        id: companiesIds
      }
    })

    yield put(
      getImportFilterTabsListRoutine.success({
        data: tabs.data,
        locations: locations.data,
        companies: companies.data
      })
    )

    //listen to tabs events
    const userId = yield select(selectCurrentUserId)
    yield spawn(listenToExchangeTabEventsWatcher, {
      exchangeType: EXCHANGE_TYPES.IMPORT,
      userId,
      tabsIds: pluck('id')(tabs.data)
    })
  } catch (e) {
    console.log(e)
  }
}

function* createImportFilterTab({ payload }) {
  try {
    const filters = yield select(selectImportFilters)
    const activeTab = yield select(selectImportActiveTab)
    const tabs = yield select(selectImportFiltersTabsList)
    const activeTabData = head(tabs.filter(v => v.id === activeTab))

    const { data } = yield call(ExchangeService.createImportFilterTab, {
      name: payload.empty ? null : filters.tabName
    })
    yield put(
      createImportFilterTabRoutine.success({
        data,
        currentFilters: payload.empty ? null : activeTabData.currentFilters
      })
    )
    //set active new tab

    yield call(setImportActiveTab, { payload: { id: data.id } })
  } catch (e) {
    console.log(e)
  }
}

function* removeImportFilterTab({ payload }) {
  try {
    yield call(ExchangeService.removeImportFilterTab, payload.id)
    yield put(removeImportFilterTabRoutine.success({ id: payload.id }))
    //after removal set active tab to first one
    const tabs = yield select(selectImportFiltersTabsList)
    yield call(setImportActiveTab, { payload: { id: head(tabs).id } })
  } catch (e) {
    console.log(e)
  }
}

function* setImportActiveTab({ payload }) {
  try {
    const tabs = yield select(selectImportFiltersTabsList)
    const tab = head(tabs.filter(v => v.id === payload.id))
    const tabsData = yield select(selectImportTabsData)

    yield put(
      setImportActiveTabRoutine.success({
        activeTab: tab.id,
        filters: tab.currentFilters
      })
    )
    yield call(getImportOffers)

    //if tab has live data stored move them to offers list
    if (!isFalsy(tabsData[tab.id])) {
      yield put({
        type: IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
        payload: { tabId: tab.id, tabData: tabsData[tab.id] }
      })
    }
  } catch (e) {
    console.log(e)
  }
}

function* importOffersCleaner() {
  yield put(importOffersCleanerRoutine.success())
}

//watchers

function* getImportOffersRoutineWatcher() {
  yield takeLatest(getImportOffersRoutine.TRIGGER, getImportOffers)
}

function* clearImportOffersRoutineWatcher() {
  yield takeLatest(clearImportOffersRoutine.TRIGGER, clearImportOffers)
}

function* cleanImportOffersDetailsRoutineWatcher() {
  yield takeLatest(
    cleanImportOffersDetailsRoutine.TRIGGER,
    cleanImportOffersDetails
  )
}

function* showImportOfferDetailsRoutineWatcher() {
  yield takeLatest(
    showImportOfferDetailsRoutine.TRIGGER,
    showImportOfferDetails
  )
}

function* setImportDrawerRoutineWatcher() {
  yield takeLatest(setImportDrawerRoutine.TRIGGER, setImportDrawer)
}

function* setImportFiltersRoutineWatcher() {
  yield takeLatest(setImportFiltersRoutine.TRIGGER, setImportFilters)
}

function* updateImportOfferRoutineWatcher() {
  yield takeLatest(updateImportOfferRoutine.TRIGGER, updateImportOffer)
}

function* reserveImportOfferRoutineWatcher() {
  yield takeLatest(reserveImportOfferRoutine.TRIGGER, reserveImportOffer)
}

function* bidImportOfferRoutineWatcher() {
  yield takeLatest(bidImportOfferRoutine.TRIGGER, bidImportOffer)
}

function* refreshImportOfferRoutineWatcher() {
  yield takeLatest(refreshImportOfferRoutine.TRIGGER, refreshImportOffer)
}

function* makeImportOfferPublicRoutineWatcher() {
  yield takeLatest(makeImportOfferPublicRoutine.TRIGGER, makeImportOfferPublic)
}

function* clearImportFiltersRoutineWatcher() {
  yield takeLatest(clearImportFiltersRoutine.TRIGGER, clearImportFilters)
}

function* getImportFilterTabsListRoutineWatcher() {
  yield takeLatest(
    getImportFilterTabsListRoutine.TRIGGER,
    getImportFilterTabsList
  )
}

function* createImportFilterTabRoutineWatcher() {
  yield takeLatest(createImportFilterTabRoutine.TRIGGER, createImportFilterTab)
}

function* removeImportFilterTabRoutineWatcher() {
  yield takeLatest(removeImportFilterTabRoutine.TRIGGER, removeImportFilterTab)
}

function* setImportActiveTabRoutineWatcher() {
  yield takeLatest(setImportActiveTabRoutine.TRIGGER, setImportActiveTab)
}

function* listenToExchangeTabEventsWatcher(payload) {
  const channel = yield call(listenToExchangeTabEvents, payload)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* importOffersCleanerRoutineWatcher() {
  yield takeLatest(importOffersCleanerRoutine.TRIGGER, importOffersCleaner)
}

export const exchangeImportSagas = [
  fork(getImportOffersRoutineWatcher),
  fork(clearImportOffersRoutineWatcher),
  fork(cleanImportOffersDetailsRoutineWatcher),
  fork(showImportOfferDetailsRoutineWatcher),
  fork(setImportDrawerRoutineWatcher),
  fork(setImportFiltersRoutineWatcher),
  fork(updateImportOfferRoutineWatcher),
  fork(reserveImportOfferRoutineWatcher),
  fork(refreshImportOfferRoutineWatcher),
  fork(clearImportFiltersRoutineWatcher),
  fork(getImportFilterTabsListRoutineWatcher),
  fork(createImportFilterTabRoutineWatcher),
  fork(removeImportFilterTabRoutineWatcher),
  fork(setImportActiveTabRoutineWatcher),
  fork(importOffersCleanerRoutineWatcher),
  fork(bidImportOfferRoutineWatcher),
  fork(makeImportOfferPublicRoutineWatcher)
]
