import translate from 'src/intl/translate'
import { generateTableNames, generateTableWidths } from 'src/utils/helpers'
import { map, values } from 'ramda'

export const INVOICE_LANGUAGES = {
  PL: 'PL',
  ENG: 'EN'
}
export const INVOICE_LANGUAGES_OPTIONS = map(
  currency => ({ id: currency, value: currency, label: currency }),
  values(INVOICE_LANGUAGES)
)

export const TAX_RATES = {
  PERCENT23: 'percent23',
  PERCENT8: 'percent8',
  PERCENT5: 'percent5',
  PERCENT0: 'percent0',
  EXEMPT: 'exempt',
  NOT_APPLICABLE: 'not_applicable'
}

export const TAX_RATES_OPTIONS = [
  { label: '23%', value: TAX_RATES.PERCENT23 },
  { label: '8%', value: TAX_RATES.PERCENT8 },
  { label: '5%', value: TAX_RATES.PERCENT5 },
  { label: '0%', value: TAX_RATES.PERCENT0 },
  {
    label: 'invoices.exempt',
    value: TAX_RATES.EXEMPT
  },
  {
    label: 'invoices.notApplicable',
    value: TAX_RATES.NOT_APPLICABLE
  }
]

export const INVOICES_TABS = {
  ISSUED: 'invoices.issued',
  RECEIVED: 'invoices.received'
}

export const ADD_INVOICE_PAYMENT_TABS = {
  PAYMENT: 'invoices.payments',
  JPK: 'invoices.jpk'
}

export const ADD_INVOICE_PAYMENT_TABS_ARRAY = values(ADD_INVOICE_PAYMENT_TABS)
export const INVOICES_TABS_ARRAY = values(INVOICES_TABS)

export const INVOICES_STATES = {
  FULLY_PAID: 'fully_paid',
  PARTIALLY_PAID: 'partially_paid',
  UNPAID: 'unpaid'
}

export const INVOICES_STATUS_FILTER_OPTIONS = [
  {
    label: 'invoices.all',
    value: null
  },
  {
    label: 'invoices.payed',
    value: INVOICES_STATES.FULLY_PAID
  },
  {
    label: 'invoices.unpaid',
    value: INVOICES_STATES.UNPAID
  },
  {
    label: 'invoices.partiallyPaid',
    value: INVOICES_STATES.PARTIALLY_PAID
  }
]

export const INVOICES_ISSUED_TABLE_HEADERS = [
  {
    name: 'nr',
    sort: null,
    width: 1
  },
  {
    name: 'purchaser',
    sort: null,
    width: 3
  },
  {
    name: 'orderNumber',
    sort: null,
    width: 2
  },
  {
    name: 'amount',
    sort: null,
    width: 1
  },
  {
    name: 'issued',
    sort: true,
    width: 1
  },
  {
    name: 'paymentTo',
    sort: null,
    width: 1
  }
]

export const INVOICES_ISSUED_TABLE_NAMES = generateTableNames(
  INVOICES_ISSUED_TABLE_HEADERS
)
export const INVOICES_ISSUED_TABLE_WIDTHS = generateTableWidths(
  INVOICES_ISSUED_TABLE_HEADERS
)

export const INVOICES_RECEIVED_TABLE_HEADERS = [
  {
    name: 'nr',
    sort: null,
    width: 1
  },
  {
    name: 'issuerOfTheOffer',
    sort: null,
    width: 3
  },
  {
    name: 'orderNumber',
    sort: null,
    width: 2
  },
  {
    name: 'amount',
    sort: null,
    width: 1
  },
  {
    name: 'issued',
    sort: true,
    width: 1
  },
  {
    name: 'paymentTo',
    sort: null,
    width: 1
  }
]

export const INVOICES_RECEIVED_TABLE_NAMES = generateTableNames(
  INVOICES_RECEIVED_TABLE_HEADERS
)
export const INVOICES_RECEIVED_TABLE_WIDTHS = generateTableWidths(
  INVOICES_RECEIVED_TABLE_HEADERS
)

export const INVOICE_NUMBERING_SCHEME = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}

export const INVOICE_NUMBERING_SCHEME_OPTIONS = [
  {
    text: 'invoices.monthly',
    value: INVOICE_NUMBERING_SCHEME.MONTHLY
  },
  {
    text: 'invoices.yearly',
    value: INVOICE_NUMBERING_SCHEME.YEARLY
  }
]

export const PAYMENTS_OPTIONS = [
  {
    label: 'invoices.cash',
    value: 'cash'
  },
  {
    label: 'invoices.transfer',
    value: 'transfer'
  }
]

export const SAFT_NAMES = {
  SW: 'SW',
  EE: 'EE',
  TP: 'TP',
  MPP: 'MPP',
  B_SPV: 'B_SPV',
  B_SPV_DOSTAWA: 'B_SPV_DOSTAWA',
  B_MPV_PROWIZJA: 'B_MPV_PROWIZJA'
}

export const SAFT_NAMES_ARRAY = values(SAFT_NAMES)

export const INVOICE_ITEMS_TABLE_HEADERS = [
  {
    name: 'index',
    sort: null,
    width: 0.6,
    required: false
  },
  {
    name: 'name',
    sort: null,
    width: 6.9,
    required: true
  },
  {
    name: 'pkwiu',
    sort: null,
    width: 1.7,
    required: false
  },
  {
    name: 'gtu',
    sort: null,
    width: 1.5,
    required: false
  },
  {
    name: 'qty',
    sort: null,
    width: 1.3,
    required: true
  },
  {
    name: 'unit',
    sort: null,
    width: 1.5,
    required: true
  },
  {
    name: 'price',
    sort: null,
    width: 2.4,
    required: true
  },
  {
    name: 'tax',
    sort: null,
    width: 1.4,
    required: true
  },
  {
    name: 'remove',
    sort: null,
    width: 0.6,
    required: false
  }
]

export const INVOICE_ITEMS_TABLE_NAMES = generateTableNames(
  INVOICE_ITEMS_TABLE_HEADERS
)
export const INVOICE_ITEMS_TABLE_WIDTHS = generateTableWidths(
  INVOICE_ITEMS_TABLE_HEADERS
)

export const CORRECTION_REASON = {
  ADVANCE_PAYMENT_RETURN: 'advance_payment_return',
  MANDATORY_DISCOUNTS: 'mandatory_discounts',
  PRICE_INCREASE: 'price_increase',
  UNDUE_AMOUNTS_RETURN: 'undue_amounts_return',
  RETURN_OF_GOODS: 'return_of_goods',
  ITEMS_RELATED_MISTAKES: 'items_related_mistakes'
}
