import { Record } from 'immutable'
import { CURRENCIES, API_STATES } from 'src/ducks/consts'
import { Transportation } from 'src/features/contracts/duck/records'
import { OFFER_TYPES, TEMPLATE_OFFER_TYPES } from './consts'

export const TemplateDetails = Record({
  id: null,
  templateName: null,
  from: '',
  to: '',
  details: []
})

export const OfferFormExport = Record({
  state: API_STATES.PRISTINE,
  loadingDate: null,
  loadingTime: null,
  loadingLocationId: null,
  returnDate: null,
  returnTime: null,
  returnLocationId: null,
  customsClearanceLocationIdOpened: false,
  customsClearanceLocationId: null,
  containerId: null,
  cargoTypeId: null,
  weight: null,
  shipownerId: null,
  adrClassId: null,
  notes: null,
  price: null,
  currency: CURRENCIES.PLN,
  templateNameOpened: false,
  templateName: '',
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesRequireTracking: true,
  goPublicAt: null,
  visibility: 'public'
})

export const OfferTemplateExport = Record({
  state: API_STATES.PRISTINE,
  offerType: TEMPLATE_OFFER_TYPES.export,
  uuid: null,
  loadingDate: null,
  loadingTime: null,
  loadingLocationId: null,
  returnDate: null,
  returnTime: null,
  returnLocationId: null,
  customsClearanceLocationIdOpened: false,
  customsClearanceLocationId: null,
  containerId: null,
  cargoTypeId: null,
  weight: null,
  shipownerId: null,
  adrClassId: null,
  notes: null,
  price: null,
  currency: CURRENCIES.PLN,
  container: {},
  shipowner: {},
  loadingLocation: {},
  returnLocation: {},
  templateDetails: new TemplateDetails(),
  selected: false,
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesRequireTracking: false
})

export const OfferFormImport = Record({
  state: API_STATES.PRISTINE,
  pickupDate: null,
  pickupTime: null,
  pickupLocationId: null,
  unloadingDate: null,
  unloadingTime: null,
  unloadingLocationId: null,
  returnLocationIdOpened: false,
  returnLocationId: null,
  customsClearanceLocationIdOpened: false,
  customsClearanceLocationId: null,
  containerId: null,
  cargoTypeId: null,
  weight: null,
  shipownerId: null,
  adrClassId: null,
  notes: '',
  price: '',
  currency: CURRENCIES.PLN,
  templateNameOpened: false,
  templateName: '',
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesRequireTracking: true,
  goPublicAt: null,
  visibility: 'public'
})

export const OfferTemplateImport = Record({
  state: API_STATES.PRISTINE,
  offerType: TEMPLATE_OFFER_TYPES.import,
  uuid: null,
  pickupDate: null,
  pickupTime: null,
  pickupLocationId: null,
  unloadingDate: null,
  unloadingTime: null,
  unloadingLocationId: null,
  returnLocationIdOpened: false,
  returnLocationId: null,
  customsClearanceLocationIdOpened: false,
  customsClearanceLocationId: null,
  containerId: null,
  cargoTypeId: null,
  weight: null,
  shipownerId: null,
  adrClassId: null,
  notes: '',
  price: '',
  currency: CURRENCIES.PLN,
  pickupLocation: {},
  unloadingLocation: {},
  returnLocation: {},
  container: {},
  shipowner: {},
  templateDetails: new TemplateDetails(),
  selected: false,
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesRequireTracking: false
})

export const OfferFormFreeCarriers = Record({
  state: API_STATES.PRISTINE,
  availabilityDate: null,
  availabilityTime: null,
  availabilityLocationId: null,
  destinationLocationId: null,
  containerSemiTrailerId: null,
  isSemiTrailerWithGenset: null,
  adrClassId: null,
  notes: null,
  templateNameOpened: false,
  templateName: '',
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  price: '',
  currency: CURRENCIES.PLN,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesAllowTracking: true,
  goPublicAt: null,
  visibility: 'public'
})

export const OfferTemplateFreeCarriers = Record({
  state: API_STATES.PRISTINE,
  offerType: TEMPLATE_OFFER_TYPES.freeCarriers,
  uuid: null,
  availabilityDate: null,
  availabilityTime: null,
  availabilityLocationId: null,
  destinationLocationId: null,
  containerSemiTrailerId: null,
  isSemiTrailerWithGenset: null,
  adrClassId: null,
  notes: null,
  availabilityLocation: {},
  destinationLocation: {},
  containerSemiTrailer: {},
  templateDetails: new TemplateDetails(),
  selected: false,
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  price: '',
  currency: CURRENCIES.PLN,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesAllowTracking: false
})

export const OfferFormPostImport = Record({
  state: API_STATES.PRISTINE,
  availabilityDate: null,
  availabilityTime: null,
  availabilityLocationId: null,
  destinationLocationId: null,
  containerId: null,
  shipownerId: null,
  adrClassId: null,
  notes: '',
  templateNameOpened: false,
  templateName: '',
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  price: '',
  currency: CURRENCIES.PLN,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesAllowTracking: true,
  goPublicAt: null,
  visibility: 'public'
})

export const OfferTemplatePostImport = Record({
  state: API_STATES.PRISTINE,
  offerType: TEMPLATE_OFFER_TYPES.postImport,
  uuid: null,
  availabilityDate: null,
  availabilityTime: null,
  availabilityLocationId: null,
  destinationLocationId: null,
  containerId: null,
  shipownerId: null,
  adrClassId: null,
  notes: '',
  container: {},
  availabilityLocation: {},
  destinationLocation: {},
  templateDetails: new TemplateDetails(),
  selected: false,
  europeanEmissionStandard: null,
  type: OFFER_TYPES.QUEUE,
  price: '',
  currency: CURRENCIES.PLN,
  isPriceVisible: true,
  auctionDeadlineDate: null,
  auctionDeadlineTime: null,
  auctionDeadline: null,
  doesAllowTracking: false
})

export const OfferFormsScaffolds = Record({
  state: API_STATES.PRISTINE,
  lastOffersExport: [],
  templatesExport: [],
  lastOffersImport: [],
  templatesImport: [],
  lastOffersFreeCarriers: [],
  templatesFreeCarriers: [],
  lastOffersPostImport: [],
  templatesPostImport: []
})

export const OfferFormContract = Record({
  state: API_STATES.PRISTINE,
  type: 'export',
  startDate: null,
  endDate: null,
  validFor: 7,
  conditions: '',
  notes: '',
  transportations: [Transportation()]
})
