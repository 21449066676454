export const MESSAGE_EVENT_TYPES = {
  MESSAGE_SENT: '.message.sent',
  OFFER_RESERVED: '.offer.reserved',
  OFFER_DELETED: '.offer.deleted',
  OFFER_FINISHED: '.offer.finished',
  OFFER_HANDLER_CHANGED: '.offer.handler.changed',
  OFFER_BID: '.offer.bid',
  OFFER_RESERVATION_ACCEPTED: '.offer.reservation.accepted',
  OFFER_RESERVATION_CANCELLED: '.offer.reservation.cancelled',
  OFFER_RESERVATION_QUEUE_PLACE_CHANGED:
    '.offer.reservation.queue.place-changed',
  OFFER_RESERVATION_REJECTED: '.offer.reservation.rejected'
}

//maps contextType to exchange uri name
export const CONTEXT_TYPE_MAP = {
  export_cargo_offer: 'export-cargo',
  import_cargo_offer: 'import-cargo',
  post_import_container_offer: 'post-import-containers',
  free_carrier_offer: 'free-carriers'
}
