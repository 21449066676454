import { API_STATES } from 'src/ducks/consts'
import { Terms, TermsOfService } from './records'
import {
  acceptTermsOfServiceRoutine,
  getTermsOfServiceRoutine
} from './actions'

export const termsOfServiceReducer = (state = new TermsOfService(), action) => {
  switch (action.type) {
    case getTermsOfServiceRoutine.REQUEST:
    case acceptTermsOfServiceRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getTermsOfServiceRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('terms', new Terms(action.payload))
    case acceptTermsOfServiceRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE)
    case getTermsOfServiceRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', action.payload.message)
        .set('terms', new Terms())
    case acceptTermsOfServiceRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
        .set('errorMessage', action.payload.message)
    case getTermsOfServiceRoutine.FULFILL:
    case acceptTermsOfServiceRoutine.FULFILL:
      return state.set('errorMessage', null).set('state', API_STATES.DONE)
    default:
      return state
  }
}
