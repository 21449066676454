import { Record } from 'immutable'
import { API_STATES } from 'src/ducks/consts'

export const Registration = Record({
  activeStepIndex: 0,
  regulationsAccepted: false,
  subscriptionType: '',
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  phoneNumber: '',
  businessProfile: '',
  taxId: '',
  companyName: '',
  address: '',
  postalCode: '',
  country: '',
  city: '',
  additionalInfo: '',
  vehiclesCount: '',
  state: API_STATES.PRISTINE,
  error: '',
  leadSource: '',
  leadSourceSelect: '',
  leadSourceOtherDetails: '',
  leadSourceAffiliateProgramDetailsFirstAndLastName: '',
  leadSourceAffiliateProgramDetailsPhone: '',
  leadSourceAffiliateProgramDetailsCompany: ''
})

export const Login = Record({
  state: API_STATES.PRISTINE,
  errorMessage: ''
})

Login.prototype.isLoading = function() {
  return this.state === API_STATES.IN_PROGRESS
}

export const ForgotPassword = Record({
  state: API_STATES.PRISTINE,
  errorMessage: '',
  sentSuccessfully: false,
  changedSuccessfully: false
})
