import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from '@redux-saga/core/effects'
import { map, prop } from 'ramda'
import { createRoutine } from 'redux-saga-routines'
import { navigate } from 'gatsby-plugin-intl'
import {
  selectMyFinishedOffersExportFiltersFormatted,
  selectMyOffersExportFiltersFormatted,
  selectMyOngoingExportOffers,
  selectSelectedExportOffers
} from 'src/features/myOffers/duck/selectors'
import OfferService from 'src/services/OfferService'
import ExchangeService from 'src/services/ExchangeService'
import TrackingService from 'src/services/TrackingService'
import ReservationService from 'src/services/ReservationService'
import {
  makeExportOfferPublicRoutine,
  refreshExportOfferRoutine
} from 'src/features/stocks/ducks/actions-export'
import { fillFormUsingTemplateExport } from 'src/features/offers/duck/actions'
import { normalizeToOfferTemplateExport } from 'src/features/offers/duck/normalizers'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import {
  acceptExportReservationRoutine,
  rejectExportReservationRoutine
} from 'src/features/reservations/duck/actions'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'

export const getMyOngoingExportOffersRoutine = createRoutine(
  'GET_MY_ONGOING_EXPORT_OFFERS'
)
export const getMyFinishedExportOffersRoutine = createRoutine(
  'GET_MY_FINISHED_EXPORT_OFFERS'
)
export const setMyExportFiltersRoutine = createRoutine('SET_MY_EXPORT_FILTERS')
export const setMyFinishedExportFiltersRoutine = createRoutine(
  'SET_MY_FINISHED_EXPORT_FILTERS'
)
export const setSelectedExportOfferRoutine = createRoutine(
  'SELECT_EXPORT_OFFER'
)
export const selectAllExportOffersRoutine = createRoutine(
  'SELECT_ALL_EXPORT_OFFERS'
)
export const clearSelectedExportOffersRoutine = createRoutine(
  'CLEAR_SELECTED_EXPORT_OFFERS'
)
export const deleteMultipleExportOffersRoutine = createRoutine(
  'DELETE_MULTIPLE_EXPORT_OFFERS'
)
export const deleteExportOfferRoutine = createRoutine('DELETE_EXPORT_OFFER')
export const restoreExportOfferRoutine = createRoutine('RESTORE_EXPORT_OFFER')
export const showOngoingExportOfferReservationsRoutine = createRoutine(
  'SHOW_ONGOING_EXPORT_OFFER_RESERVATIONS'
)
export const showFinishedExportOfferReservationsRoutine = createRoutine(
  'SHOW_FINISHED_EXPORT_OFFER_RESERVATIONS'
)
export const copyMyOfferToExportFormRoutine = createRoutine(
  'COPY_MY_OFFER_TO_EXPORT_FORM'
)

export const createExportOfferTrackingRoutine = createRoutine(
  'CREATE_EXPORT_OFFER_TRACKING'
)

//ACTIONS

export function* getMyOngoingExportOffers() {
  yield put(getMyOngoingExportOffersRoutine.request())
  try {
    const filters = yield select(selectMyOffersExportFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyOngoingExportOffers, filters)
    yield put(getMyOngoingExportOffersRoutine.success({ data, meta }))
  } catch (e) {
    yield put(getMyOngoingExportOffersRoutine.failure())
    console.log(e)
  }
}

function* getMyFinishedExportOffers() {
  yield put(getMyFinishedExportOffersRoutine.request())
  try {
    const filters = yield select(selectMyFinishedOffersExportFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OfferService.showMyFinishedExportOffers, filters)
    yield put(getMyFinishedExportOffersRoutine.success({ data, meta }))
  } catch (e) {
    yield put(getMyFinishedExportOffersRoutine.failure())
    console.log(e)
  }
}

function* showOngoingExportOfferReservations({ payload }) {
  yield put(showOngoingExportOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.showOngoingExportOfferReservations,
      payload.id
    )
    yield put(showOngoingExportOfferReservationsRoutine.success(data))
  } catch (e) {
    yield put(showOngoingExportOfferReservationsRoutine.failure())
    console.log(e)
  }
}

function* showFinishedExportOfferReservations({ payload }) {
  yield put(showFinishedExportOfferReservationsRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.showFinishedExportOfferReservations,
      payload.id
    )
    yield put(showFinishedExportOfferReservationsRoutine.success(data))
  } catch (e) {
    yield put(showFinishedExportOfferReservationsRoutine.failure())
    console.log(e)
  }
}

function* setMyExportFilters({ payload }) {
  yield put(setMyExportFiltersRoutine.success(payload))
}

function* setMyFinishedExportFilters({ payload }) {
  yield put(setMyFinishedExportFiltersRoutine.success(payload))
}

function* setSelectedOffer({ payload }) {
  yield put(setSelectedExportOfferRoutine.success(payload))
}

function* selectAllOffers() {
  const offers = yield select(selectMyOngoingExportOffers)
  const ids = map(prop('id'), offers)
  yield put(selectAllExportOffersRoutine.success({ ids }))
}

function* clearSelectedOffers() {
  yield put(clearSelectedExportOffersRoutine.success())
}

function* deleteMultipleExportOffers() {
  yield put(deleteMultipleExportOffersRoutine.request())
  try {
    const ids = yield select(selectSelectedExportOffers)
    yield call(OfferService.deleteMultipleExportOffers, { ids })
    yield put(deleteMultipleExportOffersRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOffersSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deleteMultipleExportOffersRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* deleteExportOffer({ payload }) {
  yield put(deleteExportOfferRoutine.request())
  try {
    yield call(OfferService.deleteExportOffer, payload.id, {
      reason: payload.reason
    })
    yield put(deleteExportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.deleteOneOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(deleteExportOfferRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    console.log(e)
  }
}

function* restoreExportOffer({ payload }) {
  yield put(restoreExportOfferRoutine.request())
  try {
    yield call(OfferService.restoreExportOffer, payload.id)
    yield put(restoreExportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.restoreOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    yield put(restoreExportOfferRoutine.failure())
    console.log(e)
  }
}

function* copyMyOfferToExportForm({ payload }) {
  const { data } = yield call(ExchangeService.showExportOffer, {
    id: payload.offer.id
  })
  const normalized = normalizeToOfferTemplateExport(data)

  yield call(fillFormUsingTemplateExport, { payload: { template: normalized } })
  navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.OFFER_FORM_EXPORT}`)
}

export function* createExportOfferTracking({ payload }) {
  yield put(createExportOfferTrackingRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.createExportOfferTracking,
      payload.id
    )
    yield put(createExportOfferTrackingRoutine.success(data))
  } catch (e) {
    yield put(createExportOfferTrackingRoutine.fulfill())
  }
}

//WATCHERS

function* getMyOngoingExportOffersRoutineWatcher() {
  yield takeLatest(
    [
      setMyExportFiltersRoutine.SUCCESS,
      getMyOngoingExportOffersRoutine.TRIGGER,
      deleteMultipleExportOffersRoutine.SUCCESS,
      deleteExportOfferRoutine.SUCCESS,
      restoreExportOfferRoutine.SUCCESS,
      refreshExportOfferRoutine.SUCCESS,
      makeExportOfferPublicRoutine.SUCCESS,
      rejectExportReservationRoutine.SUCCESS
    ],
    getMyOngoingExportOffers
  )
}

function* getMyFinishedExportOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyFinishedExportOffersRoutine.TRIGGER,
      setMyFinishedExportFiltersRoutine.SUCCESS,
      acceptExportReservationRoutine.SUCCESS,
      rejectExportReservationRoutine.SUCCESS,
      restoreExportOfferRoutine.SUCCESS,
      deleteExportOfferRoutine.SUCCESS
    ],
    getMyFinishedExportOffers
  )
}

function* setMyExportFiltersRoutineWatcher() {
  yield takeLatest(setMyExportFiltersRoutine.TRIGGER, setMyExportFilters)
}

function* setMyFinishedExportFiltersRoutineWatcher() {
  yield takeLatest(
    setMyFinishedExportFiltersRoutine.TRIGGER,
    setMyFinishedExportFilters
  )
}

function* setSelectedOfferRoutineWatcher() {
  yield takeLatest(setSelectedExportOfferRoutine.TRIGGER, setSelectedOffer)
}

function* selectAllOffersRoutineWatcher() {
  yield takeLatest(selectAllExportOffersRoutine.TRIGGER, selectAllOffers)
}
function* clearSelectedOffersRoutineWatcher() {
  yield takeLatest(
    [
      getMyOngoingExportOffersRoutine.SUCCESS,
      clearSelectedExportOffersRoutine.TRIGGER
    ],
    clearSelectedOffers
  )
}

function* deleteMultipleExportOffersRoutineWatcher() {
  yield takeLatest(
    deleteMultipleExportOffersRoutine.TRIGGER,
    deleteMultipleExportOffers
  )
}

function* deleteExportOfferRoutineWatcher() {
  yield takeLatest(deleteExportOfferRoutine.TRIGGER, deleteExportOffer)
}

function* restoreExportOfferRoutineWatcher() {
  yield takeLatest(restoreExportOfferRoutine.TRIGGER, restoreExportOffer)
}

function* showOngoingExportOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showOngoingExportOfferReservationsRoutine.TRIGGER,
    showOngoingExportOfferReservations
  )
}

function* showFinishedExportOfferReservationsRoutineWatcher() {
  yield takeEvery(
    showFinishedExportOfferReservationsRoutine.TRIGGER,
    showFinishedExportOfferReservations
  )
}

function* copyMyOfferToExportFormRoutineWatcher() {
  yield takeLatest(
    copyMyOfferToExportFormRoutine.TRIGGER,
    copyMyOfferToExportForm
  )
}

function* createExportOfferTrackingRoutineWatcher() {
  yield takeLatest(
    createExportOfferTrackingRoutine.TRIGGER,
    createExportOfferTracking
  )
}

export const myExportOffersSagas = [
  fork(getMyOngoingExportOffersRoutineWatcher),
  fork(setMyExportFiltersRoutineWatcher),
  fork(setSelectedOfferRoutineWatcher),
  fork(deleteMultipleExportOffersRoutineWatcher),
  fork(selectAllOffersRoutineWatcher),
  fork(clearSelectedOffersRoutineWatcher),
  fork(deleteExportOfferRoutineWatcher),
  fork(getMyFinishedExportOffersRoutineWatcher),
  fork(setMyFinishedExportFiltersRoutineWatcher),
  fork(restoreExportOfferRoutineWatcher),
  fork(showOngoingExportOfferReservationsRoutineWatcher),
  fork(copyMyOfferToExportFormRoutineWatcher),
  fork(showFinishedExportOfferReservationsRoutineWatcher),
  fork(createExportOfferTrackingRoutineWatcher)
]
