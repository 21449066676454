import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  select,
  takeLatest
} from '@redux-saga/core/effects'

import ReservationService from 'src/services/ReservationService'
import { ERROR_DELAY_TIME } from 'src/ducks/consts'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import {
  selectMyReservationsExportFinishedFiltersFormatted,
  selectMyReservationsExportOngoingFiltersFormatted,
  selectMyReservationsFreeCarrierFinishedFiltersFormatted,
  selectMyReservationsFreeCarrierOngoingFiltersFormatted,
  selectMyReservationsImportFinishedFiltersFormatted,
  selectMyReservationsImportOngoingFiltersFormatted,
  selectMyReservationsPostImportFinishedFiltersFormatted,
  selectMyReservationsPostImportOngoingFiltersFormatted
} from 'src/features/reservations/duck/selectors'
import {
  createExportOfferTracking,
  getMyOngoingExportOffers
} from 'src/features/myOffers/duck/actions-export'
import {
  createImportOfferTracking,
  getMyOngoingImportOffers
} from 'src/features/myOffers/duck/actions-import'
import {
  createPostImportOfferTracking,
  getMyOngoingPostImportOffers
} from 'src/features/myOffers/duck/actions-post-import'
import {
  createFreeCarrierOfferTracking,
  getMyOngoingFreeCarrierOffers
} from 'src/features/myOffers/duck/actions-free-carrier'
import { getErrorMessageFromApiResponse } from 'src/utils/helpers'

//routines

export const getMyExportOngoingReservationsRoutine = createRoutine(
  'GET_MY_EXPORT_ONGOING_RESERVATIONS'
)

export const getMyExportFinishedReservationsRoutine = createRoutine(
  'GET_MY_EXPORT_FINISHED_RESERVATIONS'
)

export const getMyImportOngoingReservationsRoutine = createRoutine(
  'GET_MY_IMPORT_ONGOING_RESERVATIONS'
)

export const getMyImportFinishedReservationsRoutine = createRoutine(
  'GET_MY_IMPORT_FINISHED_RESERVATIONS'
)

export const getMyPostImportOngoingReservationsRoutine = createRoutine(
  'GET_MY_POST_IMPORT_ONGOING_RESERVATIONS'
)

export const getMyPostImportFinishedReservationsRoutine = createRoutine(
  'GET_MY_POST_IMPORT_FINISHED_RESERVATIONS'
)

export const getMyFreeCarrierOngoingReservationsRoutine = createRoutine(
  'GET_MY_FREE_CARRIER_ONGOING_RESERVATIONS'
)

export const getMyFreeCarrierFinishedReservationsRoutine = createRoutine(
  'GET_MY_FREE_CARRIER_FINISHED_RESERVATIONS'
)

export const cancelExportReservationRoutine = createRoutine(
  'CANCEL_EXPORT_RESERVATION'
)

export const cancelImportReservationRoutine = createRoutine(
  'CANCEL_IMPORT_RESERVATION'
)

export const cancelPostImportReservationRoutine = createRoutine(
  'CANCEL_POST_IMPORT_RESERVATION'
)

export const cancelFreeCarrierReservationRoutine = createRoutine(
  'CANCEL_FREE_CARRIER_RESERVATION'
)

export const acceptExportReservationRoutine = createRoutine(
  'ACCEPT_EXPORT_RESERVATION'
)

export const acceptImportReservationRoutine = createRoutine(
  'ACCEPT_IMPORT_RESERVATION'
)

export const acceptPostImportReservationRoutine = createRoutine(
  'ACCEPT_POST_IMPORT_RESERVATION'
)

export const acceptFreeCarrierReservationRoutine = createRoutine(
  'ACCEPT_FREE_CARRIER_RESERVATION'
)

export const rejectExportReservationRoutine = createRoutine(
  'REJECT_EXPORT_RESERVATION'
)

export const rejectImportReservationRoutine = createRoutine(
  'REJECT_IMPORT_RESERVATION'
)

export const rejectPostImportReservationRoutine = createRoutine(
  'REJECT_POST_IMPORT_RESERVATION'
)

export const rejectFreeCarrierReservationRoutine = createRoutine(
  'REJECT_FREE_CARRIER_RESERVATION'
)

export const setMyExportOngoingReservationsFiltersRoutine = createRoutine(
  'SET_MY_EXPORT_ONGOING_RESERVATIONS_FILTERS'
)
export const setMyExportFinishedReservationsFiltersRoutine = createRoutine(
  'SET_MY_EXPORT_FINISHED_RESERVATIONS_FILTERS'
)

export const setMyImportOngoingReservationsFiltersRoutine = createRoutine(
  'SET_MY_IMPORT_ONGOING_RESERVATIONS_FILTERS'
)
export const setMyImportFinishedReservationsFiltersRoutine = createRoutine(
  'SET_MY_IMPORT_FINISHED_RESERVATIONS_FILTERS'
)

export const setMyPostImportOngoingReservationsFiltersRoutine = createRoutine(
  'SET_MY_POST_IMPORT_ONGOING_RESERVATIONS_FILTERS'
)
export const setMyPostImportFinishedReservationsFiltersRoutine = createRoutine(
  'SET_MY_POST_IMPORT_FINISHED_RESERVATIONS_FILTERS'
)

export const setMyFreeCarrierOngoingReservationsFiltersRoutine = createRoutine(
  'SET_MY_FREE_CARRIER_ONGOING_RESERVATIONS_FILTERS'
)
export const setMyFreeCarrierFinishedReservationsFiltersRoutine = createRoutine(
  'SET_MY_FREE_CARRIER_FINISHED_RESERVATIONS_FILTERS'
)

//actions

function* getMyExportOngoingReservations() {
  yield put(getMyExportOngoingReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsExportOngoingFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(ReservationService.getMyExportOngoingReservations, filters)
    yield put(
      getMyExportOngoingReservationsRoutine.success({
        data,
        meta
      })
    )
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyExportOngoingReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyExportOngoingReservationsRoutine.fulfill())
  }
}

function* getMyExportFinishedReservations() {
  yield put(getMyExportFinishedReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsExportFinishedFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(ReservationService.getMyExportFinishedReservations, filters)
    yield put(getMyExportFinishedReservationsRoutine.success({ data, meta }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyExportFinishedReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyExportFinishedReservationsRoutine.fulfill())
  }
}

function* getMyImportOngoingReservations() {
  yield put(getMyImportOngoingReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsImportOngoingFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(ReservationService.getMyImportOngoingReservations, filters)
    yield put(
      getMyImportOngoingReservationsRoutine.success({
        data,
        meta
      })
    )
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyImportOngoingReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyImportOngoingReservationsRoutine.fulfill())
  }
}

function* getMyImportFinishedReservations() {
  yield put(getMyImportFinishedReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsImportFinishedFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(ReservationService.getMyImportFinishedReservations, filters)
    yield put(getMyImportFinishedReservationsRoutine.success({ data, meta }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyImportFinishedReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyImportFinishedReservationsRoutine.fulfill())
  }
}

function* getMyPostImportOngoingReservations() {
  yield put(getMyPostImportOngoingReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsPostImportOngoingFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(
      ReservationService.getMyPostImportOngoingReservations,
      filters
    )
    yield put(
      getMyPostImportOngoingReservationsRoutine.success({
        data,
        meta
      })
    )
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyPostImportOngoingReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyPostImportOngoingReservationsRoutine.fulfill())
  }
}

function* getMyPostImportFinishedReservations() {
  yield put(getMyPostImportFinishedReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsPostImportFinishedFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(
      ReservationService.getMyPostImportFinishedReservations,
      filters
    )
    yield put(
      getMyPostImportFinishedReservationsRoutine.success({ data, meta })
    )
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyPostImportFinishedReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyPostImportFinishedReservationsRoutine.fulfill())
  }
}

function* getMyFreeCarrierOngoingReservations() {
  yield put(getMyFreeCarrierOngoingReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsFreeCarrierOngoingFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(
      ReservationService.getMyFreeCarrierOngoingReservations,
      filters
    )
    yield put(
      getMyFreeCarrierOngoingReservationsRoutine.success({
        data,
        meta
      })
    )
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyFreeCarrierOngoingReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyFreeCarrierOngoingReservationsRoutine.fulfill())
  }
}

function* getMyFreeCarrierFinishedReservations() {
  yield put(getMyFreeCarrierFinishedReservationsRoutine.request())
  try {
    const filters = yield select(
      selectMyReservationsFreeCarrierFinishedFiltersFormatted
    )
    const {
      response: { data, meta }
    } = yield call(
      ReservationService.getMyFreeCarrierFinishedReservations,
      filters
    )
    yield put(
      getMyFreeCarrierFinishedReservationsRoutine.success({ data, meta })
    )
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getMyFreeCarrierFinishedReservationsRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getMyFreeCarrierFinishedReservationsRoutine.fulfill())
  }
}

function* cancelExportReservation({ payload }) {
  yield put(cancelExportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.cancelExportReservation,
      payload.offerId,
      payload.reservationId,
      payload.reason
    )
    yield put(cancelExportReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'reservations.cancelReservationSuccessMessage'
        }),
        type: 'success'
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(cancelExportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(cancelExportReservationRoutine.fulfill())
  }
}

function* cancelImportReservation({ payload }) {
  yield put(cancelImportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.cancelImportReservation,
      payload.offerId,
      payload.reservationId,
      payload.reason
    )
    yield put(cancelImportReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'reservations.cancelReservationSuccessMessage'
        }),
        type: 'success'
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(cancelImportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(cancelImportReservationRoutine.fulfill())
  }
}

function* cancelPostImportReservation({ payload }) {
  yield put(cancelPostImportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.cancelPostImportReservation,
      payload.offerId,
      payload.reservationId,
      payload.reason
    )
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'reservations.cancelReservationSuccessMessage'
        }),
        type: 'success'
      }
    })
    yield put(cancelPostImportReservationRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(cancelPostImportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(cancelPostImportReservationRoutine.fulfill())
  }
}
function* cancelFreeCarrierReservation({ payload }) {
  yield put(cancelFreeCarrierReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.cancelFreeCarrierReservation,
      payload.offerId,
      payload.reservationId,
      payload.reason
    )
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'reservations.cancelReservationSuccessMessage'
        }),
        type: 'success'
      }
    })
    yield put(cancelFreeCarrierReservationRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(cancelFreeCarrierReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(cancelFreeCarrierReservationRoutine.fulfill())
  }
}

function* acceptExportReservation({ payload }) {
  yield put(acceptExportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.acceptExportReservation,
      payload.offerId,
      payload.reservationId
    )
    if (payload.withTracking) {
      yield call(createExportOfferTracking, {
        payload: { id: payload.offerId }
      })
    } else {
      yield call(getMyOngoingExportOffers)
    }
    if (payload.onSuccess) {
      payload.onSuccess()
    }

    yield put(acceptExportReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.acceptOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(acceptExportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(acceptExportReservationRoutine.fulfill())
  }
}

function* acceptImportReservation({ payload }) {
  yield put(acceptImportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.acceptImportReservation,
      payload.offerId,
      payload.reservationId
    )
    if (payload.withTracking) {
      yield call(createImportOfferTracking, {
        payload: { id: payload.offerId }
      })
    } else {
      yield call(getMyOngoingImportOffers)
    }
    if (payload.onSuccess) {
      payload.onSuccess()
    }
    yield put(acceptImportReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.acceptOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(acceptImportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(acceptImportReservationRoutine.fulfill())
  }
}

function* acceptPostImportReservation({ payload }) {
  yield put(acceptPostImportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.acceptPostImportReservation,
      payload.offerId,
      payload.reservationId
    )
    if (payload.withTracking) {
      yield call(createPostImportOfferTracking, {
        payload: { id: payload.offerId }
      })
    } else {
      yield call(getMyOngoingPostImportOffers)
    }
    if (payload.onSuccess) {
      payload.onSuccess()
    }
    yield put(acceptPostImportReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.acceptOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(acceptPostImportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(acceptPostImportReservationRoutine.fulfill())
  }
}
function* acceptFreeCarrierReservation({ payload }) {
  yield put(acceptFreeCarrierReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.acceptFreeCarrierReservation,
      payload.offerId,
      payload.reservationId
    )
    if (payload.withTracking) {
      yield call(createFreeCarrierOfferTracking, {
        payload: { id: payload.offerId }
      })
    } else {
      yield call(getMyOngoingFreeCarrierOffers)
    }
    if (payload.onSuccess) {
      payload.onSuccess()
    }
    yield put(acceptFreeCarrierReservationRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.acceptOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(acceptFreeCarrierReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(acceptFreeCarrierReservationRoutine.fulfill())
  }
}

function* rejectExportReservation({ payload }) {
  yield put(rejectExportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.rejectExportReservation,
      payload.data?.offerId,
      payload.data?.reservationId,
      payload.data?.reason
    )
    yield put(rejectExportReservationRoutine.success(data))
    if (payload.meta?.onSuccess) {
      payload.meta.onSuccess()
    }
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.rejectOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(rejectExportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(rejectExportReservationRoutine.fulfill())
  }
}

function* rejectImportReservation({ payload }) {
  yield put(rejectImportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.rejectImportReservation,
      payload.data?.offerId,
      payload.data?.reservationId,
      payload.data?.reason
    )
    yield put(rejectImportReservationRoutine.success(data))
    if (payload.meta?.onSuccess) {
      payload.meta.onSuccess()
    }
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.rejectOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(rejectImportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(rejectImportReservationRoutine.fulfill())
  }
}

function* rejectPostImportReservation({ payload }) {
  yield put(rejectPostImportReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.rejectPostImportReservation,
      payload.data?.offerId,
      payload.data?.reservationId,
      payload.data?.reason
    )
    yield put(rejectPostImportReservationRoutine.success(data))
    if (payload.meta?.onSuccess) {
      payload.meta.onSuccess()
    }
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.rejectOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(rejectPostImportReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(rejectPostImportReservationRoutine.fulfill())
  }
}
function* rejectFreeCarrierReservation({ payload }) {
  yield put(rejectFreeCarrierReservationRoutine.request())
  try {
    const { data } = yield call(
      ReservationService.rejectFreeCarrierReservation,
      payload.data?.offerId,
      payload.data?.reservationId,
      payload.data?.reason
    )
    yield put(rejectFreeCarrierReservationRoutine.success(data))
    if (payload.meta?.onSuccess) {
      payload.meta.onSuccess()
    }
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.rejectOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(rejectFreeCarrierReservationRoutine.failure({ message }))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(rejectFreeCarrierReservationRoutine.fulfill())
  }
}

function* setMyExportOngoingReservationsFilters({ payload }) {
  yield put(setMyExportOngoingReservationsFiltersRoutine.success(payload))
}

function* setMyExportFinishedReservationsFilters({ payload }) {
  yield put(setMyExportFinishedReservationsFiltersRoutine.success(payload))
}

function* setMyImportOngoingReservationsFilters({ payload }) {
  yield put(setMyImportOngoingReservationsFiltersRoutine.success(payload))
}

function* setMyImportFinishedReservationsFilters({ payload }) {
  yield put(setMyImportFinishedReservationsFiltersRoutine.success(payload))
}

function* setMyPostImportOngoingReservationsFilters({ payload }) {
  yield put(setMyPostImportOngoingReservationsFiltersRoutine.success(payload))
}

function* setMyPostImportFinishedReservationsFilters({ payload }) {
  yield put(setMyPostImportFinishedReservationsFiltersRoutine.success(payload))
}

function* setMyFreeCarrierOngoingReservationsFilters({ payload }) {
  yield put(setMyFreeCarrierOngoingReservationsFiltersRoutine.success(payload))
}

function* setMyFreeCarrierFinishedReservationsFilters({ payload }) {
  yield put(setMyFreeCarrierFinishedReservationsFiltersRoutine.success(payload))
}

//watchers

function* getMyExportOngoingReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyExportOngoingReservationsRoutine.TRIGGER,
      acceptExportReservationRoutine.SUCCESS,
      cancelExportReservationRoutine.SUCCESS,
      rejectExportReservationRoutine.SUCCESS,
      setMyExportOngoingReservationsFiltersRoutine.SUCCESS
    ],
    getMyExportOngoingReservations
  )
}

function* getMyExportFinishedReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyExportFinishedReservationsRoutine.TRIGGER,
      acceptExportReservationRoutine.SUCCESS,
      cancelExportReservationRoutine.SUCCESS,
      rejectExportReservationRoutine.SUCCESS,
      setMyExportFinishedReservationsFiltersRoutine.SUCCESS
    ],
    getMyExportFinishedReservations
  )
}

function* getMyImportOngoingReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyImportOngoingReservationsRoutine.TRIGGER,
      acceptImportReservationRoutine.SUCCESS,
      cancelImportReservationRoutine.SUCCESS,
      rejectImportReservationRoutine.SUCCESS,
      setMyImportOngoingReservationsFiltersRoutine.SUCCESS
    ],
    getMyImportOngoingReservations
  )
}

function* getMyImportFinishedReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyImportFinishedReservationsRoutine.TRIGGER,
      acceptImportReservationRoutine.SUCCESS,
      cancelImportReservationRoutine.SUCCESS,
      rejectImportReservationRoutine.SUCCESS,
      setMyImportFinishedReservationsFiltersRoutine.SUCCESS
    ],
    getMyImportFinishedReservations
  )
}

function* getMyPostImportOngoingReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyPostImportOngoingReservationsRoutine.TRIGGER,
      acceptPostImportReservationRoutine.SUCCESS,
      cancelPostImportReservationRoutine.SUCCESS,
      rejectPostImportReservationRoutine.SUCCESS,
      setMyPostImportOngoingReservationsFiltersRoutine.SUCCESS
    ],
    getMyPostImportOngoingReservations
  )
}

function* getMyPostImportFinishedReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyPostImportFinishedReservationsRoutine.TRIGGER,
      acceptPostImportReservationRoutine.SUCCESS,
      cancelPostImportReservationRoutine.SUCCESS,
      rejectPostImportReservationRoutine.SUCCESS,
      setMyPostImportFinishedReservationsFiltersRoutine.SUCCESS
    ],
    getMyPostImportFinishedReservations
  )
}

function* getMyFreeCarrierOngoingReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyFreeCarrierOngoingReservationsRoutine.TRIGGER,
      acceptFreeCarrierReservationRoutine.SUCCESS,
      cancelFreeCarrierReservationRoutine.SUCCESS,
      rejectFreeCarrierReservationRoutine.SUCCESS,
      setMyFreeCarrierOngoingReservationsFiltersRoutine.SUCCESS
    ],
    getMyFreeCarrierOngoingReservations
  )
}

function* getMyFreeCarrierFinishedReservationsRoutineWatcher() {
  yield takeLatest(
    [
      getMyFreeCarrierFinishedReservationsRoutine.TRIGGER,
      acceptFreeCarrierReservationRoutine.SUCCESS,
      cancelFreeCarrierReservationRoutine.SUCCESS,
      rejectFreeCarrierReservationRoutine.SUCCESS,
      setMyFreeCarrierFinishedReservationsFiltersRoutine.SUCCESS
    ],
    getMyFreeCarrierFinishedReservations
  )
}

function* cancelExportReservationRoutineWatcher() {
  yield takeLatest(
    cancelExportReservationRoutine.TRIGGER,
    cancelExportReservation
  )
}

function* cancelImportReservationRoutineWatcher() {
  yield takeLatest(
    cancelImportReservationRoutine.TRIGGER,
    cancelImportReservation
  )
}
function* cancelPostImportReservationRoutineWatcher() {
  yield takeLatest(
    cancelPostImportReservationRoutine.TRIGGER,
    cancelPostImportReservation
  )
}
function* cancelFreeCarrierReservationRoutineWatcher() {
  yield takeLatest(
    cancelFreeCarrierReservationRoutine.TRIGGER,
    cancelFreeCarrierReservation
  )
}

function* acceptExportReservationRoutineWatcher() {
  yield takeLatest(
    acceptExportReservationRoutine.TRIGGER,
    acceptExportReservation
  )
}

function* acceptImportReservationRoutineWatcher() {
  yield takeLatest(
    acceptImportReservationRoutine.TRIGGER,
    acceptImportReservation
  )
}
function* acceptPostImportReservationRoutineWatcher() {
  yield takeLatest(
    acceptPostImportReservationRoutine.TRIGGER,
    acceptPostImportReservation
  )
}
function* acceptFreeCarrierReservationRoutineWatcher() {
  yield takeLatest(
    acceptFreeCarrierReservationRoutine.TRIGGER,
    acceptFreeCarrierReservation
  )
}

function* rejectExportReservationRoutineWatcher() {
  yield takeLatest(
    rejectExportReservationRoutine.TRIGGER,
    rejectExportReservation
  )
}

function* rejectImportReservationRoutineWatcher() {
  yield takeLatest(
    rejectImportReservationRoutine.TRIGGER,
    rejectImportReservation
  )
}
function* rejectPostImportReservationRoutineWatcher() {
  yield takeLatest(
    rejectPostImportReservationRoutine.TRIGGER,
    rejectPostImportReservation
  )
}
function* rejectFreeCarrierReservationRoutineWatcher() {
  yield takeLatest(
    rejectFreeCarrierReservationRoutine.TRIGGER,
    rejectFreeCarrierReservation
  )
}
function* setMyExportOngoingReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyExportOngoingReservationsFiltersRoutine.TRIGGER,
    setMyExportOngoingReservationsFilters
  )
}

function* setMyExportFinishedReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyExportFinishedReservationsFiltersRoutine.TRIGGER,
    setMyExportFinishedReservationsFilters
  )
}
function* setMyImportOngoingReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyImportOngoingReservationsFiltersRoutine.TRIGGER,
    setMyImportOngoingReservationsFilters
  )
}

function* setMyImportFinishedReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyImportFinishedReservationsFiltersRoutine.TRIGGER,
    setMyImportFinishedReservationsFilters
  )
}
function* setMyPostImportOngoingReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyPostImportOngoingReservationsFiltersRoutine.TRIGGER,
    setMyPostImportOngoingReservationsFilters
  )
}

function* setMyPostImportFinishedReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyPostImportFinishedReservationsFiltersRoutine.TRIGGER,
    setMyPostImportFinishedReservationsFilters
  )
}
function* setMyFreeCarrierOngoingReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyFreeCarrierOngoingReservationsFiltersRoutine.TRIGGER,
    setMyFreeCarrierOngoingReservationsFilters
  )
}

function* setMyFreeCarrierFinishedReservationsFiltersRoutineWatcher() {
  yield takeLatest(
    setMyFreeCarrierFinishedReservationsFiltersRoutine.TRIGGER,
    setMyFreeCarrierFinishedReservationsFilters
  )
}

export const reservationsSagas = [
  fork(getMyExportOngoingReservationsRoutineWatcher),
  fork(getMyExportFinishedReservationsRoutineWatcher),
  fork(getMyImportOngoingReservationsRoutineWatcher),
  fork(getMyImportFinishedReservationsRoutineWatcher),
  fork(getMyPostImportOngoingReservationsRoutineWatcher),
  fork(getMyPostImportFinishedReservationsRoutineWatcher),
  fork(getMyFreeCarrierOngoingReservationsRoutineWatcher),
  fork(getMyFreeCarrierFinishedReservationsRoutineWatcher),
  fork(cancelExportReservationRoutineWatcher),
  fork(cancelImportReservationRoutineWatcher),
  fork(cancelPostImportReservationRoutineWatcher),
  fork(cancelFreeCarrierReservationRoutineWatcher),
  fork(acceptExportReservationRoutineWatcher),
  fork(acceptImportReservationRoutineWatcher),
  fork(acceptPostImportReservationRoutineWatcher),
  fork(acceptFreeCarrierReservationRoutineWatcher),
  fork(rejectExportReservationRoutineWatcher),
  fork(rejectImportReservationRoutineWatcher),
  fork(rejectPostImportReservationRoutineWatcher),
  fork(rejectFreeCarrierReservationRoutineWatcher),
  fork(setMyExportFinishedReservationsFiltersRoutineWatcher),
  fork(setMyExportOngoingReservationsFiltersRoutineWatcher),
  fork(setMyImportFinishedReservationsFiltersRoutineWatcher),
  fork(setMyImportOngoingReservationsFiltersRoutineWatcher),
  fork(setMyPostImportFinishedReservationsFiltersRoutineWatcher),
  fork(setMyPostImportOngoingReservationsFiltersRoutineWatcher),
  fork(setMyFreeCarrierFinishedReservationsFiltersRoutineWatcher),
  fork(setMyFreeCarrierOngoingReservationsFiltersRoutineWatcher)
]
