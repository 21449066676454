import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
  spawn,
  take
} from '@redux-saga/core/effects'
import { eventChannel } from 'redux-saga'
import { head, pluck } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import ExchangeService from 'src/services/ExchangeService'
import DictionariesService from 'src/services/DictionariesService'
import LaravelEchoService from 'src/services/LaravelEchoService'
import {
  ERROR_DELAY_TIME,
  EXCHANGE_TYPES,
  EXCHANGE_EVENT_TYPES
} from 'src/ducks/consts'
import { selectDictionaries } from 'src/ducks/selectors'
import { selectCurrentUserId } from 'src/features/account/duck/selectors'
import {
  selectFreeCarrierFilters,
  selectFreeCarrierFiltersFormatted,
  selectFreeCarriersFiltersTabsList,
  selectFreeCarriersActiveTab,
  selectFreeCarriersTabsData
} from 'src/features/stocks/ducks/selectors'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import {
  displayResponseErrorMessage,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'
import { normalizeToLocationsIds, normalizeToCompaniesIds } from './normalizers'

export const getFreeCarriersOffersRoutine = createRoutine(
  'GET_FREE_CARRIER_OFFERS'
)
export const clearFreeCarriersOffersRoutine = createRoutine(
  'CLEAR_FREE_CARRIER_OFFERS'
)
export const cleanFreeCarriersOffersDetailsRoutine = createRoutine(
  'CLEAN_FREE_CARRIER_OFFER_DETAILS'
)
export const showFreeCarrierOfferDetailsRoutine = createRoutine(
  'SHOW_FREE_CARRIER_OFFER_DETAILS'
)
export const setFreeCarriersDrawerRoutine = createRoutine(
  'SET_FREE_CARRIERS_DRAWER'
)
export const setFreeCarriersFiltersRoutine = createRoutine(
  'SET_FREE_CARRIER_FILTERS'
)
export const updateFreeCarrierOfferRoutine = createRoutine(
  'UPDATE_FREE_CARRIER_OFFER'
)
export const reserveFreeCarrierOfferRoutine = createRoutine(
  'RESERVE_FREE_CARRIER_OFFER'
)

export const bidFreeCarrierOfferRoutine = createRoutine(
  'BID_FREE_CARRIER_OFFER'
)
export const refreshFreeCarrierOfferRoutine = createRoutine(
  'REFRESH_FREE_CARRIER_OFFER'
)

export const makeFreeCarrierOfferPublicRoutine = createRoutine(
  'MAKE_FREE_CARRIER_OFFER_PUBLIC'
)

export const clearFreeCarriersFiltersRoutine = createRoutine(
  'CLEAR_FREE_CARRIERS_FILTERS'
)
export const getFreeCarriersFilterTabsListRoutine = createRoutine(
  'GET_FREE_CARRIERS_FILTER_TABS_LIST'
)
export const createFreeCarriersFilterTabRoutine = createRoutine(
  'CREATE_FREE_CARRIERS_FILTER_TAB'
)
export const removeFreeCarriersFilterTabRoutine = createRoutine(
  'REMOVE_FREE_CARRIERS_FILTER_TAB'
)
export const setFreeCarriersActiveTabRoutine = createRoutine(
  'SET_FREE_CARRIERS_ACTIVE_TAB'
)

export const FREE_CARRIERS_TAB_EVENT_OFFER_CREATED =
  'FREE_CARRIERS_TAB_EVENT_OFFER_CREATED'
export const FREE_CARRIERS_TAB_EVENT_OFFER_REFRESHED =
  'FREE_CARRIERS_TAB_EVENT_OFFER_REFRESHED'
export const FREE_CARRIERS_TAB_EVENT_OFFER_DELETED =
  'FREE_CARRIERS_TAB_EVENT_OFFER_DELETED'
export const FREE_CARRIERS_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA =
  'FREE_CARRIERS_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA'
export const freeCarriersOffersCleanerRoutine = createRoutine(
  'FREE_CARRIERS_OFFERS_CLEANER'
)
//actions

function* getFreeCarriersOffers() {
  yield put(getFreeCarriersOffersRoutine.request())

  try {
    const activeTab = yield select(selectFreeCarriersActiveTab)

    //case when initially loads
    if (isFalsy(activeTab)) {
      yield call(getFreeCarriersFilterTabsList)
      const tabs = yield select(selectFreeCarriersFiltersTabsList)
      yield call(setFreeCarriersActiveTab, { payload: { id: head(tabs).id } })
      return
    }

    const tabs = yield select(selectFreeCarriersFiltersTabsList)
    const filters = yield select(selectFreeCarrierFiltersFormatted)

    const data = yield call(
      ExchangeService.getFreeCarriersOffersListByTab,
      isFalsy(activeTab) ? head(tabs).id : activeTab,
      filters
    )

    yield put(getFreeCarriersOffersRoutine.success(data))
    yield call(getFreeCarriersFilterTabsList)
  } catch (e) {
    yield put(getFreeCarriersOffersRoutine.failure())
    console.log(e)
  }
}

function* clearFreeCarriersOffers() {
  yield put(clearFreeCarriersOffersRoutine.success())
}

function* cleanFreeCarriersOffersDetails() {
  yield put(cleanFreeCarriersOffersDetailsRoutine.success())
}

function* showFreeCarrierOfferDetails({ payload }) {
  yield put(showFreeCarrierOfferDetailsRoutine.request())
  try {
    const { data } = yield call(ExchangeService.showFreeCarrierOffer, payload)
    yield put(showFreeCarrierOfferDetailsRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(showFreeCarrierOfferDetailsRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(showFreeCarrierOfferDetailsRoutine.fulfill())
  }
}

function* setFreeCarriersDrawer({ payload }) {
  yield put(setFreeCarriersDrawerRoutine.success(payload))
}

function* setFreeCarriersFilters({ payload }) {
  const dictionaries = yield select(selectDictionaries)

  yield put(setFreeCarriersFiltersRoutine.success({ ...payload, dictionaries }))
  yield* getFreeCarriersOffers()
}

function* clearFreeCarriersFilters() {
  yield put(clearFreeCarriersFiltersRoutine.success())
  yield* getFreeCarriersOffers()
}

function* updateFreeCarrierOffer({ payload }) {
  yield put(updateFreeCarrierOfferRoutine.request())
  try {
    const { data } = yield call(
      ExchangeService.updateFreeCarrierOffer,
      payload.id,
      payload.values
    )
    yield put(updateFreeCarrierOfferRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(updateFreeCarrierOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(updateFreeCarrierOfferRoutine.fulfill())
  }
}

function* reserveFreeCarrierOffer({ payload }) {
  yield put(reserveFreeCarrierOfferRoutine.request())
  try {
    yield call(ExchangeService.reserveFreeCarrierOffer, payload.id)
    yield put(reserveFreeCarrierOfferRoutine.success())
    yield* showFreeCarrierOfferDetails({ payload })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(reserveFreeCarrierOfferRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(reserveFreeCarrierOfferRoutine.fulfill())
  }
}

function* bidFreeCarrierOffer({ payload }) {
  yield put(bidFreeCarrierOfferRoutine.request())
  try {
    yield call(ExchangeService.bidFreeCarrierOffer, payload.id, {
      price: payload.price
    })
    yield put(bidFreeCarrierOfferRoutine.success())
    yield call(showFreeCarrierOfferDetails, { payload: { id: payload.id } })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'makeABid.successMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(bidFreeCarrierOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(bidFreeCarrierOfferRoutine.fulfill())
  }
}

function* refreshFreeCarrierOffer({ payload }) {
  yield put(refreshFreeCarrierOfferRoutine.request())
  try {
    yield call(ExchangeService.refreshFreeCarrierOffer, payload.id)
    yield put(refreshFreeCarrierOfferRoutine.success())
    yield* showFreeCarrierOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.refreshOfferSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(refreshFreeCarrierOfferRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(refreshFreeCarrierOfferRoutine.fulfill())
  }
}

function* makeFreeCarrierferPublic({ payload }) {
  yield put(makeFreeCarrierOfferPublicRoutine.request())
  try {
    yield call(ExchangeService.makeFreeCarrierOfferPublic, payload.id)
    yield put(makeFreeCarrierOfferPublicRoutine.success())
    yield* showFreeCarrierOfferDetails({ payload })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'myOffers.makeOfferPublicSuccessMessage'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(makeFreeCarrierOfferPublicRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(makeFreeCarrierOfferPublicRoutine.fulfill())
  }
}

function listenToExchangeTabEvents(data) {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToExchangeTabs({
      exchangeType: data.exchangeType,
      userId: data.userId,
      tabsIds: data.tabsIds,
      callback: (tabId, event, payload) => {
        switch (event) {
          case EXCHANGE_EVENT_TYPES.OFFER_CREATED:
            return emitter({
              type: FREE_CARRIERS_TAB_EVENT_OFFER_CREATED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_REFRESHED:
            return emitter({
              type: FREE_CARRIERS_TAB_EVENT_OFFER_REFRESHED,
              tabId,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_DELETED:
            return emitter({
              type: FREE_CARRIERS_TAB_EVENT_OFFER_DELETED,
              tabId,
              payload
            })
          default:
            //handle information about not handled type
            return null
        }
      }
    })
    // unsubscribe function
    return () => {
      // do whatever to interrupt the socket communication here
    }
  })
}

export function* getFreeCarriersFilterTabsList() {
  try {
    const tabs = yield call(ExchangeService.getFreeCarriersFilterTabsList)
    const locationsIds = normalizeToLocationsIds(tabs.data)
    const companiesIds = normalizeToCompaniesIds(tabs.data)

    const locations = yield call(DictionariesService.locations, {
      filter: {
        id: locationsIds
      }
    })
    const companies = yield call(DictionariesService.companies, {
      filter: {
        id: companiesIds
      }
    })

    yield put(
      getFreeCarriersFilterTabsListRoutine.success({
        data: tabs.data,
        locations: locations.data,
        companies: companies.data
      })
    )

    //listen to tabs events
    const userId = yield select(selectCurrentUserId)
    yield spawn(listenToExchangeTabEventsWatcher, {
      exchangeType: EXCHANGE_TYPES.FREE_CARRIERS,
      userId,
      tabsIds: pluck('id')(tabs.data)
    })
  } catch (e) {
    console.log(e)
  }
}

function* createFreeCarriersFilterTab({ payload }) {
  try {
    const filters = yield select(selectFreeCarrierFilters)
    const activeTab = yield select(selectFreeCarriersActiveTab)
    const tabs = yield select(selectFreeCarriersFiltersTabsList)
    const activeTabData = head(tabs.filter(v => v.id === activeTab))

    const { data } = yield call(ExchangeService.createFreeCarriersFilterTab, {
      name: payload.empty ? null : filters.tabName
    })
    yield put(
      createFreeCarriersFilterTabRoutine.success({
        data,
        currentFilters: payload.empty ? null : activeTabData.currentFilters
      })
    )
    //set active new tab

    yield call(setFreeCarriersActiveTab, { payload: { id: data.id } })
  } catch (e) {
    console.log(e)
  }
}

function* removeFreeCarriersFilterTab({ payload }) {
  try {
    yield call(ExchangeService.removeFreeCarriersFilterTab, payload.id)
    yield put(removeFreeCarriersFilterTabRoutine.success({ id: payload.id }))
    //after removal set active tab to first one
    const tabs = yield select(selectFreeCarriersFiltersTabsList)
    yield call(setFreeCarriersActiveTab, { payload: { id: head(tabs).id } })
  } catch (e) {
    console.log(e)
  }
}

function* setFreeCarriersActiveTab({ payload }) {
  try {
    const tabs = yield select(selectFreeCarriersFiltersTabsList)
    const tab = head(tabs.filter(v => v.id === payload.id))
    const tabsData = yield select(selectFreeCarriersTabsData)

    yield put(
      setFreeCarriersActiveTabRoutine.success({
        activeTab: tab.id,
        filters: tab.currentFilters
      })
    )
    yield call(getFreeCarriersOffers)

    //if tab has live data stored move them to offers list
    if (!isFalsy(tabsData[tab.id])) {
      yield put({
        type: FREE_CARRIERS_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
        payload: { tabId: tab.id, tabData: tabsData[tab.id] }
      })
    }
  } catch (e) {
    console.log(e)
  }
}

function* freeCarriersOffersCleaner() {
  yield put(freeCarriersOffersCleanerRoutine.success())
}

//watchers

function* getFreeCarriersOffersRoutineWatcher() {
  yield takeLatest(getFreeCarriersOffersRoutine.TRIGGER, getFreeCarriersOffers)
}

function* clearFreeCarriersOffersRoutineWatcher() {
  yield takeLatest(
    clearFreeCarriersOffersRoutine.TRIGGER,
    clearFreeCarriersOffers
  )
}

function* cleanFreeCarriersOffersDetailsRoutineWatcher() {
  yield takeLatest(
    cleanFreeCarriersOffersDetailsRoutine.TRIGGER,
    cleanFreeCarriersOffersDetails
  )
}

function* showFreeCarrierOfferDetailsRoutineWatcher() {
  yield takeLatest(
    showFreeCarrierOfferDetailsRoutine.TRIGGER,
    showFreeCarrierOfferDetails
  )
}

function* setFreeCarriersDrawerRoutineWatcher() {
  yield takeLatest(setFreeCarriersDrawerRoutine.TRIGGER, setFreeCarriersDrawer)
}

function* setFreeCarriersFiltersRoutineWatcher() {
  yield takeLatest(
    setFreeCarriersFiltersRoutine.TRIGGER,
    setFreeCarriersFilters
  )
}

function* updateFreeCarrierOfferRoutineWatcher() {
  yield takeLatest(
    updateFreeCarrierOfferRoutine.TRIGGER,
    updateFreeCarrierOffer
  )
}

function* reserveFreeCarrierOfferRoutineWatcher() {
  yield takeLatest(
    reserveFreeCarrierOfferRoutine.TRIGGER,
    reserveFreeCarrierOffer
  )
}

function* bidFreeCarrierOfferRoutineWatcher() {
  yield takeLatest(bidFreeCarrierOfferRoutine.TRIGGER, bidFreeCarrierOffer)
}

function* refreshFreeCarrierOfferRoutineWatcher() {
  yield takeLatest(
    refreshFreeCarrierOfferRoutine.TRIGGER,
    refreshFreeCarrierOffer
  )
}

function* makeFreeCarrierOfferPublicRoutineWatcher() {
  yield takeLatest(
    makeFreeCarrierOfferPublicRoutine.TRIGGER,
    makeFreeCarrierferPublic
  )
}

function* clearFreeCarriersFiltersRoutineWatcher() {
  yield takeLatest(
    clearFreeCarriersFiltersRoutine.TRIGGER,
    clearFreeCarriersFilters
  )
}

function* getFreeCarriersFilterTabsListRoutineWatcher() {
  yield takeLatest(
    getFreeCarriersFilterTabsListRoutine.TRIGGER,
    getFreeCarriersFilterTabsList
  )
}

function* createFreeCarriersFilterTabRoutineWatcher() {
  yield takeLatest(
    createFreeCarriersFilterTabRoutine.TRIGGER,
    createFreeCarriersFilterTab
  )
}

function* removeFreeCarriersFilterTabRoutineWatcher() {
  yield takeLatest(
    removeFreeCarriersFilterTabRoutine.TRIGGER,
    removeFreeCarriersFilterTab
  )
}

function* setFreeCarriersActiveTabRoutineWatcher() {
  yield takeLatest(
    setFreeCarriersActiveTabRoutine.TRIGGER,
    setFreeCarriersActiveTab
  )
}

function* listenToExchangeTabEventsWatcher(payload) {
  const channel = yield call(listenToExchangeTabEvents, payload)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* freeCarriersOffersCleanerRoutineWatcher() {
  yield takeLatest(
    freeCarriersOffersCleanerRoutine.TRIGGER,
    freeCarriersOffersCleaner
  )
}

export const exchangeFreeCarriersSagas = [
  fork(getFreeCarriersOffersRoutineWatcher),
  fork(clearFreeCarriersOffersRoutineWatcher),
  fork(cleanFreeCarriersOffersDetailsRoutineWatcher),
  fork(showFreeCarrierOfferDetailsRoutineWatcher),
  fork(setFreeCarriersDrawerRoutineWatcher),
  fork(setFreeCarriersFiltersRoutineWatcher),
  fork(updateFreeCarrierOfferRoutineWatcher),
  fork(reserveFreeCarrierOfferRoutineWatcher),
  fork(refreshFreeCarrierOfferRoutineWatcher),
  fork(clearFreeCarriersFiltersRoutineWatcher),
  fork(getFreeCarriersFilterTabsListRoutineWatcher),
  fork(createFreeCarriersFilterTabRoutineWatcher),
  fork(removeFreeCarriersFilterTabRoutineWatcher),
  fork(setFreeCarriersActiveTabRoutineWatcher),
  fork(freeCarriersOffersCleanerRoutineWatcher),
  fork(bidFreeCarrierOfferRoutineWatcher),
  fork(makeFreeCarrierOfferPublicRoutineWatcher)
]
