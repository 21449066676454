import translate from 'src/intl/translate'
import { generateTableNames, generateTableWidths } from 'src/utils/helpers'

export const CONTRACTS_TYPES = {
  EXPORT: 'export',
  IMPORT: 'import'
}

export const CONTRACTS_TYPES_OPTIONS = [
  {
    text: translate().formatMessage({ id: 'common.export' }),
    value: CONTRACTS_TYPES.EXPORT
  },
  {
    text: translate().formatMessage({ id: 'common.import' }),
    value: CONTRACTS_TYPES.IMPORT
  }
]

export const CONTRACTS_HEADERS = {
  STOCK: [
    {
      name: 'nr',
      sort: null,
      width: 1
    },
    {
      name: 'type',
      sort: null,
      width: 1
    },
    {
      name: 'company',
      sort: null,
      width: 2
    },
    {
      name: 'from',
      sort: null,
      width: 1
    },
    {
      name: 'to',
      sort: null,
      width: 1
    },
    {
      name: 'contract',
      sort: null,
      width: 3
    },
    {
      name: 'date',
      sort: true,
      width: 1
    }
  ],
  MY_OFFERS_ONGOING: [
    {
      name: 'nr',
      sort: null,
      width: 1
    },
    {
      name: 'offers',
      sort: null,
      width: 1
    },
    {
      name: 'type',
      sort: null,
      width: 1
    },
    {
      name: 'from',
      sort: null,
      width: 1
    },
    {
      name: 'to',
      sort: null,
      width: 1
    },
    {
      name: 'contract',
      sort: null,
      width: 3
    },
    {
      name: 'handler',
      sort: null,
      width: 2
    },
    {
      name: 'date',
      sort: true,
      width: 1
    }
  ],
  MY_OFFERS_FINISHED: [
    {
      name: 'state',
      sort: null,
      width: 0.5
    },
    {
      name: 'nr',
      sort: null,
      width: 1
    },
    {
      name: 'price',
      sort: null,
      width: 1
    },
    {
      name: 'type',
      sort: null,
      width: 1
    },
    {
      name: 'from',
      sort: null,
      width: 1
    },
    {
      name: 'to',
      sort: null,
      width: 1
    },
    {
      name: 'contract',
      sort: null,
      width: 3
    },
    {
      name: 'handler',
      sort: null,
      width: 2
    },
    {
      name: 'date',
      sort: null,
      width: 1
    }
  ],
  MY_RESERVATIONS_ONGOING: [
    {
      name: 'nr',
      sort: null,
      width: 1
    },
    {
      name: 'price',
      sort: null,
      width: 1
    },
    {
      name: 'type',
      sort: null,
      width: 1
    },
    {
      name: 'company',
      sort: null,
      width: 1
    },
    {
      name: 'from',
      sort: null,
      width: 1
    },
    {
      name: 'to',
      sort: null,
      width: 1
    },
    {
      name: 'contract',
      sort: null,
      width: 3
    },
    {
      name: 'reservation',
      sort: true,
      width: 2
    }
  ],
  MY_RESERVATIONS_FINISHED: [
    {
      name: 'state',
      sort: null,
      width: 0.5
    },
    {
      name: 'nr',
      sort: null,
      width: 1
    },
    {
      name: 'price',
      sort: null,
      width: 1
    },
    {
      name: 'type',
      sort: null,
      width: 1
    },
    {
      name: 'company',
      sort: null,
      width: 1
    },
    {
      name: 'from',
      sort: null,
      width: 1
    },
    {
      name: 'to',
      sort: null,
      width: 1
    },
    {
      name: 'contract',
      sort: null,
      width: 3
    },
    {
      name: 'reservation',
      sort: true,
      width: 2
    }
  ]
}

export const CONTRACTS_HEADERS_STOCKS_WIDTHS = generateTableWidths(
  CONTRACTS_HEADERS.STOCK
)

export const CONTRACTS_HEADERS_STOCKS_NAMES = generateTableNames(
  CONTRACTS_HEADERS.STOCK
)

export const CONTRACTS_HEADERS_MY_OFFERS_ONGOING_WIDTHS = generateTableWidths(
  CONTRACTS_HEADERS.MY_OFFERS_ONGOING
)

export const CONTRACTS_HEADERS_MY_OFFERS_ONGOING_NAMES = generateTableNames(
  CONTRACTS_HEADERS.MY_OFFERS_ONGOING
)

export const CONTRACTS_HEADERS_MY_OFFERS_FINISHED_WIDTHS = generateTableWidths(
  CONTRACTS_HEADERS.MY_OFFERS_FINISHED
)

export const CONTRACTS_HEADERS_MY_OFFERS_FINISHED_NAMES = generateTableNames(
  CONTRACTS_HEADERS.MY_OFFERS_FINISHED
)

export const CONTRACTS_HEADERS_MY_RESERVATIONS_ONGOING_WIDTHS = generateTableWidths(
  CONTRACTS_HEADERS.MY_RESERVATIONS_ONGOING
)

export const CONTRACTS_HEADERS_MY_RESERVATIONS_ONGOING_NAMES = generateTableNames(
  CONTRACTS_HEADERS.MY_RESERVATIONS_ONGOING
)

export const CONTRACTS_HEADERS_MY_RESERVATIONS_FINISHED_WIDTHS = generateTableWidths(
  CONTRACTS_HEADERS.MY_RESERVATIONS_FINISHED
)

export const CONTRACTS_HEADERS_MY_RESERVATIONS_FINISHED_NAMES = generateTableNames(
  CONTRACTS_HEADERS.MY_RESERVATIONS_FINISHED
)
