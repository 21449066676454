import { Record } from 'immutable'
import { API_STATES } from 'src/ducks/consts'

export const Terms = Record({
  id: null,
  createdAt: null,
  content: '',
  notes: ''
})

export const TermsOfService = Record({
  state: API_STATES.PRISTINE,
  errorMessage: null,
  terms: Terms()
})
