import api from './APIInterceptor'

export default class NotificationsService {
  static getNotificationsList(query = '') {
    return api.get(`/me/notifications?${query}`)
  }

  static markNotificationAsRead(notificationId) {
    return api.patch(`/me/notifications/${notificationId}/mark-as-read`)
  }

  static markAllNotificationsAsRead() {
    return api.patch('/me/notifications/mark-as-read')
  }

  static markAllNotificationsAsSeen() {
    return api.patch('/me/notifications/mark-as-seen')
  }

  static getUnreadNotificationsCount() {
    return api.get('/me/notifications/unread-counter')
  }

  static getUnseenNotificationsCount() {
    return api.get('/me/notifications/unseen-counter')
  }

  static getNotificationsSettings() {
    return api.get('/me/notifications/settings')
  }

  static updateNotificationSettings(payload) {
    return api.patch('/me/notifications/settings', { settings: payload })
  }
}
