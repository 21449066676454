import { createRoutine } from 'redux-saga-routines'
import {
  fork,
  put,
  take,
  takeLatest,
  select,
  call,
  spawn,
  takeEvery
} from '@redux-saga/core/effects'
import { eventChannel } from 'redux-saga'
import { head, pathOr } from 'ramda'
import qs from 'qs'
import MessagesService from 'src/services/MessagesService'
import DictionariesService from 'src/services/DictionariesService'
import LaravelEchoService from 'src/services/LaravelEchoService'
import ExchangeService from 'src/services/ExchangeService'
import ReservationService from 'src/services/ReservationService'

import {
  selectCurrentUser,
  selectCurrentUserCompanyId
} from 'src/features/account/duck/selectors'
import { toggleChatNotifications } from 'src/features/account/duck/actions'
import { createExportOfferTracking } from 'src/features/myOffers/duck/actions-export'
import { createImportOfferTracking } from 'src/features/myOffers/duck/actions-import'
import { createPostImportOfferTracking } from 'src/features/myOffers/duck/actions-post-import'
import { createFreeCarrierOfferTracking } from 'src/features/myOffers/duck/actions-free-carrier'

import notificationSound from 'src/assets/intuition.mp3'

import { isChatMuted } from 'src/features/messages/duck/selectors'
import { selectConversationDetails, selectMessagesDetails } from './selectors'
import {
  normalizeToInterlocutor,
  normalizeMessagesList,
  normalizeSentMessage,
  normalizeLastMessagesList,
  normalizeFoundUsers,
  normalizeContext
} from './normalizers'
import { MESSAGE_EVENT_TYPES, CONTEXT_TYPE_MAP } from './consts'

const sound =
  typeof window !== 'undefined' ? new Audio(notificationSound) : null

export const setMessagesListOpenedRoutine = createRoutine(
  'SET_MESSAGES_LIST_OPENED'
)
export const setConversationOpenedRoutine = createRoutine(
  'SET_CONVERSATION_OPENED'
)
export const sendMessageRoutine = createRoutine('SEND_MESSAGE')
export const bootstrapConversationRoutine = createRoutine(
  'BOOTSTRAP_CONVERSATION'
)
export const getConversationMessagesRoutine = createRoutine(
  'GET_CONVERSATION_MESSAGES'
)
export const clearConversationRoutine = createRoutine('CLEAR_CONVERSATION')
export const getLastMessagesListRoutine = createRoutine(
  'GET_LAST_MESSAGES_LIST'
)

export const getLastContextualMessagesListRoutine = createRoutine(
  'GET_LAST_CONTEXTUAL_MESSAGES_LIST'
)
export const getLastPersonalMessagesListRoutine = createRoutine(
  'GET_LAST_PERSONAL_MESSAGES_LIST'
)

export const getUnreadMessagesCountRoutine = createRoutine(
  'GET_UNREAD_MESSAGES_COUNT'
)
export const searchForUserConversationsRoutine = createRoutine(
  'SEARCH_FOR_USER_CONVERSATIONS'
)

export const clearLastMessagesListsRoutine = createRoutine(
  'CLEAR_LAST_MESSAGES_LISTS'
)

export const muteChatRoutine = createRoutine('MUTE_CHAT')
export const unmuteChatRoutine = createRoutine('UNMUTE_CHAT')
export const muteConversationRoutine = createRoutine('MUTE_CONVERSATION')
export const unmuteConversationRoutine = createRoutine('UNMUTE_CONVERSATION')
export const toggleConversationNotificationsRoutine = createRoutine(
  'TOGGLE_CONVERSATION_NOTIFICATIONS'
)
export const getConversationContextRoutine = createRoutine(
  'GET_CONVERSATION_CONTEXT'
)
export const markConversationAsSeenRoutine = createRoutine(
  'MARK_CONVERSATION_AS_SEEN'
)

export const markMessageAsSeenRoutine = createRoutine('MARK_MESSAGE_AS_SEEN')

export const reserveOfferRoutine = createRoutine('RESERVE_OFFER')
export const acceptReservationRoutine = createRoutine('ACCEPT_RESERVATION')
export const rejectReservationRoutine = createRoutine('REJECT_RESERVATION')
export const cancelReservationRoutine = createRoutine('CANCEL_RESERVATION')
export const getContextAvailableActionsRoutine = createRoutine(
  'GET_CONTEXT_AVAILABLE_ACTIONS'
)

export const MESSAGE_EVENT_USER_MESSAGE_SENT = 'MESSAGE_EVENT_USER_MESSAGE_SENT'
export const MESSAGE_EVENT_SYSTEM_MESSAGE_SENT =
  'MESSAGE_EVENT_SYSTEM_MESSAGE_SENT'
export const CHAT_PRESENCE_CHANNEL_INIT = 'CHAT_PRESENCE_CHANNEL_INIT'
export const CHAT_PRESENCE_CHANNEL_JOIN = 'CHAT_PRESENCE_CHANNEL_JOIN'
export const CHAT_PRESENCE_CHANNEL_LEAVE = 'CHAT_PRESENCE_CHANNEL_LEAVE'

//actions

function* setMessagesListOpened({ payload }) {
  yield put(setMessagesListOpenedRoutine.success(payload))
}

function* setConversationOpened({ payload }) {
  yield put(setConversationOpenedRoutine.success(payload))
}

function* sendMessage({ payload }) {
  const { callback, message } = payload
  const conversationDetails = yield select(selectConversationDetails)
  try {
    const { data } = yield call(MessagesService.sendMessage, {
      ...conversationDetails,
      message
    })
    yield put(
      sendMessageRoutine.success({
        data,
        message: normalizeSentMessage(pathOr('', ['message'], data))
      })
    )
    typeof callback === 'function' && callback()
  } catch (e) {
    console.log(e)
  }
}

function* bootstrapConversation() {
  const conversationDetails = yield select(selectConversationDetails)
  const currentUser = yield select(selectCurrentUser)

  try {
    let data = null
    const searchResult = yield call(MessagesService.findConversation, {
      ...conversationDetails
    })
    data = searchResult.data
    if (!data.id) {
      const startResult = yield call(MessagesService.startConversation, {
        ...conversationDetails
      })
      data = startResult.data
    }
    const interlocutor = head(
      pathOr([], ['participants'], data).filter(v => v.id !== currentUser.id)
    )
    const loggedInUser = head(
      pathOr([], ['participants'], data).filter(v => v.id === currentUser.id)
    )

    yield put(
      bootstrapConversationRoutine.success({
        interlocutor: normalizeToInterlocutor(interlocutor),
        conversationId: data.id,
        isConversationMuted: pathOr(false, ['pivot', 'isMuted'], loggedInUser),
        isAvailable: data.isAvailable
      })
    )
    if (data.contextId) {
      yield call(getConversationContext, {
        payload: {
          contextType: data.contextType,
          contextId: data.contextId,
          companyName: pathOr('', ['company', 'name'], interlocutor)
        }
      })
    }

    yield call(getConversationMessages, {
      payload: { conversationId: data.id, values: { limit: 10 } }
    })
  } catch (e) {
    //conversation not found

    const { data } = yield call(
      DictionariesService.users,
      `filter[name]=${conversationDetails.receiverId}`
    )
    const interlocutor = head(data)

    yield put(
      bootstrapConversationRoutine.success({
        interlocutor: normalizeToInterlocutor(interlocutor),
        conversationId: null
      })
    )

    if (conversationDetails.contextId) {
      yield call(getConversationContext, {
        payload: {
          contextType: conversationDetails.contextType,
          contextId: conversationDetails.contextId,
          companyName: pathOr('', ['company', 'name'], interlocutor)
        }
      })
    }
  }
}

function* getConversationContext({ payload }) {
  yield put(getConversationContextRoutine.request())
  try {
    const { data } = yield call(MessagesService.getConversationContext, {
      stock: CONTEXT_TYPE_MAP[payload.contextType],
      offerId: payload.contextId
    })

    const currentUserCompanyId = yield select(selectCurrentUserCompanyId)
    yield put(
      getConversationContextRoutine.success({
        data: normalizeContext(payload.contextType, {
          ...data,
          companyName: payload.companyName,
          currentUserCompanyId,
          contextId: payload.contextId
        })
      })
    )
  } catch (e) {
    console.log(e)
    yield put(getConversationContextRoutine.failure())
  }
}

function* getConversationMessages({ payload }) {
  yield put(getConversationMessagesRoutine.request())
  const currentUser = yield select(selectCurrentUser)
  try {
    const { data } = yield call(
      MessagesService.getConversationMessages,
      payload.conversationId,
      payload.values
    )
    const normalized = normalizeMessagesList(data, currentUser.id)
    yield put(
      getConversationMessagesRoutine.success({
        data: normalized,
        conversationId: payload.conversationId
      })
    )
  } catch (e) {
    console.log(e)
    yield put(getConversationMessagesRoutine.failure())
  }
}

function* markConversationAsSeen({ payload }) {
  try {
    yield call(MessagesService.markConversationAsSeen, payload.conversationId)
    yield spawn(getUnreadMessagesCount)
  } catch (e) {
    console.log(e)
  }
}

function* markMessageAsSeen({ payload }) {
  try {
    yield call(
      MessagesService.markMessageAsSeen,
      payload.conversationId,
      payload.messageId
    )
  } catch (e) {
    console.log(e)
  }
}

function* getContextAvailableActions({ payload }) {
  yield put(getContextAvailableActionsRoutine.request())
  try {
    const { data } = yield call(
      MessagesService.getConversationContextActions,
      payload.conversationId
    )

    //reservation can throw an exception if doesn't exist
    try {
      const response = yield call(
        MessagesService.getConversationContextReservation,
        payload.conversationId
      )
      yield put(
        getContextAvailableActionsRoutine.success({
          ...data,
          reservationId: response.data.id
        })
      )
    } catch (e) {
      yield put(
        getContextAvailableActionsRoutine.success({
          ...data
        })
      )
    }
  } catch (e) {
    yield put(getContextAvailableActionsRoutine.failure())
  }
}

function* reserveOffer({ payload }) {
  try {
    switch (payload.contextType) {
      case 'export_cargo_offer':
        yield call(ExchangeService.reserveExportOffer, payload.offerId)
        break
      case 'import_cargo_offer':
        yield call(ExchangeService.reserveImportOffer, payload.offerId)
        break
      case 'post_import_container_offer':
        yield call(ExchangeService.reservePostImportOffer, payload.offerId)
        break
      case 'free_carrier_offer':
        yield call(ExchangeService.reserveFreeCarrierOffer, payload.offerId)
        break
      default:
    }
  } catch (e) {
    console.log(e)
  }
}

function* acceptReservation({ payload }) {
  try {
    switch (payload.contextType) {
      case 'export_cargo_offer':
        yield call(
          ReservationService.acceptExportReservation,
          payload.offerId,
          payload.reservationId
        )
        if (payload.withTracking) {
          yield call(createExportOfferTracking, {
            payload: { id: payload.offerId }
          })
        }
        break
      case 'import_cargo_offer':
        yield call(
          ReservationService.acceptImportReservation,
          payload.offerId,
          payload.reservationId
        )
        if (payload.withTracking) {
          yield call(createImportOfferTracking, {
            payload: { id: payload.offerId }
          })
        }
        break
      case 'post_import_container_offer':
        yield call(
          ReservationService.acceptPostImportReservation,
          payload.offerId,
          payload.reservationId
        )
        if (payload.withTracking) {
          yield call(createPostImportOfferTracking, {
            payload: { id: payload.offerId }
          })
        }
        break
      case 'free_carrier_offer':
        yield call(
          ReservationService.acceptFreeCarrierReservation,
          payload.offerId,
          payload.reservationId
        )
        if (payload.withTracking) {
          yield call(createFreeCarrierOfferTracking, {
            payload: { id: payload.offerId }
          })
        }
        break
      default:
    }
  } catch (e) {
    console.log(e)
  }
}

function* rejectReservation({ payload }) {
  try {
    switch (payload.contextType) {
      case 'export_cargo_offer':
        yield call(
          ReservationService.rejectExportReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      case 'import_cargo_offer':
        yield call(
          ReservationService.rejectImportReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      case 'post_import_container_offer':
        yield call(
          ReservationService.rejectPostImportReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      case 'free_carrier_offer':
        yield call(
          ReservationService.rejectFreeCarrierReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      default:
    }
  } catch (e) {
    console.log(e)
  }
}

function* cancelReservation({ payload }) {
  try {
    switch (payload.contextType) {
      case 'export_cargo_offer':
        yield call(
          ReservationService.cancelExportReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      case 'import_cargo_offer':
        yield call(
          ReservationService.cancelImportReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      case 'post_import_container_offer':
        yield call(
          ReservationService.cancelPostImportReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      case 'free_carrier_offer':
        yield call(
          ReservationService.cancelFreeCarrierReservation,
          payload.offerId,
          payload.reservationId
        )
        break
      default:
    }
  } catch (e) {
    console.log(e)
  }
}

function* clearConversation() {
  yield put(clearConversationRoutine.success())
}

function* clearLastMessagesLists() {
  yield put(clearLastMessagesListsRoutine.success())
}

function* getLastMessagesList() {
  yield spawn(getLastContextualMessagesList, { payload: { page: 1 } })
  yield spawn(getLastPersonalMessagesList, { payload: { page: 1 } })
  yield spawn(getUnreadMessagesCount)
}

function* getLastContextualMessagesList({ payload }) {
  yield put(getLastContextualMessagesListRoutine.request())
  try {
    const currentUser = yield select(selectCurrentUser)
    const messages = yield select(selectMessagesDetails)

    const query = qs.stringify(
      {
        page: payload.page || messages.contextualMessagesPage,
        limit: messages.limit,
        filter: {
          type: 'contextual'
        }
      },
      { skipNull: true }
    )

    if (!messages.contextualMessagesAllFetched) {
      const response = yield call(MessagesService.getMessagesList, query)

      yield put(
        getLastContextualMessagesListRoutine.success({
          data: normalizeLastMessagesList({
            list: response.data,
            currentUserId: currentUser.id,
            messagesType: 'contextual'
          }),
          meta: response.response.meta,
          append: payload.append
        })
      )
    }
    yield put(getLastContextualMessagesListRoutine.fulfill())
  } catch (e) {
    console.log(e)
  }
}

function* getLastPersonalMessagesList({ payload }) {
  yield put(getLastContextualMessagesListRoutine.request())
  try {
    const currentUser = yield select(selectCurrentUser)
    const messages = yield select(selectMessagesDetails)

    const query = qs.stringify(
      {
        page: payload.page || messages.personalMessagesPage,
        limit: messages.limit,
        filter: {
          type: 'personal'
        }
      },
      { skipNull: true }
    )

    if (!messages.personalMessagesAllFetched) {
      const response = yield call(MessagesService.getMessagesList, query)

      yield put(
        getLastPersonalMessagesListRoutine.success({
          data: normalizeLastMessagesList({
            list: response.data,
            currentUserId: currentUser.id,
            messagesType: 'personal'
          }),
          meta: response.response.meta,
          append: payload.append
        })
      )
    }
    yield put(getLastPersonalMessagesListRoutine.fulfill())
  } catch (e) {
    console.log(e)
  }
}

function* searchForUserConversations({ payload }) {
  const currentUser = yield select(selectCurrentUser)
  const messages = yield select(selectMessagesDetails)

  yield put(searchForUserConversationsRoutine.request())

  if (
    payload.filter.type === 'contextual' &&
    !messages.contextualSearchResultsAllFetched
  ) {
    const query = qs.stringify(
      {
        ...payload,
        page: payload?.page || messages.contextualSearchResultsPage,
        limit: messages.limit
      },
      { skipNull: true }
    )

    const response = yield call(MessagesService.getMessagesList, query)

    try {
      yield put(
        searchForUserConversationsRoutine.success({
          field: 'contextualSearchResults',
          pageValueName: 'contextualSearchResultsPage',
          allFetchedValueName: 'contextualSearchResultsAllFetched',
          data: normalizeLastMessagesList({
            list: response.response.data,
            currentUserId: currentUser.id,
            messagesType: 'contextual'
          }),
          meta: response.response.meta,
          append: payload.append
        })
      )
    } catch (e) {
      yield put(searchForUserConversationsRoutine.failure())
      console.log(e)
    }
  }

  if (
    payload.filter.type === 'personal' &&
    !messages.personalSearchResultsAllFetched
  ) {
    const query = qs.stringify(
      {
        filter: {
          name: payload.filter.name
        },
        page: payload?.page || messages.personalSearchResultsPage,
        limit: messages.limit
      },
      { skipNull: true }
    )

    const response = yield call(DictionariesService.users, query)

    yield put(
      searchForUserConversationsRoutine.success({
        field: 'personalSearchResults',
        pageValueName: 'personalSearchResultsPage',
        allFetchedValueName: 'personalSearchResultsAllFetched',
        data: normalizeFoundUsers(response.data),
        meta: response.response.meta,
        append: payload.append
      })
    )
  }
}

function listenChatEvents(userId) {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToChatEvents(
      userId,
      (type, payload) => {
        switch (type) {
          case MESSAGE_EVENT_TYPES.MESSAGE_SENT:
            return emitter({
              type: MESSAGE_EVENT_USER_MESSAGE_SENT,
              payload
            })
          case MESSAGE_EVENT_TYPES.OFFER_RESERVED:
          case MESSAGE_EVENT_TYPES.OFFER_DELETED:
          case MESSAGE_EVENT_TYPES.OFFER_FINISHED:
          case MESSAGE_EVENT_TYPES.OFFER_HANDLER_CHANGED:
          case MESSAGE_EVENT_TYPES.OFFER_BID:
          case MESSAGE_EVENT_TYPES.OFFER_RESERVATION_ACCEPTED:
          case MESSAGE_EVENT_TYPES.OFFER_RESERVATION_CANCELLED:
          case MESSAGE_EVENT_TYPES.OFFER_RESERVATION_QUEUE_PLACE_CHANGED:
          case MESSAGE_EVENT_TYPES.OFFER_RESERVATION_REJECTED:
            return emitter({
              type: MESSAGE_EVENT_SYSTEM_MESSAGE_SENT,
              payload
            })
          default:
            //handle information about not handled type
            return null
        }
      }
    )
    // unsubscribe function
    return () => {}
  })
}

function listenToChatPresence() {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToChatUserPresence({
      initCallback: users => {
        emitter({
          type: CHAT_PRESENCE_CHANNEL_INIT,
          payload: { users }
        })
      },
      joiningCallback: user => {
        emitter({
          type: CHAT_PRESENCE_CHANNEL_JOIN,
          payload: { user }
        })
      },
      leavingCallback: user => {
        emitter({
          type: CHAT_PRESENCE_CHANNEL_LEAVE,
          payload: { user }
        })
      }
    })

    return () => {}
  })
}

export function* getUnreadMessagesCount() {
  try {
    const { data } = yield call(MessagesService.getUnreadMessagesCount)
    yield put(getUnreadMessagesCountRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

export function* muteChat() {
  try {
    yield spawn(MessagesService.muteChat)
    yield call(toggleChatNotifications, { payload: true })
  } catch (e) {
    console.log(e)
  }
}

export function* unmuteChat() {
  try {
    yield spawn(MessagesService.unmuteChat)
    yield call(toggleChatNotifications, { payload: false })
  } catch (e) {
    console.log(e)
  }
}

export function* toggleConversationNotifications({ payload }) {
  yield put(toggleConversationNotificationsRoutine.success(payload))
}

export function* muteConversation() {
  try {
    const conversationDetails = yield select(selectConversationDetails)
    yield spawn(
      MessagesService.muteConversation,
      conversationDetails.conversationId
    )
    yield call(toggleConversationNotifications, { payload: true })
  } catch (e) {
    console.log(e)
  }
}

export function* unmuteConversation() {
  try {
    const conversationDetails = yield select(selectConversationDetails)
    yield spawn(
      MessagesService.unmuteConversation,
      conversationDetails.conversationId
    )
    yield call(toggleConversationNotifications, { payload: false })
  } catch (e) {
    console.log(e)
  }
}

const playSound = async () => {
  try {
    const permissionStatus = await navigator.permissions.query({
      name: 'microphone'
    })
    if (permissionStatus.state === 'granted') {
      sound && sound.play()
    } else {
      // Użytkownik nie udzielił zgody na dostęp do dźwięku
      console.log('Permission not granted for audio')
    }
  } catch (error) {
    console.log('Error playing sound:', error)
  }
}

function* receiveMessage({ payload }) {
  try {
    const conversationMuted = payload.is_conversation_muted
    const chatMuted = yield select(isChatMuted)
    if (!conversationMuted && !chatMuted) yield call(playSound)
  } catch (err) {
    console.log('err', err)
  }
}

//watchers

export function* listenChatEventsWatcher(userId) {
  //listen to websockets channel
  const channel = yield call(listenChatEvents, userId)
  while (true) {
    const action = yield take(channel)
    yield spawn(getUnreadMessagesCount)
    yield put(action)
  }
}

export function* listenToChatPresenceWatcher() {
  //listen to websockets channel
  const channel = yield call(listenToChatPresence)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* setMessagesListOpenedRoutineWatcher() {
  yield takeLatest(setMessagesListOpenedRoutine.TRIGGER, setMessagesListOpened)
}

function* setConversationOpenedRoutineWatcher() {
  yield takeLatest(setConversationOpenedRoutine.TRIGGER, setConversationOpened)
}

function* sendMessageRoutineWatcher() {
  yield takeEvery(sendMessageRoutine.TRIGGER, sendMessage)
}

function* bootstrapConversationRoutineWatcher() {
  yield takeLatest(bootstrapConversationRoutine.TRIGGER, bootstrapConversation)
}

function* getConversationMessagesRoutineWatcher() {
  yield takeLatest(
    getConversationMessagesRoutine.TRIGGER,
    getConversationMessages
  )
}

function* clearConversationRoutineWatcher() {
  yield takeLatest(clearConversationRoutine.TRIGGER, clearConversation)
}

function* getLastMessagesListRoutineWatcher() {
  yield takeLatest(getLastMessagesListRoutine.TRIGGER, getLastMessagesList)
}

function* getUnreadMessagesCountRoutineWatcher() {
  yield takeLatest(
    getUnreadMessagesCountRoutine.TRIGGER,
    getUnreadMessagesCount
  )
}

function* searchForUserConversationsRoutineWatcher() {
  yield takeLatest(
    searchForUserConversationsRoutine.TRIGGER,
    searchForUserConversations
  )
}

function* getLastContextualMessagesListRoutineWatcher() {
  yield takeLatest(
    getLastContextualMessagesListRoutine.TRIGGER,
    getLastContextualMessagesList
  )
}

function* getLastPersonalMessagesListRoutineWatcher() {
  yield takeLatest(
    getLastPersonalMessagesListRoutine.TRIGGER,
    getLastPersonalMessagesList
  )
}

function* clearLastMessagesListsRoutineWatcher() {
  yield takeLatest(
    clearLastMessagesListsRoutine.TRIGGER,
    clearLastMessagesLists
  )
}

function* muteChatRoutineWatcher() {
  yield takeLatest(muteChatRoutine.TRIGGER, muteChat)
}

function* unmuteChatRoutineWatcher() {
  yield takeLatest(unmuteChatRoutine.TRIGGER, unmuteChat)
}

function* toggleConversationNotificationsRoutineWatcher() {
  yield takeLatest(
    toggleConversationNotificationsRoutine.TRIGGER,
    toggleConversationNotifications
  )
}

function* muteConversationRoutineWatcher() {
  yield takeLatest(muteConversationRoutine.TRIGGER, muteConversation)
}

function* unmuteConversationRoutineWatcher() {
  yield takeLatest(unmuteConversationRoutine.TRIGGER, unmuteConversation)
}

function* getConversationContextRoutineWatcher() {
  yield takeLatest(
    getConversationContextRoutine.TRIGGER,
    getConversationContext
  )
}

function* markConversationAsSeenRoutineWatcher() {
  yield takeLatest(
    markConversationAsSeenRoutine.TRIGGER,
    markConversationAsSeen
  )
}

function* markMessageAsSeenRoutineWatcher() {
  yield takeLatest(markMessageAsSeenRoutine.TRIGGER, markMessageAsSeen)
}

function* reserveOfferRoutineWatcher() {
  yield takeLatest(reserveOfferRoutine.TRIGGER, reserveOffer)
}

function* getContextAvailableActionsRoutineWatcher() {
  yield takeLatest(
    getContextAvailableActionsRoutine.TRIGGER,
    getContextAvailableActions
  )
}

function* acceptReservationRoutineWatcher() {
  yield takeLatest(acceptReservationRoutine.TRIGGER, acceptReservation)
}

function* rejectReservationRoutineWatcher() {
  yield takeLatest(rejectReservationRoutine.TRIGGER, rejectReservation)
}

function* cancelReservationRoutineWatcher() {
  yield takeLatest(cancelReservationRoutine.TRIGGER, cancelReservation)
}

function* receiveMessageRoutineWatcher() {
  yield takeLatest(MESSAGE_EVENT_USER_MESSAGE_SENT, receiveMessage)
}

export const messagesSagas = [
  fork(setMessagesListOpenedRoutineWatcher),
  fork(setConversationOpenedRoutineWatcher),
  fork(sendMessageRoutineWatcher),
  fork(bootstrapConversationRoutineWatcher),
  fork(getConversationMessagesRoutineWatcher),
  fork(clearConversationRoutineWatcher),
  fork(getLastMessagesListRoutineWatcher),
  fork(getUnreadMessagesCountRoutineWatcher),
  fork(searchForUserConversationsRoutineWatcher),
  fork(getLastContextualMessagesListRoutineWatcher),
  fork(getLastPersonalMessagesListRoutineWatcher),
  fork(clearLastMessagesListsRoutineWatcher),
  fork(muteChatRoutineWatcher),
  fork(unmuteChatRoutineWatcher),
  fork(toggleConversationNotificationsRoutineWatcher),
  fork(muteConversationRoutineWatcher),
  fork(unmuteConversationRoutineWatcher),
  fork(getConversationContextRoutineWatcher),
  fork(markConversationAsSeenRoutineWatcher),
  fork(markMessageAsSeenRoutineWatcher),
  fork(reserveOfferRoutineWatcher),
  fork(getContextAvailableActionsRoutineWatcher),
  fork(acceptReservationRoutineWatcher),
  fork(rejectReservationRoutineWatcher),
  fork(cancelReservationRoutineWatcher),
  fork(receiveMessageRoutineWatcher)
]
