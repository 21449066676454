import { findIndex, prop, propEq } from 'ramda'
import { API_STATES } from 'src/ducks/consts'
import { Pagination } from 'src/ducks/records'
import {
  normalizeExportOffers,
  normalizeImportOffers,
  normalizePostImportOffers,
  normalizeFreeCarriersOffers,
  normalizeExportOfferDetails,
  normalizeImportOfferDetails,
  normalizePostImportOfferDetails,
  normalizeFreeCarrierOfferDetails,
  normalizeFilterTabs,
  generateTabNameExport,
  generateTabNameImport,
  generateTabNameFreeCarriers,
  generateTabNamePostImport,
  updateExchangeAfterNewLiveOfferCreatedEvent,
  updateExchangeAfterSwitchToNewTabWithLiveData,
  updateOfferTimestamps,
  updateExchangeAfterOfferDeletedEvent,
  refreshExchangeAfterOfferBeingRefreshed
} from 'src/features/stocks/ducks/normalizers'
import {
  showOngoingExportOfferReservationsRoutine,
  showFinishedExportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-export'
import {
  showFinishedImportOfferReservationsRoutine,
  showOngoingImportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-import'
import {
  showFinishedPostImportOfferReservationsRoutine,
  showOngoingPostImportOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-post-import'
import {
  showFinishedFreeCarrierOfferReservationsRoutine,
  showOngoingFreeCarrierOfferReservationsRoutine
} from 'src/features/myOffers/duck/actions-free-carrier'
import { normalizeReservations } from 'src/features/myOffers/duck/normalizers'
import {
  StockCargoExport,
  StockCargoFreeCarrier,
  StockCargoImport,
  StockCargoPostImport,
  ExportFilters,
  ImportFilters,
  FreeCarrierFilters,
  PostImportFilters,
  FilterTab,
  ExportOffer,
  FreeCarrierOffer,
  ImportOffer,
  PostImportOffer
} from './records'

import {
  clearExportOffersRoutine,
  getExportOffersRoutine,
  showExportOfferDetailsRoutine,
  setExportDrawerRoutine,
  setExportFiltersRoutine,
  updateExportOfferRoutine,
  reserveExportOfferRoutine,
  refreshExportOfferRoutine,
  clearExportFiltersRoutine,
  getExportFilterTabsListRoutine,
  createExportFilterTabRoutine,
  removeExportFilterTabRoutine,
  setExportActiveTabRoutine,
  EXPORT_TAB_EVENT_OFFER_CREATED,
  EXPORT_TAB_EVENT_OFFER_REFRESHED,
  EXPORT_TAB_EVENT_OFFER_DELETED,
  EXPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
  exportOffersCleanerRoutine,
  cleanExportOffersDetailsRoutine
} from './actions-export'

import {
  getImportOffersRoutine,
  clearImportOffersRoutine,
  showImportOfferDetailsRoutine,
  setImportDrawerRoutine,
  setImportFiltersRoutine,
  updateImportOfferRoutine,
  reserveImportOfferRoutine,
  refreshImportOfferRoutine,
  clearImportFiltersRoutine,
  createImportFilterTabRoutine,
  removeImportFilterTabRoutine,
  setImportActiveTabRoutine,
  getImportFilterTabsListRoutine,
  IMPORT_TAB_EVENT_OFFER_CREATED,
  IMPORT_TAB_EVENT_OFFER_REFRESHED,
  IMPORT_TAB_EVENT_OFFER_DELETED,
  IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
  importOffersCleanerRoutine,
  cleanImportOffersDetailsRoutine
} from './actions-import'

import {
  getPostImportOffersRoutine,
  clearPostImportOffersRoutine,
  showPostImportOfferDetailsRoutine,
  setPostImportDrawerRoutine,
  setPostImportFiltersRoutine,
  updatePostImportOfferRoutine,
  reservePostImportOfferRoutine,
  refreshPostImportOfferRoutine,
  clearPostImportFiltersRoutine,
  createPostImportFilterTabRoutine,
  removePostImportFilterTabRoutine,
  setPostImportActiveTabRoutine,
  getPostImportFilterTabsListRoutine,
  POST_IMPORT_TAB_EVENT_OFFER_CREATED,
  POST_IMPORT_TAB_EVENT_OFFER_REFRESHED,
  POST_IMPORT_TAB_EVENT_OFFER_DELETED,
  POST_IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
  postImportOffersCleanerRoutine,
  cleanPostImportOffersDetailsRoutine
} from './actions-post-import'

import {
  getFreeCarriersOffersRoutine,
  clearFreeCarriersOffersRoutine,
  showFreeCarrierOfferDetailsRoutine,
  setFreeCarriersDrawerRoutine,
  setFreeCarriersFiltersRoutine,
  updateFreeCarrierOfferRoutine,
  reserveFreeCarrierOfferRoutine,
  refreshFreeCarrierOfferRoutine,
  clearFreeCarriersFiltersRoutine,
  createFreeCarriersFilterTabRoutine,
  removeFreeCarriersFilterTabRoutine,
  setFreeCarriersActiveTabRoutine,
  getFreeCarriersFilterTabsListRoutine,
  FREE_CARRIERS_TAB_EVENT_OFFER_CREATED,
  FREE_CARRIERS_TAB_EVENT_OFFER_REFRESHED,
  FREE_CARRIERS_TAB_EVENT_OFFER_DELETED,
  FREE_CARRIERS_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA,
  freeCarriersOffersCleanerRoutine,
  cleanFreeCarriersOffersDetailsRoutine
} from './actions-free-carriers'

export const exportReducer = (state = new StockCargoExport(), action) => {
  switch (action.type) {
    case showOngoingExportOfferReservationsRoutine.REQUEST:
    case showFinishedExportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingExportOfferReservationsRoutine.FAILURE:
    case showFinishedExportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingExportOfferReservationsRoutine.FULFILL:
    case showFinishedExportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingExportOfferReservationsRoutine.SUCCESS:
    case showFinishedExportOfferReservationsRoutine.SUCCESS:
      return state
        .setIn(
          ['offerDetails', 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case reserveExportOfferRoutine.REQUEST:
    case refreshExportOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case showExportOfferDetailsRoutine.REQUEST:
    case updateExportOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'details'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getExportOffersRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getExportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeExportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.response.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
        .set('infoBoxOffersNumber', 0) //reset infoBox counter with every list fetch
    case getExportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case showExportOfferDetailsRoutine.SUCCESS:
      const indexOnOffersList = findIndex(
        propEq('id', prop('id', action.payload)),
        state.offers
      )
      return state
        .set('offerDetails', normalizeExportOfferDetails(action.payload))
        .setIn(
          ['offers', [indexOnOffersList]],
          normalizeExportOfferDetails(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case showExportOfferDetailsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case updateExportOfferRoutine.SUCCESS:
      return state
        .set('offerDetails', normalizeExportOfferDetails(action.payload))
        .setIn(['state', 'details'], API_STATES.DONE)
    case reserveExportOfferRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    case refreshExportOfferRoutine.SUCCESS:
      return state
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], true)
    case clearExportOffersRoutine.SUCCESS:
      return new StockCargoExport()
    case setExportDrawerRoutine.SUCCESS:
      return state.set('isDrawerOpen', action.payload.drawerState)
    case setExportFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state
        .setIn(['filters', 'page'], 1)
        .update(
          'filters',
          v =>
            new ExportFilters({
              ...v.toJS(),
              ...filters
            })
        )
        .update('filters', v =>
          v.set(
            'tabName',
            generateTabNameExport(v, action.payload.dictionaries)
          )
        )
    case reserveExportOfferRoutine.FAILURE:
    case refreshExportOfferRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'buttons'], API_STATES.DONE)
    case reserveExportOfferRoutine.FULFILL:
    case refreshExportOfferRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], false)
    case clearExportFiltersRoutine.SUCCESS:
      return state.set('filters', new ExportFilters())
    case createExportFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.concat(
          new FilterTab({
            ...action.payload.data,
            currentFilters: action.payload.currentFilters
          })
        )
      )
    case removeExportFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.filter(v => v.id !== action.payload.id)
      )
    case getExportFilterTabsListRoutine.SUCCESS:
      return state.set(
        'tabs',
        normalizeFilterTabs(
          action.payload.data,
          action.payload.locations,
          action.payload.companies
        )
      )
    case setExportActiveTabRoutine.SUCCESS:
      return state.set('activeTab', action.payload.activeTab).set(
        'filters',
        new ExportFilters({
          ...action.payload.filters
        })
      )
    case EXPORT_TAB_EVENT_OFFER_REFRESHED:
      return refreshExchangeAfterOfferBeingRefreshed({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizeExportOfferDetails
      })
    case EXPORT_TAB_EVENT_OFFER_CREATED:
      return updateExchangeAfterNewLiveOfferCreatedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizeExportOfferDetails
      })
    case EXPORT_TAB_EVENT_OFFER_DELETED:
      return updateExchangeAfterOfferDeletedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload
      })
    case EXPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA:
      return updateExchangeAfterSwitchToNewTabWithLiveData({
        exchangeState: state,
        tabData: action.payload.tabData,
        tabId: action.payload.tabId
      })
    case exportOffersCleanerRoutine.SUCCESS:
      return state.update('offers', list => updateOfferTimestamps(list))
    default:
      return state
    case cleanExportOffersDetailsRoutine.SUCCESS:
      return state.set('offerDetails', new ExportOffer())
  }
}

export const importReducer = (state = new StockCargoImport(), action) => {
  switch (action.type) {
    case showOngoingImportOfferReservationsRoutine.REQUEST:
    case showFinishedImportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingImportOfferReservationsRoutine.FAILURE:
    case showFinishedImportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingImportOfferReservationsRoutine.FULFILL:
    case showFinishedImportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingImportOfferReservationsRoutine.SUCCESS:
    case showFinishedImportOfferReservationsRoutine.SUCCESS:
      return state
        .setIn(
          ['offerDetails', 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case reserveImportOfferRoutine.REQUEST:
    case refreshImportOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case showImportOfferDetailsRoutine.REQUEST:
    case updateImportOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'details'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getImportOffersRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getImportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeImportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.response.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
        .set('infoBoxOffersNumber', 0) //reset infoBox counter with every list fetch
    case getImportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case showImportOfferDetailsRoutine.SUCCESS:
      const indexOnOffersList = findIndex(
        propEq('id', prop('id', action.payload)),
        state.offers
      )
      return state
        .set('offerDetails', normalizeImportOfferDetails(action.payload))
        .setIn(
          ['offers', [indexOnOffersList]],
          normalizeImportOfferDetails(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case showImportOfferDetailsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'details'], API_STATES.DONE)
    case showImportOfferDetailsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case updateImportOfferRoutine.SUCCESS:
      return state
        .set('offerDetails', normalizeImportOfferDetails(action.payload))
        .setIn(['state', 'details'], API_STATES.DONE)
    case updateImportOfferRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'details'], API_STATES.DONE)
    case reserveImportOfferRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    case refreshImportOfferRoutine.SUCCESS:
      return state
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], true)
    case clearImportOffersRoutine.SUCCESS:
      return new StockCargoImport()
    case setImportDrawerRoutine.SUCCESS:
      return state.set('isDrawerOpen', action.payload.drawerState)
    case setImportFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state
        .setIn(['filters', 'page'], 1)
        .update(
          'filters',
          v =>
            new ImportFilters({
              ...v.toJS(),
              ...filters
            })
        )
        .update('filters', v =>
          v.set(
            'tabName',
            generateTabNameImport(v, action.payload.dictionaries)
          )
        )
    case reserveImportOfferRoutine.FAILURE:
    case refreshImportOfferRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'buttons'], API_STATES.DONE)
    case reserveImportOfferRoutine.FULFILL:
    case refreshImportOfferRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], false)
    case clearImportFiltersRoutine.SUCCESS:
      return state.set('filters', new ImportFilters())
    case cleanImportOffersDetailsRoutine.SUCCESS:
      return state.set('offerDetails', new ImportOffer())
    case createImportFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.concat(
          new FilterTab({
            ...action.payload.data,
            currentFilters: action.payload.currentFilters
          })
        )
      )
    case removeImportFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.filter(v => v.id !== action.payload.id)
      )
    case getImportFilterTabsListRoutine.SUCCESS:
      return state.set(
        'tabs',
        normalizeFilterTabs(
          action.payload.data,
          action.payload.locations,
          action.payload.companies
        )
      )
    case setImportActiveTabRoutine.SUCCESS:
      return state.set('activeTab', action.payload.activeTab).set(
        'filters',
        new ImportFilters({
          ...action.payload.filters
        })
      )
    case IMPORT_TAB_EVENT_OFFER_CREATED:
      return updateExchangeAfterNewLiveOfferCreatedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizeImportOfferDetails
      })
    case IMPORT_TAB_EVENT_OFFER_REFRESHED:
      return refreshExchangeAfterOfferBeingRefreshed({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizeImportOfferDetails
      })
    case IMPORT_TAB_EVENT_OFFER_DELETED:
      return updateExchangeAfterOfferDeletedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload
      })
    case IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA:
      return updateExchangeAfterSwitchToNewTabWithLiveData({
        exchangeState: state,
        tabData: action.payload.tabData,
        tabId: action.payload.tabId
      })
    case importOffersCleanerRoutine.SUCCESS:
      return state.update('offers', list => updateOfferTimestamps(list))
    default:
      return state
  }
}

export const postImportReducer = (
  state = new StockCargoPostImport(),
  action
) => {
  switch (action.type) {
    case showOngoingPostImportOfferReservationsRoutine.REQUEST:
    case showFinishedPostImportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingPostImportOfferReservationsRoutine.FAILURE:
    case showFinishedPostImportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingPostImportOfferReservationsRoutine.FULFILL:
    case showFinishedPostImportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingPostImportOfferReservationsRoutine.SUCCESS:
    case showFinishedPostImportOfferReservationsRoutine.SUCCESS:
      return state
        .setIn(
          ['offerDetails', 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case reservePostImportOfferRoutine.REQUEST:
    case refreshPostImportOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case showPostImportOfferDetailsRoutine.REQUEST:
    case updatePostImportOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'details'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getPostImportOffersRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getPostImportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizePostImportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.response.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
        .set('infoBoxOffersNumber', 0) //reset infoBox counter with every list fetch
    case getPostImportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case showPostImportOfferDetailsRoutine.SUCCESS:
      const indexOnOffersList = findIndex(
        propEq('id', prop('id', action.payload)),
        state.offers
      )
      return state
        .set('offerDetails', normalizePostImportOfferDetails(action.payload))
        .setIn(
          ['offers', [indexOnOffersList]],
          normalizePostImportOfferDetails(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case showPostImportOfferDetailsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case updatePostImportOfferRoutine.SUCCESS:
      return state
        .set('offerDetails', normalizePostImportOfferDetails(action.payload))
        .setIn(['state', 'details'], API_STATES.DONE)
    case reservePostImportOfferRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    case refreshPostImportOfferRoutine.SUCCESS:
      return state
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], true)
    case clearPostImportOffersRoutine.SUCCESS:
      return new StockCargoPostImport()
    case cleanPostImportOffersDetailsRoutine.SUCCESS:
      return state.set('offerDetails', new PostImportOffer())
    case setPostImportDrawerRoutine.SUCCESS:
      return state.set('isDrawerOpen', action.payload.drawerState)
    case setPostImportFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state
        .setIn(['filters', 'page'], 1)
        .update(
          'filters',
          v =>
            new PostImportFilters({
              ...v.toJS(),
              ...filters
            })
        )
        .update('filters', v =>
          v.set(
            'tabName',
            generateTabNamePostImport(v, action.payload.dictionaries)
          )
        )
    case showPostImportOfferDetailsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case reservePostImportOfferRoutine.FAILURE:
    case refreshPostImportOfferRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'buttons'], API_STATES.DONE)
    case reservePostImportOfferRoutine.FULFILL:
    case refreshPostImportOfferRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], false)
    case clearPostImportFiltersRoutine.SUCCESS:
      return state.set('filters', new PostImportFilters())
    case createPostImportFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.concat(
          new FilterTab({
            ...action.payload.data,
            currentFilters: action.payload.currentFilters
          })
        )
      )
    case removePostImportFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.filter(v => v.id !== action.payload.id)
      )
    case getPostImportFilterTabsListRoutine.SUCCESS:
      return state.set(
        'tabs',
        normalizeFilterTabs(
          action.payload.data,
          action.payload.locations,
          action.payload.companies
        )
      )
    case setPostImportActiveTabRoutine.SUCCESS:
      return state.set('activeTab', action.payload.activeTab).set(
        'filters',
        new PostImportFilters({
          ...action.payload.filters
        })
      )
    case POST_IMPORT_TAB_EVENT_OFFER_CREATED:
      return updateExchangeAfterNewLiveOfferCreatedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizePostImportOfferDetails
      })
    case POST_IMPORT_TAB_EVENT_OFFER_REFRESHED:
      return refreshExchangeAfterOfferBeingRefreshed({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizePostImportOfferDetails
      })
    case POST_IMPORT_TAB_EVENT_OFFER_DELETED:
      return updateExchangeAfterOfferDeletedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload
      })
    case POST_IMPORT_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA:
      return updateExchangeAfterSwitchToNewTabWithLiveData({
        exchangeState: state,
        tabData: action.payload.tabData,
        tabId: action.payload.tabId
      })
    case postImportOffersCleanerRoutine.SUCCESS:
      return state.update('offers', list => updateOfferTimestamps(list))
    default:
      return state
  }
}

export const freeCarriersReducer = (
  state = new StockCargoFreeCarrier(),
  action
) => {
  switch (action.type) {
    case showOngoingFreeCarrierOfferReservationsRoutine.REQUEST:
    case showFinishedFreeCarrierOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingFreeCarrierOfferReservationsRoutine.FAILURE:
    case showFinishedFreeCarrierOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingFreeCarrierOfferReservationsRoutine.FULFILL:
    case showFinishedFreeCarrierOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingFreeCarrierOfferReservationsRoutine.SUCCESS:
    case showFinishedFreeCarrierOfferReservationsRoutine.SUCCESS:
      return state
        .setIn(
          ['offerDetails', 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case reserveFreeCarrierOfferRoutine.REQUEST:
    case refreshFreeCarrierOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case showFreeCarrierOfferDetailsRoutine.REQUEST:
    case updateFreeCarrierOfferRoutine.REQUEST:
      return state
        .setIn(['state', 'details'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getFreeCarriersOffersRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getFreeCarriersOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeFreeCarriersOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.response.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
        .set('infoBoxOffersNumber', 0) //reset infoBox counter with every list fetch
    case getFreeCarriersOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case showFreeCarrierOfferDetailsRoutine.SUCCESS:
      const indexOnOffersList = findIndex(
        propEq('id', prop('id', action.payload)),
        state.offers
      )
      return state
        .set('offerDetails', normalizeFreeCarrierOfferDetails(action.payload))
        .setIn(
          ['offers', [indexOnOffersList]],
          normalizeFreeCarrierOfferDetails(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case showFreeCarrierOfferDetailsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case updateFreeCarrierOfferRoutine.SUCCESS:
      return state
        .set('offerDetails', normalizeFreeCarrierOfferDetails(action.payload))
        .setIn(['state', 'details'], API_STATES.DONE)
    case reserveFreeCarrierOfferRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    case refreshFreeCarrierOfferRoutine.SUCCESS:
      return state
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], true)
    case clearFreeCarriersOffersRoutine.SUCCESS:
      return new StockCargoFreeCarrier()
    case cleanFreeCarriersOffersDetailsRoutine.SUCCESS:
      return state.set('offerDetails', new FreeCarrierOffer())
    case setFreeCarriersDrawerRoutine.SUCCESS:
      return state.set('isDrawerOpen', action.payload.drawerState)
    case setFreeCarriersFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state
        .setIn(['filters', 'page'], 1)
        .update(
          'filters',
          v =>
            new FreeCarrierFilters({
              ...v.toJS(),
              ...filters
            })
        )
        .update('filters', v =>
          v.set(
            'tabName',
            generateTabNameFreeCarriers(v, action.payload.dictionaries)
          )
        )
    case reserveFreeCarrierOfferRoutine.FAILURE:
    case refreshFreeCarrierOfferRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'buttons'], API_STATES.DONE)
    case reserveFreeCarrierOfferRoutine.FULFILL:
    case refreshFreeCarrierOfferRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'buttons'], API_STATES.DONE)
        .setIn(['state', 'refreshedSuccessfully'], false)
    case clearFreeCarriersFiltersRoutine.SUCCESS:
      return state.set('filters', new FreeCarrierFilters())
    case createFreeCarriersFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.concat(
          new FilterTab({
            ...action.payload.data,
            currentFilters: action.payload.currentFilters
          })
        )
      )
    case removeFreeCarriersFilterTabRoutine.SUCCESS:
      return state.update('tabs', list =>
        list.filter(v => v.id !== action.payload.id)
      )
    case getFreeCarriersFilterTabsListRoutine.SUCCESS:
      return state.set(
        'tabs',
        normalizeFilterTabs(
          action.payload.data,
          action.payload.locations,
          action.payload.companies
        )
      )
    case setFreeCarriersActiveTabRoutine.SUCCESS:
      return state.set('activeTab', action.payload.activeTab).set(
        'filters',
        new FreeCarrierFilters({
          ...action.payload.filters
        })
      )

    case FREE_CARRIERS_TAB_EVENT_OFFER_CREATED:
      return updateExchangeAfterNewLiveOfferCreatedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizeFreeCarrierOfferDetails
      })
    case FREE_CARRIERS_TAB_EVENT_OFFER_REFRESHED:
      return refreshExchangeAfterOfferBeingRefreshed({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload,
        offerNormalizer: normalizeFreeCarrierOfferDetails
      })
    case FREE_CARRIERS_TAB_EVENT_OFFER_DELETED:
      return updateExchangeAfterOfferDeletedEvent({
        exchangeState: state,
        tabId: action.tabId,
        eventPayload: action.payload
      })
    case FREE_CARRIERS_OFFERS_LIST_UPDATED_BY_TAB_LIVE_DATA:
      return updateExchangeAfterSwitchToNewTabWithLiveData({
        exchangeState: state,
        tabData: action.payload.tabData,
        tabId: action.payload.tabId
      })
    case freeCarriersOffersCleanerRoutine.SUCCESS:
      return state.update('offers', list => updateOfferTimestamps(list))
    default:
      return state
  }
}
