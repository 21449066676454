class LocalStorageService {
  get(key) {
    if (typeof window === 'undefined') return null
    return localStorage.getItem(key)
  }

  set(key, value) {
    return localStorage.setItem(key, value)
  }

  remove(key) {
    return localStorage.removeItem(key)
  }

  clearAll() {
    localStorage.clear()
  }
}

export { LocalStorageService }
export default new LocalStorageService()
