import { createRoutine } from 'redux-saga-routines'
import {
  put,
  takeLatest,
  fork,
  call,
  select,
  delay
} from '@redux-saga/core/effects'
import { navigate } from 'gatsby-plugin-intl'
import { assoc } from 'ramda'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import OfferService from 'src/services/OfferService'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import ContractService from 'src/services/ContractService'
import { displayResponseErrorMessage } from 'src/utils/helpers'
import {
  selectOfferFormExportData,
  selectOfferFormImportData,
  selectOfferFormFreeCarriersData,
  selectOfferFormPostImportData,
  selectOfferFormContractData
} from './selectors'
import {
  normalizeExportOfferForSave,
  normalizeImportOfferForSave,
  normalizePostImportOfferForSave,
  normalizeFreeCarriersOfferForSave,
  normalizeContractOfferForSave,
  normalizeTemplateForExportForm,
  normalizeTemplateForImportForm,
  normalizeTemplateForFreeCarriersForm,
  normalizeTemplateForPostImportForm
} from './normalizers'

//routines

export const createExportOfferRoutine = createRoutine('CREATE_EXPORT_OFFER')
export const createImportOfferRoutine = createRoutine('CREATE_IMPORT_OFFER')
export const createFreeCarriersOfferRoutine = createRoutine(
  'CREATE_FREE_CARRIERS_OFFER'
)
export const createPostImportOfferRoutine = createRoutine(
  'CREATE_POST_IMPORT_OFFER'
)
export const createContractOfferRoutine = createRoutine('CREATE_CONTRACT_OFFER')
export const clearContractOfferRoutine = createRoutine('CLEAR_CONTRACT_OFFER')
export const setOfferFormContractValueRoutine = createRoutine(
  'SET_OFFER_FORM_CONTRACT_VALUE'
)

export const createBulkExportOffersRoutine = createRoutine(
  'CREATE_BULK_EXPORT_OFFERS'
)
export const createBulkImportOffersRoutine = createRoutine(
  'CREATE_BULK_IMPORT_OFFERS'
)
export const createBulkFreeCarriersOffersRoutine = createRoutine(
  'CREATE_BULK_FREE_CARRIERS_OFFERS'
)
export const createBulkPostImportOffersRoutine = createRoutine(
  'CREATE_BULK_POST_IMPORT_OFFERS'
)

export const createAndDuplicateExportOfferRoutine = createRoutine(
  'CREATE_AND_DUPLICATE_EXPORT_OFFERS'
)
export const createAndDuplicateImportOfferRoutine = createRoutine(
  'CREATE_AND_DUPLICATE_IMPORT_OFFERS'
)
export const createAndDuplicateFreeCarriersOfferRoutine = createRoutine(
  'CREATE_AND_DUPLICATE_FREE_CARRIERS_OFFERS'
)
export const createAndDuplicatePostImportOfferRoutine = createRoutine(
  'CREATE_AND_DUPLICATE_POST_IMPORT_OFFERS'
)

export const createAndStartNewExportOfferRoutine = createRoutine(
  'CREATE_AND_START_NEW_EXPORT_OFFERS'
)
export const createAndStartNewImportOfferRoutine = createRoutine(
  'CREATE_AND_START_NEW_IMPORT_OFFERS'
)
export const createAndStartNewFreeCarriersOfferRoutine = createRoutine(
  'CREATE_AND_START_NEW_FREE_CARRIERS_OFFERS'
)
export const createAndStartNewPostImportOfferRoutine = createRoutine(
  'CREATE_AND_START_NEW_POST_IMPORT_OFFERS'
)

export const setOfferFormExportValueRoutine = createRoutine(
  'SET_OFFER_FORM_EXPORT_VALUE'
)
export const setOfferFormImportValueRoutine = createRoutine(
  'SET_OFFER_FORM_IMPORT_VALUE'
)
export const setOfferFormFreeCarriersValueRoutine = createRoutine(
  'SET_OFFER_FORM_FREE_CARRIERS_VALUE'
)
export const setOfferFormPostImportValueRoutine = createRoutine(
  'SET_OFFER_FORM_POST_IMPORT_VALUE'
)

export const clearExportOfferFormRoutine = createRoutine(
  'CLEAR_EXPORT_OFFER_FORM'
)
export const clearImportOfferFormRoutine = createRoutine(
  'CLEAR_IMPORT_OFFER_FORM'
)
export const clearFreeCarriersOfferFormRoutine = createRoutine(
  'CLEAR_FREE_CARRIERS_OFFER_FORM'
)
export const clearPostImportOfferFormRoutine = createRoutine(
  'CLEAR_POST_IMPORT_OFFER_FORM'
)

export const getExportMyCompanyLastOffersRoutine = createRoutine(
  'GET_EXPORT_MY_COMPANY_LAST_OFFERS'
)
export const getImportMyCompanyLastOffersRoutine = createRoutine(
  'GET_IMPORT_MY_COMPANY_LAST_OFFERS'
)
export const getFreeCarriersMyCompanyLastOffersRoutine = createRoutine(
  'GET_FREE_CARRIERS_MY_COMPANY_LAST_OFFERS'
)
export const getPostImportMyCompanyLastOffersRoutine = createRoutine(
  'GET_POST_IMPORT_MY_COMPANY_LAST_OFFERS'
)

export const getExportOfferTemplatesRoutine = createRoutine(
  'GET_EXPORT_OFFER_TEMPLATES'
)
export const getImportOfferTemplatesRoutine = createRoutine(
  'GET_IMPORT_OFFER_TEMPLATES'
)
export const getFreeCarriersOfferTemplatesRoutine = createRoutine(
  'GET_FREE_CARRIERS_OFFER_TEMPLATES'
)
export const getPostImportOfferTemplatesRoutine = createRoutine(
  'GET_POST_IMPORT_OFFER_TEMPLATES'
)

export const fillFormUsingTemplateExportRoutine = createRoutine(
  'FILL_FORM_USING_TEMPLATE_EXPORT'
)
export const fillFormUsingTemplateImportRoutine = createRoutine(
  'FILL_FORM_USING_TEMPLATE_IMPORT'
)
export const fillFormUsingTemplateFreeCarriersRoutine = createRoutine(
  'FILL_FORM_USING_TEMPLATE_FREE_CARRIERS'
)
export const fillFormUsingTemplatePostImportRoutine = createRoutine(
  'FILL_FORM_USING_TEMPLATE_POST_IMPORT'
)

export const removeTemplateExportRoutine = createRoutine(
  'REMOVE_TEMPLATE_EXPORT'
)
export const removeTemplateImportRoutine = createRoutine(
  'REMOVE_TEMPLATE_IMPORT'
)
export const removeTemplateFreeCarriersRoutine = createRoutine(
  'REMOVE_TEMPLATE_FREE_CARRIERS'
)
export const removeTemplatePostImportRoutine = createRoutine(
  'REMOVE_TEMPLATE_POST_IMPORT'
)

//actions

//import

function* setOfferFormImportValue({ payload }) {
  yield put(setOfferFormImportValueRoutine.success(payload))
}

function* createImportOffer() {
  yield put(createImportOfferRoutine.request())
  const formData = yield select(selectOfferFormImportData)
  const normalized = normalizeImportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createImportOffer, normalized)
    yield put(createImportOfferRoutine.success())
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`)
  } catch (e) {
    console.log(e)
    yield put(createImportOfferRoutine.failure(e))
    yield call(displayResponseErrorMessage, e, 'alert', true)
  }
}

function* createBulkImportOffers({ payload }) {
  yield put(createImportOfferRoutine.request())

  const formData = yield select(selectOfferFormImportData)
  const normalized = normalizeImportOfferForSave(formData.toJS())

  try {
    yield call(
      OfferService.createImportOffer,
      assoc('copiesAmount', payload.number, normalized)
    )
    yield put(createImportOfferRoutine.success())
    yield delay(500) //wait a bit for API to digest offers
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_IMPORT_OFFERS}`)
  } catch (e) {
    yield put(createImportOfferRoutine.failure(e))
  }
}

function* createAndDuplicateImportOffers() {
  yield put(createImportOfferRoutine.request())

  const formData = yield select(selectOfferFormImportData)
  const normalized = normalizeImportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createImportOffer, normalized)
    yield put(createImportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createImportOfferRoutine.failure(e))
  }
}

function* createAndStartNewImportOffers() {
  yield put(createImportOfferRoutine.request())

  const formData = yield select(selectOfferFormImportData)
  const normalized = normalizeImportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createImportOffer, normalized)
    yield put(createImportOfferRoutine.success())
    yield call(clearImportOfferForm, { payload: {} })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createImportOfferRoutine.failure(e))
  }
}

function* clearImportOfferForm({ payload }) {
  yield put(clearImportOfferFormRoutine.success(payload))
}

function* getExportMyCompanyLastOffers() {
  yield put(getExportMyCompanyLastOffersRoutine.request())
  try {
    const { data } = yield call(OfferService.getExportMyCompanyLastOffers)
    yield put(getExportMyCompanyLastOffersRoutine.success({ data }))
  } catch (e) {
    console.log(e)
  }
}

function* getExportOfferTemplates() {
  yield put(getExportOfferTemplatesRoutine.request())
  try {
    const { data } = yield call(OfferService.getExportOfferTemplates)
    yield put(getExportOfferTemplatesRoutine.success({ data }))
  } catch (e) {
    console.log(e)
    yield put(getExportOfferTemplatesRoutine.failure())
  }
}

export function* fillFormUsingTemplateImport({ payload }) {
  yield put(fillFormUsingTemplateImportRoutine.request())
  try {
    const template = normalizeTemplateForImportForm(payload.template)
    yield put(fillFormUsingTemplateImportRoutine.success({ template }))
  } catch (e) {
    console.log(e)
    yield put(fillFormUsingTemplateImportRoutine.failure())
  }
}

function* removeTemplateImport({ payload }) {
  try {
    yield call(OfferService.removeTemplateImport, payload.id)
    yield call(getImportOfferTemplates)
  } catch (e) {
    console.log(e)
  }
}

//export

function* setOfferFormExportValue({ payload }) {
  yield put(setOfferFormExportValueRoutine.success(payload))
}

function* createExportOffer() {
  yield put(createExportOfferRoutine.request())

  const formData = yield select(selectOfferFormExportData)
  const normalized = normalizeExportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createExportOffer, normalized)
    yield put(createExportOfferRoutine.success())
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`)
  } catch (e) {
    yield put(createExportOfferRoutine.failure(e))
    yield call(displayResponseErrorMessage, e, 'alert', true)
  }
}

function* createBulkExportOffers({ payload }) {
  yield put(createExportOfferRoutine.request())

  const formData = yield select(selectOfferFormExportData)
  const normalized = normalizeExportOfferForSave(formData.toJS())

  try {
    yield call(
      OfferService.createExportOffer,
      assoc('copiesAmount', payload.number, normalized)
    )
    yield put(createExportOfferRoutine.success())
    yield delay(500) //wait a bit for API to digest offers
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_EXPORT_OFFERS}`)
  } catch (e) {
    yield put(createExportOfferRoutine.failure(e))
  }
}

function* createAndDuplicateExportOffers() {
  yield put(createExportOfferRoutine.request())

  const formData = yield select(selectOfferFormExportData)
  const normalized = normalizeExportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createExportOffer, normalized)
    yield put(createExportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createExportOfferRoutine.failure(e))
  }
}

function* createAndStartNewExportOffers() {
  yield put(createExportOfferRoutine.request())

  const formData = yield select(selectOfferFormExportData)
  const normalized = normalizeExportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createExportOffer, normalized)
    yield put(createExportOfferRoutine.success())
    yield call(clearExportOfferForm, { payload: {} })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createExportOfferRoutine.failure(e))
  }
}

function* clearExportOfferForm({ payload }) {
  yield put(clearExportOfferFormRoutine.success(payload))
}

function* getImportMyCompanyLastOffers() {
  yield put(getImportMyCompanyLastOffersRoutine.request())
  try {
    const { data } = yield call(OfferService.getImportMyCompanyLastOffers)
    yield put(getImportMyCompanyLastOffersRoutine.success({ data }))
  } catch (e) {
    yield put(getImportMyCompanyLastOffersRoutine.failure())
    console.log(e)
  }
}

function* getImportOfferTemplates() {
  yield put(getImportOfferTemplatesRoutine.request())
  try {
    const { data } = yield call(OfferService.getImportOfferTemplates)
    yield put(getImportOfferTemplatesRoutine.success({ data }))
  } catch (e) {
    yield put(getImportOfferTemplatesRoutine.failure())
    console.log(e)
  }
}

export function* fillFormUsingTemplateExport({ payload }) {
  yield put(fillFormUsingTemplateExportRoutine.request())
  try {
    const template = normalizeTemplateForExportForm(payload.template)
    yield put(fillFormUsingTemplateExportRoutine.success({ template }))
  } catch (e) {
    yield put(fillFormUsingTemplateExportRoutine.failure())
  }
}

function* removeTemplateExport({ payload }) {
  try {
    yield call(OfferService.removeTemplateExport, payload.id)
    yield call(getExportOfferTemplates)
  } catch (e) {
    console.log(e)
  }
}

//post-import

function* setOfferFormPostImportValue({ payload }) {
  yield put(setOfferFormPostImportValueRoutine.success(payload))
}

function* createPostImportOffer() {
  yield put(createPostImportOfferRoutine.request())
  const formData = yield select(selectOfferFormPostImportData)
  const normalized = normalizePostImportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createPostImportOffer, normalized)
    yield put(createPostImportOfferRoutine.success())
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_POST_IMPORT_OFFERS}`)
  } catch (e) {
    yield put(createPostImportOfferRoutine.failure(e))
    yield call(displayResponseErrorMessage, e)
  }
}

function* createBulkPostImportOffers({ payload }) {
  yield put(createPostImportOfferRoutine.request())

  const formData = yield select(selectOfferFormPostImportData)
  const normalized = normalizePostImportOfferForSave(formData.toJS())

  try {
    yield call(
      OfferService.createPostImportOffer,
      assoc('copiesAmount', payload.number, normalized)
    )
    yield put(createPostImportOfferRoutine.success())
    yield delay(500) //wait a bit for API to digest offers
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_POST_IMPORT_OFFERS}`)
  } catch (e) {
    yield put(createPostImportOfferRoutine.failure(e))
  }
}

function* createAndDuplicatePostImportOffers() {
  yield put(createPostImportOfferRoutine.request())

  const formData = yield select(selectOfferFormPostImportData)
  const normalized = normalizePostImportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createPostImportOffer, normalized)
    yield put(createPostImportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createPostImportOfferRoutine.failure(e))
  }
}

function* createAndStartNewPostImportOffers() {
  yield put(createPostImportOfferRoutine.request())

  const formData = yield select(selectOfferFormPostImportData)
  const normalized = normalizePostImportOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createPostImportOffer, normalized)
    yield call(clearPostImportOfferForm, { payload: {} })
    yield put(createPostImportOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createPostImportOfferRoutine.failure(e))
  }
}

function* clearPostImportOfferForm({ payload }) {
  yield put(clearPostImportOfferFormRoutine.success(payload))
}

function* getPostImportMyCompanyLastOffers() {
  yield put(getPostImportMyCompanyLastOffersRoutine.request())
  try {
    const { data } = yield call(OfferService.getPostImportMyCompanyLastOffers)
    yield put(getPostImportMyCompanyLastOffersRoutine.success({ data }))
  } catch (e) {
    console.log(e)
    yield put(getPostImportMyCompanyLastOffersRoutine.failure())
  }
}

function* getPostImportOfferTemplates() {
  yield put(getPostImportOfferTemplatesRoutine.request())
  try {
    const { data } = yield call(OfferService.getPostImportOfferTemplates)
    yield put(getPostImportOfferTemplatesRoutine.success({ data }))
  } catch (e) {
    console.log(e)
    yield put(getPostImportOfferTemplatesRoutine.failure())
  }
}

export function* fillFormUsingTemplatePostImport({ payload }) {
  const template = normalizeTemplateForPostImportForm(payload.template)
  yield put(fillFormUsingTemplatePostImportRoutine.success({ template }))
}

function* removeTemplatePostImport({ payload }) {
  try {
    yield call(OfferService.removeTemplatePostImport, payload.id)
    yield call(getPostImportOfferTemplates)
  } catch (e) {
    console.log(e)
  }
}

//free-carriers

function* setOfferFormFreeCarriersValue({ payload }) {
  yield put(setOfferFormFreeCarriersValueRoutine.success(payload))
}

function* createFreeCarriersOffer() {
  yield put(createFreeCarriersOfferRoutine.request())
  const formData = yield select(selectOfferFormFreeCarriersData)
  const normalized = normalizeFreeCarriersOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createFreeCarriersOffer, normalized)
    yield put(createFreeCarriersOfferRoutine.success())
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_FREE_CARRIERS_OFFERS}`
    )
  } catch (e) {
    yield put(createFreeCarriersOfferRoutine.failure(e))
    yield call(displayResponseErrorMessage, e, 'alert', true)
  }
}

function* createBulkFreeCarriersOffers({ payload }) {
  yield put(createFreeCarriersOfferRoutine.request())

  const formData = yield select(selectOfferFormFreeCarriersData)
  const normalized = normalizeFreeCarriersOfferForSave(formData.toJS())

  try {
    yield call(
      OfferService.createFreeCarriersOffer,
      assoc('copiesAmount', payload.number, normalized)
    )
    yield put(createFreeCarriersOfferRoutine.success())
    yield delay(500) //wait a bit for API to digest offers
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.MY_FREE_CARRIERS_OFFERS}`
    )
  } catch (e) {
    yield put(createFreeCarriersOfferRoutine.failure(e))
  }
}

function* createAndDuplicateFreeCarriersOffers() {
  yield put(createFreeCarriersOfferRoutine.request())

  const formData = yield select(selectOfferFormFreeCarriersData)
  const normalized = normalizeFreeCarriersOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createFreeCarriersOffer, normalized)
    yield put(createFreeCarriersOfferRoutine.success())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createFreeCarriersOfferRoutine.failure(e))
  }
}

function* createAndStartNewFreeCarriersOffers() {
  yield put(createFreeCarriersOfferRoutine.request())

  const formData = yield select(selectOfferFormFreeCarriersData)
  const normalized = normalizeFreeCarriersOfferForSave(formData.toJS())

  try {
    yield call(OfferService.createFreeCarriersOffer, normalized)
    yield put(createFreeCarriersOfferRoutine.success())
    yield call(clearFreeCarriersOfferForm, { payload: {} })
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'forms.offerSaved'
        })
      }
    })
  } catch (e) {
    yield put(createFreeCarriersOfferRoutine.failure(e))
  }
}

function* clearFreeCarriersOfferForm({ payload }) {
  yield put(clearFreeCarriersOfferFormRoutine.success(payload))
}

function* getFreeCarriersMyCompanyLastOffers() {
  yield put(getFreeCarriersMyCompanyLastOffersRoutine.request())
  try {
    const { data } = yield call(OfferService.getFreeCarriersMyCompanyLastOffers)
    yield put(getFreeCarriersMyCompanyLastOffersRoutine.success({ data }))
  } catch (e) {
    yield put(getFreeCarriersMyCompanyLastOffersRoutine.failure())
    console.log(e)
  }
}

function* getFreeCarriersOfferTemplates() {
  yield put(getFreeCarriersOfferTemplatesRoutine.request())
  try {
    const { data } = yield call(OfferService.getFreeCarriersOfferTemplates)
    yield put(getFreeCarriersOfferTemplatesRoutine.success({ data }))
  } catch (e) {
    yield put(getFreeCarriersOfferTemplatesRoutine.failure())
    console.log(e)
  }
}

export function* fillFormUsingTemplateFreeCarriers({ payload }) {
  const template = normalizeTemplateForFreeCarriersForm(payload.template)
  yield put(fillFormUsingTemplateFreeCarriersRoutine.success({ template }))
}

function* removeTemplateFreeCarriers({ payload }) {
  try {
    yield call(OfferService.removeTemplateFreeCarriers, payload.id)
    yield call(getFreeCarriersOfferTemplates)
  } catch (e) {
    console.log(e)
  }
}

//Contracts
function* createContractOffer() {
  yield put(createContractOfferRoutine.request())
  const formData = yield select(selectOfferFormContractData)
  const normalized = normalizeContractOfferForSave(formData.toJS())
  try {
    yield call(ContractService.createContractOffer, normalized)
    yield put(createContractOfferRoutine.success())
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.CONTRACTS_OFFERS}`)
    yield put(createContractOfferRoutine.success())
  } catch (e) {
    yield put(createContractOfferRoutine.failure(e))
    yield call(displayResponseErrorMessage, e)
  }
}

function* clearContractOffer({ payload }) {
  yield put(clearContractOfferRoutine.success(payload))
}

function* setOfferFormContractValue({ payload }) {
  yield put(setOfferFormContractValueRoutine.success(payload))
}

//watchers

function* setOfferFormImportValueWatcher() {
  yield takeLatest(
    setOfferFormImportValueRoutine.TRIGGER,
    setOfferFormImportValue
  )
}

function* setOfferFormExportValueWatcher() {
  yield takeLatest(
    setOfferFormExportValueRoutine.TRIGGER,
    setOfferFormExportValue
  )
}

function* setOfferFormPostImportValueWatcher() {
  yield takeLatest(
    setOfferFormPostImportValueRoutine.TRIGGER,
    setOfferFormPostImportValue
  )
}

function* setOfferFormFreeCarriersValueWatcher() {
  yield takeLatest(
    setOfferFormFreeCarriersValueRoutine.TRIGGER,
    setOfferFormFreeCarriersValue
  )
}

function* createExportOfferWatcher() {
  yield takeLatest(createExportOfferRoutine.TRIGGER, createExportOffer)
}

function* createImportOfferWatcher() {
  yield takeLatest(createImportOfferRoutine.TRIGGER, createImportOffer)
}

function* createFreeCarriersOfferWatcher() {
  yield takeLatest(
    createFreeCarriersOfferRoutine.TRIGGER,
    createFreeCarriersOffer
  )
}

function* createPostImportOfferWatcher() {
  yield takeLatest(createPostImportOfferRoutine.TRIGGER, createPostImportOffer)
}

function* clearImportOfferFormWatcher() {
  yield takeLatest(clearImportOfferFormRoutine.TRIGGER, clearImportOfferForm)
}

function* clearExportOfferFormWatcher() {
  yield takeLatest(clearExportOfferFormRoutine.TRIGGER, clearExportOfferForm)
}

function* clearPostImportOfferFormWatcher() {
  yield takeLatest(
    clearPostImportOfferFormRoutine.TRIGGER,
    clearPostImportOfferForm
  )
}

function* clearFreeCarriersOfferFormWatcher() {
  yield takeLatest(
    clearFreeCarriersOfferFormRoutine.TRIGGER,
    clearFreeCarriersOfferForm
  )
}

function* getExportMyCompanyLastOffersRoutineWatcher() {
  yield takeLatest(
    getExportMyCompanyLastOffersRoutine.TRIGGER,
    getExportMyCompanyLastOffers
  )
}

function* getExportOfferTemplatesRoutineWatcher() {
  yield takeLatest(
    getExportOfferTemplatesRoutine.TRIGGER,
    getExportOfferTemplates
  )
}

function* getImportMyCompanyLastOffersRoutineWatcher() {
  yield takeLatest(
    getImportMyCompanyLastOffersRoutine.TRIGGER,
    getImportMyCompanyLastOffers
  )
}

function* getImportOfferTemplatesRoutineWatcher() {
  yield takeLatest(
    getImportOfferTemplatesRoutine.TRIGGER,
    getImportOfferTemplates
  )
}

function* getFreeCarriersMyCompanyLastOffersRoutineWatcher() {
  yield takeLatest(
    getFreeCarriersMyCompanyLastOffersRoutine.TRIGGER,
    getFreeCarriersMyCompanyLastOffers
  )
}

function* getFreeCarriersOfferTemplatesRoutineWatcher() {
  yield takeLatest(
    getFreeCarriersOfferTemplatesRoutine.TRIGGER,
    getFreeCarriersOfferTemplates
  )
}

function* getPostImportMyCompanyLastOffersRoutineWatcher() {
  yield takeLatest(
    getPostImportMyCompanyLastOffersRoutine.TRIGGER,
    getPostImportMyCompanyLastOffers
  )
}

function* getPostImportOfferTemplatesRoutineWatcher() {
  yield takeLatest(
    getPostImportOfferTemplatesRoutine.TRIGGER,
    getPostImportOfferTemplates
  )
}

function* fillFormUsingTemplateExportRoutineWatcher() {
  yield takeLatest(
    fillFormUsingTemplateExportRoutine.TRIGGER,
    fillFormUsingTemplateExport
  )
}

function* fillFormUsingTemplateImportRoutineWatcher() {
  yield takeLatest(
    fillFormUsingTemplateImportRoutine.TRIGGER,
    fillFormUsingTemplateImport
  )
}

function* fillFormUsingTemplateFreeCarriersRoutineWatcher() {
  yield takeLatest(
    fillFormUsingTemplateFreeCarriersRoutine.TRIGGER,
    fillFormUsingTemplateFreeCarriers
  )
}

function* fillFormUsingTemplatePostImportRoutineWatcher() {
  yield takeLatest(
    fillFormUsingTemplatePostImportRoutine.TRIGGER,
    fillFormUsingTemplatePostImport
  )
}

function* removeTemplateExportRoutineWatcher() {
  yield takeLatest(removeTemplateExportRoutine.TRIGGER, removeTemplateExport)
}

function* removeTemplateImportRoutineWatcher() {
  yield takeLatest(removeTemplateImportRoutine.TRIGGER, removeTemplateImport)
}

function* removeTemplateFreeCarriersRoutineWatcher() {
  yield takeLatest(
    removeTemplateFreeCarriersRoutine.TRIGGER,
    removeTemplateFreeCarriers
  )
}

function* removeTemplatePostImportRoutineWatcher() {
  yield takeLatest(
    removeTemplatePostImportRoutine.TRIGGER,
    removeTemplatePostImport
  )
}

function* createBulkExportOffersRoutineWatcher() {
  yield takeLatest(
    createBulkExportOffersRoutine.TRIGGER,
    createBulkExportOffers
  )
}

function* createAndDuplicateExportOffersRoutineWatcher() {
  yield takeLatest(
    createAndDuplicateExportOfferRoutine.TRIGGER,
    createAndDuplicateExportOffers
  )
}

function* createAndStartNewExportOffersRoutineWatcher() {
  yield takeLatest(
    createAndStartNewExportOfferRoutine.TRIGGER,
    createAndStartNewExportOffers
  )
}

///

function* createBulkImportOffersRoutineWatcher() {
  yield takeLatest(
    createBulkImportOffersRoutine.TRIGGER,
    createBulkImportOffers
  )
}

function* createAndDuplicateImportOffersRoutineWatcher() {
  yield takeLatest(
    createAndDuplicateImportOfferRoutine.TRIGGER,
    createAndDuplicateImportOffers
  )
}

function* createAndStartNewImportOffersRoutineWatcher() {
  yield takeLatest(
    createAndStartNewImportOfferRoutine.TRIGGER,
    createAndStartNewImportOffers
  )
}

//

function* createBulkPostImportOffersRoutineWatcher() {
  yield takeLatest(
    createBulkPostImportOffersRoutine.TRIGGER,
    createBulkPostImportOffers
  )
}

function* createAndDuplicatePostImportOffersRoutineWatcher() {
  yield takeLatest(
    createAndDuplicatePostImportOfferRoutine.TRIGGER,
    createAndDuplicatePostImportOffers
  )
}

function* createAndStartNewPostImportOffersRoutineWatcher() {
  yield takeLatest(
    createAndStartNewPostImportOfferRoutine.TRIGGER,
    createAndStartNewPostImportOffers
  )
}

//

function* createBulkFreeCarriersOffersRoutineWatcher() {
  yield takeLatest(
    createBulkFreeCarriersOffersRoutine.TRIGGER,
    createBulkFreeCarriersOffers
  )
}

function* createAndDuplicateFreeCarriersOffersRoutineWatcher() {
  yield takeLatest(
    createAndDuplicateFreeCarriersOfferRoutine.TRIGGER,
    createAndDuplicateFreeCarriersOffers
  )
}

function* createAndStartNewFreeCarriersOffersRoutineWatcher() {
  yield takeLatest(
    createAndStartNewFreeCarriersOfferRoutine.TRIGGER,
    createAndStartNewFreeCarriersOffers
  )
}

function* createContractOfferRoutineWatcher() {
  yield takeLatest(createContractOfferRoutine.TRIGGER, createContractOffer)
}

function* clearContractOfferRoutineWatcher() {
  yield takeLatest(clearContractOfferRoutine.TRIGGER, clearContractOffer)
}

function* setOfferFormContractValueRoutineWatcher() {
  yield takeLatest(
    setOfferFormContractValueRoutine.TRIGGER,
    setOfferFormContractValue
  )
}

export const offersSagas = [
  fork(setOfferFormImportValueWatcher),
  fork(setOfferFormExportValueWatcher),
  fork(setOfferFormPostImportValueWatcher),
  fork(setOfferFormFreeCarriersValueWatcher),
  fork(createExportOfferWatcher),
  fork(createImportOfferWatcher),
  fork(createFreeCarriersOfferWatcher),
  fork(createPostImportOfferWatcher),
  fork(clearImportOfferFormWatcher),
  fork(clearExportOfferFormWatcher),
  fork(clearPostImportOfferFormWatcher),
  fork(clearFreeCarriersOfferFormWatcher),
  fork(getExportMyCompanyLastOffersRoutineWatcher),
  fork(getExportOfferTemplatesRoutineWatcher),
  fork(getImportMyCompanyLastOffersRoutineWatcher),
  fork(getImportOfferTemplatesRoutineWatcher),
  fork(getFreeCarriersMyCompanyLastOffersRoutineWatcher),
  fork(getFreeCarriersOfferTemplatesRoutineWatcher),
  fork(getPostImportMyCompanyLastOffersRoutineWatcher),
  fork(getPostImportOfferTemplatesRoutineWatcher),
  fork(fillFormUsingTemplateExportRoutineWatcher),
  fork(fillFormUsingTemplateImportRoutineWatcher),
  fork(fillFormUsingTemplateFreeCarriersRoutineWatcher),
  fork(fillFormUsingTemplatePostImportRoutineWatcher),
  fork(removeTemplateExportRoutineWatcher),
  fork(removeTemplateImportRoutineWatcher),
  fork(removeTemplateFreeCarriersRoutineWatcher),
  fork(removeTemplatePostImportRoutineWatcher),
  fork(createBulkExportOffersRoutineWatcher),
  fork(createAndDuplicateExportOffersRoutineWatcher),
  fork(createAndStartNewExportOffersRoutineWatcher),
  fork(createBulkImportOffersRoutineWatcher),
  fork(createAndDuplicateImportOffersRoutineWatcher),
  fork(createAndStartNewImportOffersRoutineWatcher),
  fork(createBulkPostImportOffersRoutineWatcher),
  fork(createAndDuplicatePostImportOffersRoutineWatcher),
  fork(createAndStartNewPostImportOffersRoutineWatcher),
  fork(createBulkFreeCarriersOffersRoutineWatcher),
  fork(createAndDuplicateFreeCarriersOffersRoutineWatcher),
  fork(createAndStartNewFreeCarriersOffersRoutineWatcher),
  fork(createContractOfferRoutineWatcher),
  fork(clearContractOfferRoutineWatcher),
  fork(setOfferFormContractValueRoutineWatcher)
]
