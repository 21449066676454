import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  takeLatest,
  select
} from '@redux-saga/core/effects'
import { navigate } from 'gatsby-plugin-intl'
import { isNil, pipe } from 'ramda'
import routes from 'src/utils/routes'
import { ERROR_DELAY_TIME, IMPERSONATE_SESSION_KEY } from 'src/ducks/consts'
import AuthService from 'src/services/AuthService'
import AccountService from 'src/services/AccountService'
import appRegistry from 'src/services/AppRegistry'
import LaravelEchoService from 'src/services/LaravelEchoService'
import { selectRegisterUserForm } from 'src/features/auth/duck/selectors'
import {
  displayResponseErrorMessage,
  filterEmptyStrings,
  filterNullValues,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import qs from 'qs'

export const loginUserRoutine = createRoutine('LOGIN_USER')
export const logoutUserRoutine = createRoutine('LOGOUT_USER')
export const setRegistrationValueRoutine = createRoutine(
  'SET_REGISTRATION_VALUE'
)
export const passwordResetRequestRoutine = createRoutine(
  'PASSWORD_RESET_REQUEST'
)
export const setNewPasswordRoutine = createRoutine('SET_NEW_PASSWORD')
export const loginIfSessionExistsRoutine = createRoutine(
  'LOGIN_IF_SESSION_EXISTS'
)

export const registerUserRoutine = createRoutine('REGISTER_USER')

export const submitContactFormRoutine = createRoutine('SUBMIT_CONTACT_FORM')

//actions

function* loginUser({ payload }) {
  yield put(loginUserRoutine.request())
  try {
    yield call(AuthService.login, payload.username, payload.password)
    yield put(loginUserRoutine.success())
    const { redirect, ...rest } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    const params = qs.stringify({ ...rest })
    navigate(redirect ? `${redirect}?${params}` : routes.APP_DASHBOARD)
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(loginUserRoutine.failure({ message }))
  }
}

function* logoutUser() {
  try {
    //check if impersonalization is active
    LaravelEchoService.getInstance().leaveAllChannels()

    const isImpersonated = !isNil(
      JSON.parse(appRegistry.session.get(IMPERSONATE_SESSION_KEY))
    )
    if (isImpersonated) {
      appRegistry.session.remove(IMPERSONATE_SESSION_KEY)
      const { data } = yield call(AuthService.leaveImpersonalization)
      yield put(logoutUserRoutine.success())
      window.location.replace(data.url)
    } else {
      yield call(AuthService.logout)
      yield put(logoutUserRoutine.success())
      navigate(routes.HOME)
    }
  } catch (e) {
    console.log(e)
  }
}

function* setRegistrationValue({ payload }) {
  yield put(setRegistrationValueRoutine.success(payload))
}

function* passwordResetRequest({ payload }) {
  yield put(passwordResetRequestRoutine.request())
  try {
    const { data } = yield call(
      AuthService.passwordResetRequest,
      payload.username
    )
    yield put(passwordResetRequestRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(passwordResetRequestRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(passwordResetRequestRoutine.fulfill())
  }
}

function* setNewPassword({ payload }) {
  yield put(setNewPasswordRoutine.request())
  try {
    const { data } = yield call(
      AuthService.passwordReset,
      payload.token,
      payload.password,
      payload.username,
      payload.confirmPassword
    )
    yield put(setNewPasswordRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(setNewPasswordRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(setNewPasswordRoutine.fulfill())
  }
}

function* loginIfSessionExists() {
  try {
    const response = yield call(AccountService.currentUserDetails)
    response.status === 200 && navigate(routes.APP_DASHBOARD)
  } catch (e) {
    console.log(e)
  }
}

function* registerUser() {
  yield put(registerUserRoutine.request())
  const values = yield select(selectRegisterUserForm)
  const payload = pipe(filterEmptyStrings, filterNullValues)(values.toJS())
  try {
    yield call(AuthService.register, payload)
    yield put(registerUserRoutine.success())
    navigate(routes.HOME)
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'signUp.successMessage'
        })
      }
    })
  } catch (e) {
    yield put(registerUserRoutine.failure())
  }
}

function* submitContactForm({ payload }) {
  yield put(submitContactFormRoutine.request())

  try {
    yield call(AuthService.contact, payload)
    yield put(submitContactFormRoutine.success())
    navigate(routes.HOME)
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.sentSuccessfully'
        })
      }
    })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(submitContactFormRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  }
}

//watchers

function* loginUserRoutineWatcher() {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}

function* logoutUserRoutineWatcher() {
  yield takeLatest(logoutUserRoutine.TRIGGER, logoutUser)
}

function* setQuestionnaireValueWatcher() {
  yield takeLatest(setRegistrationValueRoutine.TRIGGER, setRegistrationValue)
}

function* passwordResetRequestWatcher() {
  yield takeLatest(passwordResetRequestRoutine.TRIGGER, passwordResetRequest)
}

function* setNewPasswordWatcher() {
  yield takeLatest(setNewPasswordRoutine.TRIGGER, setNewPassword)
}

function* loginIfSessionExistsWatcher() {
  yield takeLatest(loginIfSessionExistsRoutine.TRIGGER, loginIfSessionExists)
}

function* registerUserWatcher() {
  yield takeLatest(registerUserRoutine.TRIGGER, registerUser)
}

function* submitContactFormWatcher() {
  yield takeLatest(submitContactFormRoutine.TRIGGER, submitContactForm)
}

export const authSagas = [
  fork(loginUserRoutineWatcher),
  fork(logoutUserRoutineWatcher),
  fork(setQuestionnaireValueWatcher),
  fork(passwordResetRequestWatcher),
  fork(setNewPasswordWatcher),
  fork(loginIfSessionExistsWatcher),
  fork(registerUserWatcher),
  fork(submitContactFormWatcher)
]
