import React from 'react'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { identity, compose } from 'ramda'

import rootReducer, { rootSaga } from './rootReducer'

const initStore = () => {
  const middleware = createSagaMiddleware()

  const attachReactDevTools =
    process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__

  const reduxDevtools = attachReactDevTools
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : identity()

  const enhancer = compose(applyMiddleware(middleware), reduxDevtools)

  const store = createStore(rootReducer, enhancer)

  middleware.run(rootSaga)
  return store
}

export const store = initStore()

export default ({ element }) => <Provider store={store}>{element}</Provider>
