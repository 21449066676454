import moment from 'moment'
import {
  evolve,
  join,
  pathOr,
  pipe,
  prepend,
  values,
  keys,
  omit,
  when,
  prop,
  both
} from 'ramda'
import { API_STATES } from 'src/ducks/consts'
import snakeCase from 'lodash.snakecase'
import {
  addMsToTime,
  addPostalCodeToName,
  filterEmptyStrings,
  filterNullValues,
  formatTimeDuration,
  removeMsFromTime,
  replaceNullWithEmptyString
} from 'src/utils/helpers'
import {
  AdrClass,
  CargoType,
  Company,
  Container,
  Contractor,
  Currency,
  Location,
  Shipowner
} from 'src/ducks/records'
import { renameKeysWith } from 'ramda-adjunct'
import { capitalize } from '@material-ui/core/utils'
import { Driver, TrackingEdit } from 'src/features/tracking/duck/records'
import {
  TrackingListItem,
  TrackingEvent,
  TrackingOffer,
  TrackingDetails,
  HistoryEvent
} from './records'
import {
  TRANSPORT_EVENTS,
  SYSTEM_EVENTS,
  WORK_TIME_EVENTS,
  TRACKING_EVENTS_TRANSLATIONS
} from './consts'

export const normalizeOrderTrackingList = arr =>
  arr.map(
    el =>
      new TrackingListItem({
        ...el,
        reportedDelay: formatTimeDuration(el.reportedDelay),
        offer: TrackingOffer({ ...el.offer }),
        originLocation: Location(addPostalCodeToName(el.originLocation)),
        destinationLocation: Location(
          addPostalCodeToName(el.destinationLocation)
        ),
        forwarderUser: Contractor({ ...el.forwarderUser }),
        originLocationOperationDate: moment(
          el.originLocationOperationDate
        ).calendar(),
        originLocationOperationTime: removeMsFromTime(
          el.originLocationOperationTime
        ),
        destinationLocationOperationDate: el.destinationLocationOperationDate
          ? moment(el.destinationLocationOperationDate).calendar()
          : null,
        destinationLocationOperationTime: removeMsFromTime(
          el.destinationLocationOperationTime
        )
      })
  )

export const normalizeTrackingDetails = obj => {
  const translations = {
    ...TRACKING_EVENTS_TRANSLATIONS,
    ...renameKeysWith(snakeCase)(obj.availableTrackingEvents)
  }
  return new TrackingDetails({
    ...obj,
    reportedWorkTime: formatTimeDuration(obj.reportedWorkTime),
    reportedDelay: formatTimeDuration(obj.reportedDelay),
    offer: TrackingOffer({
      ...obj.offer,
      container: Container({ ...obj.offer.container }),
      shipowner: Shipowner({ ...obj.offer.shipowner }),
      adrClass: AdrClass({ ...obj.offer.adrClass }),
      cargoType: CargoType({ ...obj.offer.cargoType }),
      price: Currency({ ...obj.offer.price })
    }),
    originLocation: Location(addPostalCodeToName(obj.originLocation)),
    destinationLocation: Location(addPostalCodeToName(obj.destinationLocation)),
    returnLocation: Location(addPostalCodeToName(obj.returnLocation)),
    customsClearanceLocation: Location(
      addPostalCodeToName(obj.customsClearanceLocation)
    ),
    forwarderUser: Contractor({
      ...obj.forwarderUser,
      company: Company({ ...obj.forwarderUser.company })
    }),
    carrierUser: Contractor({
      ...obj.carrierUser,
      company: Company({ ...obj.carrierUser.company })
    }),
    createdAt: moment(obj.createdAt).format('DD.MM.YYYY HH:mm:ss'),
    originLocationOperationDate: obj.originLocationOperationDate
      ? moment(obj.originLocationOperationDate).calendar()
      : null,
    originLocationOperationTime: removeMsFromTime(
      obj.originLocationOperationTime
    ),
    destinationLocationOperationDate: obj.destinationLocationOperationDate
      ? moment(obj.destinationLocationOperationDate).calendar()
      : null,
    destinationLocationOperationTime: removeMsFromTime(
      obj.destinationLocationOperationTime
    ),
    state: API_STATES.DONE,
    trackingEvents: pathOr([], ['trackingEvents'], obj).map(
      item =>
        new TrackingEvent({
          ...item,
          batteryLevel: 0,
          createdAt: moment(item.createdAt).format('DD.MM HH:mm:ss')
        })
    ),
    eventsHistory: pathOr([], ['trackingEvents'], obj)
      .filter(e =>
        [
          ...pipe(renameKeysWith(snakeCase), keys)(obj.availableTrackingEvents),
          TRANSPORT_EVENTS.TRANSPORTATION_DELAY,
          TRANSPORT_EVENTS.TRANSPORTATION_FINISHED,
          ...values(SYSTEM_EVENTS),
          ...values(WORK_TIME_EVENTS)
        ].includes(e.type)
      )
      .map(
        item =>
          new HistoryEvent({
            ...item,
            title: translations[item.type] || item.type,
            createdAt: moment(item.createdAt).calendar(null, {
              sameDay: 'HH:mm',
              nextDay: 'DD.MM HH:mm',
              lastDay: 'DD.MM HH:mm',
              lastWeek: 'DD.MM HH:mm',
              sameElse: 'DD.MM HH:mm'
            }),
            delay: pathOr(null, ['additionalInfo', 'delay'], item),
            comment: pathOr(null, ['driverComment'], item)
          })
      )
  })
}

export const normalizeTrackingTimelineData = tracking => [
  {
    title: 'pickupLocation',
    location: tracking.originLocation,
    date: tracking.originLocationOperationDate,
    time: tracking.originLocationOperationTime,
    address: tracking.originLocationAddress,
    companyName: tracking.originLocationCompanyName,
    locationCity: tracking.originLocationCity
  },
  {
    title: 'unloadingLocation',
    location: tracking.destinationLocation,
    date: tracking.destinationLocationOperationDate,
    time: tracking.destinationLocationOperationTime,
    address: tracking.destinationLocationAddress,
    companyName: tracking.destinationLocationCompanyName,
    locationCity: tracking.destinationLocationCity
  },
  {
    title: 'returnLocation',
    location: tracking.returnLocation
  }
]

export const normalizeTrackingEdit = obj => {
  const trackingEditForm = pipe(
    replaceNullWithEmptyString,
    evolve({
      destinationLocationOperationTime: removeMsFromTime,
      originLocationOperationTime: removeMsFromTime
    })
  )(obj)

  return TrackingEdit({
    ...trackingEditForm
  })
}

export const normalizeTrackingForSave = pipe(
  when(prop('isCurrentUserForwarder'), omit(['driverId'])),
  when(
    both(prop('isCurrentUserForwarder'), prop('driverId')),
    omit([
      'driverIdentityCardNumber',
      'driverEmail',
      'driverPhoneNumber',
      'driverName',
      'driverId'
    ])
  ),
  omit(['state', 'isCurrentUserForwarder']),
  filterNullValues,
  filterEmptyStrings,
  evolve({
    destinationLocationOperationTime: addMsToTime,
    originLocationOperationTime: addMsToTime
  })
)

export const normalizeAddressData = locationType =>
  renameKeysWith(pipe(capitalize, prepend(locationType), join('')))

export const normalizeDrivers = drivers =>
  drivers.map(driver => new Driver({ ...driver }))
