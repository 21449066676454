import { map, values } from 'ramda'
import translate from 'src/intl/translate'
import { CURRENCIES } from 'src/ducks/consts'

export const TEMPLATE_OFFER_TYPES = {
  export: 'export',
  import: 'import',
  postImport: 'post-import',
  freeCarriers: 'free-carriers'
}

export const MAX_BULK_OFFER_NUMBER = 10

export const OFFER_TYPES = {
  QUEUE: 'queue',
  AUCTION: 'auction',
  PRIVATE: 'private'
}

export const OFFER_VISIBILITY_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private'
}

export const OFFER_VISIBILITY_TYPES_OPTIONS = [
  {
    text: 'forms.public',
    value: OFFER_VISIBILITY_TYPES.PUBLIC
  },
  {
    text: 'forms.private',
    value: OFFER_VISIBILITY_TYPES.PRIVATE
  }
]

export const OFFER_TYPES_OPTIONS = [
  {
    text: 'forms.queue',
    value: OFFER_TYPES.QUEUE
  },
  {
    text: 'forms.auction',
    value: OFFER_TYPES.AUCTION
  }
]

export const CURRENCIES_OPTIONS = map(
  currency => ({ id: currency, value: currency, label: currency }),
  values(CURRENCIES)
)
