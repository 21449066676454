import { includes, not, pipe, when } from 'ramda'
import { renameKeysWith } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import { formatFilterKey } from 'src/utils/helpers'

export const selectInvoiceAddForm = state => state.invoices.add
export const selectInvoiceSettings = state => state.invoices.settings
export const selectInvoiceDetails = state => state.invoices.details
export const selectNoteDetails = state => state.invoices.note
export const selectCorrectiveInvoiceAddForm = state =>
  state.invoices.correctiveAdd
export const selectCorrectiveInvoiceDetails = state =>
  state.invoices.correctiveDetails

//Invoices issued
export const selectAllInvoicesIssued = state => state.invoices.issued
export const selectInvoicesIssued = state => state.invoices.issued.invoices
export const selectInvoicesIssuedLength = state =>
  state.invoices.issued.invoices.length
export const selectInvoicesIssuedFilters = state =>
  state.invoices.issued.filters
export const selectInvoicesIssuedPagination = state =>
  state.invoices.issued.pagination
export const selectInvoicesIssuedButtonsState = state =>
  state.invoices.issued.state.buttons
export const selectInvoicesIssuedFiltersFormatted = state => {
  const filters = state.invoices.issued.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//Invoices received
export const selectAllInvoicesReceived = state => state.invoices.received
export const selectInvoicesReceived = state => state.invoices.received.invoices
export const selectInvoicesReceivedFilters = state =>
  state.invoices.received.filters
export const selectInvoicesReceivedPagination = state =>
  state.invoices.received.pagination
export const selectInvoicesReceivedButtonsState = state =>
  state.invoices.received.state.buttons
export const selectInvoicesReceivedFiltersFormatted = state => {
  const filters = state.invoices.received.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}
