import { includes, not, pipe, when } from 'ramda'
import { renameKeysWith } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import { formatFilterKey } from 'src/utils/helpers'
import { normalizeTrackingTimelineData } from 'src/features/tracking/duck/normalizers'

export const selectTrackingDetails = state => state.tracking.trackingDetails

export const selectTrackingTimelineData = state =>
  normalizeTrackingTimelineData(selectTrackingDetails(state))

export const selectTrackingPinCode = state => state.tracking.pinCode
export const selectTrackingTransportationId = state =>
  state.tracking.transportationId

export const selectTrackingEditForm = state => state.tracking.trackingEdit

export const selectTrackingPinCodeState = state => state.tracking.pinCodeState

export const selectDrivers = state => state.tracking.drivers

//Tracking ongoing
export const selectTrackingOngoingRecords = state =>
  state.tracking.trackingOngoing
export const selectTrackingOngoing = state =>
  state.tracking.trackingOngoing.offers
export const selectTrackingOngoingFilters = state =>
  state.tracking.trackingOngoing.filters
export const selectTrackingOngoingPagination = state =>
  state.tracking.trackingOngoing.pagination
export const selectTrackingOngoingButtonsState = state =>
  state.tracking.trackingOngoing.state.buttons
export const selectTrackingOngoingFiltersFormatted = state => {
  const filters = state.tracking.trackingOngoing.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//Tracking finished
export const selectTrackingFinishedRecords = state =>
  state.tracking.trackingFinished
export const selectTrackingFinished = state =>
  state.tracking.trackingFinished.offers
export const selectTrackingFinishedFilters = state =>
  state.tracking.trackingFinished.filters
export const selectTrackingFinishedPagination = state =>
  state.tracking.trackingFinished.pagination
export const selectTrackingFinishedButtonsState = state =>
  state.tracking.trackingFinished.state.buttons
export const selectTrackingFinishedFiltersFormatted = state => {
  const filters = state.tracking.trackingFinished.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}
