import icons from '../components/Icons'

export const NOTIFICATION_EVENT_TYPES = {
  NOTIFICATION_SENT: '.notification.sent'
}

export const mapNotificationCodeToIcon = (code = '') => {
  switch (true) {
    case code.includes('regular.queue.reservation'):
    case code.includes('offer.contract.reservation'):
      return icons.reservation
    case code.includes('offer.regular.queue'):
      return icons.offer
    case code.includes('offer.regular.auction'):
      return icons.auction
    case code.includes('company.document'):
      return icons.time
    case code.includes('company.payment'):
      return icons.money
    case code.includes('transportation-tracking'):
      return icons.tracking
    case code.includes('transportation-order'):
      return icons.order
    case code.includes('invoice'):
      return icons.invoice
    default:
      return icons.default
  }
}
