export const selectDictionaries = state => state.dictionaries

export const selectLoadingDictionary = state =>
  state.dictionaries.locations.loading

export const selectUnloadingDictionary = state =>
  state.dictionaries.locations.unloading

export const selectPickupDictionary = state =>
  state.dictionaries.locations.pickup

export const selectReturnDictionary = state =>
  state.dictionaries.locations.return

export const selectAvailabilityDictionary = state =>
  state.dictionaries.locations.availability

export const selectDestinationDictionary = state =>
  state.dictionaries.locations.destination

export const selectCompaniesDictionary = state => state.dictionaries.companies

export const selectExchangeCounters = state => state.exchangeCounters

export const selectSnackbar = state => state.snackbar

export const isGlobalLoaderLoading = state =>
  [
    state.stockExport.state.list,
    state.stockExport.state.details,
    state.stockExport.state.buttons,

    state.stockImport.state.list,
    state.stockImport.state.details,
    state.stockImport.state.buttons,

    state.stockPostImport.state.list,
    state.stockPostImport.state.details,
    state.stockPostImport.state.buttons,

    state.stockFreeCarriers.state.list,
    state.stockFreeCarriers.state.details,
    state.stockFreeCarriers.state.buttons,

    state.myOffers.exportOngoing.state.list,
    state.myOffers.exportOngoing.state.details,
    state.myOffers.exportOngoing.state.buttons,

    state.myOffers.exportFinished.state.list,
    state.myOffers.exportFinished.state.details,
    state.myOffers.exportFinished.state.buttons,

    state.myOffers.importOngoing.state.list,
    state.myOffers.importOngoing.state.details,
    state.myOffers.importOngoing.state.buttons,

    state.myOffers.importFinished.state.list,
    state.myOffers.importFinished.state.details,
    state.myOffers.importFinished.state.buttons,

    state.myOffers.freeCarrierOngoing.state.list,
    state.myOffers.freeCarrierOngoing.state.details,
    state.myOffers.freeCarrierOngoing.state.buttons,

    state.myOffers.freeCarrierFinished.state.list,
    state.myOffers.freeCarrierFinished.state.details,
    state.myOffers.freeCarrierFinished.state.buttons,

    state.myOffers.postImportOngoing.state.list,
    state.myOffers.postImportOngoing.state.details,
    state.myOffers.postImportOngoing.state.buttons,

    state.myOffers.postImportFinished.state.list,
    state.myOffers.postImportFinished.state.details,
    state.myOffers.postImportFinished.state.buttons,

    state.myReservations.exportOngoing.state.list,
    state.myReservations.exportOngoing.state.details,
    state.myReservations.exportOngoing.state.buttons,

    state.myReservations.exportFinished.state.list,
    state.myReservations.exportFinished.state.details,
    state.myReservations.exportFinished.state.buttons,

    state.myReservations.importOngoing.state.list,
    state.myReservations.importOngoing.state.details,
    state.myReservations.importOngoing.state.buttons,

    state.myReservations.importFinished.state.list,
    state.myReservations.importFinished.state.details,
    state.myReservations.importFinished.state.buttons,

    state.myReservations.freeCarrierOngoing.state.list,
    state.myReservations.freeCarrierOngoing.state.details,
    state.myReservations.freeCarrierOngoing.state.buttons,

    state.myReservations.freeCarrierFinished.state.list,
    state.myReservations.freeCarrierFinished.state.details,
    state.myReservations.freeCarrierFinished.state.buttons,

    state.myReservations.postImportOngoing.state.list,
    state.myReservations.postImportOngoing.state.details,
    state.myReservations.postImportOngoing.state.buttons,

    state.myReservations.postImportFinished.state.list,
    state.myReservations.postImportFinished.state.details,
    state.myReservations.postImportFinished.state.buttons,

    state.contracts.stockContracts.state.list,
    state.contracts.stockContracts.state.details,
    state.contracts.stockContracts.state.buttons,

    state.contracts.myOffersOngoing.state.list,
    state.contracts.myOffersOngoing.state.details,
    state.contracts.myOffersOngoing.state.buttons,

    state.contracts.myOffersFinished.state.list,
    state.contracts.myOffersFinished.state.details,
    state.contracts.myOffersFinished.state.buttons,

    state.contracts.myReservationsOngoing.state.list,
    state.contracts.myReservationsOngoing.state.details,
    state.contracts.myReservationsOngoing.state.buttons,

    state.contracts.myReservationsFinished.state.list,
    state.contracts.myReservationsFinished.state.details,
    state.contracts.myReservationsFinished.state.buttons,

    state.tracking.trackingOngoing.state.list,
    state.tracking.trackingOngoing.state.details,
    state.tracking.trackingOngoing.state.buttons,

    state.tracking.trackingFinished.state.list,
    state.tracking.trackingFinished.state.details,
    state.tracking.trackingFinished.state.buttons,

    state.tracking.trackingDetails.state,

    state.contactForm.state,
    state.companies.state,
    state.termsOfService.state,

    state.offerFormImport.state,
    state.offerFormExport.state,
    state.offerFormPostImport.state,
    state.offerFormFreeCarriers.state,
    state.offerFormContracts.state,
    state.offerFormsScaffolds.state,

    state.orders.edit.state,
    state.orders.details.state,
    state.orders.received.state.list,
    state.orders.received.state.buttons,
    state.orders.received.state.details,
    state.orders.issued.state.list,
    state.orders.issued.state.buttons,
    state.orders.issued.state.details,

    state.tracking.trackingEdit.state,

    state.invoices.add.state,
    state.invoices.details.state,
    state.invoices.issued.state.list,
    state.invoices.received.state.list,
    state.invoices.note.state,
    state.invoices.correctiveAdd.state,
    state.invoices.correctiveDetails.state,

    state.currentCompany.state,

    state.account.currentUser.state,

    state.account.changePassword.state,

    state.addUserForm.state,

    state.editUserForm.state,

    state.register.state,

    state.forgotPassword.state,

    state.debtCollection.state

    //state.offers.state
  ].includes('in-progress')
