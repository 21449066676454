import LocalStorageService from './LocalStorageService'
import SessionStorageService from './SessionStorageService'

const registry = {
  get storage() {
    return LocalStorageService
  },

  get session() {
    return SessionStorageService
  },

  add(name, value) {
    this[name] = value
  }
}

export default registry
