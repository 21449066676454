import React from 'react'
import { generateTableNames, generateTableWidths } from 'src/utils/helpers'
import translate from 'src/intl/translate'
import { values } from 'ramda'
import ImportIcon from 'src/assets/icons/import.svg'
import ExportIcon from 'src/assets/icons/export.svg'

export const TRACKING_TABS = {
  ONGOING: 'tracking.ongoing',
  FINISHED: 'tracking.finished'
}

export const TRACKING_TABS_ARRAY = values(TRACKING_TABS)

export const TRACKING_STATES = {
  INCOMPLETE_DATA: 'incomplete_data',
  WAITING_FOR_DRIVER: 'waiting_for_driver',
  ACTIVE: 'active',
  IN_TRANSIT: 'in_transit',
  COMPLETED_UNKNOWN_SCHEDULE: 'completed_unknown_schedule',
  COMPLETED_ON_SCHEDULE: 'completed_on_schedule',
  COMPLETED_BEHIND_SCHEDULE: 'completed_behind_schedule',
  COMPLETED_UNKNOWN: 'completed_unknown'
}

export const TRACKING_STATES_FILTERS = {
  INCOMPLETE_DATA: 'incomplete_data',
  WAITING_FOR_DRIVER: 'waiting_for_driver',
  ACTIVE: 'active',
  IN_TRANSIT: 'in_transit',
  ON_SITE: 'on_site',
  DELAYED: 'delayed'
}

export const TRACKING_ONGOING_STATUS_FILTER_OPTIONS = [
  {
    label: 'offersFilters.statusAll',
    value: null
  },
  {
    label: 'tracking.incomplete_data',
    value: 'incomplete_data'
  },
  {
    label: 'tracking.waiting_for_driver',
    value: 'waiting_for_driver'
  },
  {
    label: 'tracking.active',
    value: 'active'
  },
  {
    label: 'tracking.in_transit',
    value: 'in_transit'
  },
  {
    label: 'tracking.on_site',
    value: 'on_site'
  },
  {
    label: 'tracking.delayed',
    value: 'delayed'
  }
]

export const TRACKING_FINISHED_STATUS_FILTER_OPTIONS = [
  {
    label: 'offersFilters.statusAll',
    value: null
  },
  {
    label: 'tracking.incomplete_data',
    value: 'incomplete_data'
  },
  {
    label: 'tracking.on_site',
    value: 'on_site'
  },
  {
    label: 'tracking.delayed',
    value: 'delayed'
  }
]

export const TRACKING_EVENT_TABLE_HEADERS = [
  {
    name: 'Urządzenie',
    sort: null
  },
  {
    name: 'lat',
    sort: null
  },
  {
    name: 'long',
    sort: null
  },
  {
    name: 'bateria',
    sort: null
  },
  {
    name: 'Godzina',
    sort: null
  }
]

export const TRACKING_TABLE_HEADERS = [
  {
    name: 'status',
    sort: null,
    width: 0.7
  },
  {
    name: 'nrRef',
    sort: null,
    width: 0.7
  },
  {
    name: 'fromWhere',
    sort: null,
    width: 1
  },
  {
    name: 'toWhere',
    sort: null,
    width: 1
  },
  {
    name: 'cargo',
    sort: null,
    width: 0.7
  },
  {
    name: 'customer',
    sort: null,
    width: 1
  },
  {
    name: 'date',
    sort: true,
    width: 0.7
  }
]

export const TRACKING_TABLE_NAMES = generateTableNames(TRACKING_TABLE_HEADERS)
export const TRACKING_TABLE_WIDTHS = generateTableWidths(TRACKING_TABLE_HEADERS)

export const TRACKING_LOCATION_TYPES = {
  ORIGIN: 'originLocation',
  DESTINATION: 'destinationLocation'
}

export const FREE_CARRIER_TRANSPORTATION_TYPES = {
  IMPORT: 'import',
  EXPORT: 'export'
}

export const FREE_CARRIER_TRANSPORTATION_TYPES_OPTIONS = [
  {
    text: 'common.import',
    description: 'tracking.fullContainerForPickup',
    value: FREE_CARRIER_TRANSPORTATION_TYPES.IMPORT,
    icon: <ImportIcon />
  },
  {
    text: 'common.export',
    description: 'tracking.emptyContainerForPickup',
    value: FREE_CARRIER_TRANSPORTATION_TYPES.EXPORT,
    icon: <ExportIcon />
  }
]

export const TRANSPORT_EVENTS = {
  IN_TRANSIT: 'in_transit',
  TRANSPORTATION_DELAY: 'transportation_delay',
  TRANSPORTATION_FINISHED: 'transportation_finished'
}

export const SYSTEM_EVENTS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  ERROR: 'error'
}

export const WORK_TIME_EVENTS = {
  START_WORK: 'start_work',
  PAUSE_WORK: 'pause_work',
  STOP_WORK: 'stop_work'
}

export const TRACKING_EVENTS_TRANSLATIONS = {
  [TRANSPORT_EVENTS.IN_TRANSIT]: 'tracking.event_in_transit',
  [TRANSPORT_EVENTS.TRANSPORTATION_DELAY]:
    'tracking.event_transportation_delay',
  [TRANSPORT_EVENTS.TRANSPORTATION_FINISHED]:
    'tracking.event_transportation_finished',
  [SYSTEM_EVENTS.LOGIN]: 'tracking.event_login',
  [SYSTEM_EVENTS.LOGOUT]: 'tracking.event_logout',
  [SYSTEM_EVENTS.ERROR]: 'tracking.event_error',
  [WORK_TIME_EVENTS.START_WORK]: 'tracking.event_start_work',
  [WORK_TIME_EVENTS.PAUSE_WORK]: 'tracking.event_pause_work',
  [WORK_TIME_EVENTS.STOP_WORK]: 'tracking.event_stop_work'
}

export const TRACKING_OFFER_TYPES = {
  EXPORT: 'export_cargo_offer',
  IMPORT: 'import_cargo_offer',
  FREE_CARRIER: 'free_carrier_offer',
  POST_IMPORT: 'post_import_container_offer'
}

export const ORIGIN_LOCATION_NAMES = {
  [TRACKING_OFFER_TYPES.EXPORT]: {
    sectionName: 'loadingSectionName',
    placeInputLabel: 'loadingPlaceInputLabel',
    dateInputLabel: 'loadingDateInputLabel'
  },
  [TRACKING_OFFER_TYPES.IMPORT]: {
    sectionName: 'pickupSectionName',
    placeInputLabel: 'pickupPlaceInputLabel',
    dateInputLabel: 'pickupDateInputLabel'
  },
  [TRACKING_OFFER_TYPES.POST_IMPORT]: {
    sectionName: 'availabilitySectionName',
    placeInputLabel: 'availabilityPlaceInputLabel',
    dateInputLabel: 'availabilityDateInputLabel'
  },
  [TRACKING_OFFER_TYPES.FREE_CARRIER]: {
    sectionName: 'availabilitySectionName',
    placeInputLabel: 'availabilityFreeCarriersPlaceInputLabel',
    dateInputLabel: 'availabilityDateInputLabel'
  }
}

export const DESTINATION_LOCATION_NAMES = {
  [TRACKING_OFFER_TYPES.EXPORT]: {
    sectionName: 'returnSectionName',
    placeInputLabel: 'returnPlaceInputLabel',
    dateInputLabel: 'returnDateInputLabel'
  },
  [TRACKING_OFFER_TYPES.IMPORT]: {
    sectionName: 'unloadingSectionName',
    placeInputLabel: 'unloadingPlaceInputLabel',
    dateInputLabel: 'unloadingDateInputLabel'
  },
  [TRACKING_OFFER_TYPES.POST_IMPORT]: {
    sectionName: 'destinationSectionName',
    placeInputLabel: 'destinationPlaceInputLabel',
    dateInputLabel: 'availabilityDateInputLabel'
  },
  [TRACKING_OFFER_TYPES.FREE_CARRIER]: {
    sectionName: 'destinationSectionName',
    placeInputLabel: 'destinationFreeCarriersPlaceInputLabel',
    dateInputLabel: 'availabilityDateInputLabel'
  }
}
