import { Record } from 'immutable'
import { Image } from 'src/features/account/duck/records'
import { API_STATES } from 'src/ducks/consts'

export const Company = Record({
  id: null,
  name: '',
  email: '',
  address: '',
  addressNote: '',
  city: '',
  postalCode: '',
  countryCode: '',
  phoneNumber: '',
  taxId: '',
  symbol: '',
  homepageUrl: '',
  status: '',
  businessProfile: '',
  logo: Image(),
  documents: [],
  users: [],
  createdAt: null,
  isBlacklisted: false,
  isWhitelisted: false,
  isTopContainerCertified: false,
  hasPaymentIssues: false
})

export const Companies = Record({
  state: API_STATES.PRISTINE,
  errorMessage: null,
  isSearchResultEmpty: false,
  companyDetails: Company(),
  searchResult: Company(),
  whitelist: [],
  blacklist: [],
  allMarkedCompanies: []
})
