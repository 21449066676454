import { combineReducers } from 'redux'
import {
  CorrectiveInvoice,
  CorrectiveNote,
  Invoice,
  Invoices,
  InvoicesFilters,
  InvoicesSettings
} from 'src/features/invoices/duck/records'
import {
  showMyCompanyInvoiceSettingsRoutine,
  setInvoicesIssuedFiltersRoutine,
  setInvoicesReceivedFiltersRoutine,
  saveMyCompanyInvoiceSettingsRoutine,
  setInvoiceSettingsValueRoutine,
  getInvoicesListIssuedRoutine,
  getInvoicesListReceivedRoutine,
  prepareInvoiceRoutine,
  setInvoiceEditFormValueRoutine,
  recalculateInvoiceRoutine,
  regenerateInvoiceNumberRoutine,
  searchForInvoiceRecipientRoutine,
  clearRecipientRoutine,
  setInvoiceMultipleFormValuesRoutine,
  createInvoiceRoutine,
  showInvoiceDetailsRoutine,
  clearInvoiceDetailsRoutine,
  deletePaymentRoutine,
  deletePaymentFromCorrectiveInvoiceRoutine,
  markAsPaidRoutine,
  clearRecipientSearchRoutine,
  prepareInvoiceForTransportationOrderRoutine,
  clearAddInvoiceFormRoutine,
  showCorrectionNoteRoutine,
  createCorrectionNoteRoutine,
  clearAddCorrectiveInvoiceFormRoutine,
  prepareCorrectiveInvoiceRoutine,
  setCorrectiveInvoiceEditFormValueRoutine,
  recalculateCorrectiveInvoiceRoutine,
  createCorrectiveInvoiceRoutine,
  markCorrectiveAsPaidRoutine,
  showCorrectiveInvoiceDetailsRoutine,
  clearCorrectiveInvoiceDetailsRoutine
} from 'src/features/invoices/duck/actions'
import { API_STATES, COUNTRIES } from 'src/ducks/consts'
import { Currency, Pagination } from 'src/ducks/records'
import {
  normalizeCorrectiveInvoice,
  normalizeInvoice,
  normalizeInvoicesList
} from 'src/features/invoices/duck/normalizers'

const invoicesIssuedReducer = (state = new Invoices(), action) => {
  switch (action.type) {
    case getInvoicesListIssuedRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getInvoicesListIssuedRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getInvoicesListIssuedRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getInvoicesListIssuedRoutine.SUCCESS:
      return state
        .set('invoices', normalizeInvoicesList(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setInvoicesIssuedFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new InvoicesFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const invoicesReceivedReducer = (state = new Invoices(), action) => {
  switch (action.type) {
    case getInvoicesListReceivedRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getInvoicesListReceivedRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getInvoicesListReceivedRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getInvoicesListReceivedRoutine.SUCCESS:
      return state
        .set('invoices', normalizeInvoicesList(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setInvoicesReceivedFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new InvoicesFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const invoiceDetailsReducer = (state = new Invoice(), action) => {
  switch (action.type) {
    case clearInvoiceDetailsRoutine.SUCCESS:
      return new Invoice()
    case showInvoiceDetailsRoutine.REQUEST:
    case deletePaymentRoutine.REQUEST:
    case markAsPaidRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case showInvoiceDetailsRoutine.SUCCESS:
    case deletePaymentRoutine.SUCCESS:
    case markAsPaidRoutine.SUCCESS:
      return normalizeInvoice(action.payload).set('state', API_STATES.DONE)
    case markAsPaidRoutine.FAILURE:
    case showInvoiceDetailsRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case showInvoiceDetailsRoutine.FULFILL:
    case markAsPaidRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    default:
      return state
  }
}

const invoiceAddReducer = (state = new Invoice(), action) => {
  switch (action.type) {
    case saveMyCompanyInvoiceSettingsRoutine.SUCCESS:
      return state
        .set('paymentBankAccount', action.payload.paymentBankAccount)
        .set('numberingScheme', action.payload.numberingScheme)
        .set('issuePlace', action.payload.issuePlace)
        .set('paymentBankName', action.payload.paymentBankName)
    case createInvoiceRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createInvoiceRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE)
    case createInvoiceRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case createInvoiceRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case setInvoiceMultipleFormValuesRoutine.SUCCESS:
      return state.merge({ ...action.payload })
    case clearRecipientRoutine.SUCCESS:
      return state
        .set('recipientCompanyAddress', '')
        .set('recipientCompanyAddressLine2', '')
        .set('recipientCompanyCity', '')
        .set('recipientCompanyCountryCode', COUNTRIES.PL)
        .set('recipientCompanyEmail', '')
        .set('recipientCompanyId', null)
        .set('recipientCompanyName', '')
        .set('recipientCompanyPostalCode', '')
        .set('recipientCompanyTaxId', '')
    case searchForInvoiceRecipientRoutine.REQUEST:
      return state
        .set('recipientSearchState', API_STATES.IN_PROGRESS)
        .set('recipientSearchResults', [])
    case searchForInvoiceRecipientRoutine.SUCCESS:
      return state
        .set('recipientSearchState', API_STATES.DONE)
        .set('recipientSearchResults', action.payload)
    case searchForInvoiceRecipientRoutine.FAILURE:
      return state.set('recipientSearchState', API_STATES.ERROR)
    case searchForInvoiceRecipientRoutine.FULFILL:
      return state.set('recipientSearchState', API_STATES.DONE)
    case clearRecipientSearchRoutine.SUCCESS:
      return state.set('recipientSearchResults', [])
    case regenerateInvoiceNumberRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case regenerateInvoiceNumberRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('number', action.payload.number)
    case regenerateInvoiceNumberRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case regenerateInvoiceNumberRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case recalculateInvoiceRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case recalculateInvoiceRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('amountDue', new Currency({ ...action.payload.amountDue }))
        .set('subtotal', new Currency({ ...action.payload.subtotal }))
        .set('tax', new Currency({ ...action.payload.tax }))
        .set('total', new Currency({ ...action.payload.total }))
    case recalculateInvoiceRoutine.FAILURE:
      return state
        .set('state', API_STATES.ERROR)
        .set('amountDue', new Currency())
        .set('subtotal', new Currency({ formatted: '0,00 PLN' }))
        .set('tax', new Currency({ formatted: '0,00 PLN' }))
        .set('total', new Currency({ formatted: '0,00 PLN' }))
    case recalculateInvoiceRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case setInvoiceEditFormValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case prepareInvoiceRoutine.REQUEST:
    case prepareInvoiceForTransportationOrderRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case prepareInvoiceRoutine.SUCCESS:
    case prepareInvoiceForTransportationOrderRoutine.SUCCESS:
      return normalizeInvoice(action.payload).set('state', API_STATES.DONE)
    case prepareInvoiceRoutine.FAILURE:
    case prepareInvoiceForTransportationOrderRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case prepareInvoiceRoutine.FULFILL:
    case prepareInvoiceForTransportationOrderRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case clearAddInvoiceFormRoutine.SUCCESS:
      return new Invoice()
    default:
      return state
  }
}

const invoiceSettingsReducer = (state = new InvoicesSettings(), action) => {
  switch (action.type) {
    case showMyCompanyInvoiceSettingsRoutine.REQUEST:
    case saveMyCompanyInvoiceSettingsRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case showMyCompanyInvoiceSettingsRoutine.FAILURE:
    case saveMyCompanyInvoiceSettingsRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case showMyCompanyInvoiceSettingsRoutine.SUCCESS:
    case saveMyCompanyInvoiceSettingsRoutine.SUCCESS:
      return InvoicesSettings(action.payload).set('state', API_STATES.DONE)
    case setInvoiceSettingsValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case showMyCompanyInvoiceSettingsRoutine.FULFILL:
    case saveMyCompanyInvoiceSettingsRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    default:
      return state
  }
}
const invoiceNoteReducer = (state = new CorrectiveNote(), action) => {
  switch (action.type) {
    case createCorrectionNoteRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createCorrectionNoteRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE)
    case createCorrectionNoteRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case createCorrectionNoteRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case showCorrectionNoteRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case showCorrectionNoteRoutine.SUCCESS:
      return new CorrectiveNote({ ...action.payload }).set(
        'state',
        API_STATES.DONE
      )
    case showCorrectionNoteRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case showCorrectionNoteRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    default:
      return state
  }
}

const correctiveAddReducer = (state = new CorrectiveInvoice(), action) => {
  switch (action.type) {
    case createCorrectiveInvoiceRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createCorrectiveInvoiceRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE)
    case createCorrectiveInvoiceRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case createCorrectiveInvoiceRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case recalculateCorrectiveInvoiceRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case recalculateCorrectiveInvoiceRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'invoiceTotalAfterCorrection',
          new Currency({ ...action.payload.invoiceTotalAfterCorrection })
        )
        .set('subtotal', new Currency({ ...action.payload.subtotal }))
        .set('tax', new Currency({ ...action.payload.tax }))
        .set('total', new Currency({ ...action.payload.total }))
    case recalculateCorrectiveInvoiceRoutine.FAILURE:
      return state
        .set('state', API_STATES.ERROR)
        .set('invoiceTotalAfterCorrection', new Currency())
        .set('subtotal', new Currency({ formatted: '0,00 PLN' }))
        .set('tax', new Currency({ formatted: '0,00 PLN' }))
        .set('total', new Currency({ formatted: '0,00 PLN' }))
    case recalculateCorrectiveInvoiceRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case setCorrectiveInvoiceEditFormValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case prepareCorrectiveInvoiceRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case prepareCorrectiveInvoiceRoutine.SUCCESS:
      return normalizeCorrectiveInvoice(action.payload).set(
        'state',
        API_STATES.DONE
      )
    case prepareCorrectiveInvoiceRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case prepareCorrectiveInvoiceRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case clearAddCorrectiveInvoiceFormRoutine.SUCCESS:
      return new CorrectiveInvoice()
    default:
      return state
  }
}

const correctiveDetailsReducer = (state = new CorrectiveInvoice(), action) => {
  switch (action.type) {
    case clearCorrectiveInvoiceDetailsRoutine.SUCCESS:
      return new CorrectiveInvoice()
    case showCorrectiveInvoiceDetailsRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case showCorrectiveInvoiceDetailsRoutine.SUCCESS:
      return normalizeCorrectiveInvoice(action.payload).set(
        'state',
        API_STATES.DONE
      )
    case showCorrectiveInvoiceDetailsRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case showCorrectiveInvoiceDetailsRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case deletePaymentFromCorrectiveInvoiceRoutine.REQUEST:
    case markCorrectiveAsPaidRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case deletePaymentFromCorrectiveInvoiceRoutine.SUCCESS:
    case markCorrectiveAsPaidRoutine.SUCCESS:
      return normalizeCorrectiveInvoice(action.payload).set(
        'state',
        API_STATES.DONE
      )
    case markCorrectiveAsPaidRoutine.FAILURE:
    case markCorrectiveAsPaidRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)

    default:
      return state
  }
}

export const invoicesReducer = combineReducers({
  issued: invoicesIssuedReducer,
  received: invoicesReceivedReducer,
  details: invoiceDetailsReducer,
  add: invoiceAddReducer,
  settings: invoiceSettingsReducer,
  note: invoiceNoteReducer,
  correctiveAdd: correctiveAddReducer,
  correctiveDetails: correctiveDetailsReducer
})
