import { Contractor, Currency } from 'src/ducks/records'
import { isFalsy } from 'ramda-adjunct'
import moment from 'moment'
import { DATE_SHORT_FORMAT_WITH_TIME } from 'src/ducks/consts'
import { Reservation } from 'src/features/myOffers/duck/records'
import {
  normalizeExportOfferDetails,
  normalizeFreeCarrierOfferDetails,
  normalizeImportOfferDetails,
  normalizePostImportOfferDetails
} from 'src/features/stocks/ducks/normalizers'

export const normalizeReservation = reservation =>
  new Reservation({
    ...reservation,
    createdAt: isFalsy(reservation.createdAt)
      ? null
      : moment(reservation.createdAt).format(DATE_SHORT_FORMAT_WITH_TIME),
    offeror: Contractor({ ...reservation.offeror }),
    price: Currency({ ...reservation.price })
  })

export const normalizeReservations = arr =>
  arr.map(reservation => normalizeReservation(reservation))

export const normalizeMyExportOffers = arr =>
  arr.map(v => normalizeExportOfferDetails({ ...v, raw: v }))

export const normalizeMyImportOffers = arr =>
  arr.map(v => normalizeImportOfferDetails({ ...v, raw: v }))

export const normalizeMyPostImportOffers = arr =>
  arr.map(v => normalizePostImportOfferDetails({ ...v, raw: v }))

export const normalizeMyFreeCarrierOffers = arr =>
  arr.map(v => normalizeFreeCarrierOfferDetails({ ...v, raw: v }))
