import {
  allPass,
  assoc,
  evolve,
  ifElse,
  isNil,
  omit,
  pathSatisfies,
  pick,
  pipe,
  map,
  propEq,
  propOr,
  props,
  when,
  prop
} from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import {
  filterEmptyStrings,
  filterNullValues,
  getDateFromIsoString,
  getTimeFromIsoString
} from 'src/utils/helpers'
import { OFFER_TYPES } from 'src/features/offers/duck/consts'
import { DATE_API_FORMAT } from 'src/ducks/consts'
import {
  OfferTemplateExport,
  OfferTemplateFreeCarriers,
  OfferTemplateImport,
  OfferTemplatePostImport,
  TemplateDetails
} from './records'

const formatAuctionDeadline = obj => {
  const date = propOr('', 'auctionDeadlineDate', obj)
  const time = propOr('', 'auctionDeadlineTime', obj)
  const UTCDate = moment(`${date} ${time}`)
    .utc()
    .toISOString()
  return assoc('auctionDeadline', UTCDate, obj)
}

export const normalizeExportOfferForSave = pipe(
  filterEmptyStrings,
  filterNullValues,
  evolve({
    loadingTime: v => `${v}:00`,
    returnTime: v => `${v}:00`,
    auctionDeadlineTime: v => `${v}:00`
  }),
  ifElse(
    propEq('type', OFFER_TYPES.QUEUE),
    omit(['isPriceVisible', 'auctionDeadline']),
    formatAuctionDeadline
  ),
  omit(['auctionDeadlineTime', 'auctionDeadlineDate'])
)

export const normalizeImportOfferForSave = pipe(
  filterEmptyStrings,
  filterNullValues,
  evolve({
    pickupTime: v => `${v}:00`,
    unloadingTime: v => `${v}:00`,
    auctionDeadlineTime: v => `${v}:00`
  }),
  ifElse(
    propEq('type', OFFER_TYPES.QUEUE),
    omit(['isPriceVisible', 'auctionDeadline']),
    formatAuctionDeadline
  ),
  omit(['auctionDeadlineTime', 'auctionDeadlineDate'])
)

export const normalizePostImportOfferForSave = pipe(
  filterEmptyStrings,
  filterNullValues,
  evolve({
    availabilityTime: v => `${v}:00`,
    auctionDeadlineTime: v => `${v}:00`
  }),
  ifElse(
    propEq('type', OFFER_TYPES.QUEUE),
    omit(['isPriceVisible', 'auctionDeadline']),
    formatAuctionDeadline
  ),
  omit(['auctionDeadlineTime', 'auctionDeadlineDate'])
)

export const normalizeFreeCarriersOfferForSave = pipe(
  filterEmptyStrings,
  filterNullValues,
  evolve({
    availabilityTime: v => `${v}:00`,
    auctionDeadlineTime: v => `${v}:00`
  }),
  ifElse(
    propEq('type', OFFER_TYPES.QUEUE),
    omit(['isPriceVisible', 'auctionDeadline']),
    formatAuctionDeadline
  ),
  omit(['auctionDeadlineTime', 'auctionDeadlineDate'])
)

const normalizeTransportationsForSave = map(
  pipe(
    pick([
      'containerId',
      'shipownerId',
      'originLocationId',
      'destinationLocationId',
      'returnLocationId',
      'customsClearanceLocationId',
      'cargoTypeId',
      'adrClassId',
      'europeanEmissionStandard',
      'numberOfContainers',
      'weight'
    ]),
    filterEmptyStrings,
    filterNullValues
  )
)

export const normalizeContractOfferForSave = pipe(
  filterEmptyStrings,
  filterNullValues,
  evolve({
    transportations: normalizeTransportationsForSave
  })
)

export const normalizeToOfferTemplatesExport = arr =>
  arr.map(v => normalizeToOfferTemplateExport(v))

export const formatTemplateDate = date => {
  if (isFalsy(date)) {
    return null
  }

  return moment(date).isBefore(moment().format(DATE_API_FORMAT))
    ? moment().format(DATE_API_FORMAT)
    : moment(date).format(DATE_API_FORMAT)
}

export const normalizeToOfferTemplateExport = v => {
  const isLoadingDateInThePast =
    isFalsy(v.loadingDate) ||
    moment(v.loadingDate).isBefore(moment().format(DATE_API_FORMAT))
  return new OfferTemplateExport({
    ...v,
    loadingDate: formatTemplateDate(v.loadingDate),
    loadingTime: isFalsy(v.loadingTime) ? null : v.loadingTime.substring(0, 5),
    returnDate: isLoadingDateInThePast
      ? null
      : formatTemplateDate(v.returnDate),
    returnTime: isFalsy(v.returnTime) ? null : v.returnTime.substring(0, 5),
    price: isFalsy(v.price) ? null : v.price.float,
    customsClearanceLocationIdOpened: !isFalsy(v.customsClearanceLocationId),
    uuid: uuid(),
    templateDetails: new TemplateDetails({
      id: v.id,
      templateName: v.name,
      from: props(['countryCode', 'name', 'postalCode'])(
        v.loadingLocation
      ).join(', '),
      to: props(['countryCode', 'name', 'postalCode'])(v.returnLocation).join(
        ', '
      ),
      details: [v.container.name, v.shipowner.name]
    }),
    auctionDeadlineDate: getDateFromIsoString(v.auctionDeadline),
    auctionDeadlineTime: getTimeFromIsoString(v.auctionDeadline)
  })
}

export const normalizeToOfferTemplatesImport = arr =>
  arr.map(v => normalizeToOfferTemplateImport(v))

export const normalizeToOfferTemplateImport = v => {
  const isPickupDateInThePast =
    isFalsy(v.pickupDate) ||
    moment(v.pickupDate).isBefore(moment().format(DATE_API_FORMAT))
  return new OfferTemplateImport({
    ...v,
    pickupDate: formatTemplateDate(v.pickupDate),
    pickupTime: isFalsy(v.pickupTime) ? null : v.pickupTime.substring(0, 5),
    unloadingDate: isPickupDateInThePast
      ? null
      : formatTemplateDate(v.unloadingDate),
    unloadingTime: isFalsy(v.unloadingTime)
      ? null
      : v.unloadingTime.substring(0, 5),
    uuid: uuid(),
    price: isFalsy(v.price) ? null : v.price.float,
    returnLocationIdOpened: !isFalsy(v.returnLocationId),
    customsClearanceLocationIdOpened: !isFalsy(v.customsClearanceLocationId),
    templateDetails: new TemplateDetails({
      id: v.id,
      templateName: v.name,
      from: props(['countryCode', 'name', 'postalCode'])(v.pickupLocation).join(
        ', '
      ),
      to: props(['countryCode', 'name', 'postalCode'])(
        v.unloadingLocation
      ).join(', '),
      details: [v.container.name, v.shipowner.name]
    }),
    auctionDeadlineDate: getDateFromIsoString(v.auctionDeadline),
    auctionDeadlineTime: getTimeFromIsoString(v.auctionDeadline)
  })
}

export const normalizeToOfferTemplatesFreeCarriers = arr =>
  arr.map(v => normalizeToOfferTemplateFreeCarriers(v))

export const normalizeToOfferTemplateFreeCarriers = v =>
  new OfferTemplateFreeCarriers({
    ...v,
    availabilityDate: formatTemplateDate(v.availabilityDate),
    availabilityTime: isFalsy(v.availabilityTime)
      ? null
      : v.availabilityTime.substring(0, 5),
    uuid: uuid(),
    price: isFalsy(v.price) ? null : v.price.float,
    templateDetails: new TemplateDetails({
      id: v.id,
      templateName: v.name,
      from: props(['countryCode', 'name', 'postalCode'])(
        v.availabilityLocation
      ).join(', '),
      to: v.destinationLocation
        ? props(['countryCode', 'name', 'postalCode'])(
            v.destinationLocation
          ).join(', ')
        : '',
      details: [v.containerSemiTrailer.name]
    }),
    auctionDeadlineDate: getDateFromIsoString(v.auctionDeadline),
    auctionDeadlineTime: getTimeFromIsoString(v.auctionDeadline)
  })

export const normalizeToOfferTemplatesPostImport = arr =>
  arr.map(v => normalizeToOfferTemplatePostImport(v))

export const normalizeToOfferTemplatePostImport = v =>
  new OfferTemplatePostImport({
    ...v,
    availabilityDate: formatTemplateDate(v.availabilityDate),
    availabilityTime: isFalsy(v.availabilityTime)
      ? null
      : v.availabilityTime.substring(0, 5),
    uuid: uuid(),
    price: isFalsy(v.price) ? null : v.price.float,
    templateDetails: new TemplateDetails({
      id: v.id,
      templateName: v.name,
      from: props(['countryCode', 'name', 'postalCode'])(
        v.availabilityLocation
      ).join(', '),
      to: v.destinationLocation
        ? props(['countryCode', 'name', 'postalCode'])(
            v.destinationLocation
          ).join(', ')
        : '',
      details: [v.container.name, v.shipowner.name]
    }),
    auctionDeadlineDate: getDateFromIsoString(v.auctionDeadline),
    auctionDeadlineTime: getTimeFromIsoString(v.auctionDeadline)
  })

const isAuction = propEq('type', OFFER_TYPES.AUCTION)
const isNotTemplate = pathSatisfies(isNil, ['templateDetails', 'templateName'])

const isInPast = dateKey => template =>
  isFalsy(prop(dateKey, template)) ||
  moment(prop(dateKey, template)).isBefore(
    moment().format(DATE_API_FORMAT),
    'day'
  )

export const normalizeTemplateForExportForm = template =>
  pipe(
    when(allPass([isAuction, isNotTemplate]), omit(['price'])),
    when(isInPast('loadingDate'), omit(['loadingDate'])),
    when(isInPast('returnDate'), omit(['returnDate'])),
    when(isInPast('auctionDeadlineDate'), omit(['auctionDeadlineDate'])),
    omit([
      'container',
      'shipowner',
      'loadingLocation',
      'returnLocation',
      'templateDetails',
      'selected'
    ]),
    filterNullValues,
    filterEmptyStrings
  )(template.toJS())

export const normalizeTemplateForImportForm = template =>
  pipe(
    when(allPass([isAuction, isNotTemplate]), omit(['price'])),
    when(isInPast('pickupDate'), omit(['pickupDate'])),
    when(isInPast('unloadingDate'), omit(['unloadingDate'])),
    when(isInPast('auctionDeadlineDate'), omit(['auctionDeadlineDate'])),
    omit([
      'pickupLocation',
      'unloadingLocation',
      'returnLocation',
      'container',
      'shipowner',
      'templateDetails',
      'selected'
    ]),
    filterNullValues,
    filterEmptyStrings
  )(template.toJS())

export const normalizeTemplateForFreeCarriersForm = template =>
  pipe(
    when(allPass([isAuction, isNotTemplate]), omit(['price'])),
    when(isInPast('availabilityDate'), omit(['availabilityDate'])),
    when(isInPast('auctionDeadlineDate'), omit(['auctionDeadlineDate'])),
    omit([
      'availabilityLocation',
      'destinationLocation',
      'containerSemiTrailer',
      'templateDetails',
      'selected'
    ]),
    filterNullValues,
    filterEmptyStrings
  )(template.toJS())

export const normalizeTemplateForPostImportForm = template =>
  pipe(
    when(allPass([isAuction, isNotTemplate]), omit(['price'])),
    when(isInPast('availabilityDate'), omit(['availabilityDate'])),
    when(isInPast('auctionDeadlineDate'), omit(['auctionDeadlineDate'])),
    omit([
      'container',
      'availabilityLocation',
      'destinationLocation',
      'templateDetails',
      'selected'
    ]),
    filterNullValues,
    filterEmptyStrings
  )(template.toJS())
