import {
  normalizeContractExportOfferTimelineData,
  normalizeContractImportOfferTimelineData
} from 'src/features/stocks/ducks/normalizers'
import { ifElse, propEq, map, pipe, prop, when, not, includes } from 'ramda'
import { CONTRACTS_TYPES } from 'src/features/contracts/duck/consts'
import { renameKeysWith } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import { formatFilterKey } from 'src/utils/helpers'

//Stock
export const selectContractsStock = state => state.contracts.stockContracts
export const selectContractsOffers = state =>
  state.contracts.stockContracts.offers
export const selectContractsFilters = state =>
  state.contracts.stockContracts.filters
export const selectContractsPagination = state =>
  state.contracts.stockContracts.pagination
export const selectContractsOfferDetails = state =>
  state.contracts.stockContracts.offerDetails
export const selectIsContractsDrawerOpen = state =>
  state.contracts.stockContracts.isDrawerOpen
export const selectContractsOfferTimelineData = state => {
  const offerDetails = selectContractsOfferDetails(state)
  return ifElse(
    propEq('type', CONTRACTS_TYPES.EXPORT),
    pipe(
      prop('transportations'),
      map(normalizeContractExportOfferTimelineData)
    ),
    pipe(prop('transportations'), map(normalizeContractImportOfferTimelineData))
  )(offerDetails)
}
export const selectContractsButtonsState = state =>
  state.contracts.stockContracts.state.buttons

//My offers ongoing
export const selectMyOffersOnGoing = state => state.contracts.myOffersOngoing
export const selectMyContractsOffersOngoing = state =>
  state.contracts.myOffersOngoing.offers
export const selectMyContractsOngoingFilters = state =>
  state.contracts.myOffersOngoing.filters
export const selectMyContractsOngoingPagination = state =>
  state.contracts.myOffersOngoing.pagination
export const selectMyOffersContractsOngoingButtonsState = state =>
  state.contracts.myOffersOngoing.state.buttons
export const selectMyOffersContractsFiltersFormatted = state => {
  const filters = state.contracts.myOffersOngoing.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//My offers finished
export const selectMyOffersFinishedContractsFiltersFormatted = state => {
  const filters = state.contracts.myOffersFinished.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyContractsOffersFinished = state =>
  state.contracts.myOffersFinished.offers
export const selectMyContractsFinishedFilters = state =>
  state.contracts.myOffersFinished.filters
export const selectMyContractsFinishedPagination = state =>
  state.contracts.myOffersFinished.pagination
export const selectMyOffersContractsFinishedButtonsState = state =>
  state.contracts.myOffersFinished.state.buttons

//My reservations ongoing
export const selectContractsReservationsOngoing = state =>
  state.contracts.myReservationsOngoing
export const selectMyContractsReservationsOngoing = state =>
  state.contracts.myReservationsOngoing.offers
export const selectMyContractsReservationsOngoingFilters = state =>
  state.contracts.myReservationsOngoing.filters
export const selectMyContractsReservationsOngoingPagination = state =>
  state.contracts.myReservationsOngoing.pagination
export const selectMyReservationsContractsOngoingButtonsState = state =>
  state.contracts.myReservationsOngoing.state.buttons
export const selectMyReservationsContractsFiltersFormatted = state => {
  const filters = state.contracts.myReservationsOngoing.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

//My reservations finished
export const selectMyReservationsFinishedContractsFiltersFormatted = state => {
  const filters = state.contracts.myReservationsFinished.filters
  const omittedValues = ['sort', 'page', 'limit']
  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}
export const selectContractsReservationsFinished = state =>
  state.contracts.myReservationsFinished
export const selectMyContractsReservationsFinished = state =>
  state.contracts.myReservationsFinished.offers
export const selectMyContractsReservationsFinishedFilters = state =>
  state.contracts.myReservationsFinished.filters
export const selectMyContractsReservationsFinishedPagination = state =>
  state.contracts.myReservationsFinished.pagination
export const selectMyReservationsContractsFinishedButtonsState = state =>
  state.contracts.myReservationsFinished.state.buttons
