import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  takeLatest,
  select
} from '@redux-saga/core/effects'
import { isEmpty } from 'ramda'
import CompanyService from 'src/services/CompanyService'
import { ERROR_DELAY_TIME } from 'src/ducks/consts'
import {
  selectCompaniesBlacklist,
  selectCompaniesWhitelist,
  selectCompanyDetails
} from 'src/features/companies/duck/selectors'
import { getErrorMessageFromApiResponse } from 'src/utils/helpers'

export const searchCompanyRoutine = createRoutine('SEARCH_COMPANY')
export const clearCompanySearchResultRoutine = createRoutine(
  'CLEAR_COMPANY_SEARCH_RESULT'
)
export const showCompanyPublicDetailsRoutine = createRoutine(
  'SHOW_COMPANY_PUBLIC_DETAILS'
)
export const clearCompanyPublicDetailsRoutine = createRoutine(
  'CLEAR_COMPANY_PUBLIC_DETAILS'
)

export const getWhitelistedCompaniesRoutine = createRoutine(
  'GET_WHITELISTED_COMPANIES'
)

export const getBlacklistedCompaniesRoutine = createRoutine(
  'GET_BLACKLISTED_COMPANIES'
)

export const getAllMarkedCompaniesRoutine = createRoutine(
  'GET_ALL_MARKED_COMPANIES'
)

export const addCompanyToWhitelistRoutine = createRoutine(
  'ADD_COMPANY_TO_WHITELIST'
)
export const removeCompanyFromWhitelistRoutine = createRoutine(
  'REMOVE_COMPANY_FROM_WHITELIST'
)
export const addCompanyToBlacklistRoutine = createRoutine(
  'ADD_COMPANY_TO_BLACKLIST'
)
export const removeCompanyFromBlacklistRoutine = createRoutine(
  'REMOVE_COMPANY_FROM_BLACKLIST'
)

//actions

function* searchCompany({ payload }) {
  yield put(searchCompanyRoutine.request())
  try {
    const { data } = yield call(CompanyService.searchCompany, payload)
    isEmpty(data)
      ? yield put(searchCompanyRoutine.failure({ message: 'no-content' }))
      : yield put(searchCompanyRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(searchCompanyRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(searchCompanyRoutine.fulfill())
  }
}

function* showCompanyPublicDetails({ payload }) {
  yield put(showCompanyPublicDetailsRoutine.request())
  try {
    const { data } = yield call(CompanyService.showCompany, payload)
    yield put(showCompanyPublicDetailsRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(showCompanyPublicDetailsRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(showCompanyPublicDetailsRoutine.fulfill())
  }
}

function* getWhitelistedCompanies() {
  yield put(getWhitelistedCompaniesRoutine.request())
  try {
    const { data } = yield call(CompanyService.getWhitelistedCompanies)
    yield put(getWhitelistedCompaniesRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getWhitelistedCompaniesRoutine.failure({ message }))
  } finally {
    yield put(getWhitelistedCompaniesRoutine.fulfill())
  }
}

function* getBlacklistedCompanies() {
  yield put(getBlacklistedCompaniesRoutine.request())
  try {
    const { data } = yield call(CompanyService.getBlacklistedCompanies)
    yield put(getBlacklistedCompaniesRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getBlacklistedCompaniesRoutine.failure({ message }))
  } finally {
    yield put(getBlacklistedCompaniesRoutine.fulfill())
  }
}

function* getAllMarkedCompanies() {
  yield put(getAllMarkedCompaniesRoutine.request())
  try {
    yield call(getBlacklistedCompanies)
    yield call(getWhitelistedCompanies)
    const whitelist = yield select(selectCompaniesWhitelist)
    const blacklist = yield select(selectCompaniesBlacklist)
    yield put(
      getAllMarkedCompaniesRoutine.success([...whitelist, ...blacklist])
    )
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getAllMarkedCompaniesRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(getAllMarkedCompaniesRoutine.fulfill())
  }
}

function* addCompanyToWhitelist({ payload }) {
  yield put(addCompanyToWhitelistRoutine.request())
  try {
    const { data } = yield call(CompanyService.addCompanyToWhitelist, payload)
    yield put(addCompanyToWhitelistRoutine.success(data))
    const { id } = yield select(selectCompanyDetails)
    if (id) yield call(showCompanyPublicDetails, { payload: id })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(addCompanyToWhitelistRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(addCompanyToWhitelistRoutine.fulfill())
  }
}

function* addCompanyToBlacklist({ payload }) {
  yield put(addCompanyToBlacklistRoutine.request())
  try {
    const { data } = yield call(CompanyService.addCompanyToBlacklist, payload)
    yield put(addCompanyToBlacklistRoutine.success(data))
    const { id } = yield select(selectCompanyDetails)
    if (id) yield call(showCompanyPublicDetails, { payload: id })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(addCompanyToBlacklistRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(addCompanyToBlacklistRoutine.fulfill())
  }
}

function* removeCompanyFromWhitelist({ payload }) {
  yield put(removeCompanyFromWhitelistRoutine.request())
  try {
    yield call(CompanyService.deleteCompanyFromWhitelist, payload)
    yield put(removeCompanyFromWhitelistRoutine.success())
    const { id } = yield select(selectCompanyDetails)
    if (id) yield call(showCompanyPublicDetails, { payload: id })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(removeCompanyFromWhitelistRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(removeCompanyFromWhitelistRoutine.fulfill())
  }
}

function* removeCompanyFromBlacklist({ payload }) {
  yield put(removeCompanyFromBlacklistRoutine.request())
  try {
    yield call(CompanyService.deleteCompanyFromBlacklist, payload)
    yield put(removeCompanyFromBlacklistRoutine.success())
    const { id } = yield select(selectCompanyDetails)
    if (id) yield call(showCompanyPublicDetails, { payload: id })
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(removeCompanyFromBlacklistRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(removeCompanyFromBlacklistRoutine.fulfill())
  }
}

function* clearCompanySearchResult() {
  yield put(clearCompanySearchResultRoutine.success())
}

function* clearCompanyPublicDetails() {
  yield put(clearCompanyPublicDetailsRoutine.success())
}

//watchers

function* searchCompanyRoutineWatcher() {
  yield takeLatest(searchCompanyRoutine.TRIGGER, searchCompany)
}

function* showCompanyPublicDetailsRoutineWatcher() {
  yield takeLatest(
    showCompanyPublicDetailsRoutine.TRIGGER,
    showCompanyPublicDetails
  )
}

function* clearCompanySearchResultRoutineWatcher() {
  yield takeLatest(
    [
      clearCompanySearchResultRoutine.TRIGGER,
      addCompanyToWhitelistRoutine.SUCCESS,
      addCompanyToBlacklistRoutine.SUCCESS,
      removeCompanyFromWhitelistRoutine.SUCCESS,
      removeCompanyFromBlacklistRoutine.SUCCESS
    ],
    clearCompanySearchResult
  )
}

function* clearCompanyPublicDetailsRoutineWatcher() {
  yield takeLatest(
    clearCompanyPublicDetailsRoutine.TRIGGER,
    clearCompanyPublicDetails
  )
}

function* getWhitelistedCompaniesRoutineWatcher() {
  yield takeLatest(
    getWhitelistedCompaniesRoutine.TRIGGER,
    getWhitelistedCompanies
  )
}

function* getBlacklistedCompaniesRoutineWatcher() {
  yield takeLatest(
    getBlacklistedCompaniesRoutine.TRIGGER,
    getBlacklistedCompanies
  )
}

function* getAllMarkedCompaniesRoutineWatcher() {
  yield takeLatest(
    [
      getAllMarkedCompaniesRoutine.TRIGGER,
      addCompanyToWhitelistRoutine.SUCCESS,
      addCompanyToBlacklistRoutine.SUCCESS,
      removeCompanyFromWhitelistRoutine.SUCCESS,
      removeCompanyFromBlacklistRoutine.SUCCESS
    ],
    getAllMarkedCompanies
  )
}

function* addCompanyToWhitelistRoutineWatcher() {
  yield takeLatest(addCompanyToWhitelistRoutine.TRIGGER, addCompanyToWhitelist)
}

function* addCompanyToBlacklistRoutineWatcher() {
  yield takeLatest(addCompanyToBlacklistRoutine.TRIGGER, addCompanyToBlacklist)
}

function* removeCompanyFromWhitelistRoutineWatcher() {
  yield takeLatest(
    removeCompanyFromWhitelistRoutine.TRIGGER,
    removeCompanyFromWhitelist
  )
}

function* removeCompanyFromBlacklistRoutineWatcher() {
  yield takeLatest(
    removeCompanyFromBlacklistRoutine.TRIGGER,
    removeCompanyFromBlacklist
  )
}

export const companiesSagas = [
  fork(searchCompanyRoutineWatcher),
  fork(clearCompanySearchResultRoutineWatcher),
  fork(clearCompanyPublicDetailsRoutineWatcher),
  fork(showCompanyPublicDetailsRoutineWatcher),
  fork(getWhitelistedCompaniesRoutineWatcher),
  fork(getBlacklistedCompaniesRoutineWatcher),
  fork(getAllMarkedCompaniesRoutineWatcher),
  fork(addCompanyToWhitelistRoutineWatcher),
  fork(addCompanyToBlacklistRoutineWatcher),
  fork(removeCompanyFromBlacklistRoutineWatcher),
  fork(removeCompanyFromWhitelistRoutineWatcher)
]
