import { createRoutine } from 'redux-saga-routines'
import { call, delay, fork, put, takeLatest } from '@redux-saga/core/effects'
import TermsService from 'src/services/TermsService'
import { ERROR_DELAY_TIME } from 'src/ducks/consts'
import { getErrorMessageFromApiResponse } from 'src/utils/helpers'

export const getTermsOfServiceRoutine = createRoutine('GET_TERMS_OF_SERVICE')
export const acceptTermsOfServiceRoutine = createRoutine(
  'ACCEPT_TERMS_OF_SERVICE'
)

function* getTermsOfService() {
  yield put(getTermsOfServiceRoutine.request())
  try {
    const { data } = yield call(TermsService.getCurrentTerms)
    yield put(getTermsOfServiceRoutine.success(data))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(getTermsOfServiceRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getTermsOfServiceRoutine.fulfill())
  }
}

function* acceptTermsOfService() {
  yield put(acceptTermsOfServiceRoutine.request())
  try {
    yield call(TermsService.acceptTerms)
    yield put(acceptTermsOfServiceRoutine.success())
    window.location.reload()
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(acceptTermsOfServiceRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(acceptTermsOfServiceRoutine.fulfill())
  }
}

function* getTermsOfServiceRoutineWatcher() {
  yield takeLatest(getTermsOfServiceRoutine.TRIGGER, getTermsOfService)
}

function* acceptTermsOfServiceRoutineWatcher() {
  yield takeLatest(acceptTermsOfServiceRoutine.TRIGGER, acceptTermsOfService)
}

export const termsOfServiceSagas = [
  fork(getTermsOfServiceRoutineWatcher),
  fork(acceptTermsOfServiceRoutineWatcher)
]
