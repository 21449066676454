import { flatten, map, prop, sortBy, values } from 'ramda'
import { renameKeys } from 'ramda-adjunct'
import moment from 'moment'

export const selectOfferFormImportData = state => state.offerFormImport

export const selectOfferFormExportData = state => state.offerFormExport

export const selectOfferFormPostImportData = state => state.offerFormPostImport

export const selectOfferFormFreeCarriersData = state =>
  state.offerFormFreeCarriers

export const selectOfferFormContractData = state => state.offerFormContracts

export const selectMyOffers = state => state.myOffers

export const selectAllMyOffers = state => {
  const myOffers = selectMyOffers(state)
  const allMyOffersAsArray = flatten(
    map(prop('offers'), values(myOffers.toJS()))
  )
  const sortByCreatedAt = sortBy((a, b) =>
    moment(prop('addedToListingAt', b)).diff(
      moment(prop('addedToListingAt', a))
    )
  )
  const allMyOffersSorted = sortByCreatedAt(allMyOffersAsArray)

  //it is a temporary solution to display all types of offers on one list of
  //my offers. In the future it must be divided into specific exchanges.
  const unifyAllExchanges = map(
    renameKeys({
      pickupLocation: 'loadingLocation',
      pickupDate: 'loadingDate',
      pickupTime: 'loadingTime',
      unloadingLocation: 'returnLocation',
      unloadingDate: 'returnDate',
      unloadingTime: 'returnTime',
      availabilityLocation: 'loadingLocation',
      availabilityDate: 'loadingDate',
      availabilityTime: 'loadingTime',
      destinationLocation: 'returnLocation',
      containerSemiTrailer: 'container'
    })
  )

  return unifyAllExchanges(allMyOffersSorted)
}

export const selectMyCompanyExportLastOffers = state =>
  state.offerFormsScaffolds.lastOffersExport

export const selectMyCompanyImportLastOffers = state =>
  state.offerFormsScaffolds.lastOffersImport

export const selectMyCompanyFreeCarriersLastOffers = state =>
  state.offerFormsScaffolds.lastOffersFreeCarriers

export const selectMyCompanyPostImportLastOffers = state =>
  state.offerFormsScaffolds.lastOffersPostImport

export const selectOfferTemplatesExport = state =>
  state.offerFormsScaffolds.templatesExport

export const selectOfferTemplatesImport = state =>
  state.offerFormsScaffolds.templatesImport

export const selectOfferTemplatesFreeCarriers = state =>
  state.offerFormsScaffolds.templatesFreeCarriers

export const selectOfferTemplatesPostImport = state =>
  state.offerFormsScaffolds.templatesPostImport
