import { Record } from 'immutable'
import { API_STATES } from 'src/ducks/consts'
import { ExportOffer } from 'src/features/stocks/ducks/records'
import { Company } from 'src/features/companies/duck/records'

export const DebtConfirmation = Record({
  status: null,
  requestorId: null,
  creditorId: null,
  debtorId: null,
  content: '',
  referenceNumber: '',
  offerId: null,
  offerType: null,
  id: null,
  attachments: [],
  offer: null,
  creditor: Company(),
  debtor: Company()
})

export const DebtCollection = Record({
  state: API_STATES.PRISTINE,
  errorMessage: null,
  selectedOffer: ExportOffer(),
  additionalInfo: '',
  files: [],
  confirmation: DebtConfirmation()
})
