import { API_STATES } from 'src/ducks/consts'
import { Pagination } from 'src/ducks/records'
import { combineReducers } from 'redux'
import {
  Tracking,
  TrackingDetails,
  TrackingEdit,
  TrackingFilters
} from 'src/features/tracking/duck/records'
import {
  normalizeDrivers,
  normalizeOrderTrackingList,
  normalizeTrackingDetails,
  normalizeTrackingEdit
} from 'src/features/tracking/duck/normalizers'
import {
  getTrackingListOngoingRoutine,
  setTrackingOngoingFiltersRoutine,
  getTrackingListFinishedRoutine,
  setTrackingFinishedFiltersRoutine,
  getTrackingDetailsRoutine,
  generateDriverCredentialsRoutine,
  updateTrackingRoutine,
  clearEditFormRoutine,
  setEditTrackingFormRoutine,
  getDriversRoutine
} from './actions'

const trackingOngoingReducer = (state = new Tracking(), action) => {
  switch (action.type) {
    case getTrackingListOngoingRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getTrackingListOngoingRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getTrackingListOngoingRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getTrackingListOngoingRoutine.SUCCESS:
      return state
        .set('offers', normalizeOrderTrackingList(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setTrackingOngoingFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new TrackingFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const trackingDetailsReducer = (state = new TrackingDetails(), action) => {
  switch (action.type) {
    case getTrackingDetailsRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getTrackingDetailsRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case getTrackingDetailsRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case getTrackingDetailsRoutine.SUCCESS:
      return normalizeTrackingDetails(action.payload)
    case generateDriverCredentialsRoutine.REQUEST:
      return state.set('pinCodeState', API_STATES.IN_PROGRESS)
    case generateDriverCredentialsRoutine.SUCCESS:
      return state
        .set('pinCodeState', API_STATES.DONE)
        .set('transportationId', action.payload.transportationId)
        .set('pinCode', action.payload.pinCode)
    case generateDriverCredentialsRoutine.FULFILL:
      return state.set('pinCodeState', API_STATES.DONE)
    default:
      return state
  }
}

const trackingFinishedReducer = (state = new Tracking(), action) => {
  switch (action.type) {
    case getTrackingListFinishedRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getTrackingListFinishedRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getTrackingListFinishedRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getTrackingListFinishedRoutine.SUCCESS:
      return state
        .set('offers', normalizeOrderTrackingList(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setTrackingFinishedFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new TrackingFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const trackingEditReducer = (state = new TrackingEdit(), action) => {
  switch (action.type) {
    case setEditTrackingFormRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case getTrackingDetailsRoutine.SUCCESS:
      return normalizeTrackingEdit(action.payload)
    case updateTrackingRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case updateTrackingRoutine.FAILURE:
      return state.set('state', API_STATES.ERROR)
    case updateTrackingRoutine.FULFILL:
      return state.set('state', API_STATES.DONE)
    case updateTrackingRoutine.SUCCESS:
      return normalizeTrackingEdit(action.payload).set('state', API_STATES.DONE)
    case clearEditFormRoutine.SUCCESS:
      return new TrackingEdit()
    default:
      return state
  }
}

export const driversReducer = (state = [], action) => {
  switch (action.type) {
    case getDriversRoutine.SUCCESS:
      return normalizeDrivers(action.payload)
    default:
      return state
  }
}

export const trackingReducer = combineReducers({
  trackingOngoing: trackingOngoingReducer,
  trackingFinished: trackingFinishedReducer,
  trackingDetails: trackingDetailsReducer,
  trackingEdit: trackingEditReducer,
  drivers: driversReducer
})
