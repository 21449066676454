import { Record } from 'immutable'
import {
  ExportOffer,
  FreeCarrierOffer,
  ImportOffer,
  PostImportOffer,
  StockStates
} from 'src/features/stocks/ducks/records'
import { Contractor, Currency, Pagination } from 'src/ducks/records'
import {
  API_STATES,
  OFFERS_DEFAULT_LIMIT,
  OFFERS_DEFAULT_PAGE
} from 'src/ducks/consts'

export const MyReservationsFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: OFFERS_DEFAULT_LIMIT,
  onlyMe: 0
})

export const MyReservation = Record({
  state: StockStates(),
  errorMessage: null,
  reservations: [],
  isDrawerOpen: false,
  filters: MyReservationsFilters(),
  total: '',
  pagination: Pagination()
})

MyReservation.prototype.isLoading = function(key) {
  return this.state[key] === API_STATES.IN_PROGRESS
}

export const ExportReservation = Record({
  acceptedAt: null,
  addedToListingAt: null,
  annulledAt: null,
  annulledBy: null,
  annulmentReason: null,
  createdAt: null,
  createdAtFormatted: '',
  id: null,
  isCancellable: false,
  offer: ExportOffer(),
  offerId: null,
  offeror: Contractor(),
  offerorId: null,
  queuePlace: null,
  currency: null,
  price: Currency()
})

export const ImportReservation = Record({
  acceptedAt: null,
  addedToListingAt: null,
  annulledAt: null,
  annulledBy: null,
  annulmentReason: null,
  createdAt: null,
  createdAtFormatted: '',
  id: null,
  isCancellable: false,
  offer: ImportOffer(),
  offerId: null,
  offeror: Contractor(),
  offerorId: null,
  queuePlace: null,
  currency: null,
  price: Currency()
})

export const PostImportReservation = Record({
  acceptedAt: null,
  addedToListingAt: null,
  annulledAt: null,
  annulledBy: null,
  annulmentReason: null,
  createdAt: null,
  createdAtFormatted: '',
  id: null,
  isCancellable: false,
  offer: PostImportOffer(),
  offerId: null,
  offeror: Contractor(),
  offerorId: null,
  queuePlace: null,
  currency: null,
  price: Currency()
})

export const FreeCarrierReservation = Record({
  acceptedAt: null,
  addedToListingAt: null,
  annulledAt: null,
  annulledBy: null,
  annulmentReason: null,
  createdAt: null,
  createdAtFormatted: '',
  id: null,
  isCancellable: false,
  offer: FreeCarrierOffer(),
  offerId: null,
  offeror: Contractor(),
  offerorId: null,
  queuePlace: null,
  currency: null,
  price: Currency()
})
