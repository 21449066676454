import { combineReducers } from 'redux'
import { Pagination } from 'src/ducks/records'
import { MyOffers, MyOffersFilters } from 'src/features/myOffers/duck/records'
import {
  getMyOngoingExportOffersRoutine,
  setMyExportFiltersRoutine,
  setSelectedExportOfferRoutine,
  clearSelectedExportOffersRoutine,
  selectAllExportOffersRoutine,
  getMyFinishedExportOffersRoutine,
  setMyFinishedExportFiltersRoutine,
  showOngoingExportOfferReservationsRoutine,
  showFinishedExportOfferReservationsRoutine,
  createExportOfferTrackingRoutine
} from 'src/features/myOffers/duck/actions-export'
import {
  getMyOngoingImportOffersRoutine,
  setMyImportFiltersRoutine,
  showOngoingImportOfferReservationsRoutine,
  selectAllImportOffersRoutine,
  clearSelectedImportOffersRoutine,
  setSelectedImportOfferRoutine,
  showFinishedImportOfferReservationsRoutine,
  setMyFinishedImportFiltersRoutine,
  getMyFinishedImportOffersRoutine,
  createImportOfferTrackingRoutine
} from 'src/features/myOffers/duck/actions-import'
import {
  getMyOngoingPostImportOffersRoutine,
  setMyPostImportFiltersRoutine,
  showOngoingPostImportOfferReservationsRoutine,
  selectAllPostImportOffersRoutine,
  clearSelectedPostImportOffersRoutine,
  setSelectedPostImportOfferRoutine,
  showFinishedPostImportOfferReservationsRoutine,
  setMyFinishedPostImportFiltersRoutine,
  getMyFinishedPostImportOffersRoutine,
  createPostImportOfferTrackingRoutine
} from 'src/features/myOffers/duck/actions-post-import'
import {
  getMyOngoingFreeCarrierOffersRoutine,
  setMyFreeCarrierFiltersRoutine,
  showOngoingFreeCarrierOfferReservationsRoutine,
  selectAllFreeCarrierOffersRoutine,
  clearSelectedFreeCarrierOffersRoutine,
  setSelectedFreeCarrierOfferRoutine,
  showFinishedFreeCarrierOfferReservationsRoutine,
  setMyFinishedFreeCarrierFiltersRoutine,
  getMyFinishedFreeCarrierOffersRoutine,
  createFreeCarrierOfferTrackingRoutine
} from 'src/features/myOffers/duck/actions-free-carrier'

import {
  acceptExportReservationRoutine,
  rejectExportReservationRoutine,
  rejectImportReservationRoutine,
  acceptImportReservationRoutine,
  rejectPostImportReservationRoutine,
  rejectFreeCarrierReservationRoutine,
  acceptFreeCarrierReservationRoutine,
  acceptPostImportReservationRoutine
} from 'src/features/reservations/duck/actions'
import { toggleArrayItem } from 'src/utils/helpers'
import { propEq, prop, head, findIndex } from 'ramda'
import {
  normalizeReservations,
  normalizeMyExportOffers,
  normalizeMyImportOffers,
  normalizeMyFreeCarrierOffers,
  normalizeMyPostImportOffers
} from 'src/features/myOffers/duck/normalizers'
import { API_STATES } from 'src/ducks/consts'
import {
  refreshExportOfferRoutine,
  makeExportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-export'
import {
  refreshFreeCarrierOfferRoutine,
  makeFreeCarrierOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-free-carriers'
import {
  refreshImportOfferRoutine,
  makeImportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-import'
import {
  refreshPostImportOfferRoutine,
  makePostImportOfferPublicRoutine
} from 'src/features/stocks/ducks/actions-post-import'

//EXPORT
const myOffersExportOngoingReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyOngoingExportOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyOngoingExportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyOngoingExportOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyOngoingExportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyExportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyExportFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case setSelectedExportOfferRoutine.SUCCESS:
      return state.set(
        'selectedOffers',
        toggleArrayItem(state.selectedOffers, action.payload.id)
      )
    case clearSelectedExportOffersRoutine.SUCCESS:
      return state.set('selectedOffers', [])
    case selectAllExportOffersRoutine.SUCCESS:
      return state.set('selectedOffers', action.payload.ids)
    case showOngoingExportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingExportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingExportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingExportOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [offerIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectExportReservationRoutine.REQUEST:
    case acceptExportReservationRoutine.REQUEST:
    case createExportOfferTrackingRoutine.REQUEST:
    case refreshExportOfferRoutine.REQUEST:
    case makeExportOfferPublicRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectExportReservationRoutine.FAILURE:
    case acceptExportReservationRoutine.FAILURE:
    case createExportOfferTrackingRoutine.FAILURE:
    case refreshExportOfferRoutine.FAILURE:
    case makeExportOfferPublicRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptExportReservationRoutine.FULFILL:
    case rejectExportReservationRoutine.FULFILL:
    case acceptExportReservationRoutine.SUCCESS:
    case createExportOfferTrackingRoutine.FULFILL:
    case createExportOfferTrackingRoutine.SUCCESS:
    case refreshExportOfferRoutine.FULFILL:
    case refreshExportOfferRoutine.SUCCESS:
    case makeExportOfferPublicRoutine.SUCCESS:
    case makeExportOfferPublicRoutine.FULFILL:
    case rejectExportReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

const myOffersExportFinishedReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyFinishedExportOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyFinishedExportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyFinishedExportOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyFinishedExportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyExportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFinishedExportFiltersRoutine.SUCCESS:
      const filtersFinished =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filtersFinished
          })
      )
    case showFinishedExportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showFinishedExportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showFinishedExportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showFinishedExportOfferReservationsRoutine.SUCCESS:
      const finishedOfferIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [finishedOfferIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectExportReservationRoutine.REQUEST:
    case acceptExportReservationRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectExportReservationRoutine.FAILURE:
    case acceptExportReservationRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptExportReservationRoutine.FULFILL:
    case rejectExportReservationRoutine.FULFILL:
    case acceptExportReservationRoutine.SUCCESS:
    case rejectExportReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

//IMPORT
const myOffersImportOngoingReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyOngoingImportOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyOngoingImportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyOngoingImportOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyOngoingImportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyImportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyImportFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case setSelectedImportOfferRoutine.SUCCESS:
      return state.set(
        'selectedOffers',
        toggleArrayItem(state.selectedOffers, action.payload.id)
      )
    case clearSelectedImportOffersRoutine.SUCCESS:
      return state.set('selectedOffers', [])
    case selectAllImportOffersRoutine.SUCCESS:
      return state.set('selectedOffers', action.payload.ids)
    case showOngoingImportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingImportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingImportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingImportOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [offerIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectImportReservationRoutine.REQUEST:
    case acceptImportReservationRoutine.REQUEST:
    case createImportOfferTrackingRoutine.REQUEST:
    case refreshImportOfferRoutine.REQUEST:
    case makeImportOfferPublicRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectImportReservationRoutine.FAILURE:
    case acceptImportReservationRoutine.FAILURE:
    case createImportOfferTrackingRoutine.FAILURE:
    case refreshImportOfferRoutine.FAILURE:
    case makeImportOfferPublicRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptImportReservationRoutine.FULFILL:
    case createImportOfferTrackingRoutine.FULFILL:
    case rejectImportReservationRoutine.FULFILL:
    case acceptImportReservationRoutine.SUCCESS:
    case createImportOfferTrackingRoutine.SUCCESS:
    case refreshImportOfferRoutine.FULFILL:
    case refreshImportOfferRoutine.SUCCESS:
    case makeImportOfferPublicRoutine.SUCCESS:
    case makeImportOfferPublicRoutine.FULFILL:
    case rejectImportReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

const myOffersImportFinishedReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyFinishedImportOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyFinishedImportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyFinishedImportOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyFinishedImportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyImportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFinishedImportFiltersRoutine.SUCCESS:
      const filtersFinished =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filtersFinished
          })
      )
    case showFinishedImportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showFinishedImportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showFinishedImportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showFinishedImportOfferReservationsRoutine.SUCCESS:
      const finishedOfferIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [finishedOfferIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectImportReservationRoutine.REQUEST:
    case acceptImportReservationRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectImportReservationRoutine.FAILURE:
    case acceptImportReservationRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptImportReservationRoutine.FULFILL:
    case rejectImportReservationRoutine.FULFILL:
    case acceptImportReservationRoutine.SUCCESS:
    case rejectImportReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

//POST IMPORT
const myOffersPostImportOngoingReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyOngoingPostImportOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyOngoingPostImportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyOngoingPostImportOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyOngoingPostImportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyPostImportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyPostImportFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case setSelectedPostImportOfferRoutine.SUCCESS:
      return state.set(
        'selectedOffers',
        toggleArrayItem(state.selectedOffers, action.payload.id)
      )
    case clearSelectedPostImportOffersRoutine.SUCCESS:
      return state.set('selectedOffers', [])
    case selectAllPostImportOffersRoutine.SUCCESS:
      return state.set('selectedOffers', action.payload.ids)
    case showOngoingPostImportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingPostImportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingPostImportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingPostImportOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [offerIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectPostImportReservationRoutine.REQUEST:
    case acceptPostImportReservationRoutine.REQUEST:
    case refreshPostImportOfferRoutine.REQUEST:
    case makePostImportOfferPublicRoutine.REQUEST:
    case createPostImportOfferTrackingRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectPostImportReservationRoutine.FAILURE:
    case acceptPostImportReservationRoutine.FAILURE:
    case refreshPostImportOfferRoutine.FAILURE:
    case makePostImportOfferPublicRoutine.FAILURE:
    case createPostImportOfferTrackingRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptPostImportReservationRoutine.FULFILL:
    case rejectPostImportReservationRoutine.FULFILL:
    case acceptPostImportReservationRoutine.SUCCESS:
    case refreshPostImportOfferRoutine.FULFILL:
    case makePostImportOfferPublicRoutine.FULFILL:
    case createPostImportOfferTrackingRoutine.FULFILL:
    case refreshPostImportOfferRoutine.SUCCESS:
    case makePostImportOfferPublicRoutine.SUCCESS:
    case createPostImportOfferTrackingRoutine.SUCCESS:
    case rejectPostImportReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

const myOffersPostImportFinishedReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyFinishedPostImportOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyFinishedPostImportOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyFinishedPostImportOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyFinishedPostImportOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyPostImportOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFinishedPostImportFiltersRoutine.SUCCESS:
      const filtersFinished =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filtersFinished
          })
      )
    case showFinishedPostImportOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showFinishedPostImportOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showFinishedPostImportOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showFinishedPostImportOfferReservationsRoutine.SUCCESS:
      const finishedOfferIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [finishedOfferIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectPostImportReservationRoutine.REQUEST:
    case acceptPostImportReservationRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectPostImportReservationRoutine.FAILURE:
    case acceptPostImportReservationRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptPostImportReservationRoutine.FULFILL:
    case rejectPostImportReservationRoutine.FULFILL:
    case acceptPostImportReservationRoutine.SUCCESS:
    case rejectPostImportReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

//FREE CARRIER
const myOffersFreeCarrierOngoingReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyOngoingFreeCarrierOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyOngoingFreeCarrierOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyOngoingFreeCarrierOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyOngoingFreeCarrierOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyFreeCarrierOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFreeCarrierFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case setSelectedFreeCarrierOfferRoutine.SUCCESS:
      return state.set(
        'selectedOffers',
        toggleArrayItem(state.selectedOffers, action.payload.id)
      )
    case clearSelectedFreeCarrierOffersRoutine.SUCCESS:
      return state.set('selectedOffers', [])
    case selectAllFreeCarrierOffersRoutine.SUCCESS:
      return state.set('selectedOffers', action.payload.ids)
    case showOngoingFreeCarrierOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingFreeCarrierOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingFreeCarrierOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingFreeCarrierOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [offerIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectFreeCarrierReservationRoutine.REQUEST:
    case acceptFreeCarrierReservationRoutine.REQUEST:
    case createFreeCarrierOfferTrackingRoutine.REQUEST:
    case refreshFreeCarrierOfferRoutine.REQUEST:
    case makeFreeCarrierOfferPublicRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectFreeCarrierReservationRoutine.FAILURE:
    case acceptFreeCarrierReservationRoutine.FAILURE:
    case createFreeCarrierOfferTrackingRoutine.FAILURE:
    case refreshFreeCarrierOfferRoutine.FAILURE:
    case makeFreeCarrierOfferPublicRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptFreeCarrierReservationRoutine.FULFILL:
    case createFreeCarrierOfferTrackingRoutine.FULFILL:
    case rejectFreeCarrierReservationRoutine.FULFILL:
    case acceptFreeCarrierReservationRoutine.SUCCESS:
    case createFreeCarrierOfferTrackingRoutine.SUCCESS:
    case refreshFreeCarrierOfferRoutine.FULFILL:
    case refreshFreeCarrierOfferRoutine.SUCCESS:
    case makeFreeCarrierOfferPublicRoutine.SUCCESS:
    case makeExportOfferPublicRoutine.FULFILL:
    case rejectFreeCarrierReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

const myOffersFreeCarrierFinishedReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyFinishedFreeCarrierOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyFinishedFreeCarrierOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyFinishedFreeCarrierOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyFinishedFreeCarrierOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeMyFreeCarrierOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFinishedFreeCarrierFiltersRoutine.SUCCESS:
      const filtersFinished =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filtersFinished
          })
      )
    case showFinishedFreeCarrierOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showFinishedFreeCarrierOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showFinishedFreeCarrierOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showFinishedFreeCarrierOfferReservationsRoutine.SUCCESS:
      const finishedOfferIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state
        .setIn(
          ['offers', [finishedOfferIndex], 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case rejectFreeCarrierReservationRoutine.REQUEST:
    case acceptFreeCarrierReservationRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case rejectFreeCarrierReservationRoutine.FAILURE:
    case acceptFreeCarrierReservationRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptFreeCarrierReservationRoutine.FULFILL:
    case rejectFreeCarrierReservationRoutine.FULFILL:
    case acceptFreeCarrierReservationRoutine.SUCCESS:
    case rejectFreeCarrierReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

export const myOffersReducer = combineReducers({
  exportOngoing: myOffersExportOngoingReducer,
  exportFinished: myOffersExportFinishedReducer,
  importOngoing: myOffersImportOngoingReducer,
  importFinished: myOffersImportFinishedReducer,
  freeCarrierOngoing: myOffersFreeCarrierOngoingReducer,
  freeCarrierFinished: myOffersFreeCarrierFinishedReducer,
  postImportOngoing: myOffersPostImportOngoingReducer,
  postImportFinished: myOffersPostImportFinishedReducer
})
