import { Record } from 'immutable'
import { Image } from 'src/features/account/duck/records'
import { EXCHANGE_TYPES } from './consts'

export const Currency = Record({
  amount: '',
  currency: '',
  formatted: '',
  float: 0
})

export const Container = Record({
  id: null,
  name: ''
})

export const Shipowner = Record({
  id: null,
  name: ''
})

export const CargoType = Record({
  id: null,
  name: ''
})

export const AdrClass = Record({
  id: null,
  class: '',
  name: ''
})

export const ContainerSemiTrailer = Record({
  id: null,
  name: ''
})

export const Document = Record({
  type: null,
  url: null,
  validFrom: null,
  ValidTo: null
})

export const Company = Record({
  id: null,
  name: '',
  address: '',
  city: '',
  postalCode: '',
  countryCode: '',
  phoneNumber: '',
  documents: [],
  isWhitelisted: false,
  isBlacklisted: false,
  isTopContainerCertified: false,
  hasPaymentIssues: false
})

export const Contractor = Record({
  id: null,
  companyId: null,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  isActive: false,
  contactInfo: null,
  communicators: null,
  lang: '',
  company: Company(),
  avatar: Image()
})

export const Location = Record({
  id: null,
  name: '',
  postalCode: '',
  countryCode: '',
  lat: null,
  long: null,
  type: null
})

export const DictionaryEntry = Record({
  label: '',
  value: null,
  type: null
})

export const Dictionary = Record({
  values: [],
  page: 1,
  limit: 100,
  name: ''
})

export const LocationDictionaries = Record({
  loading: new Dictionary({ name: 'loading' }),
  unloading: new Dictionary({ name: 'unloading' }),
  pickup: new Dictionary({ name: 'pickup' }),
  return: new Dictionary({ name: 'return' }),
  availability: new Dictionary({ name: 'availability' }),
  destination: new Dictionary({ name: 'destination' })
})

export const Dictionaries = Record({
  containers: [],
  containerSemiTrailers: [],
  shipowners: [],
  locations: LocationDictionaries(),
  companies: new Dictionary({ name: 'company' }),
  cargoTypes: [],
  adrClasses: [],
  contactCategories: [],
  gtuCodes: []
})

export const Pagination = Record({
  currentPage: null,
  from: null,
  lastPage: null,
  path: null,
  perPage: null,
  to: null,
  total: 0
})

export const ExchangeCounters = Record({
  [EXCHANGE_TYPES.EXPORT]: 0,
  [EXCHANGE_TYPES.IMPORT]: 0,
  [EXCHANGE_TYPES.POST_IMPORT_CONTAINERS]: 0,
  [EXCHANGE_TYPES.FREE_CARRIERS]: 0,
  [EXCHANGE_TYPES.CONTRACTS]: 0
})

export const Snackbar = Record({
  message: '',
  title: '',
  open: false,
  type: 'success'
})

export const EUFinancingDialog = Record({
  open: false
})
