import { Record } from 'immutable'
import { API_STATES } from 'src/ducks/consts'
import ExampleUserAvatarUrl from 'src/assets/avatar-placeholder.svg'

export const Message = Record({
  id: null,
  text: '',
  avatar: ExampleUserAvatarUrl,
  userName: '',
  isInterlocutorMessage: false,
  isSystemMessage: false,
  createdAt: null,
  seenAt: null
})

export const Context = Record({
  id: null,
  from: '',
  fromDate: '',
  fromTime: '',
  to: '',
  toDate: '',
  toTime: '',
  referenceNumber: null,
  container: '',
  shipowner: '',
  companyName: '',
  belongsToTheCurrentCompany: false,
  isReservable: false,
  reservationsCount: 0,
  contextType: null,
  contextId: null,
  reserveActionAvailableAt: null,
  bidActionAvailableAt: null,
  doesRequireTracking: null,
  outcome: null
})

export const LastMessage = Record({
  id: null,
  contextId: null,
  contextType: null,
  interlocutorId: null,
  avatar: ExampleUserAvatarUrl,
  text: '',
  userName: '',
  companyName: '',
  isInterlocutorMessage: false,
  messageSeen: false,
  messageSeenAt: null,
  messageType: null,
  createdAt: '',
  context: Context()
})

export const FoundUser = Record({
  id: null,
  userName: '',
  companyName: '',
  avatar: ExampleUserAvatarUrl
})

export const Interlocutor = Record({
  avatar: ExampleUserAvatarUrl,
  firstName: '',
  lastName: '',
  id: null,
  companyName: '',
  companyId: null
})

export const Conversation = Record({
  receiverId: null,
  contextId: null,
  contextType: null,
  conversationId: null,
  messages: [],
  interlocutor: new Interlocutor(),
  isConversationMuted: false,
  isAvailable: false,

  //context
  context: null,
  contextActions: {},
  contextState: API_STATES.DONE
})

export const Messages = Record({
  listState: API_STATES.DONE,
  listOpened: false,
  conversationState: API_STATES.PRISTINE,
  conversationOpened: false,
  lastRequest: {},

  //counters
  allUnreadMessagesCount: 0,
  contextualMessagesCount: 0,
  personalMessagesCount: 0,

  //lists
  personalMessages: [],
  personalMessagesPage: 1,
  personalMessagesAllFetched: false,

  contextualMessages: [],
  contextualMessagesPage: 1,
  contextualMessagesAllFetched: false,

  //search results
  personalSearchResults: [],
  personalSearchResultsPage: 1,
  personalSearchResultsAllFetched: false,

  contextualSearchResults: [],
  contextualSearchResultsPage: 1,
  contextualSearchResultsAllFetched: false,

  limit: 25,
  //conversation related fields
  conversation: Conversation(),

  onlineUsers: {},

  ongoing: false
})
