class SessionStorageService {
  get(key) {
    if (typeof window === 'undefined') return null
    return sessionStorage.getItem(key)
  }

  set(key, value) {
    return sessionStorage.setItem(key, value)
  }

  remove(key) {
    return sessionStorage.removeItem(key)
  }

  clearAll() {
    sessionStorage.clear()
  }
}

export { SessionStorageService }
export default new SessionStorageService()
