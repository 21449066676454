import { parseFiltersToQuery, getOriginQueryParams } from 'src/utils/helpers'
import api from './APIInterceptor'

export default class ContractService {
  static getContractOffersList(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/contracts/offers/?${query}`)
  }

  static getContractOffer({ id, query = {} }) {
    const _paramString = getOriginQueryParams(query)
    return api.get(`/exchange/contracts/offers/${id}${_paramString}`)
  }

  static reserveContractOffer({ id, values }) {
    return api.post(`/exchange/contracts/offers/${id}/reservations`, values)
  }

  static getMyOngoingContractOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/contracts/offers/ongoing?${query}`)
  }

  static getMyFinishedContractOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/contracts/offers/finished?${query}`)
  }

  static getMyOngoingContractReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/contracts/offers/ongoing/reservations?${query}`)
  }

  static getMyFinishedContractReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/contracts/offers/finished/reservations?${query}`)
  }

  static deleteContractOffer(id) {
    return api.delete(`/exchange/contracts/offers/${id}`)
  }

  static createContractOffer(payload) {
    return api.post('/exchange/contracts/offers', payload)
  }

  static acceptContractReservation(offerId, reservationId) {
    return api.get(
      `/exchange/contracts/offers/${offerId}/reservations/${reservationId}/accept`
    )
  }

  static cancelContractReservation(offerId, reservationId) {
    return api.post(
      `/exchange/contracts/offers/${offerId}/reservations/${reservationId}/cancel`
    )
  }

  static showOngoingContractOfferReservations(id) {
    return api.get(`/me/contracts/offers/ongoing/${id}/reservations`)
  }

  static showFinishedContractOfferReservations(id) {
    return api.get(`/me/contracts/offers/finished/${id}/reservations`)
  }
}
