import { createRoutine } from 'redux-saga-routines'
import {
  fork,
  put,
  take,
  takeLatest,
  call,
  spawn,
  select
} from '@redux-saga/core/effects'
import { eventChannel } from 'redux-saga'
import qs from 'qs'
import { omit } from 'ramda'
import NotificationsService from 'src/services/NotificationsService'
import LaravelEchoService from 'src/services/LaravelEchoService'

import { NOTIFICATION_EVENT_TYPES } from './consts'
import { selectNotificationsDetails } from './selectors'

export const setNotificationsListOpenedRoutine = createRoutine(
  'SET_NOTIFICATIONS_LIST_OPENED'
)
export const getNotificationsListRoutine = createRoutine(
  'GET_NOTIFICATIONS_LIST'
)
export const getStickyNotificationsListRoutine = createRoutine(
  'GET_STICKY_NOTIFICATIONS_LIST'
)
export const getUnseenNotificationsCountRoutine = createRoutine(
  'GET_UNSEEN_NOTIFICATIONS_COUNT'
)
export const markAllNotificationsAsReadRoutine = createRoutine(
  'MARK_ALL_NOTIFICATIONS_AS_READ'
)

export const markNotificationAsReadRoutine = createRoutine(
  'MARK_NOTIFICATION_AS_READ'
)

export const markAllNotificationsAsSeenRoutine = createRoutine(
  'MARK_ALL_NOTIFICATIONS_AS_SEEN'
)
export const clearNotificationsRoutine = createRoutine('CLEAR_NOTIFICATIONS')

export const getNotificationsSettingsRoutine = createRoutine(
  'GET_NOTIFICATIONS_SETTINGS'
)

export const updateNotificationSettingRoutine = createRoutine(
  'UPDATE_NOTIFICATION_SETTING'
)

export const NOTIFICATION_EVENT_USER_NOTIFICATION_SENT =
  'NOTIFICATION_EVENT_USER_NOTIFICATION_SENT'

//actions

function* setNotificationsListOpened({ payload }) {
  yield put(setNotificationsListOpenedRoutine.success(payload))
}

function* getNotificationsList({ payload }) {
  yield put(getNotificationsListRoutine.request())
  try {
    const notifications = yield select(selectNotificationsDetails)
    const query = qs.stringify(
      {
        page: payload.page || notifications.page,
        limit: notifications.limit
      },
      { skipNull: true }
    )
    if (!notifications.allFetched) {
      const response = yield call(
        NotificationsService.getNotificationsList,
        query
      )

      yield put(
        getNotificationsListRoutine.success({
          data: response.data,
          meta: response.response.meta,
          append: payload.append
        })
      )
    }
    yield put(getNotificationsListRoutine.fulfill())
  } catch (e) {
    console.log('error', e)
    yield put(getNotificationsListRoutine.failure(e))
  }
}

function* getStickyNotificationsList() {
  yield put(getStickyNotificationsListRoutine.request())
  try {
    const query = qs.stringify(
      {
        page: 1,
        limit: 20,
        filter: {
          only_sticky: true
        }
      },
      { skipNull: true }
    )

    const response = yield call(
      NotificationsService.getNotificationsList,
      query
    )

    yield put(getStickyNotificationsListRoutine.success(response.data))
  } catch (e) {
    console.log('error', e)
    yield put(getStickyNotificationsListRoutine.failure(e))
  } finally {
    yield put(getStickyNotificationsListRoutine.fulfill())
  }
}

function listenNotificationsEvents(userId) {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToNotificationEvents(
      userId,
      (type, payload) => {
        switch (type) {
          case NOTIFICATION_EVENT_TYPES.NOTIFICATION_SENT:
            return emitter({
              type: NOTIFICATION_EVENT_USER_NOTIFICATION_SENT,
              payload
            })
          default:
            //handle information about not handled type
            return null
        }
      }
    )
    // unsubscribe function
    return () => {}
  })
}

export function* getUnseenNotificationsCount() {
  try {
    const { data } = yield call(
      NotificationsService.getUnseenNotificationsCount
    )
    yield put(getUnseenNotificationsCountRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

export function* markAllNotificationsAsRead() {
  try {
    yield call(NotificationsService.markAllNotificationsAsRead)
    yield spawn(getUnseenNotificationsCount)
    yield spawn(getNotificationsList, { payload: { page: 1 } })
  } catch (e) {
    console.log(e)
  }
}

export function* markNotificationAsRead({ payload }) {
  try {
    yield call(NotificationsService.markNotificationAsRead, payload.id)
    yield put(markNotificationAsReadRoutine.success({ id: payload.id }))
  } catch (e) {
    console.log(e)
  }
}

export function* markAllNotificationsAsSeen() {
  try {
    yield call(NotificationsService.markAllNotificationsAsSeen)
    yield spawn(getUnseenNotificationsCount)
  } catch (e) {
    console.log(e)
  }
}

export function* getNotificationsSettings() {
  yield put(getNotificationsSettingsRoutine.request())
  try {
    const { data } = yield call(NotificationsService.getNotificationsSettings)
    yield put(getNotificationsSettingsRoutine.success({ data }))
  } catch (e) {
    yield put(getNotificationsSettingsRoutine.failure())
    console.log(e)
  }
}

export function* clearNotifications() {
  yield put(clearNotificationsRoutine.success())
}

export function* updateNotificationSetting({ payload }) {
  yield put(updateNotificationSettingRoutine.request(payload))
  try {
    yield call(NotificationsService.updateNotificationSettings, [
      omit(['label'], payload.toJS())
    ])
    yield call(getNotificationsSettings)
  } catch (e) {
    console.log(e)
  }
}

//watchers

export function* listenNotificationsEventsWatcher(userId) {
  //listen to websockets channel
  const channel = yield call(listenNotificationsEvents, userId)
  while (true) {
    const action = yield take(channel)
    //fetch unseen notifications number
    yield spawn(getUnseenNotificationsCount)
    yield put(action)
  }
}

function* setNotificationsListOpenedRoutineWatcher() {
  yield takeLatest(
    setNotificationsListOpenedRoutine.TRIGGER,
    setNotificationsListOpened
  )
}

function* getNotificationsListRoutineWatcher() {
  yield takeLatest(getNotificationsListRoutine.TRIGGER, getNotificationsList)
}

function* getStickyNotificationsListRoutineWatcher() {
  yield takeLatest(
    getStickyNotificationsListRoutine.TRIGGER,
    getStickyNotificationsList
  )
}

function* getUnseenNotificationsCountRoutineWatcher() {
  yield takeLatest(
    getUnseenNotificationsCountRoutine.TRIGGER,
    getUnseenNotificationsCount
  )
}

function* markAllNotificationsAsReadRoutineWatcher() {
  yield takeLatest(
    markAllNotificationsAsReadRoutine.TRIGGER,
    markAllNotificationsAsRead
  )
}

function* markAllNotificationsAsSeenRoutineWatcher() {
  yield takeLatest(
    markAllNotificationsAsSeenRoutine.TRIGGER,
    markAllNotificationsAsSeen
  )
}

function* clearNotificationsRoutineWatcher() {
  yield takeLatest(clearNotificationsRoutine.TRIGGER, clearNotifications)
}

function* markNotificationAsReadRoutineWatcher() {
  yield takeLatest(
    markNotificationAsReadRoutine.TRIGGER,
    markNotificationAsRead
  )
}

function* getNotificationsSettingsRoutineWatcher() {
  yield takeLatest(
    getNotificationsSettingsRoutine.TRIGGER,
    getNotificationsSettings
  )
}

function* updateNotificationSettingRoutineWatcher() {
  yield takeLatest(
    updateNotificationSettingRoutine.TRIGGER,
    updateNotificationSetting
  )
}

export const notificationsSagas = [
  fork(setNotificationsListOpenedRoutineWatcher),
  fork(getNotificationsListRoutineWatcher),
  fork(getUnseenNotificationsCountRoutineWatcher),
  fork(markAllNotificationsAsReadRoutineWatcher),
  fork(markAllNotificationsAsSeenRoutineWatcher),
  fork(clearNotificationsRoutineWatcher),
  fork(markNotificationAsReadRoutineWatcher),
  fork(getNotificationsSettingsRoutineWatcher),
  fork(updateNotificationSettingRoutineWatcher),
  fork(getStickyNotificationsListRoutineWatcher)
]
