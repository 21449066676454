import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  takeLatest,
  select
} from '@redux-saga/core/effects'
import { navigate } from 'gatsby-plugin-intl'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import { ERROR_DELAY_TIME } from 'src/ducks/consts'
import {
  selectAdditionalInfo,
  selectDebtCollectionOfferId,
  selectFiles
} from 'src/features/debtCollection/duck/selectors'
import ExchangeService from 'src/services/ExchangeService'
import { getErrorMessageFromApiResponse } from 'src/utils/helpers'

export const selectOfferForDebtCollectionRoutine = createRoutine(
  'SELECT_OFFER_FOR_DEBT_COLLECTION'
)
export const setAdditionalInfoRoutine = createRoutine('SET_ADDITIONAL_INFO')

export const setDebtCollectionFilesRoutine = createRoutine(
  'SET_DEBT_COLLECTION_FILES'
)

export const createDebtCollectionExportRoutine = createRoutine(
  'CREATE_DEBT_COLLECTION_EXPORT'
)

export const createDebtCollectionImportRoutine = createRoutine(
  'CREATE_DEBT_COLLECTION_IMPORT'
)

export const createDebtCollectionPostImportRoutine = createRoutine(
  'CREATE_DEBT_COLLECTION_POST_IMPORT'
)

export const createDebtCollectionFreeCarriersRoutine = createRoutine(
  'CREATE_DEBT_COLLECTION_FREE_CARRIERS'
)

export const clearDebtCollectionRoutine = createRoutine('CLEAR_DEBT_COLLECTION')

//actions

function* selectOfferForDebtCollection({ payload }) {
  yield put(selectOfferForDebtCollectionRoutine.success(payload))
  navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.DEBT_COLLECTION}`)
}

function* setAdditionalInfo({ payload }) {
  yield put(setAdditionalInfoRoutine.success(payload))
}

function* setDebtCollectionFiles({ payload }) {
  yield put(setDebtCollectionFilesRoutine.success(payload))
}

function* clearDebtCollection() {
  yield put(clearDebtCollectionRoutine.success())
}

function* createDebtCollectionExport() {
  yield put(createDebtCollectionExportRoutine.request())
  try {
    const id = yield select(selectDebtCollectionOfferId)
    const files = yield select(selectFiles)
    const content = yield select(selectAdditionalInfo)

    const { data } = yield call(
      ExchangeService.createDebtCollectionExport,
      id,
      { files, content }
    )

    yield put(createDebtCollectionExportRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(createDebtCollectionExportRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(createDebtCollectionExportRoutine.fulfill())
  }
}

function* createDebtCollectionImport() {
  yield put(createDebtCollectionImportRoutine.request())
  try {
    const id = yield select(selectDebtCollectionOfferId)
    const files = yield select(selectFiles)
    const content = yield select(selectAdditionalInfo)
    const { data } = yield call(
      ExchangeService.createDebtCollectionImport,
      id,
      { files, content }
    )
    yield put(createDebtCollectionImportRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(createDebtCollectionImportRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(createDebtCollectionImportRoutine.fulfill())
  }
}

function* createDebtCollectionPostImport() {
  yield put(createDebtCollectionPostImportRoutine.request())
  try {
    const id = yield select(selectDebtCollectionOfferId)
    const files = yield select(selectFiles)
    const content = yield select(selectAdditionalInfo)
    const { data } = yield call(
      ExchangeService.createDebtCollectionPostImport,
      id,
      { files, content }
    )
    yield put(createDebtCollectionPostImportRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(createDebtCollectionPostImportRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(createDebtCollectionPostImportRoutine.fulfill())
  }
}

function* createDebtCollectionFreeCarriers() {
  yield put(createDebtCollectionFreeCarriersRoutine.request())
  try {
    const id = yield select(selectDebtCollectionOfferId)
    const files = yield select(selectFiles)
    const content = yield select(selectAdditionalInfo)
    const { data } = yield call(
      ExchangeService.createDebtCollectionFreeCarriers,
      id,
      {
        files,
        content
      }
    )
    yield put(createDebtCollectionFreeCarriersRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(createDebtCollectionFreeCarriersRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(createDebtCollectionFreeCarriersRoutine.fulfill())
  }
}

//watchers

function* selectOfferForDebtCollectionRoutineWatcher() {
  yield takeLatest(
    selectOfferForDebtCollectionRoutine.TRIGGER,
    selectOfferForDebtCollection
  )
}

function* setAdditionalInfoRoutineWatcher() {
  yield takeLatest(setAdditionalInfoRoutine.TRIGGER, setAdditionalInfo)
}

function* setDebtCollectionFilesRoutineWatcher() {
  yield takeLatest(
    setDebtCollectionFilesRoutine.TRIGGER,
    setDebtCollectionFiles
  )
}

function* createDebtCollectionExportRoutineWatcher() {
  yield takeLatest(
    createDebtCollectionExportRoutine.TRIGGER,
    createDebtCollectionExport
  )
}
function* createDebtCollectionImportRoutineWatcher() {
  yield takeLatest(
    createDebtCollectionImportRoutine.TRIGGER,
    createDebtCollectionImport
  )
}
function* createDebtCollectionPostImportRoutineWatcher() {
  yield takeLatest(
    createDebtCollectionPostImportRoutine.TRIGGER,
    createDebtCollectionPostImport
  )
}
function* createDebtCollectionFreeCarriersRoutineWatcher() {
  yield takeLatest(
    createDebtCollectionFreeCarriersRoutine.TRIGGER,
    createDebtCollectionFreeCarriers
  )
}
function* clearDebtCollectionRoutineWatcher() {
  yield takeLatest(clearDebtCollectionRoutine.TRIGGER, clearDebtCollection)
}

export const debtCollectionSagas = [
  fork(selectOfferForDebtCollectionRoutineWatcher),
  fork(setAdditionalInfoRoutineWatcher),
  fork(setDebtCollectionFilesRoutineWatcher),
  fork(createDebtCollectionExportRoutineWatcher),
  fork(createDebtCollectionFreeCarriersRoutineWatcher),
  fork(createDebtCollectionPostImportRoutineWatcher),
  fork(createDebtCollectionImportRoutineWatcher),
  fork(clearDebtCollectionRoutineWatcher)
]
