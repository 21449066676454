import translate from 'src/intl/translate'

export const ADR_OPTIONS = [
  {
    label: 'offersFilters.adrAll',
    value: null
  },
  {
    label: 'offersFilters.adrYes',
    value: '1'
  },
  {
    label: 'offersFilters.adrNo',
    value: '0'
  }
]

export const GENSET_OPTIONS = [
  {
    label: 'offersFilters.gensetAll',
    value: null
  },
  {
    label: 'offersFilters.gensetYes',
    value: '1'
  },
  {
    label: 'offersFilters.gensetNo',
    value: '0'
  }
]

export const RESERVATION_STATUS_OPTIONS = [
  {
    label: 'offersFilters.statusAll',
    value: null
  },
  {
    label: 'offersFilters.statusWithReservation',
    value: 'with_reservations'
  },
  {
    label: 'offersFilters.statusWithoutReservation',
    value: 'without_reservations'
  }
]

export const VISIBILITY_OPTIONS = [
  {
    label: 'offersFilters.visibilityAll',
    value: null
  },
  {
    label: 'offersFilters.visibilityPublic',
    value: 'public'
  },
  {
    label: 'offersFilters.visibilityPrivate',
    value: 'private'
  }
]

export const EURO_OPTIONS = [
  {
    label: 'offersFilters.euroAll',
    value: null
  },
  {
    label: 'offersFilters.euro1',
    value: 1
  },
  {
    label: 'offersFilters.euro2',
    value: 2
  },
  {
    label: 'offersFilters.euro3',
    value: 3
  },
  {
    label: 'offersFilters.euro4',
    value: 4
  },
  {
    label: 'offersFilters.euro5',
    value: 5
  },
  {
    label: 'offersFilters.euro6',
    value: 6
  },
  {
    label: 'offersFilters.euroAlternative',
    value: 7
  }
]

export const VALID_FOR_OPTIONS = [
  {
    label: translate().formatMessage({ id: 'forms.day' }, { count: 1 }),
    value: 1
  },
  {
    label: translate().formatMessage({ id: 'forms.day' }, { count: 3 }),
    value: 3
  },
  {
    label: translate().formatMessage({ id: 'forms.day' }, { count: 5 }),
    value: 5
  },
  {
    label: translate().formatMessage({ id: 'forms.day' }, { count: 7 }),
    value: 7
  },
  {
    label: translate().formatMessage({ id: 'forms.day' }, { count: 14 }),
    value: 14
  }
]

export const STOCKS_HEADERS = {
  CARGO_EXPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.loading',
      sort: 'loading_date'
    },
    {
      name: 'stocksHeaders.return',
      sort: 'return_date'
    },
    {
      name: 'stocksHeaders.container',
      sort: 'container'
    },
    {
      name: 'stocksHeaders.shipper',
      sort: 'shipowner'
    },
    {
      name: 'stocksHeaders.company',
      sort: 'company'
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: 'added_to_listing_at'
    },
    {
      name: 'emptyString',
      sort: null
    }
  ],
  CARGO_IMPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.pickup',
      sort: 'pickup_date'
    },
    {
      name: 'stocksHeaders.unloading',
      sort: 'unloading_date'
    },
    {
      name: 'stocksHeaders.container',
      sort: 'container'
    },
    {
      name: 'stocksHeaders.shipper',
      sort: 'shipowner'
    },
    {
      name: 'stocksHeaders.company',
      sort: 'company'
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: 'added_to_listing_at'
    },
    {
      name: 'emptyString',
      sort: null
    }
  ],
  FREE_CARRIERS: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.availability',
      sort: 'availability_date'
    },
    {
      name: 'stocksHeaders.destination',
      sort: 'destination'
    },
    {
      name: 'stocksHeaders.trailer',
      sort: 'container_semi_trailer'
    },
    {
      name: 'stocksHeaders.company',
      sort: 'company'
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: 'added_to_listing_at'
    },
    {
      name: 'emptyString',
      sort: null
    }
  ],
  POST_IMPORT: [
    {
      name: 'stocksHeaders.nr',
      sort: null
    },
    {
      name: 'stocksHeaders.availability',
      sort: 'availability_date'
    },
    {
      name: 'stocksHeaders.destination',
      sort: 'destination'
    },
    {
      name: 'stocksHeaders.container',
      sort: 'container'
    },
    {
      name: 'stocksHeaders.shipper',
      sort: 'shipowner'
    },
    {
      name: 'stocksHeaders.company',
      sort: 'company'
    },
    {
      name: 'stocksHeaders.createdAt',
      sort: 'added_to_listing_at'
    },
    {
      name: 'emptyString',
      sort: null
    }
  ]
}

export const STOCKS_TYPES = {
  // Matched svg icons
  CARGO_EXPORT: 'export',
  CARGO_IMPORT: 'import',
  FREE_CARRIERS: 'truck',
  POST_IMPORT: 'container',
  CONTRACT: 'description',
  TRACKING: 'tracking'
}

export const REFERENCE_NUMBER_PREFIX = {
  CARGO_EXPORT: 'EXP-',
  CARGO_IMPORT: 'IMP-',
  FREE_CARRIERS: 'WPO-',
  POST_IMPORT: 'KPO-',
  CONTRACTS: 'KON-'
}

export const REFERENCE_NUMBER_REGEX = /^[a-zA-Z]{3}-\d*$/
