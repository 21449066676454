import {
  ExportReservation,
  FreeCarrierReservation,
  ImportReservation,
  PostImportReservation
} from 'src/features/reservations/duck/records'
import {
  normalizeExportOfferDetails,
  normalizePostImportOfferDetails,
  normalizeFreeCarrierOfferDetails,
  normalizeImportOfferDetails
} from 'src/features/stocks/ducks/normalizers'
import { DATE_SHORT_FORMAT_WITH_TIME } from 'src/ducks/consts'
import moment from 'moment'

export const normalizeExportReservation = reservation =>
  new ExportReservation({
    ...reservation,
    addedToListingAt: reservation.createdAt,
    offer: normalizeExportOfferDetails(reservation.offer),
    createdAtFormatted: moment(reservation.createdAt).format(
      DATE_SHORT_FORMAT_WITH_TIME
    )
  })

export const normalizeExportReservations = arr =>
  arr.map(offer => normalizeExportReservation(offer))

export const normalizeImportReservation = reservation =>
  new ImportReservation({
    ...reservation,
    addedToListingAt: reservation.createdAt,
    offer: normalizeImportOfferDetails(reservation.offer),
    createdAtFormatted: moment(reservation.createdAt).format(
      DATE_SHORT_FORMAT_WITH_TIME
    )
  })

export const normalizeImportReservations = arr =>
  arr.map(offer => normalizeImportReservation(offer))

export const normalizePostImportReservation = reservation =>
  new PostImportReservation({
    ...reservation,
    addedToListingAt: reservation.createdAt,
    offer: normalizePostImportOfferDetails(reservation.offer),
    createdAtFormatted: moment(reservation.createdAt).format(
      DATE_SHORT_FORMAT_WITH_TIME
    )
  })

export const normalizePostImportReservations = arr =>
  arr.map(offer => normalizePostImportReservation(offer))

export const normalizeFreeCarrierReservation = reservation =>
  new FreeCarrierReservation({
    ...reservation,
    addedToListingAt: reservation.createdAt,
    offer: normalizeFreeCarrierOfferDetails(reservation.offer),
    createdAtFormatted: moment(reservation.createdAt).format(
      DATE_SHORT_FORMAT_WITH_TIME
    )
  })

export const normalizeFreeCarrierReservations = arr =>
  arr.map(offer => normalizeFreeCarrierReservation(offer))
