import { Record } from 'immutable'
import {
  API_STATES,
  OFFERS_DEFAULT_LIMIT,
  OFFERS_DEFAULT_PAGE
} from 'src/ducks/consts'
import { StockStates } from 'src/features/stocks/ducks/records'
import {
  Contractor,
  Pagination,
  Location,
  Shipowner,
  Container,
  AdrClass,
  CargoType,
  Currency,
  ContainerSemiTrailer
} from 'src/ducks/records'

export const TrackingFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: OFFERS_DEFAULT_LIMIT,
  hideCompanyTransportations: 0,
  status: null
})

export const TrackingOffer = Record({
  id: null,
  containerId: null,
  shipownerId: null,
  referenceNumber: null,
  adrClassId: null,
  cargoTypeId: null,
  notes: null,
  weight: null,
  europeanEmissionStandard: null,
  isSemiTrailerWithGenset: false,
  container: Container(),
  containerSemiTrailer: ContainerSemiTrailer(),
  shipowner: Shipowner(),
  adrClass: AdrClass(),
  cargoType: CargoType(),
  price: Currency()
})

export const Tracking = Record({
  state: StockStates(),
  errorMessage: null,
  offers: [],
  selectedOffers: [],
  isDrawerOpen: false,
  filters: TrackingFilters(),
  pagination: Pagination(),
  total: '',
  transportationId: '',
  pinCode: '',
  pinCodeState: API_STATES.PRISTINE
})

export const TrackingListItem = Record({
  id: null,
  status: '',
  originLocationId: null,
  originLocationAddress: null,
  originLocationPostalCode: null,
  originLocationCountryCode: null,
  originLocationOperationDate: null,
  originLocationOperationTime: null,
  destinationLocationId: null,
  destinationLocationAddress: null,
  destinationLocationPostalCode: null,
  destinationLocationCountryCode: null,
  destinationLocationOperationDate: null,
  destinationLocationOperationTime: null,
  createdAt: null,
  originLocationCity: null,
  destinationLocationCity: null,
  reportedDelay: null,
  referenceNumber: '',
  isEditable: false,
  offer: TrackingOffer(),
  originLocation: Location(),
  destinationLocation: Location(),
  forwarderUser: Contractor()
})

export const TrackingDetails = Record({
  state: API_STATES.PRISTINE,
  isCurrentUserForwarder: false,
  id: null,
  offerType: null,
  pinCode: null,
  pinCodeValidUntil: null,
  transportationType: null,
  transportationId: null,
  driverId: null,
  driverName: null,
  driverPhoneNumber: null,
  driverEmail: null,
  driverIdentityCardNumber: null,
  vehicleRegistrationPlate: null,
  notes: null,
  availableTrackingEvents: null,
  status: '',
  returnLocationId: null,
  forwarderId: null,
  carrierId: null,
  forwarderUserId: null,
  carrierUserId: null,
  lastDriverTrackingEvent: null,
  originLocationId: null,
  originLocationCompanyName: '',
  originLocationAddress: null,
  originLocationPostalCode: null,
  originLocationCountryCode: null,
  originLocationOperationDate: null,
  originLocationOperationTime: null,
  originLocationLat: null,
  originLocationLong: null,
  destinationLocationId: null,
  destinationLocationCompanyName: '',
  destinationLocationAddress: null,
  destinationLocationPostalCode: null,
  destinationLocationCountryCode: null,
  destinationLocationOperationDate: null,
  destinationLocationOperationTime: null,
  destinationLocationLat: null,
  destinationLocationLong: null,
  createdAt: null,
  updatedAt: null,
  originLocationCity: null,
  destinationLocationCity: null,
  reportedDelay: null,
  reportedWorkTime: null,
  referenceNumber: '',
  isEditable: false,
  canGenerateDriversAppCredentials: false,
  pinCodeState: API_STATES.PRISTINE,
  transportationOrderId: null,
  transportationOrder: null,
  canCreateTransportationOrder: false,
  trackingEvents: [],
  eventsHistory: [],
  offer: TrackingOffer(),
  originLocation: Location(),
  destinationLocation: Location(),
  returnLocation: Location(),
  customsClearanceLocation: Location(),
  files: [],
  forwarderUser: Contractor(),
  carrierUser: Contractor()
})

export const TrackingEvent = Record({
  id: null,
  createdAt: '',
  deviceId: '',
  batteryLevel: '',
  lat: '',
  long: '',
  type: ''
})

export const HistoryEvent = Record({
  id: null,
  title: '',
  lat: 0,
  long: 0,
  createdAt: '',
  delay: null,
  comment: null,
  files: []
})

export const TrackingEdit = Record({
  state: API_STATES.PRISTINE,
  isCurrentUserForwarder: false,
  originLocationId: null,
  originLocationAddress: '',
  originLocationCity: '',
  originLocationPostalCode: '',
  originLocationCountryCode: '',
  originLocationLat: null,
  originLocationLong: null,
  originLocationCompanyName: '',
  originLocationOperationDate: '',
  originLocationOperationTime: '',
  destinationLocationId: null,
  destinationLocationAddress: '',
  destinationLocationCity: '',
  destinationLocationPostalCode: '',
  destinationLocationCountryCode: '',
  destinationLocationLat: null,
  destinationLocationLong: null,
  destinationLocationCompanyName: '',
  destinationLocationOperationDate: '',
  destinationLocationOperationTime: '',
  notes: '',
  driverId: null,
  driverName: '',
  driverPhoneNumber: '',
  driverEmail: '',
  driverIdentityCardNumber: '',
  vehicleRegistrationPlate: ''
})

export const AddressData = Record({
  address: '',
  city: '',
  postalCode: '',
  countryCode: '',
  lat: null,
  long: null
})

export const Driver = Record({
  id: null,
  companyId: null,
  name: '',
  phoneNumber: '',
  email: '',
  identityCardNumber: '',
  createdAt: null,
  updatedAt: null
})
