import React from 'react'
import { values } from 'ramda'

import routes from 'src/utils/routes'
import CustomIcon from 'src/components/CustomIcon'

import translate from 'src/intl/translate'
import { USER_ROLES } from 'src/ducks/consts'

export const ROLE_OPTIONS = [
  {
    text: 'common.admin',
    value: USER_ROLES.ADMIN
  },
  {
    text: 'common.user',
    value: USER_ROLES.USER
  }
]

export const MENU_ITEMS = {
  MY_PROFILE: 'my-profile',
  MY_COMPANY: 'my-company',
  INVOICES: 'invoices',
  DEBT_COLLECTION: 'debt-collection',
  ADD_USER: 'add-user',
  EDIT_USER: 'edit-user',
  SETTINGS: 'settings',
  CONTACT: 'contact'
}

export const MY_COMPANY_TABS = {
  COMPANY_DATA:  'myCompany.companyData',
  EMPLOYEES: 'myCompany.employees',
}

export const MY_COMPANY_TABS_ARRAY = values(MY_COMPANY_TABS)

export const MENU_LIST = [
  {
    name: 'account.myProfile',
    value: MENU_ITEMS.MY_PROFILE,
    activeWhen: [MENU_ITEMS.MY_PROFILE],
    icon: <CustomIcon name='person' size={24} />,
    spacer: false
  },
  {
    name: 'myCompany.myCompany',
    value: MENU_ITEMS.MY_COMPANY,
    activeWhen: [MENU_ITEMS.MY_COMPANY, MENU_ITEMS.ADD_USER],
    icon: <CustomIcon name='business' size={24} />,
    spacer: false
  },
  {
    name: 'account.settings',
    value: MENU_ITEMS.SETTINGS,
    activeWhen: [MENU_ITEMS.SETTINGS],
    icon: <CustomIcon name='cog' size={24} />,
    spacer: false
  },
  {
    name: 'navigation.contact',
    value: MENU_ITEMS.CONTACT,
    activeWhen: [MENU_ITEMS.CONTACT],
    icon: <CustomIcon name='mail' size={24} />,
    spacer: true
  }
]

export const DROPDOWN_NAVIGATION_LIST = [
  {
    name:  'account.myProfile',
    url: `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_PROFILE}`,
    activeWhen: [MENU_ITEMS.MY_PROFILE],
    icon: <CustomIcon name='person' size={24} />,
    withSeparator: true
  },
  {
    name: 'myCompany.myCompany',
    url: `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.MY_COMPANY}`,
    activeWhen: [MENU_ITEMS.MY_COMPANY],
    icon: <CustomIcon name='company' size={24} />
  },
  {
    name: 'account.settings',
    url: `${routes.APP_ACCOUNT}?tab=${MENU_ITEMS.SETTINGS}`,
    activeWhen: [MENU_ITEMS.SETTINGS],
    icon: <CustomIcon name='cog' size={24} />
  },
  {
    name:  'navigation.contact',
    url: `${routes.APP_CONTACT}`,
    activeWhen: [MENU_ITEMS.CONTACT],
    icon: <CustomIcon name='mail' size={24} />,
    withSeparator: true
  }
]

export const DOCUMENTS_HEADERS = [
  {
    name: 'myCompany.documents.documentName',
    sort: null
  },
  {
    name: 'myCompany.documents.validFrom',
    sort: null
  },
  {
    name: 'myCompany.documents.validUntil',
    sort: null
  },
  {
    name: 'myCompany.documents.status',
    sort: null
  },
  {
    name: 'emptyString',
    sort: null
  }
]

export const USERS_HEADERS = [
  {
    name: 'myCompany.users.name',
    sort: null
  },
  {
    name: 'myCompany.users.login',
    sort: true
  },
  {
    name: 'myCompany.users.email',
    sort: null
  },
  {
    name: 'myCompany.users.role',
    sort: null
  },
  {
    name: 'myCompany.users.status',
    sort: null
  },
  {
    name: 'emptyString',
    sort: null
  },
  {
    name: 'emptyString',
    sort: null
  }
]

export const DOCUMENTS_STATES = {
  ADDED: 'added',
  EXPIRING: 'expiring',
  EXPIRED: 'expired',
  MISSING: 'missing'
}

export const DOCUMENTS_COLORS = {
  [DOCUMENTS_STATES.ADDED]: 'apple',
  [DOCUMENTS_STATES.EXPIRED]: 'fadedRed',
  [DOCUMENTS_STATES.EXPIRING]: 'california',
  [DOCUMENTS_STATES.MISSING]: 'fadedRed'
}

export const REQUIRED_DOCUMENTS_TYPES = {
  carrier_forwarder: [
    'registration_document',
    'carrier_license',
    'carrier_third_party_insurance',
    'forwarder_license'
  ],
  carrier: [
    'registration_document',
    'carrier_license',
    'carrier_third_party_insurance'
  ],
  forwarder: [
    'registration_document',
    'forwarder_license',
    'forwarder_third_party_insurance'
  ]
}
