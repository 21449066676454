import { forEach } from 'ramda'
import { parseFiltersToQuery } from 'src/utils/helpers'
import api from './APIInterceptor'

export default class TrackingService {
  static generateDriverCredentials(id) {
    return api.patch(`/me/tracking/${id}/driver/credentials`)
  }

  static updateTracking(id, values) {
    return api.patch(`/me/tracking/${id}`, values)
  }

  static setTrackingTransportationType(id, values) {
    return api.patch(`/me/tracking/${id}/type`, values)
  }

  static createExportOfferTracking(id) {
    return api.post(`/me/export-cargo/offers/finished/${id}/tracking`)
  }

  static createImportOfferTracking(id) {
    return api.post(`/me/import-cargo/offers/finished/${id}/tracking`)
  }

  static createPostImportOfferTracking(id) {
    return api.post(`/me/post-import-containers/offers/finished/${id}/tracking`)
  }

  static createFreeCarriersOfferTracking(id) {
    return api.post(`/me/free-carriers/offers/finished/${id}/tracking`)
  }

  static getTrackingListOngoing(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/tracking/ongoing?${query}`)
  }

  static getTrackingListFinished(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/tracking/finished?${query}`)
  }

  static getTrackingDetails(id) {
    return api.get(`/me/tracking/${id}`)
  }

  static async uploadTrackingFiles(files, id) {
    const formData = new FormData()
    forEach(file => formData.append('files[]', file), files)
    return api.post(`/me/tracking/${id}/files`, formData)
  }

  static deleteTrackingFile(trackingId, fileId) {
    return api.delete(`/me/tracking/${trackingId}/files/${fileId}`)
  }

  static getTrackingEventFile(url) {
    return api.get(url)
  }

  static prepareOrder(trackingId) {
    return api.get(`/me/tracking/${trackingId}/transportation-orders`)
  }
}
