import { createRoutine } from 'redux-saga-routines'
import { isEmptyString } from 'ramda-adjunct'
import { isNil } from 'ramda'
import {
  put,
  take,
  all,
  call,
  takeLatest,
  takeEvery,
  fork,
  spawn,
  delay,
  select
} from '@redux-saga/core/effects'
import { eventChannel } from 'redux-saga'
import DictionariesService from 'src/services/DictionariesService'
import LaravelEchoService from 'src/services/LaravelEchoService'
import ExchangeService from 'src/services/ExchangeService'

import {
  getUserPermissions,
  getCurrentUserDetails
} from 'src/features/account/duck/actions'
import { selectCurrentUserId } from 'src/features/account/duck/selectors'

import {
  exportOffersCleanerRoutine,
  getExportFilterTabsList
} from 'src/features/stocks/ducks/actions-export'
import {
  getImportFilterTabsList,
  importOffersCleanerRoutine
} from 'src/features/stocks/ducks/actions-import'
import {
  getPostImportFilterTabsList,
  postImportOffersCleanerRoutine
} from 'src/features/stocks/ducks/actions-post-import'
import {
  freeCarriersOffersCleanerRoutine,
  getFreeCarriersFilterTabsList
} from 'src/features/stocks/ducks/actions-free-carriers'
import {
  listenChatEventsWatcher,
  getUnreadMessagesCount,
  listenToChatPresenceWatcher
} from 'src/features/messages/duck/actions'

import {
  listenNotificationsEventsWatcher,
  getUnseenNotificationsCount
} from 'src/features/notifications/duck/actions'

import {
  ERROR_DELAY_TIME,
  EXCHANGE_EVENT_TYPES,
  OFFERS_TABLE_CLEANER_INTERVAL
} from './consts'

//routines
export const bootstrapApplicationRoutine = createRoutine(
  'BOOTSTRAP_APPLICATION'
)
export const initDictionariesRoutine = createRoutine('INIT_DICTIONARIES')
export const fetchLocationsRoutine = createRoutine('FETCH_LOCATIONS')
export const appendLocationsRoutine = createRoutine('APPEND_LOCATIONS')
export const fetchCompaniesRoutine = createRoutine('FETCH_COMPANIES')
export const getExchangeOffersCountersRoutine = createRoutine(
  'GET_EXCHANGE_OFFERS_COUNTERS'
)
export const GLOBAL_EXCHANGE_COUNTERS_OFFER_CREATED =
  'GLOBAL_EXCHANGE_COUNTERS_OFFER_CREATED'
export const GLOBAL_EXCHANGE_COUNTERS_OFFER_DELETED =
  'GLOBAL_EXCHANGE_COUNTERS_OFFER_DELETED'
export const setSnackbarValuesRoutine = createRoutine('SET_SNACKBAR_VALUES')

//actions
function* bootstrapApplication() {
  yield call(getUserPermissions)
  yield call(getCurrentUserDetails)
  yield call(initDictionaries)
  yield call(getExportFilterTabsList)
  yield call(getImportFilterTabsList)
  yield call(getPostImportFilterTabsList)
  yield call(getFreeCarriersFilterTabsList)

  yield spawn(getUnreadMessagesCount)
  yield spawn(getUnseenNotificationsCount)

  const userId = yield select(selectCurrentUserId)
  yield spawn(listenChatEventsWatcher, userId)
  yield spawn(listenToChatPresenceWatcher)
  yield spawn(listenNotificationsEventsWatcher, userId)

  yield spawn(listenToExportOffersCleaner)
  yield spawn(listenToImportOffersCleaner)
  yield spawn(listenToPostImportOffersCleaner)
  yield spawn(listenToFreeCarriersOffersCleaner)

  yield call(listenToGlobalExchangeCountersWatcher)
}

export function* initDictionaries() {
  try {
    const [
      containers,
      containerSemiTrailers,
      shipowners,
      cargoTypes,
      adrClasses,
      contactCategories,
      gtuCodes
    ] = yield all([
      call(DictionariesService.containers),
      call(DictionariesService.containersSemiTrailers),
      call(DictionariesService.shipowners),
      call(DictionariesService.cargoTypes),
      call(DictionariesService.adrClasses),
      call(DictionariesService.contactCategories),
      call(DictionariesService.gtuCodes)
    ])
    yield put(
      initDictionariesRoutine.success({
        containers,
        containerSemiTrailers,
        shipowners,
        cargoTypes,
        adrClasses,
        contactCategories,
        gtuCodes
      })
    )
  } catch (e) {
    console.log('Unable initDictionaries', e)
  }
}

function* fetchLocations({ payload }) {
  try {
    const { data } = yield call(DictionariesService.locations, payload)
    if (payload.append) {
      return yield put(
        appendLocationsRoutine.success({
          dictionaryName: payload.dictionaryName,
          data
        })
      )
    }

    yield put(
      fetchLocationsRoutine.success({
        dictionaryName: payload.dictionaryName,
        data
      })
    )
  } catch (e) {
    console.log(e)
  }
}

function* fetchCompanies({ payload }) {
  try {
    if (isEmptyString(payload.filter.name) || isNil(payload.filter.name)) return
    const { data } = yield call(DictionariesService.companies, payload)
    yield put(fetchCompaniesRoutine.success({ data }))
  } catch (e) {
    console.log(e)
  }
}

function* getExchangeOffersCounters() {
  yield put(getExchangeOffersCountersRoutine.request())
  try {
    const { data } = yield call(ExchangeService.getExchangeOffersCounters)
    yield put(getExchangeOffersCountersRoutine.success(data))
  } catch (e) {
    console.log(e)
  }
}

function listenToGlobalExchangeCounters() {
  return eventChannel(emitter => {
    LaravelEchoService.getInstance().listenToGlobalExchangeCounters(
      (type, payload) => {
        switch (type) {
          case EXCHANGE_EVENT_TYPES.OFFER_CREATED:
            return emitter({
              type: GLOBAL_EXCHANGE_COUNTERS_OFFER_CREATED,
              payload
            })
          case EXCHANGE_EVENT_TYPES.OFFER_DELETED:
            return emitter({
              type: GLOBAL_EXCHANGE_COUNTERS_OFFER_DELETED,
              payload
            })
          default:
            //handle information about not handled type
            return null
        }
      }
    )
    // unsubscribe function
    return () => {
      // do whatever to interrupt the socket communication here
    }
  })
}

function initExportOffersCleaner() {
  return eventChannel(emitter => {
    const ref = setInterval(() => {
      emitter(exportOffersCleanerRoutine.success())
      //randomized for better store performance
    }, OFFERS_TABLE_CLEANER_INTERVAL + Math.floor(Math.random() * 100))

    return () => {
      clearInterval(ref)
    }
  })
}

function initImportOffersCleaner() {
  return eventChannel(emitter => {
    const ref = setInterval(() => {
      emitter(importOffersCleanerRoutine.success())
      //randomized for better store performance
    }, OFFERS_TABLE_CLEANER_INTERVAL + Math.floor(Math.random() * 100))

    return () => {
      clearInterval(ref)
    }
  })
}

function initPostImportOffersCleaner() {
  return eventChannel(emitter => {
    const ref = setInterval(() => {
      emitter(postImportOffersCleanerRoutine.success())
      //randomized for better store performance
    }, OFFERS_TABLE_CLEANER_INTERVAL + Math.floor(Math.random() * 100))

    return () => {
      clearInterval(ref)
    }
  })
}

function initFreeCarriersOffersCleaner() {
  return eventChannel(emitter => {
    const ref = setInterval(() => {
      emitter(freeCarriersOffersCleanerRoutine.success())
      //randomized for better store performance
    }, OFFERS_TABLE_CLEANER_INTERVAL + Math.floor(Math.random() * 100))

    return () => {
      clearInterval(ref)
    }
  })
}

export function* setSnackbarValues({ payload }) {
  yield put(setSnackbarValuesRoutine.success(payload))
  yield delay(ERROR_DELAY_TIME)
  yield put(setSnackbarValuesRoutine.fulfill())
}

//watchers

function* initDictionariesWatcher() {
  yield takeLatest(initDictionariesRoutine.TRIGGER, initDictionaries)
}

function* fetchLocationsWatcher() {
  yield takeEvery(fetchLocationsRoutine.TRIGGER, fetchLocations)
}

function* fetchCompaniesWatcher() {
  yield takeLatest(fetchCompaniesRoutine.TRIGGER, fetchCompanies)
}

function* setSnackbarValuesRoutineWatcher() {
  yield takeLatest(setSnackbarValuesRoutine.TRIGGER, setSnackbarValues)
}

function* listenToGlobalExchangeCountersWatcher() {
  //get initial counter values
  yield call(getExchangeOffersCounters)
  //listen to websockets channel
  const channel = yield call(listenToGlobalExchangeCounters)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* bootstrapApplicationWatcher() {
  yield takeLatest(bootstrapApplicationRoutine.TRIGGER, bootstrapApplication)
}

function* listenToExportOffersCleaner() {
  const channel = yield call(initExportOffersCleaner)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* listenToImportOffersCleaner() {
  const channel = yield call(initImportOffersCleaner)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* listenToPostImportOffersCleaner() {
  const channel = yield call(initPostImportOffersCleaner)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* listenToFreeCarriersOffersCleaner() {
  const channel = yield call(initFreeCarriersOffersCleaner)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

export const globalSagas = [
  fork(initDictionariesWatcher),
  fork(fetchLocationsWatcher),
  fork(fetchCompaniesWatcher),
  fork(bootstrapApplicationWatcher),
  fork(setSnackbarValuesRoutineWatcher)
]

export const openEUFinancingDialog = () => ({
  type: 'SET_EU_FINANCING_DIALOG_OPEN'
})

export const closeEUFinancingDialog = () => ({
  type: 'SET_EU_FINANCING_DIALOG_CLOSED'
})
