import React from 'react'
import styled from 'styled-components'
import MUISettings from '@material-ui/icons/SettingsOutlined'

import ContainerUrl from 'src/assets/icons/container.svg'
import ExportUrl from 'src/assets/icons/export.svg'
import HomeUrl from 'src/assets/icons/home.svg'
import ImportUrl from 'src/assets/icons/import.svg'
import TruckUrl from 'src/assets/icons/truck.svg'
import AlertUrl from 'src/assets/icons/alert.svg'
import SuccessUrl from 'src/assets/icons/success.svg'
import InfoUrl from 'src/assets/icons/info.svg'
import WarningUrl from 'src/assets/icons/warning.svg'
import DeleteUrl from 'src/assets/icons/delete.svg'
import BusinessUrl from 'src/assets/icons/business.svg'
import EditUrl from 'src/assets/icons/edit.svg'
import GavelUrl from 'src/assets/icons/gavel.svg'
import MoneyUrl from 'src/assets/icons/money.svg'
import PersonUrl from 'src/assets/icons/person.svg'
import LeftUrl from 'src/assets/icons/left.svg'
import ChatUrl from 'src/assets/icons/icon_chat.svg'
import AddPhotoUrl from 'src/assets/icons/add-photo.svg'
import CancelUrl from 'src/assets/icons/cancel.svg'
import WaitUrl from 'src/assets/icons/wait.svg'
import OfferUrl from 'src/assets/icons/offer.svg'
import ReservationUrl from 'src/assets/icons/bookmarks.svg'
import NotificationActiveUrl from 'src/assets/icons/notification-active.svg'
import NotificationOffUrl from 'src/assets/icons/notifications-off.svg'
import MailUrl from 'src/assets/icons/mail.svg'
import WorkCaseUrl from 'src/assets/icons/work-case.svg'
import PowerUrl from 'src/assets/icons/power.svg'
import DescriptionUrl from 'src/assets/icons/description.svg'
import TrackingUrl from 'src/assets/icons/track.svg'
import PhotoIconUrl from 'src/assets/icons/image.svg'
import InvoiceUrl from 'src/assets/icons/invoice.svg'
import AttachIcon from 'src/assets/icons/attach-file.svg'
import MegaphoneIcon from 'src/assets/icons/megaphone.svg'
import PrivateIcon from 'src/assets/icons/private.svg'
import VisibilityIconUrl from 'src/assets/icons/visibility.svg'

const CogIcon = styled(MUISettings)`
  display: inline-block;
  color: inherit;
  height: auto;
  position: relative;
  z-index: 20;
  color: inherit;
  margin-top: 4px;
`

const ICONS = {
  container: ContainerUrl,
  export: ExportUrl,
  home: HomeUrl,
  import: ImportUrl,
  truck: TruckUrl,
  alert: AlertUrl,
  info: InfoUrl,
  success: SuccessUrl,
  warning: WarningUrl,
  delete: DeleteUrl,
  business: BusinessUrl,
  edit: EditUrl,
  gavel: GavelUrl,
  money: MoneyUrl,
  person: PersonUrl,
  left: LeftUrl,
  chat: ChatUrl,
  addPhoto: AddPhotoUrl,
  cancel: CancelUrl,
  wait: WaitUrl,
  offer: OfferUrl,
  reservation: ReservationUrl,
  cog: CogIcon,
  notificationActive: NotificationActiveUrl,
  notificationOff: NotificationOffUrl,
  mail: MailUrl,
  company: WorkCaseUrl,
  power: PowerUrl,
  description: DescriptionUrl,
  tracking: TrackingUrl,
  photo: PhotoIconUrl,
  invoice: InvoiceUrl,
  order: AttachIcon,
  announcement: MegaphoneIcon,
  private: PrivateIcon,
  visibility: VisibilityIconUrl
}

const Icon = ({ className, name, size = 20, ...params }) => {
  const Component = ICONS[name] || null
  return (
    <i className={className} {...params}>
      <Component height={`${size}px`} width='auto' />
    </i>
  )
}

export default styled(Icon)`
  display: inline-flex;
  color: inherit;
  width: ${({ size }) => size}px;
  height: 100%;
  position: relative;
  z-index: 20;
  justify-content: center;
  align-items: center;
`
