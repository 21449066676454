import { API_STATES } from 'src/ducks/consts'
import translate from 'src/intl/translate'
import { ContactForm } from 'src/features/account/duck/records'
import { MyCompany, User, Account, Image } from './records'
import {
  setAddUserFormRoutine,
  getUserPermissionsRoutine,
  clearUserPermissionsRoutine,
  getCurrentUserDetailsRoutine,
  getCurrentUserCoworkersRoutine,
  updateCurrentUserRoutine,
  updateCurrentUserLanguageRoutine,
  changeCurrentUserPasswordRoutine,
  updateCurrentCompanyRoutine,
  getCurrentCompanyDetailsRoutine,
  uploadUserAvatarRoutine,
  addCurrentCompanyUserRoutine,
  editCurrentCompanyUserRoutine,
  setEditUserFormRoutine,
  clearAddUserFormRoutine,
  clearEditUserFormRoutine,
  deactivateCompanyUserRoutine,
  prefillEditUserFormRoutine,
  uploadEmployeeAvatarRoutine,
  toggleChatNotificationsRoutine,
  sendContactFormRoutine,
  setContactFormValueRoutine
} from './actions'
import {
  normalizeUserPermissions,
  normalizeCurrentUserData,
  normalizeCoworkersData,
  normalizeMyCompany
} from './normalizers'

export const currentCompanyReducer = (state = new MyCompany(), action) => {
  switch (action.type) {
    case getCurrentCompanyDetailsRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
        .set('changedSuccessfully', false)
    case getCurrentCompanyDetailsRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
        .set('changedSuccessfully', false)
        .set('errorMessage', action.payload.message)
    case getCurrentCompanyDetailsRoutine.SUCCESS:
      return new MyCompany(normalizeMyCompany(action.payload.data))
        .set('state', API_STATES.DONE)
        .set('changedSuccessfully', false)
        .set('errorMessage', null)
    case getCurrentCompanyDetailsRoutine.FULFILL:
      return state
        .set('state', API_STATES.DONE)
        .set('changedSuccessfully', false)
        .set('errorMessage', null)
    default:
      return state
  }
}

export const addUserReducer = (state = new User(), action) => {
  switch (action.type) {
    case clearAddUserFormRoutine.SUCCESS:
      return User()
    case addCurrentCompanyUserRoutine.REQUEST:
      return state
        .set('errorMessage', null)
        .set('state', API_STATES.IN_PROGRESS)
    case addCurrentCompanyUserRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .set('state', API_STATES.DONE)
    case addCurrentCompanyUserRoutine.SUCCESS:
      return state.set('errorMessage', null).set('state', API_STATES.DONE)
    case addCurrentCompanyUserRoutine.FULFILL:
      return state.set('errorMessage', null)
    case setAddUserFormRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    default:
      return state
  }
}

export const editUserReducer = (state = new User(), action) => {
  switch (action.type) {
    case clearEditUserFormRoutine.SUCCESS:
      return User()
    case editCurrentCompanyUserRoutine.REQUEST:
      return state
        .set('errorMessage', null)
        .set('state', API_STATES.IN_PROGRESS)
    case editCurrentCompanyUserRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .set('state', API_STATES.DONE)
    case editCurrentCompanyUserRoutine.SUCCESS:
      return state.set('errorMessage', null).set('state', API_STATES.DONE)
    case editCurrentCompanyUserRoutine.FULFILL:
      return state.set('errorMessage', null).set('state', API_STATES.DONE)
    case setEditUserFormRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case prefillEditUserFormRoutine.SUCCESS:
      return User({
        ...action.payload
      })
    case uploadEmployeeAvatarRoutine.REQUEST:
      return state.set('errorMessage', null)
    case uploadEmployeeAvatarRoutine.FAILURE:
      return state.set(
        'errorMessage',
        translate().formatMessage({
          id: 'myCompany.users.toLargePhotoErrorMessage'
        })
      )
    case uploadEmployeeAvatarRoutine.SUCCESS:
      return state.set('errorMessage', null)
    case uploadEmployeeAvatarRoutine.FULFILL:
      return state.set('errorMessage', null)
    default:
      return state
  }
}

export const accountReducer = (state = new Account(), action) => {
  switch (action.type) {
    case uploadUserAvatarRoutine.REQUEST:
      return state.set('avatarState', API_STATES.IN_PROGRESS)
    case uploadUserAvatarRoutine.SUCCESS:
      return state
        .set('avatarState', API_STATES.DONE)
        .setIn(
          ['currentUser', 'avatar'],
          new Image({ ...action.payload.data.avatar })
        )
    case uploadUserAvatarRoutine.FAILURE:
      return state.set('avatarState', API_STATES.ERROR)
    case uploadUserAvatarRoutine.FULFILL:
      return state.set('avatarState', API_STATES.DONE)
    case updateCurrentCompanyRoutine.REQUEST:
      return state
        .setIn(['updateMyCompany', 'state'], API_STATES.IN_PROGRESS)
        .setIn(['updateMyCompany', 'changedSuccessfully'], false)
    case updateCurrentUserRoutine.REQUEST:
      return state
        .setIn(['updateUser', 'state'], API_STATES.IN_PROGRESS)
        .setIn(['updateUser', 'changedSuccessfully'], false)
    case getUserPermissionsRoutine.SUCCESS:
      return state.set(
        'permissions',
        normalizeUserPermissions(action.payload.data)
      )
    case changeCurrentUserPasswordRoutine.REQUEST:
      return state.setIn(['changePassword', 'state'], API_STATES.IN_PROGRESS)
    case changeCurrentUserPasswordRoutine.FAILURE:
      return state
        .setIn(['changePassword', 'state'], API_STATES.DONE)
        .setIn(['changePassword', 'changedSuccessfully'], false)
        .setIn(['changePassword', 'errorMessage'], action.payload.message)
    case updateCurrentCompanyRoutine.FAILURE:
      return state
        .setIn(['updateMyCompany', 'state'], API_STATES.DONE)
        .setIn(['updateMyCompany', 'changedSuccessfully'], false)
        .setIn(['updateMyCompany', 'errorMessage'], action.payload.message)
    case updateCurrentUserRoutine.FAILURE:
      return state
        .setIn(['updateUser', 'state'], API_STATES.DONE)
        .setIn(['updateUser', 'changedSuccessfully'], false)
        .setIn(['updateUser', 'errorMessage'], action.payload.message)
    case changeCurrentUserPasswordRoutine.SUCCESS:
      return state
        .setIn(['changePassword', 'state'], API_STATES.DONE)
        .setIn(['changePassword', 'changedSuccessfully'], true)
    case updateCurrentCompanyRoutine.SUCCESS:
      return state
        .setIn(['updateMyCompany', 'state'], API_STATES.DONE)
        .setIn(['updateMyCompany', 'changedSuccessfully'], true)
        .setIn(
          ['currentUser', 'company'],
          normalizeMyCompany(action.payload.data)
        )
    case getCurrentUserDetailsRoutine.REQUEST:
      return state.setIn(['currentUser', 'state'], API_STATES.IN_PROGRESS)
    case getCurrentUserDetailsRoutine.SUCCESS:
      return state
        .set('currentUser', normalizeCurrentUserData(action.payload.data))
        .setIn(['currentUser', 'state'], API_STATES.DONE)
    case getCurrentUserDetailsRoutine.FAILURE:
      return state.setIn(['currentUser', 'state'], API_STATES.ERROR)
    case updateCurrentUserLanguageRoutine.SUCCESS:
      return state.set(
        'currentUser',
        normalizeCurrentUserData(action.payload.data)
      )
    case updateCurrentUserRoutine.SUCCESS:
      return state
        .set('currentUser', normalizeCurrentUserData(action.payload.data))
        .setIn(['updateUser', 'state'], API_STATES.DONE)
        .setIn(['updateUser', 'changedSuccessfully'], true)
    case getCurrentUserCoworkersRoutine.SUCCESS:
      return state.set('coworkers', normalizeCoworkersData(action.payload.data))
    case deactivateCompanyUserRoutine.SUCCESS:
      const deactivatedUserIndex = state
        .get('coworkers')
        .findIndex(user => user.get('id') === action.payload.data.id)
      return state.setIn(['coworkers', deactivatedUserIndex, 'isActive'], false)
    case clearUserPermissionsRoutine.SUCCESS:
      return state.set('permissions', {})
    case changeCurrentUserPasswordRoutine.FULFILL:
      return state
        .setIn(['changePassword', 'state'], API_STATES.DONE)
        .setIn(['changePassword', 'changedSuccessfully'], false)
        .setIn(['changePassword', 'errorMessage'], null)
    case updateCurrentCompanyRoutine.FULFILL:
      return state
        .setIn(['updateMyCompany', 'state'], API_STATES.DONE)
        .setIn(['updateMyCompany', 'changedSuccessfully'], false)
        .setIn(['updateMyCompany', 'errorMessage'], null)
    case updateCurrentUserRoutine.FULFILL:
      return state
        .setIn(['updateUser', 'state'], API_STATES.DONE)
        .setIn(['updateUser', 'changedSuccessfully'], false)
        .setIn(['updateUser', 'errorMessage'], null)
    case toggleChatNotificationsRoutine.SUCCESS:
      return state.setIn(['currentUser', 'isChatMuted'], action.payload)
    default:
      return state
  }
}

export const contactFormReducer = (state = new ContactForm(), action) => {
  switch (action.type) {
    case sendContactFormRoutine.REQUEST:
    case setContactFormValueRoutine.REQUEST:
      return state
        .set('sendSuccessfully', false)
        .set('errorMessage', null)
        .set('state', API_STATES.IN_PROGRESS)
    case sendContactFormRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .set('state', API_STATES.ERROR)
        .set('sendSuccessfully', false)
    case setContactFormValueRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .set('state', API_STATES.ERROR)
    case sendContactFormRoutine.SUCCESS:
      return state
        .set('errorMessage', null)
        .set('message', '')
        .set('subject', '')
        .set('category', '')
        .set('state', API_STATES.DONE)
        .set('sendSuccessfully', true)
    case setContactFormValueRoutine.SUCCESS:
      return state
        .set('errorMessage', null)
        .set('state', API_STATES.DONE)
        .set(action.payload.name, action.payload.value)
    case sendContactFormRoutine.FULFILL:
      return state
        .set('sendSuccessfully', false)
        .set('errorMessage', null)
        .set('state', API_STATES.DONE)
    case setContactFormValueRoutine.FULFILL:
      return state.set('errorMessage', null).set('state', API_STATES.DONE)
    default:
      return state
  }
}
