import {
  match,
  pipe,
  propOr,
  replace,
  join,
  filter,
  propEq,
  head,
  keys,
  test,
  equals,
  prop
} from 'ramda'
import { INVOICES_STATES } from 'src/features/invoices/duck/consts'

export const removePrefixFromGtuCodeValue = replace(/GTU_/, '')

/**
 * getInvoiceStatus
 * @param {object} invoice - invoice record
 * @returns {string} one of INVOICES_STATES 'fully_paid', 'partially_paid', 'unpaid'
 */
export const getInvoiceStatus = ({
  amountDue: { float: amountDue },
  amountPaid: { float: amountPaid }
}) => {
  if (amountDue === 0) {
    return INVOICES_STATES.FULLY_PAID
  }
  if (amountDue !== 0 && amountPaid !== 0) {
    return INVOICES_STATES.PARTIALLY_PAID
  }
  if (amountDue !== 0 && amountPaid === 0) {
    return INVOICES_STATES.UNPAID
  }
}
export const getFilenameFromHeaders = pipe(
  propOr('', 'contentDisposition'),
  match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/gi),
  join(''),
  replace('filename=', ''),
  replace(/"/g, '')
)
export const formatPrice = price =>
  price >= 0 ? Number(price).toFixed(2) : '0.00'

/**
 * Get label from select options
 * @param value {string} -
 * @param options {array}
 * @returns {string}  label or ''
 */
export const getLabelFromSelectOptions = (value, options) =>
  pipe(filter(propEq('value', value)), head, propOr('', 'label'))(options)

/**
 * get invoice saft codes from invoice.saft object
 * @param {object} saft - saft object
 * @return {array} array of saft codes names
 */
export const getInvoiceSaftCodes = pipe(filter(equals(true)), keys)

export const isCorrectiveInvoice = pipe(prop('number'), test(/^KOR/))
