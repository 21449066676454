import React from 'react'
import { pathOr } from 'ramda'

export default React.createContext({})

// if permission object has proper path true will be returned
export const hasPermissionTo = (path, obj) =>
  pathOr(false, path.split('.'))(obj)

// PERMISSION object
// key: <SECTION> (
// one of: company, user,
// stock_import, stock_export, post_import, stock_free_carriers,
// offer, reservation ),
// <VERB> ...

export const PERMISSIONS = {
  COMPANY_EDIT_DETAILS: 'company.details.edit',
  COMPANY_MANAGE_SHOWCASE: 'company.showcase.manage',
  COMPANY_MANAGE_BLACKLIST: 'company.blacklist.manage',
  COMPANY_MANAGE_WHITELIST: 'company.whitelist.manage',
  COMPANY_MANAGE_USERS: 'company.users.manage',

  USER_CREATE: 'user.create',
  USER_MANAGE_CONTACT_DETAILS: 'user.contact-details.manage',
  USER_DEACTIVATE: 'user.deactivate',
  USER_CHANGE_OTHER_PASSWORD: 'user.password.reset.any',
  USER_CHANGE_OWN_PASSWORD: 'user.password.reset.own',
  USER_CREATE_SUPERUSER: 'user.super.create',
  USER_GRANT_SUPERUSER: 'user.super.permission.grant',
  USER_REVOKE_SUPERUSER: 'user.super.permission.revoke',

  STOCK_IMPORT_ACCESS: 'exchange.import.access',
  STOCK_IMPORT_ADD_OFFER: 'exchange.import.offer.add',
  STOCK_IMPORT_RESERVE: 'exchange.import.offer.reserve',

  STOCK_EXPORT_ACCESS: 'exchange.export.access',
  STOCK_EXPORT_ADD_OFFER: 'exchange.export.offer.add',
  STOCK_EXPORT_RESERVE: 'exchange.export.offer.reserve',

  STOCK_POST_IMPORT_ACCESS: 'exchange.post-import.access',
  STOCK_POST_IMPORT_ADD_OFFER: 'exchange.post-import.offer.add',
  STOCK_POST_IMPORT_RESERVE: 'exchange.post-import.offer.reserve',

  STOCK_FREE_CARRIERS_ACCESS: 'exchange.free-carriers.access',
  STOCK_FREE_CARRIERS_ADD_OFFER: 'exchange.free-carriers.offer.add',
  STOCK_FREE_CARRIERS_RESERVE: 'exchange.free-carriers.offer.reserve',

  STOCK_PRIVATE_ACCESS: 'exchange.private.access',
  STOCK_PRIVATE_ADD_OFFER: 'exchange.private.offer.add',
  STOCK_PRIVATE_OFFERS_VIEW: 'exchange.private.offers.view',
  STOCK_PRIVATE_OFFER_RESERVE: 'exchange.private.offer.reserve',

  CONTRACTS_ACCESS: 'exchange.contracts.access',
  CONTRACTS_ADD_OFFER: 'exchange.contracts.offer.add',
  CONTRACTS_RESERVE: 'exchange.contracts.offer.reserve',

  OFFER_EDIT_ANY: 'offer.edit.any',
  OFFER_EDIT_OWN: 'offer.edit.own',
  OFFER_DELETE_ANY: 'offer.delete.any',
  OFFER_DELETE_OWN: 'offer.delete.own',

  RESERVATION_CANCEL_ANY: 'reservation.cancel.any',
  RESERVATION_CANCEL_OWN: 'reservation.cancel.own',
  RESERVATION_ACCEPT_ANY: 'reservation.accept.offer.any',
  RESERVATION_ACCEPT_OWN: 'reservation.accept.offer.own',
  RESERVATION_REFUSE_ANY: 'reservation.refuse.offer.any',
  RESERVATION_REFUSE_OWN: 'reservation.refuse.offer.own',

  CHAT_COMPANY_CONVERSATIONS_SEE: 'chat.company.context.conversations.see',

  INVOICE_CREATE_TRANSPORTATION_ORDER_ANY:
    'invoice.create.transportation-order.any',
  INVOICE_CORRECTIVE_INVOICE_CREATE: 'invoice.corrective-invoice.create',
  INVOICE_CORRECTIVE_NOTE_CREATE: 'invoice.corrective-note.create'
}
