import api from './APIInterceptor'

export default class AuthService {
  static login(username, password) {
    //this is the only call to API without /api part
    const APIBareUrl = process.env.GATSBY_API_URL.replace(/(\/api)$/, '')
    return api
      .get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
      .then(() => api.post('/login', { username, password }))
  }

  static logout() {
    return api.get('/logout')
  }

  static leaveImpersonalization() {
    return api.get('/impersonate/leave')
  }

  static passwordResetRequest(username) {
    const APIBareUrl = process.env.GATSBY_API_URL.replace(/(\/api)$/, '')
    return api
      .get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
      .then(() => api.post('/password/request-reset', { username }))
  }

  static passwordReset(token, password, username, passwordConfirmation) {
    const APIBareUrl = process.env.GATSBY_API_URL.replace(/(\/api)$/, '')
    return api
      .get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
      .then(() =>
        api.post('/password/reset', {
          token,
          password,
          username,
          passwordConfirmation
        })
      )
  }

  static register(values) {
    //this is the only call to API without /api part
    const APIBareUrl = process.env.GATSBY_API_URL.replace(/(\/api)$/, '')
    return api
      .get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
      .then(() => api.post('/register', values))
  }

  static contact(values) {
    //this is the only call to API without /api part
    const APIBareUrl = process.env.GATSBY_API_URL.replace(/(\/api)$/, '')
    return api
      .get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
      .then(() => api.post('/contact/guest', values))
  }
}
