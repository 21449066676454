import { includes, not, pipe, when } from 'ramda'
import { formatFilterKey } from 'src/utils/helpers'
import { renameKeysWith } from 'ramda-adjunct'
import snakeCase from 'lodash.snakecase'
import { API_STATES } from 'src/ducks/consts'

//EXPORT ONGOING
export const selectOngoingExportOffers = state => state.myOffers.exportOngoing
export const selectMyOngoingExportOffers = state =>
  state.myOffers.exportOngoing.offers

export const selectSelectedExportOffers = state =>
  state.myOffers.exportOngoing.selectedOffers

export const selectMyOffersExportFilters = state =>
  state.myOffers.exportOngoing.filters

export const selectMyOffersExportPagination = state =>
  state.myOffers.exportOngoing.pagination

export const selectMyOffersExportErrorMessage = state =>
  state.myOffers.exportOngoing.errorMessage

export const selectMyOffersExportFiltersFormatted = state => {
  const filters = selectMyOffersExportFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersExportOngoingButtonsState = state =>
  state.myOffers.exportOngoing.state.buttons

//EXPORT FINISHED
export const selectFinishedExportOffers = state => state.myOffers.exportFinished
export const selectMyFinishedExportOffers = state =>
  state.myOffers.exportFinished.offers

export const selectMyFinishedOffersExportFilters = state =>
  state.myOffers.exportFinished.filters

export const selectMyFinishedOffersExportPagination = state =>
  state.myOffers.exportFinished.pagination

export const selectMyFinishedOffersExportErrorMessage = state =>
  state.myOffers.exportFinished.errorMessage

export const selectMyFinishedOffersExportFiltersFormatted = state => {
  const filters = selectMyFinishedOffersExportFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersExportFinishedButtonsState = state =>
  state.myOffers.exportFinished.state.buttons

export const isMyOffersExportDriverGeneratedPinCodeLoading = state =>
  state.myOffers.exportFinished.pinCodeState === API_STATES.IN_PROGRESS

export const selectMyOffersExportGeneratedDriverPinCode = state =>
  state.myOffers.exportFinished.pinCode

export const selectMyOffersExportGeneratedDriverTransportationId = state =>
  state.myOffers.exportFinished.transportationId

//IMPORT ONGOING
export const selectOngoingImportOffers = state => state.myOffers.importOngoing
export const selectMyOngoingImportOffers = state =>
  state.myOffers.importOngoing.offers

export const selectSelectedImportOffers = state =>
  state.myOffers.importOngoing.selectedOffers

export const selectMyOffersImportFilters = state =>
  state.myOffers.importOngoing.filters

export const selectMyOffersImportPagination = state =>
  state.myOffers.importOngoing.pagination

export const selectMyOffersImportErrorMessage = state =>
  state.myOffers.importOngoing.errorMessage

export const selectMyOffersImportFiltersFormatted = state => {
  const filters = selectMyOffersImportFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersImportOngoingButtonsState = state =>
  state.myOffers.importOngoing.state.buttons

//IMPORT FINISHED
export const selectFinishedImportOffers = state => state.myOffers.importFinished
export const selectMyFinishedImportOffers = state =>
  state.myOffers.importFinished.offers

export const selectMyFinishedOffersImportFilters = state =>
  state.myOffers.importFinished.filters

export const selectMyFinishedOffersImportPagination = state =>
  state.myOffers.importFinished.pagination

export const selectMyFinishedOffersImportErrorMessage = state =>
  state.myOffers.importFinished.errorMessage

export const selectMyFinishedOffersImportFiltersFormatted = state => {
  const filters = selectMyFinishedOffersImportFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersImportFinishedButtonsState = state =>
  state.myOffers.importFinished.state.buttons

//POST IMPORT ONGOING
export const selectOngoingPostImportOffers = state =>
  state.myOffers.postImportOngoing

export const selectMyOngoingPostImportOffers = state =>
  state.myOffers.postImportOngoing.offers

export const selectSelectedPostImportOffers = state =>
  state.myOffers.postImportOngoing.selectedOffers

export const selectMyOffersPostImportFilters = state =>
  state.myOffers.postImportOngoing.filters

export const selectMyOffersPostImportPagination = state =>
  state.myOffers.postImportOngoing.pagination

export const selectMyOffersPostImportErrorMessage = state =>
  state.myOffers.postImportOngoing.errorMessage

export const selectMyOffersPostImportFiltersFormatted = state => {
  const filters = selectMyOffersPostImportFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersPostImportOngoingButtonsState = state =>
  state.myOffers.postImportOngoing.state.buttons

//POST IMPORT FINISHED
export const selectFinishedPostImportOffers = state =>
  state.myOffers.postImportFinished

export const selectMyFinishedPostImportOffers = state =>
  state.myOffers.postImportFinished.offers

export const selectMyFinishedOffersPostImportFilters = state =>
  state.myOffers.postImportFinished.filters

export const selectMyFinishedOffersPostImportPagination = state =>
  state.myOffers.postImportFinished.pagination

export const selectMyFinishedOffersPostImportErrorMessage = state =>
  state.myOffers.postImportFinished.errorMessage

export const selectMyFinishedOffersPostImportFiltersFormatted = state => {
  const filters = selectMyFinishedOffersPostImportFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersPostImportFinishedButtonsState = state =>
  state.myOffers.postImportFinished.state.buttons

//FREE CARRIER ONGOING
export const selectOngoingFreeCarrierOffers = state =>
  state.myOffers.freeCarrierOngoing

export const selectMyOngoingFreeCarrierOffers = state =>
  state.myOffers.freeCarrierOngoing.offers

export const selectSelectedFreeCarrierOffers = state =>
  state.myOffers.freeCarrierOngoing.selectedOffers

export const selectMyOffersFreeCarrierFilters = state =>
  state.myOffers.freeCarrierOngoing.filters

export const selectMyOffersFreeCarrierPagination = state =>
  state.myOffers.freeCarrierOngoing.pagination

export const selectMyOffersFreeCarrierErrorMessage = state =>
  state.myOffers.freeCarrierOngoing.errorMessage

export const selectMyOffersFreeCarrierFiltersFormatted = state => {
  const filters = selectMyOffersFreeCarrierFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersFreeCarrierOngoingButtonsState = state =>
  state.myOffers.freeCarrierOngoing.state.buttons

//FREE CARRIER FINISHED
export const selectFinishedFreeCarrierOffers = state =>
  state.myOffers.freeCarrierFinished
export const selectMyFinishedFreeCarrierOffers = state =>
  state.myOffers.freeCarrierFinished.offers

export const selectMyFinishedOffersFreeCarrierFilters = state =>
  state.myOffers.freeCarrierFinished.filters

export const selectMyFinishedOffersFreeCarrierPagination = state =>
  state.myOffers.freeCarrierFinished.pagination

export const selectMyFinishedOffersFreeCarrierErrorMessage = state =>
  state.myOffers.freeCarrierFinished.errorMessage

export const selectMyFinishedOffersFreeCarrierFiltersFormatted = state => {
  const filters = selectMyFinishedOffersFreeCarrierFilters(state)
  const omittedValues = ['sort', 'page', 'limit']

  return pipe(
    renameKeysWith(snakeCase),
    renameKeysWith(
      when(key => not(includes(key, omittedValues)), formatFilterKey)
    )
  )(filters.toJS())
}

export const selectMyOffersFreeCarrierFinishedButtonsState = state =>
  state.myOffers.freeCarrierFinished.state.buttons
