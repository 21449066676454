import api from 'src/services/APIInterceptor'

export default class TermsService {
  static getCurrentTerms() {
    return api.get('/terms-of-service')
  }

  static acceptTerms() {
    return api.patch('/terms-of-service/accept')
  }

  static getCurrentTermsPdf() {
    return api.get('/terms-of-service/pdf')
  }
}
