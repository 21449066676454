import { parseFiltersToQuery, getOriginQueryParams } from 'src/utils/helpers'
import api from './APIInterceptor'
import qs from 'qs'
import { isEmptyString, isFalsy, isEmptyArray, isNotEmpty } from 'ramda-adjunct'
import { forEach, keys, omit, isNil, evolve, filter, pipe, not } from 'ramda'

const parsePayload = evolve({
  //filter out empty strings and null values
  filter: pipe(filter(pipe(isEmptyString, not)), filter(pipe(isNil, not)))
})

export default class ExchangeService {
  // EXPORT
  static getExportOffersList(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/export-cargo/offers/?${query}`)
  }

  static getExportOffersListByTab(tabId, filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/export-cargo/offers/tabs/${tabId}?${query}`)
  }

  static showExportOffer({ id, query = {} }) {
    const _paramString = getOriginQueryParams(query)

    return api.get(`/exchange/export-cargo/offers/${id}${_paramString}`)
  }

  static reserveExportOffer(id) {
    return api.post(`/exchange/export-cargo/offers/${id}/reservations`)
  }

  static bidExportOffer(id, values) {
    return api.post(`/exchange/export-cargo/offers/${id}/bids`, values)
  }

  static refreshExportOffer(id) {
    return api.get(`/exchange/export-cargo/offers/${id}/refresh`)
  }

  static makeExportOfferPublic(id) {
    return api.get(`/exchange/export-cargo/offers/${id}/make-public`)
  }

  static makeImportOfferPublic(id) {
    return api.get(`/exchange/import-cargo/offers/${id}/make-public`)
  }

  static makePostImportOfferPublic(id) {
    return api.get(`/exchange/post-import-containers/offers/${id}/make-public`)
  }

  static makeFreeCarrierOfferPublic(id) {
    return api.get(`/exchange/free-carriers/offers/${id}/make-public`)
  }

  static updateExportOffer(id, values) {
    return api.patch(`/exchange/export-cargo/offers/${id}`, values)
  }

  static getExportFilterTabsList() {
    return api.get('/exchange/export-cargo/tabs')
  }

  static createExportFilterTab(payload) {
    return api.post('/exchange/export-cargo/tabs', payload)
  }

  static removeExportFilterTab(id) {
    return api.delete(`/exchange/export-cargo/tabs/${id}`)
  }

  static async createDebtCollectionExport(id, values) {
    const formData = new FormData()
    forEach(
      key => formData.append(key, values[key]),
      keys(omit(['files'], values))
    )
    if (isNotEmpty(values.files) && Array.isArray(values.files)) {
      const files = await Promise.all(
        values.files.map(async file =>
          fetch(file.image.url).then(r => r.blob())
        )
      )
      files.forEach((file, index) =>
        formData.append('files[]', file, values.files[index].name)
      )
    }

    return api.post(
      `/me/export-cargo/offers/finished/${id}/debt-collections`,
      formData
    )
  }

  static prepareExportOrder(id) {
    return api.get(
      `/me/export-cargo/offers/finished/${id}/transportation-orders`
    )
  }

  // IMPORT
  static getImportOffersList(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/import-cargo/offers?${query}`)
  }

  static getImportOffersListByTab(tabId, filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/import-cargo/offers/tabs/${tabId}?${query}`)
  }

  static showImportOffer({ id, query = {} }) {
    const _paramString = getOriginQueryParams(query)

    return api.get(`/exchange/import-cargo/offers/${id}${_paramString}`)
  }

  static reserveImportOffer(id) {
    return api.post(`/exchange/import-cargo/offers/${id}/reservations`)
  }

  static bidImportOffer(id, values) {
    return api.post(`/exchange/import-cargo/offers/${id}/bids`, values)
  }

  static refreshImportOffer(id) {
    return api.get(`/exchange/import-cargo/offers/${id}/refresh`)
  }

  static updateImportOffer(id, values) {
    return api.patch(`/exchange/import-cargo/offers/${id}`, values)
  }

  static getImportFilterTabsList() {
    return api.get('/exchange/import-cargo/tabs')
  }

  static createImportFilterTab(payload) {
    return api.post('/exchange/import-cargo/tabs', payload)
  }

  static removeImportFilterTab(id) {
    return api.delete(`/exchange/import-cargo/tabs/${id}`)
  }

  static async createDebtCollectionImport(id, values) {
    const formData = new FormData()
    forEach(
      key => formData.append(key, values[key]),
      keys(omit(['files'], values))
    )
    if (isNotEmpty(values.files) && Array.isArray(values.files)) {
      const files = await Promise.all(
        values.files.map(async file =>
          fetch(file.image.url).then(r => r.blob())
        )
      )
      files.forEach((file, index) =>
        formData.append('files[]', file, values.files[index].name)
      )
    }

    return api.post(
      `/me/import-cargo/offers/finished/${id}/debt-collections`,
      formData
    )
  }

  static prepareImportOrder(id) {
    return api.get(
      `/me/import-cargo/offers/finished/${id}/transportation-orders`
    )
  }

  // POST IMPORT
  static getPostImportOffersList(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/post-import-containers/offers/?${query}`)
  }

  static getPostImportOffersListByTab(tabId, filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(
      `/exchange/post-import-containers/offers/tabs/${tabId}?${query}`
    )
  }

  static showPostImportOffer({ id, query = {} }) {
    const _paramString = getOriginQueryParams(query)

    return api.get(
      `/exchange/post-import-containers/offers/${id}${_paramString}`
    )
  }

  static reservePostImportOffer(id) {
    return api.post(
      `/exchange/post-import-containers/offers/${id}/reservations`
    )
  }

  static bidPostImportOffer(id, values) {
    return api.post(
      `/exchange/post-import-containers/offers/${id}/bids`,
      values
    )
  }

  static refreshPostImportOffer(id) {
    return api.get(`/exchange/post-import-containers/offers/${id}/refresh`)
  }

  static updatePostImportOffer(id, values) {
    return api.patch(`/exchange/post-import-containers/offers/${id}`, values)
  }

  static getPostImportFilterTabsList() {
    return api.get('/exchange/post-import-containers/tabs')
  }

  static createPostImportFilterTab(payload) {
    return api.post('/exchange/post-import-containers/tabs', payload)
  }

  static removePostImportFilterTab(id) {
    return api.delete(`/exchange/post-import-containers/tabs/${id}`)
  }

  static async createDebtCollectionPostImport(id, values) {
    const formData = new FormData()
    forEach(
      key => formData.append(key, values[key]),
      keys(omit(['files'], values))
    )
    if (isNotEmpty(values.files) && Array.isArray(values.files)) {
      const files = await Promise.all(
        values.files.map(async file =>
          fetch(file.image.url).then(r => r.blob())
        )
      )
      files.forEach((file, index) =>
        formData.append('files[]', file, values.files[index].name)
      )
    }

    return api.post(
      `/me/post-import-containers/offers/finished/${id}/debt-collections`,
      formData
    )
  }

  static preparePostImportOrder(id) {
    return api.get(
      `/me/post-import-containers/offers/finished/${id}/transportation-orders`
    )
  }

  //FREE CARRIERS
  static getFreeCarriersOffersList(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/free-carriers/offers/?${query}`)
  }

  static getFreeCarriersOffersListByTab(tabId, filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/exchange/free-carriers/offers/tabs/${tabId}?${query}`)
  }

  static showFreeCarrierOffer({ id, query = {} }) {
    const _paramString = getOriginQueryParams(query)

    return api.get(`/exchange/free-carriers/offers/${id}${_paramString}`)
  }

  static reserveFreeCarrierOffer(id) {
    return api.post(`/exchange/free-carriers/offers/${id}/reservations`)
  }

  static bidFreeCarrierOffer(id, values) {
    return api.post(`/exchange/free-carriers/offers/${id}/bids`, values)
  }

  static refreshFreeCarrierOffer(id) {
    return api.get(`/exchange/free-carriers/offers/${id}/refresh`)
  }

  static updateFreeCarrierOffer(id, values) {
    return api.patch(`/exchange/free-carriers/offers/${id}`, values)
  }

  static getFreeCarriersFilterTabsList() {
    return api.get('/exchange/free-carriers/tabs')
  }

  static createFreeCarriersFilterTab(payload) {
    return api.post('/exchange/free-carriers/tabs', payload)
  }

  static removeFreeCarriersFilterTab(id) {
    return api.delete(`/exchange/free-carriers/tabs/${id}`)
  }

  static async createDebtCollectionFreeCarriers(id, values) {
    const formData = new FormData()
    forEach(
      key => formData.append(key, values[key]),
      keys(omit(['files'], values))
    )
    if (isNotEmpty(values.files) && Array.isArray(values.files)) {
      const files = await Promise.all(
        values.files.map(async file =>
          fetch(file.image.url).then(r => r.blob())
        )
      )
      files.forEach((file, index) =>
        formData.append('files[]', file, values.files[index].name)
      )
    }

    return api.post(
      `/me/free-carriers/offers/finished/${id}/debt-collections`,
      formData
    )
  }

  static prepareFreeCarriersOrder(id) {
    return api.get(
      `/me/free-carriers/offers/finished/${id}/transportation-orders`
    )
  }

  static getExchangeRoomMembers(exchangeType, filters) {
    const query = parsePayload(filters)
    return api.get(
      `/me/${exchangeType}/rooms/private/members?${qs.stringify(query)}`
    )
  }

  static removeExchangeRoomMember(exchangeType, memberId) {
    return api.delete(`/me/${exchangeType}/rooms/private/members/${memberId}`)
  }

  static addExchangeRoomMember(exchangeType, memberId) {
    return api.post(`/me/${exchangeType}/rooms/private/members`, { memberId })
  }

  static getExchangeRoomMember(exchangeType, memberId) {
    return api.get(`/me/${exchangeType}/rooms/private/members/${memberId}`)
  }

  static searchExchangeRoomMembers(exchangeType, filters) {
    const query = parsePayload(filters)
    return api.get(
      `/me/${exchangeType}/rooms/private/members/search?${qs.stringify(query)}`,
      {
        cache: {
          maxAge: 0
        }
      }
    )
  }

  //GENERAL
  static getExchangeOffersCounters() {
    return api.get('/exchange/offers/counters')
  }
}
