import {
  not,
  map,
  isEmpty,
  pipe,
  pick,
  evolve,
  prop,
  drop,
  toLower,
  keys
} from 'ramda'
import { Contractor, Currency } from 'src/ducks/records'
import {
  CorrectiveInvoice,
  CorrectiveNote,
  Invoice,
  InvoiceItem,
  Payment,
  Saft
} from 'src/features/invoices/duck/records'
import { OrderListItem } from 'src/features/orders/duck/records'
import { COUNTRIES, DATE_API_FORMAT } from 'src/ducks/consts'
import { isNilOrEmpty } from 'ramda-adjunct'
import moment from 'moment'
import { replaceNullWithEmptyString } from 'src/utils/helpers'
import snakeCase from 'lodash.snakecase'
import { isCorrectiveInvoice } from 'src/features/invoices/duck/helpers'

const normalizeInvoiceItem = el =>
  new InvoiceItem({
    ...el,
    unitPrice: Currency({ ...el.unitPrice }),
    subtotal: Currency({ ...el.subtotal }),
    tax: Currency({ ...el.tax }),
    total: Currency({ ...el.total })
  })

const normalizeCorrectiveNote = el =>
  new CorrectiveNote({
    ...el,
    invoice: new Invoice({ ...el.invoice }),
    issuer: new Contractor({ ...el.issuer })
  })

export const normalizeInvoice = el => {
  const invoice = replaceNullWithEmptyString(el)
  return new Invoice({
    ...invoice,
    isCorrectiveInvoice: isCorrectiveInvoice(el),
    additionalInfo: invoice.additionalInfo || '',
    payments: invoice.payments?.map(
      payment =>
        new Payment({ ...payment, amount: new Currency({ ...payment.amount }) })
    ),
    subtotal: new Currency({ ...invoice.subtotal }),
    tax: new Currency({ ...invoice.tax }),
    total: new Currency({ ...invoice.total }),
    amountDue: new Currency({ ...invoice.amountDue }),
    amountPaid: new Currency({ ...invoice.amountPaid }),
    items: not(isEmpty(invoice.items))
      ? invoice.items?.map(item => normalizeInvoiceItem({ ...item }))
      : [InvoiceItem()],
    correctiveNotes: not(isEmpty(invoice.correctiveNotes))
      ? invoice.correctiveNotes?.map(note =>
          normalizeCorrectiveNote({ ...note })
        )
      : [],
    issuer: new Contractor({ ...invoice.issuer }),
    transportationOrder: new OrderListItem({
      ...invoice.transportationOrder
    }),
    saft: new Saft({ ...invoice.saft }),
    numberPostfix: isNilOrEmpty(invoice.numberPostfix)
      ? '/'
      : invoice.numberPostfix,
    recipientCompanyCountryCode: isNilOrEmpty(
      invoice.recipientCompanyCountryCode
    )
      ? COUNTRIES.PL
      : invoice.recipientCompanyCountryCode
  })
}

export const normalizeCorrectiveInvoice = el => {
  const invoice = replaceNullWithEmptyString(el)
  return new CorrectiveInvoice({
    ...invoice,
    subtotal: new Currency({ ...invoice.subtotal }),
    tax: new Currency({ ...invoice.tax }),
    total: new Currency({ ...invoice.total }),
    amountDue: new Currency({ ...invoice.amountDue }),
    amountPaid: new Currency({ ...invoice.amountPaid }),
    saft: new Saft({ ...invoice.saft }),
    invoiceTotalAfterCorrection: new Currency({
      ...invoice.invoiceTotalAfterCorrection
    }),
    items: not(isEmpty(invoice.items))
      ? invoice.items?.map(item => normalizeInvoiceItem({ ...item }))
      : [],
    invoice: normalizeInvoice(invoice.invoice),
    issuer: new Contractor(invoice.issuer),
    payments: invoice.payments?.map(
      payment =>
        new Payment({ ...payment, amount: new Currency({ ...payment.amount }) })
    ),
    availableCorrectionReasons: keys(invoice.availableCorrectionReasons).map(
      key => ({
        value: snakeCase(key),
        label: invoice.availableCorrectionReasons[key]
      })
    )
  })
}

export const normalizeInvoiceItemForSave = pipe(
  pick([
    'description',
    'qty',
    'unit',
    'unitPrice',
    'taxRate',
    'pkwiuCode',
    'gtuCode'
  ]),
  evolve({
    unitPrice: prop('float')
  })
)

export const normalizeInvoiceForSave = pipe(
  pick([
    'numberingScheme',
    'numberPostfix',
    'issuePlace',
    'issueDate',
    'sellDate',
    'dueDate',
    'recipientCompanyId',
    'recipientCompanyName',
    'recipientCompanyAddress',
    'recipientCompanyAddressLine2',
    'recipientCompanyCity',
    'recipientCompanyPostalCode',
    'recipientCompanyCountryCode',
    'recipientCompanyTaxId',
    'recipientCompanyEmail',
    'currency',
    'amountPaid',
    'paymentMethod',
    'paymentBankAccount',
    'paymentBankName',
    'isSplitPayment',
    'saft',
    'additionalInfo',
    'items',
    'pdfLang'
  ]),
  evolve({
    items: map(normalizeInvoiceItemForSave),
    amountPaid: prop('float'),
    numberPostfix: drop(1),
    pdfLang: toLower
  })
)

export const normalizeCorrectiveInvoiceForSave = pipe(
  pick([
    'issuePlace',
    'issueDate',
    'dueDate',
    'correctionReason',
    'paymentMethod',
    'paymentBankAccount',
    'paymentBankName',
    'isSplitPayment',
    'saft',
    'additionalInfo',
    'items'
  ]),
  evolve({
    items: map(normalizeInvoiceItemForSave),
    amountPaid: prop('float')
  })
)

export const normalizeInvoiceForOrder = pipe(
  pick([
    'transportationOrderId',
    'recipientCompanyId',
    'numberingScheme',
    'numberPostfix',
    'issuePlace',
    'issueDate',
    'sellDate',
    'dueDate',
    'currency',
    'amountPaid',
    'paymentMethod',
    'paymentBankAccount',
    'paymentBankName',
    'isSplitPayment',
    'saft',
    'additionalInfo',
    'items',
    'pdfLang'
  ]),
  evolve({
    items: map(normalizeInvoiceItemForSave),
    amountPaid: prop('float'),
    numberPostfix: drop(1),
    pdfLang: toLower
  })
)

export const normalizeInvoicesList = map(normalizeInvoice)

export const normalizeMarkAsPaidValues = evolve({
  paidAt: date => moment(date).format(DATE_API_FORMAT)
})
