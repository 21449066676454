import { combineReducers } from 'redux'
import { API_STATES } from 'src/ducks/consts'
import { Pagination } from 'src/ducks/records'
import {
  MyReservation,
  MyReservationsFilters
} from 'src/features/reservations/duck/records'
import {
  normalizeExportReservations,
  normalizeFreeCarrierReservations,
  normalizeImportReservations,
  normalizePostImportReservations
} from 'src/features/reservations/duck/normalizers'
import {
  getMyExportFinishedReservationsRoutine,
  getMyExportOngoingReservationsRoutine,
  getMyFreeCarrierFinishedReservationsRoutine,
  getMyFreeCarrierOngoingReservationsRoutine,
  getMyImportFinishedReservationsRoutine,
  getMyImportOngoingReservationsRoutine,
  getMyPostImportFinishedReservationsRoutine,
  getMyPostImportOngoingReservationsRoutine,
  setMyExportFinishedReservationsFiltersRoutine,
  setMyExportOngoingReservationsFiltersRoutine,
  setMyFreeCarrierFinishedReservationsFiltersRoutine,
  setMyFreeCarrierOngoingReservationsFiltersRoutine,
  setMyImportFinishedReservationsFiltersRoutine,
  setMyImportOngoingReservationsFiltersRoutine,
  setMyPostImportFinishedReservationsFiltersRoutine,
  setMyPostImportOngoingReservationsFiltersRoutine
} from './actions'

const myReservationsExportOngoingReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyExportOngoingReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyExportOngoingReservationsRoutine.SUCCESS:
      return state
        .set('reservations', normalizeExportReservations(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyExportOngoingReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyExportOngoingReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyExportOngoingReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const myReservationsExportFinishedReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyExportFinishedReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyExportFinishedReservationsRoutine.SUCCESS:
      return state
        .set('reservations', normalizeExportReservations(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyExportFinishedReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyExportFinishedReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyExportFinishedReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const myReservationsImportOngoingReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyImportOngoingReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyImportOngoingReservationsRoutine.SUCCESS:
      return state
        .set('reservations', normalizeImportReservations(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyImportOngoingReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyImportOngoingReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyImportOngoingReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const myReservationsImportFinishedReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyImportFinishedReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyImportFinishedReservationsRoutine.SUCCESS:
      return state
        .set('reservations', normalizeImportReservations(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyImportFinishedReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyImportFinishedReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyImportFinishedReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const myReservationsPostImportOngoingReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyPostImportOngoingReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyPostImportOngoingReservationsRoutine.SUCCESS:
      return state
        .set(
          'reservations',
          normalizePostImportReservations(action.payload.data)
        )
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyPostImportOngoingReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyPostImportOngoingReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyPostImportOngoingReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const myReservationsPostImportFinishedReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyPostImportFinishedReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyPostImportFinishedReservationsRoutine.SUCCESS:
      return state
        .set(
          'reservations',
          normalizePostImportReservations(action.payload.data)
        )
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyPostImportFinishedReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyPostImportFinishedReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyPostImportFinishedReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const myReservationsFreeCarrierOngoingReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyFreeCarrierOngoingReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyFreeCarrierOngoingReservationsRoutine.SUCCESS:
      return state
        .set(
          'reservations',
          normalizeFreeCarrierReservations(action.payload.data)
        )
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyFreeCarrierOngoingReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyFreeCarrierOngoingReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFreeCarrierOngoingReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

const myReservationsFreeCarrierFinishedReducer = (
  state = new MyReservation(),
  action
) => {
  switch (action.type) {
    case getMyFreeCarrierFinishedReservationsRoutine.REQUEST:
      return state
        .setIn(['state', 'list'], API_STATES.IN_PROGRESS)
        .set('errorMessage', null)
    case getMyFreeCarrierFinishedReservationsRoutine.SUCCESS:
      return state
        .set(
          'reservations',
          normalizeFreeCarrierReservations(action.payload.data)
        )
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyFreeCarrierFinishedReservationsRoutine.FAILURE:
      return state
        .set('errorMessage', action.payload.message)
        .setIn(['state', 'list'], API_STATES.DONE)
    case getMyFreeCarrierFinishedReservationsRoutine.FULFILL:
      return state
        .set('errorMessage', null)
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFreeCarrierFinishedReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyReservationsFilters({
            ...v.toJS(),
            ...filters
          })
      )
    default:
      return state
  }
}

export const myReservationsReducer = combineReducers({
  exportOngoing: myReservationsExportOngoingReducer,
  exportFinished: myReservationsExportFinishedReducer,
  importOngoing: myReservationsImportOngoingReducer,
  importFinished: myReservationsImportFinishedReducer,
  freeCarrierOngoing: myReservationsFreeCarrierOngoingReducer,
  freeCarrierFinished: myReservationsFreeCarrierFinishedReducer,
  postImportOngoing: myReservationsPostImportOngoingReducer,
  postImportFinished: myReservationsPostImportFinishedReducer
})
