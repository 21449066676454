module.exports = {
  HOME: '/',
  ABOUT: '/about',
  PRICING: '/pricing',
  JOBS: '/jobs',
  CONTACT: '/contact',
  HELP: '/help',
  SIGN_UP: '/register',
  SIGN_IN: '/login',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  NEW_PASSWORD: '/new-password',
  REGULATIONS: '/regulations',
  PRIVACY_POLICY: '/privacy-policy',
  APP_BASE_PATH: '/app',
  APP_DASHBOARD: '/app/dashboard',
  APP_MESSAGES: '/app/messages',
  APP_CONVERSATION: '/app/conversation/:id',
  APP_NOTIFICATIONS: '/app/notifications',
  APP_ACCOUNT: '/app/account',
  APP_COMPANY: '/app/companies/:id',
  APP_CONTACT: '/app/account?tab=contact',
  CERTIFICATE: '/certificate'
}
