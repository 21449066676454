import React from 'react'
import { slice } from 'ramda'
import CustomIcon from 'src/components/CustomIcon'
import { PERMISSIONS } from 'src/utils/permissions'
import { EXCHANGE_TYPES } from 'src/ducks/consts'
import translate from 'src/intl/translate'
import { REFERENCE_NUMBER_PREFIX } from 'src/features/stocks/ducks/consts'
import * as routes from 'src/utils/routes'

export const MENU_ITEMS = {
  DASHBOARD: 'dashboard',
  STOCKS: 'stocks',
  STOCKS_CARGO_EXPORT: 'stocks-cargo-export',
  STOCKS_CARGO_IMPORT: 'stocks-cargo-import',
  STOCKS_POST_IMPORT: 'stocks-post-import',
  STOCKS_FREE_CARRIERS: 'stocks-free-carriers',
  MY_EXPORT_OFFERS: 'my-export-offers',
  MY_IMPORT_OFFERS: 'my-import-offers',
  MY_POST_IMPORT_OFFERS: 'my-post-import-offers',
  MY_FREE_CARRIERS_OFFERS: 'my-free-carriers-offers',
  MY_EXPORT_RESERVATIONS: 'my-export-reservations',
  MY_IMPORT_RESERVATIONS: 'my-import-reservations',
  MY_POST_IMPORT_RESERVATIONS: 'my-post-import-reservations',
  MY_FREE_CARRIERS_RESERVATIONS: 'my-free-carriers-reservations',
  OFFER_EXPORT: 'offer-export',
  OFFER_IMPORT: 'offer-import',
  OFFER_FREE_CARRIERS: 'offer-free-carriers',
  OFFER_POST_IMPORT: 'offer-post-import',
  OFFER_CONTRACT: 'offer-contract',
  OFFER_FORM_EXPORT: 'offer-form-export',
  OFFER_FORM_IMPORT: 'offer-form-import',
  OFFER_FORM_FREE_CARRIERS: 'offer-form-free-carriers',
  OFFER_FORM_POST_IMPORT: 'offer-form-post-import',
  OFFER_FORM_CONTRACTS: 'offer-form-contracts',
  RESERVATION: 'reservation',
  CONTRACTS: 'contracts',
  CONTRACTS_OFFERS: 'contracts-offers',
  CONTRACTS_RESERVATIONS: 'contracts-reservations',
  TRACKING: 'tracking',
  TRACKING_DETAILS: 'tracking-details',
  TRACKING_HISTORY: 'tracking-history',
  TRACKING_EDIT: 'tracking-edit',
  ORDERS: 'orders',
  ORDER_ADD: 'order-add',
  ORDER_EDIT: 'order-edit',
  ORDER_DETAILS: 'order-details',
  COMPANIES: 'companies',
  COMPANY: 'company',
  DEBT_COLLECTION: 'debt-collection',
  INVOICES: 'invoices',
  INVOICES_ADD: 'add-invoice',
  INVOICE_DETAILS: 'invoice-details',
  INVOICE_ADD_NOTE: 'add-note',
  INVOICE_NOTE_DETAILS: 'corrective-note-details',
  CORRECTIVE_INVOICE_ADD: 'add-corrective-invoice',
  CORRECTIVE_INVOICE_DETAILS: 'corrective-invoice-details'
}

export const MENU_LIST = [
  {
    name: 'dashboard.title',
    value: MENU_ITEMS.DASHBOARD,
    activeWhen: [MENU_ITEMS.DASHBOARD],
    icon: <CustomIcon name='home' size={24} />
  },
  {
    name: 'dashboard.exportTitle',
    value: MENU_ITEMS.STOCKS_CARGO_EXPORT,
    activeWhen: [
      MENU_ITEMS.STOCKS_CARGO_EXPORT,
      MENU_ITEMS.OFFER_FORM_EXPORT,
      MENU_ITEMS.OFFER_EXPORT
    ],
    icon: <CustomIcon name='export' size={24} />,
    permission: PERMISSIONS.STOCK_EXPORT_ACCESS,
    type: EXCHANGE_TYPES.EXPORT,
    submenu: [
      {
        name: 'dashboard.stock',
        value: MENU_ITEMS.STOCKS_CARGO_EXPORT,
        activeWhen: [
          MENU_ITEMS.STOCKS_CARGO_EXPORT,
          MENU_ITEMS.OFFER_FORM_EXPORT,
          MENU_ITEMS.OFFER_EXPORT
        ],
        showWhen: [
          MENU_ITEMS.MY_EXPORT_OFFERS,
          MENU_ITEMS.MY_EXPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_CARGO_EXPORT,
          MENU_ITEMS.OFFER_FORM_EXPORT,
          MENU_ITEMS.OFFER_EXPORT
        ],
        icon: null,
        permission: PERMISSIONS.STOCK_EXPORT_ACCESS,
        type: EXCHANGE_TYPES.EXPORT
      },
      {
        name: 'dashboard.myOffers',
        value: MENU_ITEMS.MY_EXPORT_OFFERS,
        showWhen: [
          MENU_ITEMS.MY_EXPORT_OFFERS,
          MENU_ITEMS.MY_EXPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_CARGO_EXPORT,
          MENU_ITEMS.OFFER_FORM_EXPORT,
          MENU_ITEMS.OFFER_EXPORT
        ],
        activeWhen: [MENU_ITEMS.MY_EXPORT_OFFERS],
        icon: <CustomIcon name='offer' size={24} />
      },
      {
        name: 'dashboard.myReservations',
        value: MENU_ITEMS.MY_EXPORT_RESERVATIONS,
        showWhen: [
          MENU_ITEMS.MY_EXPORT_OFFERS,
          MENU_ITEMS.MY_EXPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_CARGO_EXPORT,
          MENU_ITEMS.OFFER_FORM_EXPORT,
          MENU_ITEMS.OFFER_EXPORT
        ],
        activeWhen: [MENU_ITEMS.MY_EXPORT_RESERVATIONS],
        icon: <CustomIcon name='reservation' size={24} />
      }
    ]
  },
  {
    name: 'dashboard.importTitle',
    value: MENU_ITEMS.STOCKS_CARGO_IMPORT,
    activeWhen: [
      MENU_ITEMS.STOCKS_CARGO_IMPORT,
      MENU_ITEMS.OFFER_FORM_IMPORT,
      MENU_ITEMS.OFFER_IMPORT
    ],
    icon: <CustomIcon name='import' size={24} />,
    permission: PERMISSIONS.STOCK_IMPORT_ACCESS,
    type: EXCHANGE_TYPES.IMPORT,
    submenu: [
      {
        name:  'dashboard.stock',
        value: MENU_ITEMS.STOCKS_CARGO_IMPORT,
        activeWhen: [
          MENU_ITEMS.STOCKS_CARGO_IMPORT,
          MENU_ITEMS.OFFER_FORM_IMPORT,
          MENU_ITEMS.OFFER_IMPORT
        ],
        showWhen: [
          MENU_ITEMS.MY_IMPORT_OFFERS,
          MENU_ITEMS.MY_IMPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_CARGO_IMPORT,
          MENU_ITEMS.OFFER_FORM_IMPORT,
          MENU_ITEMS.OFFER_IMPORT
        ],
        icon: null,
        permission: PERMISSIONS.STOCK_IMPORT_ACCESS,
        type: EXCHANGE_TYPES.IMPORT
      },
      {
        name:  'dashboard.myOffers',
        value: MENU_ITEMS.MY_IMPORT_OFFERS,
        showWhen: [
          MENU_ITEMS.MY_IMPORT_OFFERS,
          MENU_ITEMS.MY_IMPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_CARGO_IMPORT,
          MENU_ITEMS.OFFER_FORM_IMPORT,
          MENU_ITEMS.OFFER_IMPORT
        ],
        activeWhen: [MENU_ITEMS.MY_IMPORT_OFFERS],
        icon: <CustomIcon name='offer' size={24} />
      },
      {
        name: 'dashboard.myReservations',
        value: MENU_ITEMS.MY_IMPORT_RESERVATIONS,
        showWhen: [
          MENU_ITEMS.MY_IMPORT_OFFERS,
          MENU_ITEMS.MY_IMPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_CARGO_IMPORT,
          MENU_ITEMS.OFFER_FORM_IMPORT,
          MENU_ITEMS.OFFER_IMPORT
        ],
        activeWhen: [MENU_ITEMS.MY_IMPORT_RESERVATIONS],
        icon: <CustomIcon name='reservation' size={24} />
      }
    ]
  },
  {
    name:  'dashboard.postImportTitle',
    value: MENU_ITEMS.STOCKS_POST_IMPORT,
    activeWhen: [
      MENU_ITEMS.STOCKS_POST_IMPORT,
      MENU_ITEMS.OFFER_FORM_POST_IMPORT,
      MENU_ITEMS.OFFER_POST_IMPORT
    ],
    icon: <CustomIcon name='container' size={24} />,
    permission: PERMISSIONS.STOCK_POST_IMPORT_ACCESS,
    type: EXCHANGE_TYPES.POST_IMPORT_CONTAINERS,
    submenu: [
      {
        name: 'dashboard.stock',
        value: MENU_ITEMS.STOCKS_POST_IMPORT,
        activeWhen: [
          MENU_ITEMS.STOCKS_POST_IMPORT,
          MENU_ITEMS.OFFER_FORM_POST_IMPORT,
          MENU_ITEMS.OFFER_POST_IMPORT
        ],
        showWhen: [
          MENU_ITEMS.MY_POST_IMPORT_OFFERS,
          MENU_ITEMS.MY_POST_IMPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_POST_IMPORT,
          MENU_ITEMS.OFFER_FORM_POST_IMPORT,
          MENU_ITEMS.OFFER_POST_IMPORT
        ],
        icon: null,
        permission: PERMISSIONS.STOCK_POST_IMPORT_ACCESS,
        type: EXCHANGE_TYPES.POST_IMPORT_CONTAINERS
      },
      {
        name:  'dashboard.myOffers',
        value: MENU_ITEMS.MY_POST_IMPORT_OFFERS,
        showWhen: [
          MENU_ITEMS.MY_POST_IMPORT_OFFERS,
          MENU_ITEMS.MY_POST_IMPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_POST_IMPORT,
          MENU_ITEMS.OFFER_FORM_POST_IMPORT,
          MENU_ITEMS.OFFER_POST_IMPORT
        ],
        activeWhen: [MENU_ITEMS.MY_POST_IMPORT_OFFERS],
        icon: <CustomIcon name='offer' size={24} />
      },
      {
        name: 'dashboard.myReservations',
        value: MENU_ITEMS.MY_POST_IMPORT_RESERVATIONS,
        showWhen: [
          MENU_ITEMS.MY_POST_IMPORT_OFFERS,
          MENU_ITEMS.MY_POST_IMPORT_RESERVATIONS,
          MENU_ITEMS.STOCKS_POST_IMPORT,
          MENU_ITEMS.OFFER_FORM_POST_IMPORT,
          MENU_ITEMS.OFFER_POST_IMPORT
        ],
        activeWhen: [MENU_ITEMS.MY_POST_IMPORT_RESERVATIONS],
        icon: <CustomIcon name='reservation' size={24} />
      }
    ]
  },
  {
    name: 'dashboard.freeCarriersTitle',
    value: MENU_ITEMS.STOCKS_FREE_CARRIERS,
    activeWhen: [
      MENU_ITEMS.STOCKS_FREE_CARRIERS,
      MENU_ITEMS.OFFER_FORM_FREE_CARRIERS,
      MENU_ITEMS.OFFER_FREE_CARRIERS
    ],
    icon: <CustomIcon name='truck' size={24} />,
    permission: PERMISSIONS.STOCK_FREE_CARRIERS_ACCESS,
    type: EXCHANGE_TYPES.FREE_CARRIERS,
    submenu: [
      {
        name:  'dashboard.stock',
        value: MENU_ITEMS.STOCKS_FREE_CARRIERS,
        activeWhen: [
          MENU_ITEMS.STOCKS_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FORM_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FREE_CARRIERS
        ],
        icon: null,
        showWhen: [
          MENU_ITEMS.MY_FREE_CARRIERS_OFFERS,
          MENU_ITEMS.MY_FREE_CARRIERS_RESERVATIONS,
          MENU_ITEMS.STOCKS_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FORM_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FREE_CARRIERS
        ],
        permission: PERMISSIONS.STOCK_FREE_CARRIERS_ACCESS,
        type: EXCHANGE_TYPES.FREE_CARRIERS
      },
      {
        name: 'dashboard.myOffers',
        value: MENU_ITEMS.MY_FREE_CARRIERS_OFFERS,
        showWhen: [
          MENU_ITEMS.MY_FREE_CARRIERS_OFFERS,
          MENU_ITEMS.MY_FREE_CARRIERS_RESERVATIONS,
          MENU_ITEMS.STOCKS_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FORM_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FREE_CARRIERS
        ],
        activeWhen: [MENU_ITEMS.MY_FREE_CARRIERS_OFFERS],
        icon: <CustomIcon name='offer' size={24} />
      },
      {
        name: 'dashboard.myReservations',
        value: MENU_ITEMS.MY_FREE_CARRIERS_RESERVATIONS,
        showWhen: [
          MENU_ITEMS.MY_FREE_CARRIERS_OFFERS,
          MENU_ITEMS.MY_FREE_CARRIERS_RESERVATIONS,
          MENU_ITEMS.STOCKS_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FORM_FREE_CARRIERS,
          MENU_ITEMS.OFFER_FREE_CARRIERS
        ],
        activeWhen: [MENU_ITEMS.MY_FREE_CARRIERS_RESERVATIONS],
        icon: <CustomIcon name='reservation' size={24} />
      }
    ]
  },
  {
    name: 'dashboard.contracts',
    value: MENU_ITEMS.CONTRACTS,
    activeWhen: [
      MENU_ITEMS.CONTRACTS,
      MENU_ITEMS.OFFER_FORM_CONTRACTS,
      MENU_ITEMS.OFFER_CONTRACT
    ],
    icon: <CustomIcon name='description' size={24} />,
    type: EXCHANGE_TYPES.CONTRACTS,
    permission: PERMISSIONS.CONTRACTS_ACCESS,
    submenu: [
      {
        name: 'dashboard.stock',
        value: MENU_ITEMS.CONTRACTS,
        showWhen: [
          MENU_ITEMS.CONTRACTS,
          MENU_ITEMS.CONTRACTS_OFFERS,
          MENU_ITEMS.OFFER_FORM_CONTRACTS,
          MENU_ITEMS.CONTRACTS_RESERVATIONS,
          MENU_ITEMS.OFFER_CONTRACT
        ],
        activeWhen: [
          MENU_ITEMS.CONTRACTS,
          MENU_ITEMS.OFFER_FORM_CONTRACTS,
          MENU_ITEMS.OFFER_CONTRACT
        ],
        icon: null,
        type: EXCHANGE_TYPES.CONTRACTS,
        permission: PERMISSIONS.CONTRACTS_ACCESS
      },
      {
        name: 'dashboard.myOffers',
        value: MENU_ITEMS.CONTRACTS_OFFERS,
        showWhen: [
          MENU_ITEMS.CONTRACTS,
          MENU_ITEMS.CONTRACTS_OFFERS,
          MENU_ITEMS.CONTRACTS_RESERVATIONS,
          MENU_ITEMS.OFFER_FORM_CONTRACTS,
          MENU_ITEMS.OFFER_CONTRACT
        ],
        activeWhen: [MENU_ITEMS.CONTRACTS_OFFERS],
        icon: <CustomIcon name='offer' size={24} />
      },
      {
        name: 'dashboard.myReservations',
        value: MENU_ITEMS.CONTRACTS_RESERVATIONS,
        showWhen: [
          MENU_ITEMS.CONTRACTS,
          MENU_ITEMS.CONTRACTS_RESERVATIONS,
          MENU_ITEMS.CONTRACTS_OFFERS,
          MENU_ITEMS.OFFER_FORM_CONTRACTS,
          MENU_ITEMS.OFFER_CONTRACT
        ],
        activeWhen: [MENU_ITEMS.CONTRACTS_RESERVATIONS],
        icon: <CustomIcon name='reservation' size={24} />
      }
    ]
  },
  {
    name: 'companies.companiesDatabase',
    value: MENU_ITEMS.COMPANIES,
    activeWhen: [MENU_ITEMS.COMPANIES, MENU_ITEMS.COMPANY],
    icon: <CustomIcon name='company' size={24} />
  },
  {
    name:  'tracking.tracking',
    value: MENU_ITEMS.TRACKING,
    activeWhen: [
      MENU_ITEMS.TRACKING,
      MENU_ITEMS.TRACKING_DETAILS,
      MENU_ITEMS.TRACKING_HISTORY
    ],
    icon: <CustomIcon name='tracking' size={24} />
  },
  {
    name:  'dashboard.orders',
    value: MENU_ITEMS.ORDERS,
    activeWhen: [
      MENU_ITEMS.ORDERS,
      MENU_ITEMS.ORDER_ADD,
      MENU_ITEMS.ORDER_EDIT
    ],
    icon: <CustomIcon name='order' size={24} />
  },
  {
    name:  'dashboard.invoices',
    value: MENU_ITEMS.INVOICES,
    activeWhen: [
      MENU_ITEMS.INVOICES,
      MENU_ITEMS.INVOICE_DETAILS,
      MENU_ITEMS.INVOICES_ADD
    ],
    icon: <CustomIcon name='invoice' size={24} />
  }
]

export const createOfferDetailsLink = (referenceNumber = '') => {
  if (!referenceNumber) return routes.APP_DASHBOARD
  const offerNumberPrefix = slice(0, 4, referenceNumber)

  const stockAddress = {
    [REFERENCE_NUMBER_PREFIX.CARGO_EXPORT]: MENU_ITEMS.OFFER_EXPORT,
    [REFERENCE_NUMBER_PREFIX.CARGO_IMPORT]: MENU_ITEMS.OFFER_IMPORT,
    [REFERENCE_NUMBER_PREFIX.FREE_CARRIERS]: MENU_ITEMS.OFFER_FREE_CARRIERS,
    [REFERENCE_NUMBER_PREFIX.POST_IMPORT]: MENU_ITEMS.OFFER_POST_IMPORT,
    [REFERENCE_NUMBER_PREFIX.CONTRACTS]: MENU_ITEMS.OFFER_CONTRACT
  }[offerNumberPrefix]
  if (!stockAddress) return routes.APP_DASHBOARD

  return `${routes.APP_DASHBOARD}?tab=${stockAddress}&id=${referenceNumber}`
}
