import { pathOr, path, isNil } from 'ramda'
import { isArray, isString, isFalsy } from 'ramda-adjunct'
import moment from 'moment'

import {
  Notification,
  OfferDetails,
  NotificationGroupSettings,
  Settings,
  InvoiceDetails,
  Cta
} from './records'

const parseNotificationBody = body => {
  switch (true) {
    case isNil(body):
      return null
    case isString(body):
      return body
    case isArray(body):
      return body.map(v => ({ title: v.label, value: v.value }))
    default:
      return null
  }
}

export const normalizeNotificationsList = (arr = []) =>
  arr.map(notification => {
    const hasOfferInformation =
      !isFalsy(path(['data', 'offer'], notification)) ||
      !isFalsy(path(['data', 'tracking'], notification))
    const objectOfferKey = !isFalsy(path(['data', 'tracking'], notification))
      ? 'tracking'
      : 'offer'

    return new Notification({
      ...notification,
      cta: Cta({ ...notification.data.cta }),
      transportationOrder: pathOr(
        null,
        ['data', 'transportationOrder', 'details'],
        notification
      ),
      subject: pathOr('', ['data', 'subject'], notification),
      code: pathOr(null, ['data', 'code'], notification),
      type: pathOr(null, ['type'], notification),
      readAt: pathOr(null, ['readAt'], notification),
      invoice: new InvoiceDetails({
        ...notification.data.invoice,
        ...notification.data.correctiveNote,
        ...notification.data.correctiveInvoice,
        transportationOrderNumber: pathOr(
          null,
          ['data', 'transportationOrder', 'referenceNumber'],
          notification
        )
      }),
      marked: moment(pathOr('', ['createdAt'], notification)).isSame(
        moment(),
        'day'
      ),
      createdAt: moment(pathOr('', ['createdAt'], notification)).calendar(
        null,
        {
          sameDay: 'HH:mm',
          nextDay: 'HH:mm',
          lastDay: 'DD.MM HH:mm',
          lastWeek: 'DD.MM HH:mm',
          sameElse: 'DD.MM HH:mm'
        }
      ),
      offer: !hasOfferInformation
        ? null
        : new OfferDetails({
            referenceNumber: pathOr(
              '',
              ['data', objectOfferKey, 'referenceNumber'],
              notification
            ),
            id: pathOr('', ['data', objectOfferKey, 'id'], notification),
            from: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'from'],
              notification
            ),
            fromDate: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'fromDate'],
              notification
            ),
            to: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'to'],
              notification
            ),
            toDate: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'toDate'],
              notification
            ),
            startDate: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'startDate'],
              notification
            ),
            endDate: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'endDate'],
              notification
            ),
            container: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'container'],
              notification
            ),
            handlerCompany: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'handler', 'company'],
              notification
            ),
            handlerName: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'handler', 'name'],
              notification
            ),
            shipowner: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'shipowner'],
              notification
            ),
            driver: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'driver', 'name'],
              notification
            ),
            driverPhone: pathOr(
              '',
              ['data', objectOfferKey, 'details', 'driver', 'phoneNumber'],
              notification
            )
          }),
      coworkerName: pathOr('', ['data', 'coworker', 'name'], notification),
      body: parseNotificationBody(pathOr({}, ['data', 'body'], notification))
    })
  })

export const normalizeNotificationsSettingsList = arr =>
  arr.map(
    group =>
      new NotificationGroupSettings({
        ...group,
        values: group.values.map(value => new Settings({ ...value }))
      })
  )
