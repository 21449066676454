import { OfferFormContract } from 'src/features/offers/duck/records'
import { API_STATES } from 'src/ducks/consts'
import {
  OfferFormImport,
  OfferFormExport,
  OfferFormPostImport,
  OfferFormFreeCarriers,
  OfferFormsScaffolds
} from './records'
import {
  setOfferFormImportValueRoutine,
  setOfferFormExportValueRoutine,
  setOfferFormPostImportValueRoutine,
  setOfferFormFreeCarriersValueRoutine,
  clearImportOfferFormRoutine,
  clearExportOfferFormRoutine,
  clearPostImportOfferFormRoutine,
  clearFreeCarriersOfferFormRoutine,
  getExportMyCompanyLastOffersRoutine,
  getImportMyCompanyLastOffersRoutine,
  getFreeCarriersMyCompanyLastOffersRoutine,
  getPostImportMyCompanyLastOffersRoutine,
  getExportOfferTemplatesRoutine,
  getImportOfferTemplatesRoutine,
  getFreeCarriersOfferTemplatesRoutine,
  getPostImportOfferTemplatesRoutine,
  fillFormUsingTemplateExportRoutine,
  fillFormUsingTemplateImportRoutine,
  fillFormUsingTemplateFreeCarriersRoutine,
  fillFormUsingTemplatePostImportRoutine,
  clearContractOfferRoutine,
  setOfferFormContractValueRoutine,
  createImportOfferRoutine,
  createExportOfferRoutine,
  createPostImportOfferRoutine,
  createFreeCarriersOfferRoutine,
  createContractOfferRoutine
} from './actions'

import {
  normalizeToOfferTemplatesExport,
  normalizeToOfferTemplatesImport,
  normalizeToOfferTemplatesFreeCarriers,
  normalizeToOfferTemplatesPostImport
} from './normalizers'

export const offerFormImportReducer = (
  state = new OfferFormImport(),
  action
) => {
  switch (action.type) {
    case createImportOfferRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createImportOfferRoutine.SUCCESS:
    case createImportOfferRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case setOfferFormImportValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case fillFormUsingTemplateImportRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case fillFormUsingTemplateImportRoutine.SUCCESS:
      return new OfferFormImport({ ...action.payload.template }).set(
        'state',
        API_STATES.DONE
      )
    case clearImportOfferFormRoutine.SUCCESS:
      return new OfferFormImport()
    default:
      return state
  }
}

export const offerFormExportReducer = (
  state = new OfferFormExport(),
  action
) => {
  switch (action.type) {
    case createExportOfferRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createExportOfferRoutine.SUCCESS:
    case createExportOfferRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case setOfferFormExportValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case fillFormUsingTemplateExportRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case fillFormUsingTemplateExportRoutine.SUCCESS:
      return new OfferFormExport({ ...action.payload.template }).set(
        'state',
        API_STATES.DONE
      )
    case clearExportOfferFormRoutine.SUCCESS:
      return new OfferFormExport()
    default:
      return state
  }
}

export const offerFormPostImportReducer = (
  state = new OfferFormPostImport(),
  action
) => {
  switch (action.type) {
    case createPostImportOfferRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createPostImportOfferRoutine.SUCCESS:
    case createPostImportOfferRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case setOfferFormPostImportValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case fillFormUsingTemplatePostImportRoutine.SUCCESS:
      return new OfferFormPostImport({ ...action.payload.template })
    case clearPostImportOfferFormRoutine.SUCCESS:
      return new OfferFormPostImport()
    default:
      return state
  }
}

export const offerFormFreeCarriersReducer = (
  state = new OfferFormFreeCarriers(),
  action
) => {
  switch (action.type) {
    case createFreeCarriersOfferRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createFreeCarriersOfferRoutine.SUCCESS:
    case createFreeCarriersOfferRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case setOfferFormFreeCarriersValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case fillFormUsingTemplateFreeCarriersRoutine.SUCCESS:
      return new OfferFormFreeCarriers({ ...action.payload.template })
    case clearFreeCarriersOfferFormRoutine.SUCCESS:
      return new OfferFormFreeCarriers()
    default:
      return state
  }
}

export const offerFormContractReducer = (
  state = new OfferFormContract(),
  action
) => {
  switch (action.type) {
    case createContractOfferRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createContractOfferRoutine.SUCCESS:
    case createContractOfferRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case setOfferFormContractValueRoutine.SUCCESS:
      return Array.isArray(action.payload.name)
        ? state.setIn(action.payload.name, action.payload.value)
        : state.set(action.payload.name, action.payload.value)
    case clearContractOfferRoutine.SUCCESS:
      return new OfferFormContract()
    default:
      return state
  }
}

export const offerFormsScaffoldsReducer = (
  state = new OfferFormsScaffolds(),
  action
) => {
  switch (action.type) {
    case getExportMyCompanyLastOffersRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getExportMyCompanyLastOffersRoutine.SUCCESS:
      return state
        .set(
          'lastOffersExport',
          normalizeToOfferTemplatesExport(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case getExportOfferTemplatesRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getExportOfferTemplatesRoutine.SUCCESS:
      return state
        .set(
          'templatesExport',
          normalizeToOfferTemplatesExport(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case fillFormUsingTemplateExportRoutine.SUCCESS:
      return state
        .update('lastOffersExport', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
        .update('templatesExport', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
    case getImportMyCompanyLastOffersRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getImportMyCompanyLastOffersRoutine.SUCCESS:
      return state
        .set(
          'lastOffersImport',
          normalizeToOfferTemplatesImport(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case getImportOfferTemplatesRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getImportOfferTemplatesRoutine.SUCCESS:
      return state
        .set(
          'templatesImport',
          normalizeToOfferTemplatesImport(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case fillFormUsingTemplateImportRoutine.SUCCESS:
      return state
        .update('lastOffersImport', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
        .update('templatesImport', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
    case getFreeCarriersMyCompanyLastOffersRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getFreeCarriersMyCompanyLastOffersRoutine.SUCCESS:
      return state
        .set(
          'lastOffersFreeCarriers',
          normalizeToOfferTemplatesFreeCarriers(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case getFreeCarriersOfferTemplatesRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getFreeCarriersOfferTemplatesRoutine.SUCCESS:
      return state
        .set(
          'templatesFreeCarriers',
          normalizeToOfferTemplatesFreeCarriers(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case fillFormUsingTemplateFreeCarriersRoutine.SUCCESS:
      return state
        .update('lastOffersFreeCarriers', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
        .update('templatesFreeCarriers', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
    case getPostImportMyCompanyLastOffersRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getPostImportMyCompanyLastOffersRoutine.SUCCESS:
      return state
        .set(
          'lastOffersPostImport',
          normalizeToOfferTemplatesPostImport(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case getPostImportOfferTemplatesRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getPostImportOfferTemplatesRoutine.SUCCESS:
      return state
        .set(
          'templatesPostImport',
          normalizeToOfferTemplatesPostImport(action.payload.data)
        )
        .set('state', API_STATES.DONE)
    case fillFormUsingTemplatePostImportRoutine.SUCCESS:
      return state
        .update('lastOffersPostImport', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
        .update('templatesPostImport', list =>
          list.map(t =>
            t.set('selected', t.uuid === action.payload.template.uuid)
          )
        )
    default:
      return state
  }
}
