import qs from 'qs'
import { isEmptyString, isFalsy, isEmptyArray } from 'ramda-adjunct'
import { isNil, evolve, filter, pipe, not } from 'ramda'
import api from './APIInterceptor'

const parsePayload = evolve({
  //filter out empty strings and null values
  filter: pipe(filter(pipe(isEmptyString, not)), filter(pipe(isNil, not)))
})

export default class DictionariesService {
  static containers() {
    return api.get('/dictionaries/containers', {
      cache: {
        maxAge: 15 * 60 * 1000
        // maxAge: 1
      }
    })
  }

  static containersSemiTrailers() {
    return api.get('/dictionaries/container-semi-trailers', {
      cache: {
        maxAge: 15 * 60 * 1000
        // maxAge: 1
      }
    })
  }

  static locations(payload) {
    const parsed = parsePayload(payload)
    return api.get(`/dictionaries/locations?${qs.stringify(parsed)}`, {
      cache: {
        // maxAge: 15 * 60 * 1000
        maxAge: 1
      }
    })
  }

  static companies(payload) {
    //remove query for empty array
    if (!isFalsy(payload.filter.id) && isEmptyArray(payload.filter.id)) {
      return { data: [] }
    }

    const parsed = parsePayload(payload)
    return api.get(`/dictionaries/companies?${qs.stringify(parsed)}`, {
      cache: {
        maxAge: 15 * 60 * 1000
      }
    })
  }

  static shipowners() {
    return api.get('/dictionaries/shipowners', {
      cache: {
        maxAge: 15 * 60 * 1000
        // maxAge: 1
      }
    })
  }

  static cargoTypes() {
    return api.get('/dictionaries/cargo-types', {
      cache: {
        maxAge: 15 * 60 * 1000
        // maxAge: 1
      }
    })
  }

  static adrClasses() {
    return api.get('/dictionaries/adr-classes', {
      cache: {
        maxAge: 15 * 60 * 1000
        // maxAge: 1
      }
    })
  }

  static users(query) {
    return api.get(`/dictionaries/users?${query}`, {
      cache: {
        maxAge: 15 * 60 * 1000
      }
    })
  }

  static contactCategories() {
    return api.get('/dictionaries/contact-form-categories', {
      cache: {
        maxAge: 15 * 60 * 1000
        // maxAge: 1
      }
    })
  }

  static gtuCodes() {
    return api.get('/dictionaries/gtu-codes', {
      cache: {
        maxAge: 15 * 60 * 1000
        // maxAge: 1
      }
    })
  }
}
