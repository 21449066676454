import { pickAll } from 'ramda'
import { API_STATES } from 'src/ducks/consts'

export const selectIsAvailable = state =>
  state.messages.conversation.isAvailable
export const selectLastMessageRequest = state => state.messages.lastRequest
export const isMessagesListOpened = state => state.messages.listOpened === true
export const isConversationOpened = state =>
  state.messages.conversationOpened === true

export const selectConversationDetails = state =>
  pickAll(
    ['receiverId', 'contextId', 'contextType', 'conversationId'],
    state.messages.conversation
  )

export const selectConversation = state => state.messages.conversation

export const selectInterlocutor = state =>
  state.messages.conversation.interlocutor

export const selectConversationMessages = state =>
  state.messages.conversation.messages

export const selectContextualMessages = state =>
  state.messages.contextualMessages

export const selectPersonalMessages = state => state.messages.personalMessages

export const selectMessagesCounters = state =>
  pickAll(
    [
      'allUnreadMessagesCount',
      'contextualMessagesCount',
      'personalMessagesCount'
    ],
    state.messages
  )

export const selectPersonalSearchResults = state =>
  state.messages.personalSearchResults

export const selectContextualSearchResults = state =>
  state.messages.contextualSearchResults

export const selectMessagesDetails = state => state.messages

export const isMessagesListLoading = state =>
  state.messages.listState === API_STATES.IN_PROGRESS

export const isConversationLoading = state =>
  state.messages.conversationState === API_STATES.IN_PROGRESS

export const isChatMuted = state => state.account.currentUser.isChatMuted

export const isConversationMuted = state =>
  state.messages.conversation.isConversationMuted

export const selectOnlineUsers = state => state.messages.onlineUsers

export const selectConversationContextActions = state =>
  state.messages.conversation.contextActions

export const isConversationContextLoading = state =>
  state.messages.conversation.contextState === API_STATES.IN_PROGRESS
