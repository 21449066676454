import queryString from 'query-string'
import { parseFiltersToQuery } from 'src/utils/helpers'
import api from './APIInterceptor'

export default class OfferService {
  static createExportOffer(data) {
    return api.post('/exchange/export-cargo/offers', data)
  }

  static createImportOffer(data) {
    return api.post('/exchange/import-cargo/offers', data)
  }

  static createPostImportOffer(data) {
    return api.post('/exchange/post-import-containers/offers', data)
  }

  static createFreeCarriersOffer(data) {
    return api.post('/exchange/free-carriers/offers', data)
  }

  static getExportMyCompanyLastOffers() {
    return api.get('/me/export-cargo/offers?limit=10')
  }

  static getImportMyCompanyLastOffers() {
    return api.get('/me/import-cargo/offers?limit=10')
  }

  static getFreeCarriersMyCompanyLastOffers() {
    return api.get('/me/free-carriers/offers?limit=10')
  }

  static getPostImportMyCompanyLastOffers() {
    return api.get('/me/post-import-containers/offers?limit=10')
  }

  static getExportOfferTemplates() {
    return api.get('/exchange/export-cargo/offers/templates?limit=10')
  }

  static getImportOfferTemplates() {
    return api.get('/exchange/import-cargo/offers/templates?limit=10')
  }

  static getFreeCarriersOfferTemplates() {
    return api.get('/exchange/free-carriers/offers/templates?limit=10')
  }

  static getPostImportOfferTemplates() {
    return api.get('/exchange/post-import-containers/offers/templates?limit=10')
  }

  static removeTemplateExport(id) {
    return api.delete(`/exchange/export-cargo/offers/templates/${id}`)
  }

  static removeTemplateImport(id) {
    return api.delete(`/exchange/import-cargo/offers/templates/${id}`)
  }

  static removeTemplateFreeCarriers(id) {
    return api.delete(`/exchange/free-carriers/offers/templates/${id}`)
  }

  static removeTemplatePostImport(id) {
    return api.delete(`/exchange/post-import-containers/offers/templates/${id}`)
  }

  //MY EXPORT OFFERS
  static showMyExportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/export-cargo/offers/?${query}`)
  }

  static showMyOngoingExportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/export-cargo/offers/ongoing?${query}`)
  }

  static showMyFinishedExportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/export-cargo/offers/finished?${query}`)
  }

  static deleteMultipleExportOffers(data) {
    return api.delete('/exchange/export-cargo/offers', { data })
  }

  static deleteExportOffer(id, values) {
    const query = queryString.stringify(values)
    return api.delete(`/exchange/export-cargo/offers/${id}?${query}`)
  }

  static restoreExportOffer(id) {
    return api.get(`/exchange/export-cargo/offers/${id}/restore`)
  }

  //MY IMPORT OFFERS
  static showMyImportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/import-cargo/offers/?${query}`)
  }

  static showMyOngoingImportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/import-cargo/offers/ongoing?${query}`)
  }

  static showMyFinishedImportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/import-cargo/offers/finished?${query}`)
  }

  static deleteMultipleImportOffers(data) {
    return api.delete('/exchange/import-cargo/offers', { data })
  }

  static deleteImportOffer(id, values) {
    const query = queryString.stringify(values)
    return api.delete(`/exchange/import-cargo/offers/${id}?${query}`)
  }

  static restoreImportOffer(id) {
    return api.get(`/exchange/import-cargo/offers/${id}/restore`)
  }

  static showOngoingImportOfferReservations(id) {
    return api.get(`/me/import-cargo/offers/ongoing/${id}/reservations`)
  }

  static showFinishedImportOfferReservations(id) {
    return api.get(`/me/import-cargo/offers/finished/${id}/reservations`)
  }

  //MY POST IMPORT OFFERS
  static showMyPostImportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/post-import-containers/offers/?${query}`)
  }

  static showMyOngoingPostImportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/post-import-containers/offers/ongoing?${query}`)
  }

  static showMyFinishedPostImportOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/post-import-containers/offers/finished?${query}`)
  }

  static deleteMultiplePostImportOffers(data) {
    return api.delete('/exchange/post-import-containers/offers', { data })
  }

  static deletePostImportOffer(id, values) {
    const query = queryString.stringify(values)
    return api.delete(`/exchange/post-import-containers/offers/${id}?${query}`)
  }

  static restorePostImportOffer(id) {
    return api.get(`/exchange/post-import-containers/offers/${id}/restore`)
  }

  static showOngoingPostImportOfferReservations(id) {
    return api.get(
      `/me/post-import-containers/offers/ongoing/${id}/reservations`
    )
  }

  static showFinishedPostImportOfferReservations(id) {
    return api.get(
      `/me/post-import-containers/offers/finished/${id}/reservations`
    )
  }

  //MY FREE CARRIER OFFERS
  static showMyFreeCarrierOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/free-carriers/offers/?${query}`)
  }

  static showMyOngoingFreeCarrierOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/free-carriers/offers/ongoing?${query}`)
  }

  static showMyFinishedFreeCarrierOffers(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/free-carriers/offers/finished?${query}`)
  }

  static deleteMultipleFreeCarrierOffers(data) {
    return api.delete('/exchange/free-carriers/offers', { data })
  }

  static deleteFreeCarrierOffer(id, values) {
    const query = queryString.stringify(values)
    return api.delete(`/exchange/free-carriers/offers/${id}?${query}`)
  }

  static restoreFreeCarrierOffer(id) {
    return api.get(`/exchange/free-carriers/offers/${id}/restore`)
  }

  static showOngoingFreeCarrierOfferReservations(id) {
    return api.get(`/me/free-carriers/offers/ongoing/${id}/reservations`)
  }

  static showFinishedFreeCarrierOfferReservations(id) {
    return api.get(`/me/free-carriers/offers/finished/${id}/reservations`)
  }
}
