import { parseFiltersToQuery } from 'src/utils/helpers'
import api from './APIInterceptor'

export default class ReservationService {
  //EXPORT
  static getMyExportOngoingReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/export-cargo/offers/ongoing/reservations?${query}`)
  }

  static getMyExportFinishedReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/export-cargo/offers/finished/reservations?${query}`)
  }

  static cancelExportReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/export-cargo/offers/${offerId}/reservations/${reservationId}/cancel`,
      { reason }
    )
  }

  static rejectExportReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/export-cargo/offers/${offerId}/reservations/${reservationId}/reject`,
      { reason }
    )
  }

  static acceptExportReservation(offerId, reservationId) {
    return api.get(
      `/exchange/export-cargo/offers/${offerId}/reservations/${reservationId}/accept`
    )
  }

  static showOngoingExportOfferReservations(id) {
    return api.get(`/me/export-cargo/offers/ongoing/${id}/reservations`)
  }

  static showFinishedExportOfferReservations(id) {
    return api.get(`/me/export-cargo/offers/finished/${id}/reservations`)
  }

  //IMPORT
  static getMyImportOngoingReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/import-cargo/offers/ongoing/reservations?${query}`)
  }

  static getMyImportFinishedReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/import-cargo/offers/finished/reservations?${query}`)
  }

  static cancelImportReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/import-cargo/offers/${offerId}/reservations/${reservationId}/cancel`,
      { reason }
    )
  }

  static rejectImportReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/import-cargo/offers/${offerId}/reservations/${reservationId}/reject`,
      { reason }
    )
  }

  static acceptImportReservation(offerId, reservationId) {
    return api.get(
      `/exchange/import-cargo/offers/${offerId}/reservations/${reservationId}/accept`
    )
  }

  static showOngoingImportOfferReservations(id) {
    return api.get(`/me/import-cargo/offers/ongoing/${id}/reservations`)
  }

  static showFinishedImportOfferReservations(id) {
    return api.get(`/me/import-cargo/offers/finished/${id}/reservations`)
  }

  //POST IMPORT
  static getMyPostImportOngoingReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(
      `/me/post-import-containers/offers/ongoing/reservations?${query}`
    )
  }

  static getMyPostImportFinishedReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(
      `/me/post-import-containers/offers/finished/reservations?${query}`
    )
  }

  static cancelPostImportReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/post-import-containers/offers/${offerId}/reservations/${reservationId}/cancel`,
      { reason }
    )
  }

  static rejectPostImportReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/post-import-containers/offers/${offerId}/reservations/${reservationId}/reject`,
      { reason }
    )
  }

  static acceptPostImportReservation(offerId, reservationId) {
    return api.get(
      `/exchange/post-import-containers/offers/${offerId}/reservations/${reservationId}/accept`
    )
  }

  static showOngoingPostImportOfferReservations(id) {
    return api.get(
      `/me/post-import-containers/offers/ongoing/${id}/reservations`
    )
  }

  static showFinishedPostImportOfferReservations(id) {
    return api.get(
      `/me/post-import-containers/offers/finished/${id}/reservations`
    )
  }

  //FREE CARRIER
  static getMyFreeCarrierOngoingReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/free-carriers/offers/ongoing/reservations?${query}`)
  }

  static getMyFreeCarrierFinishedReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/free-carriers/offers/finished/reservations?${query}`)
  }

  static cancelFreeCarrierReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/free-carriers/offers/${offerId}/reservations/${reservationId}/cancel`,
      { reason }
    )
  }

  static rejectFreeCarrierReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/free-carriers/offers/${offerId}/reservations/${reservationId}/reject`,
      { reason }
    )
  }

  static acceptFreeCarrierReservation(offerId, reservationId) {
    return api.get(
      `/exchange/free-carriers/offers/${offerId}/reservations/${reservationId}/accept`
    )
  }

  static showOngoingFreeCarrierOfferReservations(id) {
    return api.get(`/me/free-carriers/offers/ongoing/${id}/reservations`)
  }

  static showFinishedFreeCarrierOfferReservations(id) {
    return api.get(`/me/free-carriers/offers/finished/${id}/reservations`)
  }

  //Contracts
  static getMyContractOngoingReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/contracts/offers/ongoing/reservations?${query}`)
  }

  static getMyContractFinishedReservations(filters) {
    const query = parseFiltersToQuery(filters)
    return api.get(`/me/contracts/offers/finished/reservations?${query}`)
  }

  static cancelContractReservation(offerId, reservationId, reason) {
    return api.post(
      `/exchange/contracts/offers/${offerId}/reservations/${reservationId}/cancel`,
      { reason }
    )
  }

  static acceptContractReservation(offerId, reservationId) {
    return api.get(
      `/exchange/contracts/offers/${offerId}/reservations/${reservationId}/accept`
    )
  }

  static showOngoingContractOfferReservations(id) {
    return api.get(`/me/contracts/offers/ongoing/${id}/reservations`)
  }

  static showFinishedContractOfferReservations(id) {
    return api.get(`/me/contracts/offers/finished/${id}/reservations`)
  }
}
