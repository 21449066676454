import { COMPANY_PROFILES } from 'src/ducks/consts'

export const selectCurrentUser = state => state.account.currentUser

export const selectEditUserForm = state => state.editUserForm

export const selectCurrentCompanyInformation = state => state.currentCompany

export const selectAddUserForm = state => state.addUserForm

export const selectCurrentUserPermissions = state => state.account.permissions

export const selectCurrentUserCoworkers = state => state.account.coworkers

export const selectCurrentUserCompanyId = state =>
  state.account.currentUser.companyId

export const selectCurrentUserId = state => state.account.currentUser.id

export const selectChangePasswordStatus = state => state.account.changePassword

export const selectUpdateMyCompanyStatus = state =>
  state.account.updateMyCompany

export const selectUpdateCurrentUserStatus = state => state.account.updateUser

export const selectCurrentUserCompany = state =>
  state.account.currentUser.company

export const selectCurrentUserCompanyName = state =>
  state.account.currentUser.company.name

export const selectCurrentUserAvatarState = state => state.account.avatarState

export const selectContactForm = state => state.contactForm

export const selectContactCategories = state =>
  state.dictionaries.contactCategories

export const selectIsCarrierForwarder = state =>
  state.account.currentUser.company.businessProfile ===
  COMPANY_PROFILES.CARRIER_FORWARDER

export const selectIsCurrentCompanyCarrier = state =>
  state.account.currentUser.company.businessProfile === COMPANY_PROFILES.CARRIER
