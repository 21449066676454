import { Record } from 'immutable'
import { Contractor, Currency, Pagination } from 'src/ducks/records'
import { StockStates } from 'src/features/stocks/ducks/records'
import {
  OFFERS_DEFAULT_LIMIT,
  OFFERS_DEFAULT_PAGE,
  API_STATES
} from 'src/ducks/consts'

export const MyOffersFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: OFFERS_DEFAULT_LIMIT,
  onlyMe: 0
})

export const MyOffers = Record({
  state: StockStates(),
  errorMessage: null,
  offers: [],
  selectedOffers: [],
  isDrawerOpen: false,
  filters: MyOffersFilters(),
  pagination: Pagination(),
  total: '',
  transportationId: '',
  pinCode: '',
  pinCodeState: API_STATES.PRISTINE
})

export const Reservation = Record({
  acceptedAt: null,
  annulledAt: null,
  annulledBy: null,
  annulmentReason: null,
  createdAt: null,
  id: null,
  isAcceptable: false,
  isRejectable: false,
  offerId: null,
  offeror: Contractor(),
  price: Currency()
})
