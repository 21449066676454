import { combineReducers } from 'redux'
import { Pagination } from 'src/ducks/records'
import { MyOffers, MyOffersFilters } from 'src/features/myOffers/duck/records'

import { normalizeReservations } from 'src/features/myOffers/duck/normalizers'
import {
  getContractOffersListRoutine,
  setContractOffersFiltersRoutine,
  setContractDrawerRoutine,
  getContractOfferRoutine,
  acceptContractReservationRoutine,
  showOngoingContractOfferReservationsRoutine,
  setMyOngoingContractOffersFiltersRoutine,
  getMyOngoingContractOffersRoutine,
  cancelContractReservationRoutine,
  getMyFinishedContractOffersRoutine,
  setMyFinishedContractOffersFiltersRoutine,
  showFinishedContractOfferReservationsRoutine,
  getMyOngoingContractReservationsRoutine,
  getMyFinishedContractReservationsRoutine,
  setMyOngoingContractReservationsFiltersRoutine,
  setMyFinishedContractReservationsFiltersRoutine,
  cleanContractsDetailsRoutine
} from 'src/features/contracts/duck/actions'
import { Contract, Contracts } from 'src/features/contracts/duck/records'
import {
  normalizeContractOfferDetails,
  normalizeContractOffers,
  normalizeContractReservations
} from 'src/features/contracts/duck/normalizers'
import { API_STATES } from 'src/ducks/consts'
import { findIndex, head, prop, propEq } from 'ramda'

const myContractReservationsOngoingReducer = (
  state = new MyOffers(),
  action
) => {
  switch (action.type) {
    case getMyOngoingContractReservationsRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyOngoingContractReservationsRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyOngoingContractReservationsRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyOngoingContractReservationsRoutine.SUCCESS:
      return state
        .set('offers', normalizeContractReservations(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyOngoingContractReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case showOngoingContractOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state.setIn(
        ['offers', [offerIndex], 'reservations'],
        normalizeReservations(action.payload)
      )
    case cancelContractReservationRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case cancelContractReservationRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case cancelContractReservationRoutine.FULFILL:
    case cancelContractReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

const myContractReservationsFinishedReducer = (
  state = new MyOffers(),
  action
) => {
  switch (action.type) {
    case getMyFinishedContractReservationsRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyFinishedContractReservationsRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyFinishedContractReservationsRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyFinishedContractReservationsRoutine.SUCCESS:
      return state
        .set('offers', normalizeContractReservations(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFinishedContractReservationsFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case showFinishedContractOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state.setIn(
        ['offers', [offerIndex], 'reservations'],
        normalizeReservations(action.payload)
      )
    case cancelContractReservationRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case cancelContractReservationRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case cancelContractReservationRoutine.FULFILL:
    case cancelContractReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

const myContractOffersOngoingReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyOngoingContractOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyOngoingContractOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyOngoingContractOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyOngoingContractOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeContractOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyOngoingContractOffersFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case showOngoingContractOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state.setIn(
        ['offers', [offerIndex], 'reservations'],
        normalizeReservations(action.payload)
      )
    case cancelContractReservationRoutine.REQUEST:
    case acceptContractReservationRoutine.REQUEST:
      return state.setIn(['state', 'buttons'], API_STATES.IN_PROGRESS)
    case cancelContractReservationRoutine.FAILURE:
    case acceptContractReservationRoutine.FAILURE:
      return state.setIn(['state', 'buttons'], API_STATES.ERROR)
    case acceptContractReservationRoutine.FULFILL:
    case cancelContractReservationRoutine.FULFILL:
    case acceptContractReservationRoutine.SUCCESS:
    case cancelContractReservationRoutine.SUCCESS:
      return state.setIn(['state', 'buttons'], API_STATES.DONE)
    default:
      return state
  }
}

const myContractOffersFinishedReducer = (state = new MyOffers(), action) => {
  switch (action.type) {
    case getMyFinishedContractOffersRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getMyFinishedContractOffersRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.ERROR)
    case getMyFinishedContractOffersRoutine.FULFILL:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getMyFinishedContractOffersRoutine.SUCCESS:
      return state
        .set('offers', normalizeContractOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case setMyFinishedContractOffersFiltersRoutine.SUCCESS:
      const filters =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filters
          })
      )
    case showFinishedContractOfferReservationsRoutine.SUCCESS:
      const offerIndex = findIndex(
        propEq('id', prop('offerId', head(action.payload))),
        state.offers
      )
      return state.setIn(
        ['offers', [offerIndex], 'reservations'],
        normalizeReservations(action.payload)
      )
    default:
      return state
  }
}

const contractOffersListReducer = (state = new Contracts(), action) => {
  switch (action.type) {
    case showOngoingContractOfferReservationsRoutine.REQUEST:
    case showFinishedContractOfferReservationsRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case showOngoingContractOfferReservationsRoutine.FAILURE:
    case showFinishedContractOfferReservationsRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.ERROR)
    case showOngoingContractOfferReservationsRoutine.FULFILL:
    case showFinishedContractOfferReservationsRoutine.FULFILL:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case showOngoingContractOfferReservationsRoutine.SUCCESS:
    case showFinishedContractOfferReservationsRoutine.SUCCESS:
      return state
        .setIn(
          ['offerDetails', 'reservations'],
          normalizeReservations(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case getContractOffersListRoutine.REQUEST:
      return state.setIn(['state', 'list'], API_STATES.IN_PROGRESS)
    case getContractOffersListRoutine.SUCCESS:
      return state
        .set('offers', normalizeContractOffers(action.payload.data))
        .set('pagination', new Pagination(action.payload.meta))
        .setIn(['state', 'list'], API_STATES.DONE)
    case getContractOffersListRoutine.FAILURE:
      return state.setIn(['state', 'list'], API_STATES.DONE)
    case getContractOfferRoutine.REQUEST:
      return state.setIn(['state', 'details'], API_STATES.IN_PROGRESS)
    case getContractOfferRoutine.FAILURE:
      return state.setIn(['state', 'details'], API_STATES.DONE)
    case cleanContractsDetailsRoutine.SUCCESS:
      return state.set('offerDetails', new Contract())
    case getContractOfferRoutine.SUCCESS:
      const indexOnOffersList = findIndex(
        propEq('id', prop('id', action.payload)),
        state.offers
      )
      return state
        .set('offerDetails', normalizeContractOfferDetails(action.payload))
        .setIn(
          ['offers', [indexOnOffersList]],
          normalizeContractOfferDetails(action.payload)
        )
        .setIn(['state', 'details'], API_STATES.DONE)
    case setContractOffersFiltersRoutine.SUCCESS:
      const filtersFinished =
        action.payload.type === 'bulk'
          ? action.payload
          : { [action.payload.name]: action.payload.value }
      return state.setIn(['filters', 'page'], 1).update(
        'filters',
        v =>
          new MyOffersFilters({
            ...v.toJS(),
            ...filtersFinished
          })
      )
    case setContractDrawerRoutine.SUCCESS:
      return state.set('isDrawerOpen', action.payload.drawerState)
    default:
      return state
  }
}

export const contractsReducer = combineReducers({
  myOffersOngoing: myContractOffersOngoingReducer,
  myOffersFinished: myContractOffersFinishedReducer,
  myReservationsOngoing: myContractReservationsOngoingReducer,
  myReservationsFinished: myContractReservationsFinishedReducer,
  stockContracts: contractOffersListReducer
})
