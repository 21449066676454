import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, select, takeLatest } from '@redux-saga/core/effects'
import TrackingService from 'src/services/TrackingService'
import { navigate } from 'gatsby-plugin-intl'
import { pipe } from 'ramda'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import OrdersService from 'src/services/OrdersService'
import { normalizeOrderEditFormForSave } from 'src/features/orders/duck/normalizers'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import ExchangeService from 'src/services/ExchangeService'

import {
  selectOrdersIssuedFiltersFormatted,
  selectOrdersReceivedFiltersFormatted
} from 'src/features/orders/duck/selectors'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import {
  filterNullValues,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'

export const prepareOrderRoutine = createRoutine('PREPARE_ORDER')
export const setOrderEditFormValueRoutine = createRoutine(
  'SET_ORDER_EDIT_FORM_VALUE'
)
export const clearEditOrderFormRoutine = createRoutine('CLEAR_EDIT_ORDER_FORM')
export const createOrderRoutine = createRoutine('CREATE_ORDER')
export const updateOrderRoutine = createRoutine('UPDATE_ORDER')

export const getOrdersListIssuedRoutine = createRoutine(
  'GET_ORDERS_LIST_ISSUED'
)
export const getOrdersListReceivedRoutine = createRoutine(
  'GET_ORDERS_LIST_RECEIVED'
)

export const setOrdersIssuedFiltersRoutine = createRoutine(
  'SET_ORDERS_ISSUED_FILTERS'
)

export const setOrdersReceivedFiltersRoutine = createRoutine(
  'SET_ORDERS_RECEIVED_FILTERS'
)

export const getOrderDetailsRoutine = createRoutine('GET_ORDER_DETAILS')

export const acceptOrderRoutine = createRoutine('ACCEPT_ORDER')

export const rejectOrderRoutine = createRoutine('REJECT_ORDER')

export const downloadOrderPdfRoutine = createRoutine('DOWNLOAD_ORDER_PDF')

export const clearOrderDetailsRoutine = createRoutine('CLEAR_ORDER_DETAILS')

export const prefillOrderFormRoutine = createRoutine('PREFILL_ORDER_FORM')

function* prefillOrderForm({ payload }) {
  yield put(prefillOrderFormRoutine.request())
  try {
    const { data } = yield call(OrdersService.getOrderDetails, payload.id)
    yield put(prefillOrderFormRoutine.success(data))
  } catch (err) {
    yield put(prefillOrderFormRoutine.failure(err))
  }
}

function* clearOrderDetails() {
  yield put(clearOrderDetailsRoutine.request())
  try {
    yield put(clearOrderDetailsRoutine.success())
  } catch (err) {
    yield put(clearOrderDetailsRoutine.failure(err))
  }
}

function* downloadOrderPdf({ payload }) {
  yield put(downloadOrderPdfRoutine.request())
  try {
    window.open(
      `${process.env.GATSBY_API_URL}/me/transportation-orders/${payload.id}/pdf`
    )
    yield put(downloadOrderPdfRoutine.success())
  } catch (err) {
    yield put(downloadOrderPdfRoutine.failure(err))
  }
}

function* acceptOrder({ payload }) {
  yield put(acceptOrderRoutine.request())
  try {
    const { data } = yield call(OrdersService.acceptOrder, payload.id)
    yield put(acceptOrderRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'orders.acceptedSuccessfully'
        }),
        type: 'success'
      }
    })
  } catch (err) {
    yield put(acceptOrderRoutine.failure(err))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
  }
}

function* rejectOrder({ payload }) {
  yield put(rejectOrderRoutine.request())
  try {
    const { data } = yield call(OrdersService.rejectOrder, payload.id, {
      reason: payload.reason
    })
    yield put(rejectOrderRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'orders.rejectedSuccessfully'
        }),
        type: 'success'
      }
    })
  } catch (err) {
    yield put(rejectOrderRoutine.failure(err))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
  }
}

function* getOrderDetails({ payload }) {
  yield put(getOrderDetailsRoutine.request())
  try {
    const { data } = yield call(OrdersService.getOrderDetails, payload.id)
    yield put(getOrderDetailsRoutine.success(data))
  } catch (err) {
    yield put(getOrderDetailsRoutine.failure(err))
  }
}

function* setOrdersIssuedFilters({ payload }) {
  yield put(setOrdersIssuedFiltersRoutine.request())
  try {
    yield put(setOrdersIssuedFiltersRoutine.success(payload))
  } catch (err) {
    yield put(setOrdersIssuedFiltersRoutine.failure(err))
  }
}

function* setOrdersReceivedFilters({ payload }) {
  yield put(setOrdersReceivedFiltersRoutine.request())
  try {
    yield put(setOrdersReceivedFiltersRoutine.success(payload))
  } catch (err) {
    yield put(setOrdersReceivedFiltersRoutine.failure(err))
  }
}

function* getOrdersListIssued() {
  yield put(getOrdersListIssuedRoutine.request())
  try {
    const filters = yield select(selectOrdersIssuedFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OrdersService.getOrdersListIssued, filters)
    yield put(getOrdersListIssuedRoutine.success({ data, meta }))
  } catch (err) {
    yield put(getOrdersListIssuedRoutine.failure(err))
  }
}

function* getOrdersListReceived() {
  yield put(getOrdersListReceivedRoutine.request())
  try {
    const filters = yield select(selectOrdersReceivedFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(OrdersService.getOrdersListReceived, filters)
    yield put(getOrdersListReceivedRoutine.success({ data, meta }))
  } catch (err) {
    yield put(getOrdersListReceivedRoutine.failure(err))
  }
}

function* prepareOrder({ payload }) {
  yield put(prepareOrderRoutine.request())

  const prepareOrderService = {
    [STOCKS_TYPES.TRACKING]: TrackingService.prepareOrder,
    [STOCKS_TYPES.CARGO_EXPORT]: ExchangeService.prepareExportOrder,
    [STOCKS_TYPES.CARGO_IMPORT]: ExchangeService.prepareImportOrder,
    [STOCKS_TYPES.POST_IMPORT]: ExchangeService.preparePostImportOrder,
    [STOCKS_TYPES.FREE_CARRIERS]: ExchangeService.prepareFreeCarriersOrder
  }[payload.offerType]

  try {
    const { data } = yield call(prepareOrderService, payload.id)
    yield put(prepareOrderRoutine.success(data))
    navigate(`${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_ADD}`)
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(prepareOrderRoutine.failure({ message }))
  } finally {
    yield put(prepareOrderRoutine.fulfill())
  }
}

function* setOrderEditFormValue({ payload }) {
  yield put(setOrderEditFormValueRoutine.request())
  try {
    yield put(setOrderEditFormValueRoutine.success(payload))
  } catch (e) {
    console.log(e)
    yield put(setOrderEditFormValueRoutine.failure())
  } finally {
    yield put(setOrderEditFormValueRoutine.fulfill())
  }
}
function* updateOrder({ payload }) {
  yield put(updateOrderRoutine.request())

  const values = pipe(filterNullValues)(payload.values.toJS())

  try {
    const { data } = yield call(OrdersService.updateOrder, payload.id, values)
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${data.referenceNumber}`
    )
    yield put(updateOrderRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'orders.sentSuccessfully'
        }),
        type: 'success'
      }
    })
  } catch (e) {
    console.log(e)
    yield put(updateOrderRoutine.failure())
  } finally {
    yield put(updateOrderRoutine.fulfill())
  }
}

function* createOrder({ payload }) {
  yield put(createOrderRoutine.request())

  try {
    const normalizedForm = normalizeOrderEditFormForSave(payload.values.toJS())
    const { data } = yield call(OrdersService.createOrder, normalizedForm)
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.ORDER_DETAILS}&id=${data.referenceNumber}`
    )
    yield put(createOrderRoutine.success(data))
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'orders.createdSuccessfully'
        }),
        type: 'success'
      }
    })
  } catch (e) {
    console.log('e', e)
    yield put(createOrderRoutine.failure())
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'common.errorMessage'
        }),
        type: 'alert'
      }
    })
  } finally {
    yield put(createOrderRoutine.fulfill())
  }
}
function* clearEditOrderForm() {
  yield put(clearEditOrderFormRoutine.request())
  try {
    yield put(clearEditOrderFormRoutine.success())
  } catch (e) {
    console.log(e)
    yield put(clearEditOrderFormRoutine.failure())
  } finally {
    yield put(clearEditOrderFormRoutine.fulfill())
  }
}

function* prepareOrderRoutineWatcher() {
  yield takeLatest([prepareOrderRoutine.TRIGGER], prepareOrder)
}

function* setOrderEditFormValueRoutineWatcher() {
  yield takeLatest(
    [setOrderEditFormValueRoutine.TRIGGER],
    setOrderEditFormValue
  )
}

function* updateOrderRoutineWatcher() {
  yield takeLatest([updateOrderRoutine.TRIGGER], updateOrder)
}

function* createOrderRoutineWatcher() {
  yield takeLatest([createOrderRoutine.TRIGGER], createOrder)
}

function* clearEditOrderFormRoutineWatcher() {
  yield takeLatest([clearEditOrderFormRoutine.TRIGGER], clearEditOrderForm)
}

export function* getOrdersListReceivedRoutineWatcher() {
  yield takeLatest(
    [
      getOrdersListReceivedRoutine.TRIGGER,
      setOrdersReceivedFiltersRoutine.SUCCESS
    ],
    getOrdersListReceived
  )
}

export function* getOrdersListIssuedRoutineWatcher() {
  yield takeLatest(
    [getOrdersListIssuedRoutine.TRIGGER, setOrdersIssuedFiltersRoutine.SUCCESS],
    getOrdersListIssued
  )
}

export function* setOrdersReceivedFiltersRoutineWatcher() {
  yield takeLatest(
    setOrdersReceivedFiltersRoutine.TRIGGER,
    setOrdersReceivedFilters
  )
}

export function* setOrdersIssuedFiltersRoutineWatcher() {
  yield takeLatest(
    setOrdersIssuedFiltersRoutine.TRIGGER,
    setOrdersIssuedFilters
  )
}

export function* getOrderDetailsRoutineWatcher() {
  yield takeLatest(getOrderDetailsRoutine.TRIGGER, getOrderDetails)
}

export function* acceptOrderRoutineWatcher() {
  yield takeLatest(acceptOrderRoutine.TRIGGER, acceptOrder)
}

export function* rejectOrderRoutineWatcher() {
  yield takeLatest(rejectOrderRoutine.TRIGGER, rejectOrder)
}

export function* downloadOrderPdfRoutineWatcher() {
  yield takeLatest(downloadOrderPdfRoutine.TRIGGER, downloadOrderPdf)
}

export function* clearOrderDetailsRoutineWatcher() {
  yield takeLatest(clearOrderDetailsRoutine.TRIGGER, clearOrderDetails)
}

export function* prefillOrderFormRoutineWatcher() {
  yield takeLatest(prefillOrderFormRoutine.TRIGGER, prefillOrderForm)
}

export const ordersSagas = [
  fork(prepareOrderRoutineWatcher),
  fork(setOrderEditFormValueRoutineWatcher),
  fork(updateOrderRoutineWatcher),
  fork(createOrderRoutineWatcher),
  fork(clearEditOrderFormRoutineWatcher),
  fork(getOrdersListIssuedRoutineWatcher),
  fork(getOrdersListReceivedRoutineWatcher),
  fork(setOrdersIssuedFiltersRoutineWatcher),
  fork(setOrdersReceivedFiltersRoutineWatcher),
  fork(getOrderDetailsRoutineWatcher),
  fork(acceptOrderRoutineWatcher),
  fork(rejectOrderRoutineWatcher),
  fork(downloadOrderPdfRoutineWatcher),
  fork(clearOrderDetailsRoutineWatcher),
  fork(prefillOrderFormRoutineWatcher)
]
