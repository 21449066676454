import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  select,
  takeLatest
} from '@redux-saga/core/effects'
import { navigate } from 'gatsby-plugin-intl'
import TrackingService from 'src/services/TrackingService'
import { propEq } from 'ramda'
import { ERROR_DELAY_TIME } from 'src/ducks/consts'
import {
  selectTrackingDetails,
  selectTrackingFinishedFiltersFormatted,
  selectTrackingOngoingFiltersFormatted
} from 'src/features/tracking/duck/selectors'
import { normalizeTrackingForSave } from 'src/features/tracking/duck/normalizers'
import { createFreeCarrierOfferTrackingRoutine } from 'src/features/myOffers/duck/actions-free-carrier'
import { createExportOfferTrackingRoutine } from 'src/features/myOffers/duck/actions-export'
import { createImportOfferTrackingRoutine } from 'src/features/myOffers/duck/actions-import'
import { createPostImportOfferTrackingRoutine } from 'src/features/myOffers/duck/actions-post-import'
import { setSnackbarValues } from 'src/ducks/actions'
import translate from 'src/intl/translate'
import CompanyService from 'src/services/CompanyService'
import { selectCurrentUserId } from 'src/features/account/duck/selectors'
import routes from 'src/utils/routes'
import { MENU_ITEMS } from 'src/features/dashboard/duck/consts'
import {
  displayResponseErrorMessage,
  getErrorMessageFromApiResponse
} from 'src/utils/helpers'

export const getTrackingDetailsRoutine = createRoutine('GET_TRACKING_DETAILS')

export const getTrackingListOngoingRoutine = createRoutine(
  'GET_TRACKING_LIST_ONGOING'
)
export const getTrackingListFinishedRoutine = createRoutine(
  'GET_TRACKING_LIST_FINISHED'
)

export const setTrackingOngoingFiltersRoutine = createRoutine(
  'SET_TRACKING_ONGOING_FILTERS'
)
export const setTrackingFinishedFiltersRoutine = createRoutine(
  'SET_TRACKING_FINISHED_FILTERS'
)

export const generateDriverCredentialsRoutine = createRoutine(
  'GENERATE_DRIVER_CREDENTIALS'
)

export const updateTrackingRoutine = createRoutine('UPDATE_TRACKING')

export const setEditTrackingFormRoutine = createRoutine(
  'SET_EDIT_TRACKING_FORM'
)

export const clearEditFormRoutine = createRoutine('CLEAR_EDIT_TRACKING_FORM')

export const uploadTrackingFilesRoutine = createRoutine('UPLOAD_TRACKING_FILES')

export const setTrackingTransportationTypeRoutine = createRoutine(
  'SET_TRACKING_TRANSPORTATION_TYPE'
)

export const deleteTrackingFileRoutine = createRoutine('DELETE_TRACKING_FILE')
export const getTrackingEventFileRoutine = createRoutine(
  'GET_TRACKING_EVENT_FILE'
)

export const getDriversRoutine = createRoutine('GET_DRIVERS')

function* getTrackingListOngoing() {
  yield put(getTrackingListOngoingRoutine.request())
  try {
    const filters = yield select(selectTrackingOngoingFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(TrackingService.getTrackingListOngoing, filters)
    yield put(getTrackingListOngoingRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getTrackingListOngoingRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getTrackingListOngoingRoutine.fulfill())
  }
}
function* getTrackingListFinished() {
  yield put(getTrackingListFinishedRoutine.request())
  try {
    const filters = yield select(selectTrackingFinishedFiltersFormatted)
    const {
      response: { data, meta }
    } = yield call(TrackingService.getTrackingListFinished, filters)
    yield put(getTrackingListFinishedRoutine.success({ data, meta }))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getTrackingListFinishedRoutine.failure({ message }))
    yield delay(ERROR_DELAY_TIME)
  } finally {
    yield put(getTrackingListFinishedRoutine.fulfill())
  }
}
function* getTrackingDetails({ payload }) {
  yield put(getTrackingDetailsRoutine.request())
  try {
    const currentUserId = yield select(selectCurrentUserId)
    const { data } = yield call(TrackingService.getTrackingDetails, payload.id)
    const isCurrentUserForwarder = propEq(
      'forwarderUserId',
      currentUserId
    )(data)

    const values = {
      ...data,
      isCurrentUserForwarder
    }
    yield put(getTrackingDetailsRoutine.success(values))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getTrackingDetailsRoutine.failure({ message }))
  } finally {
    yield put(getTrackingDetailsRoutine.fulfill())
  }
}
function* setTrackingOngoingFilters({ payload }) {
  yield put(setTrackingOngoingFiltersRoutine.request())
  try {
    yield put(setTrackingOngoingFiltersRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}
function* setTrackingFinishedFilters({ payload }) {
  yield put(setTrackingFinishedFiltersRoutine.request())
  try {
    yield put(setTrackingFinishedFiltersRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}
function* setEditTrackingForm({ payload }) {
  yield put(setEditTrackingFormRoutine.request())
  try {
    yield put(setEditTrackingFormRoutine.success(payload))
  } catch (e) {
    console.log(e)
  }
}

function* clearEditForm() {
  yield put(clearEditFormRoutine.request())
  try {
    yield put(clearEditFormRoutine.success())
  } catch (e) {
    console.log(e)
  }
}
function* generateDriverCredentials({ payload }) {
  yield put(generateDriverCredentialsRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.generateDriverCredentials,
      payload.id
    )
    yield put(
      generateDriverCredentialsRoutine.success({
        pinCode: data.pinCode,
        transportationId: data.transportationId
      })
    )
  } catch (e) {
    yield put(generateDriverCredentialsRoutine.fulfill())
  }
}

function* updateTracking({ payload }) {
  yield put(updateTrackingRoutine.request())
  const currentUserId = yield select(selectCurrentUserId)
  const trackingItem = yield select(selectTrackingDetails)

  const isCurrentUserForwarder = propEq(
    'forwarderUserId',
    currentUserId
  )(trackingItem.toJS())

  const values = normalizeTrackingForSave({
    ...payload.values.toJS(),
    isCurrentUserForwarder
  })
  try {
    const { data } = yield call(
      TrackingService.updateTracking,
      payload.id,
      values
    )
    yield put(updateTrackingRoutine.success(data))
    navigate(
      `${routes.APP_DASHBOARD}?tab=${MENU_ITEMS.TRACKING_DETAILS}&id=${trackingItem.referenceNumber}`
    )
    yield call(setSnackbarValues, {
      payload: {
        message: translate().formatMessage({
          id: 'tracking.updatedSuccessfully'
        })
      }
    })
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(updateTrackingRoutine.failure({ message }))
    yield call(displayResponseErrorMessage, e)
  } finally {
    yield put(updateTrackingRoutine.fulfill())
  }
}

function* setTrackingTransportationType({ payload }) {
  yield put(setTrackingTransportationTypeRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.setTrackingTransportationType,
      payload.id,
      payload.values
    )
    yield call(getTrackingDetails, { payload: { id: payload.id } })
    yield put(setTrackingTransportationTypeRoutine.success(data))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(setTrackingTransportationTypeRoutine.failure({ message }))
  } finally {
    yield put(setTrackingTransportationTypeRoutine.fulfill())
  }
}

function* uploadTrackingFiles({ payload }) {
  yield put(uploadTrackingFilesRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.uploadTrackingFiles,
      payload.files,
      payload.id
    )
    yield call(getTrackingDetails, { payload: { id: payload.id } })
    yield put(uploadTrackingFilesRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(uploadTrackingFilesRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(uploadTrackingFilesRoutine.fulfill())
  }
}

function* deleteTrackingFile({ payload }) {
  yield put(deleteTrackingFileRoutine.request())
  try {
    const { data } = yield call(
      TrackingService.deleteTrackingFile,
      payload.trackingId,
      payload.fileId
    )
    yield call(getTrackingDetails, { payload: { id: payload.trackingId } })
    yield put(deleteTrackingFileRoutine.success({ data }))
  } catch (e) {
    const message = getErrorMessageFromApiResponse(e)
    yield put(deleteTrackingFileRoutine.failure({ message }))
  } finally {
    yield delay(ERROR_DELAY_TIME)
    yield put(deleteTrackingFileRoutine.fulfill())
  }
}

function* getTrackingEventFile({ payload }) {
  yield call(TrackingService.getTrackingEventFile, payload.url)
}

function* getDrivers() {
  yield put(getDriversRoutine.request())
  try {
    const { data } = yield call(CompanyService.getMyCompanyDrivers)
    yield put(getDriversRoutine.success(data))
  } catch (e) {
    console.log(e)
    const message = getErrorMessageFromApiResponse(e)
    yield put(getDriversRoutine.failure({ message }))
  } finally {
    yield put(getDriversRoutine.fulfill())
  }
}

function* getTrackingListOngoingRoutineWatcher() {
  yield takeLatest(
    [
      getTrackingListOngoingRoutine.TRIGGER,
      setTrackingOngoingFiltersRoutine.SUCCESS
    ],
    getTrackingListOngoing
  )
}
function* getTrackingListFinishedRoutineWatcher() {
  yield takeLatest(
    [
      getTrackingListFinishedRoutine.TRIGGER,
      setTrackingFinishedFiltersRoutine.SUCCESS
    ],
    getTrackingListFinished
  )
}
function* getTrackingItemRoutineWatcher() {
  yield takeLatest(
    [
      getTrackingDetailsRoutine.TRIGGER,
      createFreeCarrierOfferTrackingRoutine.SUCCESS,
      createExportOfferTrackingRoutine.SUCCESS,
      createImportOfferTrackingRoutine.SUCCESS,
      createPostImportOfferTrackingRoutine.SUCCESS
    ],
    getTrackingDetails
  )
}
function* setTrackingOngoingFiltersRoutineWatcher() {
  yield takeLatest(
    setTrackingOngoingFiltersRoutine.TRIGGER,
    setTrackingOngoingFilters
  )
}

function* setTrackingFinishedFiltersRoutineWatcher() {
  yield takeLatest(
    setTrackingFinishedFiltersRoutine.TRIGGER,
    setTrackingFinishedFilters
  )
}

export function* generateDriverCredentialsRoutineWatcher() {
  yield takeLatest(
    generateDriverCredentialsRoutine.TRIGGER,
    generateDriverCredentials
  )
}

export function* updateTrackingRoutineWatcher() {
  yield takeLatest(updateTrackingRoutine.TRIGGER, updateTracking)
}
export function* setEditTrackingFormRoutineWatcher() {
  yield takeLatest(setEditTrackingFormRoutine.TRIGGER, setEditTrackingForm)
}

export function* clearEditFormRoutineWatcher() {
  yield takeLatest(clearEditFormRoutine.TRIGGER, clearEditForm)
}

export function* uploadTrackingFilesRoutineWatcher() {
  yield takeLatest(uploadTrackingFilesRoutine.TRIGGER, uploadTrackingFiles)
}

export function* deleteTrackingFileRoutineWatcher() {
  yield takeLatest(deleteTrackingFileRoutine.TRIGGER, deleteTrackingFile)
}

export function* setTrackingTransportationTypeRoutineWatcher() {
  yield takeLatest(
    setTrackingTransportationTypeRoutine.TRIGGER,
    setTrackingTransportationType
  )
}

export function* getTrackingEventFileRoutineWatcher() {
  yield takeLatest(getTrackingEventFileRoutine.TRIGGER, getTrackingEventFile)
}

export function* getDriversRoutineWatcher() {
  yield takeLatest(
    [getDriversRoutine.TRIGGER, updateTrackingRoutine.SUCCESS],
    getDrivers
  )
}

export const trackingSagas = [
  fork(getTrackingListOngoingRoutineWatcher),
  fork(getTrackingListFinishedRoutineWatcher),
  fork(getTrackingItemRoutineWatcher),
  fork(setTrackingOngoingFiltersRoutineWatcher),
  fork(setTrackingFinishedFiltersRoutineWatcher),
  fork(generateDriverCredentialsRoutineWatcher),
  fork(updateTrackingRoutineWatcher),
  fork(setEditTrackingFormRoutineWatcher),
  fork(uploadTrackingFilesRoutineWatcher),
  fork(deleteTrackingFileRoutineWatcher),
  fork(setTrackingTransportationTypeRoutineWatcher),
  fork(getTrackingEventFileRoutineWatcher),
  fork(getDriversRoutineWatcher),
  fork(clearEditFormRoutineWatcher)
]
