import { Record } from 'immutable'
import {
  AdrClass,
  CargoType,
  Container,
  Contractor,
  Currency,
  Location,
  Pagination,
  Shipowner
} from 'src/ducks/records'
import { StockStates } from 'src/features/stocks/ducks/records'
import { MyOffersFilters } from 'src/features/myOffers/duck/records'
import { API_STATES, CURRENCIES } from 'src/ducks/consts'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'

export const Transportation = Record({
  id: null,
  offerId: null,
  containerId: null,
  shipownerId: null,
  originLocationId: null,
  destinationLocationId: null,
  returnLocationId: null,
  customsClearanceLocationId: null,
  cargoTypeId: null,
  adrClassId: null,
  europeanEmissionStandard: null,
  numberOfContainers: null,
  weight: null,
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
  originLocation: Location(),
  destinationLocation: Location(),
  returnLocation: Location(),
  customsClearanceLocation: Location(),
  container: Container(),
  shipowner: Shipowner(),
  cargoType: CargoType(),
  adrClass: AdrClass(),
  customsClearanceLocationIdOpened: false,
  returnLocationIdOpened: false
})

export const AcceptedReservation = Record({
  id: null,
  offerId: null,
  offerorId: null,
  price: Currency(),
  currency: null,
  acceptedAt: null,
  createdAt: null,
  annulledAt: null
})

export const Contract = Record({
  id: null,
  type: null,
  offerType: STOCKS_TYPES.CONTRACT,
  startDate: null,
  startDateFormatted: '',
  endDate: null,
  endDateFormatted: '',
  validUntilDate: null,
  issuerId: null,
  handlerId: null,
  referenceNumber: '',
  reservationsCount: 0,
  reservations: [],
  currency: CURRENCIES.PLN,
  reserveActionAvailableAt: null,
  isReservable: null,
  isDeletable: null,
  outcome: null,
  conditions: '',
  notes: '',
  createdAt: null,
  updatedAt: null,
  addedToListingAt: null,
  finishedAt: null,
  acceptedReservation: AcceptedReservation(),
  issuer: Contractor(),
  handler: Contractor(),
  transportations: [],
  price: Currency(),
  customsClearanceLocation: Location(),
  container: Container(),
  shipowner: Shipowner(),
  cargoType: CargoType(),
  adrClass: AdrClass()
})

export const ContractReservation = Record({
  id: null,
  offerId: null,
  offerorId: null,
  price: Currency(),
  currency: null,
  acceptedAt: null,
  createdAt: null,
  annulledAt: null,
  queuePlace: null,
  isCancellable: false,
  offer: Contract(),
  offeror: Contractor()
})

export const Contracts = Record({
  state: StockStates(),
  errorMessage: null,
  offers: [],
  isDrawerOpen: false,
  offerDetails: Contract(),
  filters: MyOffersFilters(),
  pagination: Pagination(),
  total: ''
})

Contracts.prototype.isLoading = function(key) {
  return this.state[key] === API_STATES.IN_PROGRESS
}
