import { Record } from 'immutable'
import {
  API_STATES,
  CURRENCIES,
  OFFERS_DEFAULT_LIMIT,
  OFFERS_DEFAULT_PAGE,
  OFFERS_DEFAULT_SORTING
} from 'src/ducks/consts'
import {
  Currency,
  Container,
  Contractor,
  Shipowner,
  Location,
  Pagination
} from 'src/ducks/records'
import { STOCKS_TYPES } from 'src/features/stocks/ducks/consts'
import LocalStorageService from 'src/services/LocalStorageService'

const limitOffersPerPageFromStorage = LocalStorageService.get('offersPerPage')

export const StockStates = new Record({
  list: API_STATES.PRISTINE,
  details: API_STATES.PRISTINE,
  buttons: API_STATES.PRISTINE,
  refreshedSuccessfully: false
})

export const ExportOffer = Record({
  addedToListingAt: null,
  cargoType: null,
  container: Container(),
  containerId: null,
  createdAt: null,
  currency: CURRENCIES.PLN,
  customsClearanceLocation: Location(),
  deletedAt: null,
  finishedAt: null,
  handler: Contractor(),
  handlerId: null,
  id: null,
  adrClassId: null,
  isDeletable: false,
  isOfferLiveNew: false,
  isOfferNew: false,
  isRefreshable: false,
  isReservable: false,
  isRestorable: false,
  isUpdatable: false,
  isPublicable: false,
  isPriceVisible: true,
  isBiddable: false,
  issuer: Contractor(),
  issuerId: null,
  liveNewOfferAddedAt: null,
  liveOfferRemovedAt: null,
  loadingDate: null,
  loadingLocation: Location(),
  loadingLocationId: null,
  loadingTime: null,
  notes: null,
  offerType: STOCKS_TYPES.CARGO_EXPORT,
  outcome: null,
  price: Currency(),
  raw: {},
  referenceNumber: null,
  reservations: [],
  reservationsCount: 0,
  returnDate: null,
  returnLocation: Location(),
  returnLocationId: null,
  returnTime: null,
  shipowner: Shipowner(),
  shipownerId: null,
  adrClass: {},
  timeFromAdded: '',
  type: null,
  transportationTrackingId: null,
  transportationTracking: null,
  updatedAt: null,
  weight: null,
  transportationOrderId: null,
  transportationOrder: null,
  canCreateTransportationOrder: false,
  offerContextType: 'export_cargo_offer',
  auctionDeadline: null,
  reserveActionAvailableAt: null,
  bidActionAvailableAt: null,
  isDebtCollectable: false,
  europeanEmissionStandard: null,
  doesRequireTracking: false,
  goPublicAt: null,
  visibility: 'public',
  isPrivate: false
})

export const ImportOffer = Record({
  addedToListingAt: null,
  cargoType: null,
  container: Container(),
  containerId: null,
  createdAt: null,
  customsClearanceLocation: Location(),
  currency: CURRENCIES.PLN,
  deletedAt: null,
  finishedAt: null,
  handler: Contractor(),
  handlerId: null,
  id: null,
  adrClassId: null,
  isDeletable: false,
  isOfferLiveNew: false,
  isOfferNew: false,
  isRefreshable: false,
  isReservable: false,
  isRestorable: false,
  isUpdatable: false,
  isPublicable: false,
  isPriceVisible: true,
  isBiddable: false,
  issuer: Contractor(),
  issuerId: null,
  liveNewOfferAddedAt: null,
  liveOfferRemovedAt: null,
  notes: null,
  offerType: STOCKS_TYPES.CARGO_IMPORT,
  outcome: null,
  pickupDate: null,
  pickupLocation: Location(),
  pickupLocationId: null,
  pickupTime: null,
  price: Currency(),
  raw: {},
  referenceNumber: null,
  reservations: [],
  reservationsCount: 0,
  returnLocation: Location(),
  returnLocationId: null,
  shipowner: Shipowner(),
  shipownerId: null,
  timeFromAdded: '',
  type: null,
  transportationTrackingId: null,
  transportationTracking: null,
  unloadingDate: null,
  unloadingLocation: Location(),
  unloadingLocationId: null,
  unloadingTime: null,
  updatedAt: null,
  weight: null,
  adrClass: {},
  transportationOrderId: null,
  transportationOrder: null,
  canCreateTransportationOrder: false,
  offerContextType: 'import_cargo_offer',
  auctionDeadline: null,
  reserveActionAvailableAt: null,
  bidActionAvailableAt: null,
  isDebtCollectable: false,
  europeanEmissionStandard: null,
  doesRequireTracking: false,
  goPublicAt: null,
  visibility: 'public',
  isPrivate: false
})

export const FreeCarrierOffer = Record({
  addedToListingAt: null,
  availabilityDate: null,
  availabilityLocation: Location(),
  availabilityLocationId: null,
  availabilityTime: null,
  containerSemiTrailer: Container(),
  containerSemiTrailerId: null,
  createdAt: null,
  currency: CURRENCIES.PLN,
  customsClearanceLocation: Location(),
  deletedAt: null,
  destinationLocation: Location(),
  destinationLocationId: null,
  finishedAt: null,
  handler: Contractor(),
  handlerId: null,
  id: null,
  adrClassId: null,
  isDeletable: false,
  isOfferLiveNew: false,
  isOfferNew: false,
  isRefreshable: false,
  isReservable: false,
  isRestorable: false,
  isUpdatable: false,
  isPublicable: false,
  isPriceVisible: true,
  isBiddable: false,
  isSemiTrailerWithGenset: false,
  issuer: Contractor(),
  issuerId: null,
  liveNewOfferAddedAt: null,
  liveOfferRemovedAt: null,
  notes: null,
  offerType: STOCKS_TYPES.FREE_CARRIERS,
  outcome: null,
  raw: {},
  referenceNumber: null,
  reservations: [],
  reservationsCount: 0,
  shipowner: Shipowner(),
  timeFromAdded: '',
  type: null,
  transportationTrackingId: null,
  transportationTracking: null,
  updatedAt: null,
  adrClass: {},
  transportationOrderId: null,
  transportationOrder: null,
  canCreateTransportationOrder: false,
  offerContextType: 'free_carrier_offer',
  auctionDeadline: null,
  price: Currency(),
  reserveActionAvailableAt: null,
  bidActionAvailableAt: null,
  isDebtCollectable: false,
  europeanEmissionStandard: null,
  doesAllowTracking: false,
  goPublicAt: null,
  visibility: 'public',
  isPrivate: false
})

export const PostImportOffer = Record({
  addedToListingAt: null,
  availabilityDate: null,
  availabilityLocation: Location(),
  availabilityLocationId: null,
  customsClearanceLocation: Location(),
  availabilityTime: null,
  container: Container(),
  containerId: null,
  currency: CURRENCIES.PLN,
  createdAt: null,
  deletedAt: null,
  destinationLocation: Location(),
  destinationLocationId: null,
  finishedAt: null,
  handler: Contractor(),
  handlerId: null,
  id: null,
  adrClassId: null,
  isDeletable: false,
  isOfferLiveNew: false,
  isOfferNew: false,
  isRefreshable: false,
  isReservable: false,
  isRestorable: false,
  isUpdatable: false,
  isPublicable: false,
  isPriceVisible: true,
  isBiddable: false,
  issuer: Contractor(),
  issuerId: null,
  liveNewOfferAddedAt: null,
  liveOfferRemovedAt: null,
  notes: null,
  offerType: STOCKS_TYPES.POST_IMPORT,
  outcome: null,
  raw: {},
  referenceNumber: null,
  reservations: [],
  reservationsCount: 0,
  shipowner: Shipowner(),
  shipownerId: null,
  timeFromAdded: '',
  type: null,
  transportationTrackingId: null,
  transportationTracking: null,
  updatedAt: null,
  adrClass: {},
  transportationOrderId: null,
  transportationOrder: null,
  canCreateTransportationOrder: false,
  offerContextType: 'post_import_container_offer',
  auctionDeadline: null,
  price: Currency(),
  reserveActionAvailableAt: null,
  bidActionAvailableAt: null,
  isDebtCollectable: false,
  europeanEmissionStandard: null,
  doesAllowTracking: false,
  goPublicAt: null,
  visibility: 'public',
  isPrivate: false
})

export const ExportFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: limitOffersPerPageFromStorage || OFFERS_DEFAULT_LIMIT,
  sort: OFFERS_DEFAULT_SORTING,
  //locations format: [{id, range}, {id,range}]
  loadingLocation: [],
  loadingDateFrom: null,
  loadingDateTo: null,
  // [{id, range}, {id,range}]
  returnLocation: [],
  returnDateFrom: null,
  returnDateTo: null,
  container: [],
  shipowner: [],
  adrClass: [],
  europeanEmissionStandard: null,
  cargoType: [],
  company: [],
  status: null,
  visibility: null,
  tabName: null,
  tabId: null,
  referenceNumber: null
})

export const ImportFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: limitOffersPerPageFromStorage || OFFERS_DEFAULT_LIMIT,
  sort: OFFERS_DEFAULT_SORTING,
  pickupLocation: [],
  pickupDateFrom: null,
  pickupDateTo: null,
  unloadingLocation: [],
  unloadingDateFrom: null,
  unloadingDateTo: null,
  container: [],
  shipowner: [],
  adrClass: [],
  europeanEmissionStandard: null,
  cargoType: [],
  company: [],
  status: null,
  visibility: null,
  tabName: null,
  tabId: null,
  referenceNumber: null
})

export const PostImportFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: limitOffersPerPageFromStorage || OFFERS_DEFAULT_LIMIT,
  sort: OFFERS_DEFAULT_SORTING,
  availabilityLocation: [],
  availabilityDateFrom: null,
  availabilityDateTo: null,
  destinationLocation: [],
  container: [],
  shipowner: [],
  adrClass: [],
  europeanEmissionStandard: null,
  company: [],
  status: null,
  visibility: null,
  tabName: null,
  tabId: null,
  referenceNumber: null
})

export const FreeCarrierFilters = Record({
  page: OFFERS_DEFAULT_PAGE,
  limit: limitOffersPerPageFromStorage || OFFERS_DEFAULT_LIMIT,
  sort: OFFERS_DEFAULT_SORTING,
  availabilityLocation: [],
  availabilityDateFrom: null,
  availabilityDateTo: null,
  destinationLocation: [],
  containerSemiTrailer: [],
  genset: null,
  adrClass: [],
  europeanEmissionStandard: null,
  company: [],
  status: null,
  visibility: null,
  tabName: null,
  tabId: null,
  referenceNumber: null
})

export const FilterTab = Record({
  currentFilters: {},
  exchange: null,
  id: null,
  name: '',
  userId: null
})

export const FilterTabData = Record({
  counter: 0,
  addedOffers: [],
  removedOffers: []
})

export const StockCargoExport = Record({
  state: StockStates(),
  errorMessage: null,
  offerDetails: ExportOffer(),
  offers: [],
  isDrawerOpen: false,
  filters: ExportFilters(),
  pagination: Pagination(),
  total: '',
  tabs: [],
  tabsData: {},
  activeTab: null,
  infoBoxOffersNumber: 0
})

export const StockCargoImport = Record({
  state: StockStates(),
  errorMessage: null,
  offerDetails: ImportOffer(),
  offers: [],
  isDrawerOpen: false,
  filters: ImportFilters(),
  pagination: Pagination(),
  total: '',
  tabs: [],
  tabsData: {},
  activeTab: null,
  infoBoxOffersNumber: 0
})

export const StockCargoPostImport = Record({
  state: StockStates(),
  errorMessage: null,
  offerDetails: PostImportOffer(),
  offers: [],
  isDrawerOpen: false,
  filters: PostImportFilters(),
  pagination: Pagination(),
  total: '',
  tabs: [],
  tabsData: {},
  activeTab: null,
  infoBoxOffersNumber: 0
})

export const StockCargoFreeCarrier = Record({
  state: StockStates(),
  errorMessage: null,
  offerDetails: FreeCarrierOffer(),
  offers: [],
  isDrawerOpen: false,
  filters: FreeCarrierFilters(),
  pagination: Pagination(),
  total: '',
  tabs: [],
  tabsData: {},
  activeTab: null,
  infoBoxOffersNumber: 0
})

/**
 * @deprecated
 */
StockCargoExport.prototype.isLoading = function(key) {
  return this.state[key] === API_STATES.IN_PROGRESS
}

/**
 * @deprecated
 */
StockCargoImport.prototype.isLoading = function(key) {
  return this.state[key] === API_STATES.IN_PROGRESS
}

/**
 * @deprecated
 */
StockCargoPostImport.prototype.isLoading = function(key) {
  return this.state[key] === API_STATES.IN_PROGRESS
}

/**
 * @deprecated
 */
StockCargoFreeCarrier.prototype.isLoading = function(key) {
  return this.state[key] === API_STATES.IN_PROGRESS
}
