import { parseFiltersToQuery } from 'src/utils/helpers'
import api from './APIInterceptor'

export default class InvoiceService {
  static showIssuedInvoices(filters) {
    const query = parseFiltersToQuery(filters)

    return api.get(`me/invoices/issued?${query}`)
  }

  static showReceivedInvoices(filters) {
    const query = parseFiltersToQuery(filters)

    return api.get(`me/invoices/received?${query}`)
  }

  static prepareInvoice() {
    return api.get('me/invoices/new')
  }

  static createInvoice(payload) {
    return api.post('me/invoices', payload)
  }

  static previewInvoice(payload) {
    return api.post('me/invoices/pdf', payload, {
      responseType: 'blob'
    })
  }

  static recalculateInvoice(payload) {
    return api.post('me/invoices/recalculate-totals', payload)
  }

  static regenerateInvoiceNumber(payload) {
    return api.post('me/invoices/regenerate-number', payload)
  }

  static searchForInvoiceRecipient(payload) {
    return api.post('me/invoices/search-recipient', payload)
  }

  static markAsPaid(id, payload) {
    return api.post(`me/invoices/${id}/payments`, payload)
  }

  static showInvoiceDetails(id) {
    return api.get(`me/invoices/${id}`)
  }

  static deletePaymentFromInvoice(invoiceId, paymentId) {
    return api.delete(`me/invoices/${invoiceId}/payments/${paymentId}`)
  }

  static prepareInvoiceForTransportationOrder(transportationOrderId) {
    return api.get(`me/transportation-orders/${transportationOrderId}/invoice`)
  }

  static createInvoiceForTransportationOrder(payload) {
    return api.post('me/invoices/transportation-order', payload)
  }

  static createCorrectionNote(invoiceId, payload) {
    return api.post(`me/invoices/${invoiceId}/corrective-notes`, payload)
  }

  static showCorrectionNote(id) {
    return api.get(`me/invoices/corrective-notes/${id}`)
  }

  static prepareCorrectiveInvoice(id) {
    return api.get(`me/invoices/${id}/corrective-invoices/new`)
  }

  static createCorrectiveInvoice(id, payload) {
    return api.post(`me/invoices/${id}/corrective-invoices`, payload)
  }

  static previewCorrectiveInvoice(id, payload) {
    return api.post(`me/invoices/${id}/corrective-invoices/pdf`, payload, {
      responseType: 'blob'
    })
  }

  static recalculateCorrectiveInvoice(id, payload) {
    return api.post(
      `me/invoices/${id}/corrective-invoices/recalculate-totals`,
      payload
    )
  }

  static markCorrectiveAsPaid(id, payload) {
    return api.post(`me/invoices/corrective-invoices/${id}/payments`, payload)
  }

  static showCorrectiveInvoiceDetails(id) {
    return api.get(`me/invoices/corrective-invoices/${id}`)
  }

  static deletePaymentFromCorrectiveInvoice(invoiceId, paymentId) {
    return api.delete(
      `me/invoices/corrective-invoices/${invoiceId}/payments/${paymentId}`
    )
  }
}
