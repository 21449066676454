import {
  map,
  pipe,
  evolve,
  identity,
  pick,
  path,
  call,
  head,
  prop,
  filter,
  includes,
  pluck,
  values,
  find,
  pathEq,
  isNil,
  pickAll,
  join,
  isEmpty,
  type as rType,
  splitEvery,
  when,
  always,
  pathOr,
  flatten
} from 'ramda'
import { isEmptyString, isFalsy, renameKeys } from 'ramda-adjunct'
import moment from 'moment'
import snakeCase from 'lodash.snakecase'
import queryString from 'query-string'
import qs from 'qs'
import { DOCUMENTS_STATES } from 'src/features/account/duck/consts'
import { DATE_API_FORMAT, LOCATION_TYPES, TIME_FORMAT } from 'src/ducks/consts'
import translate from 'src/intl/translate'
import { setSnackbarValues } from 'src/ducks/actions'

// gatsby-image type = 'fluid' or 'fixed' for <Img/> from gatsby-image
export const findFluidOrFixedInEdgesByFileName = (
  name,
  edges,
  type = 'fluid'
) =>
  pipe(
    find(pathEq(['node', 'name'], name)),
    path(['node', 'childImageSharp', type])
  )(edges)

export const findSingleImageFluidOrFixed = (type = 'fluid') =>
  path(['childImageSharp', type])

export const filterNullValues = filter(v => !isNil(v))

export const filterEmptyStrings = filter(v => !isEmptyString(v))

export const filterFalsyValues = filter(v => !isFalsy(v))

export const isNotOlderThanMinutes = (date, minutes = 1) =>
  moment(date).isSameOrAfter(moment().subtract(minutes, 'minute'))

const timeWithoutMsRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

export const removeMsFromTime = str =>
  rType(str) === 'String' ? str.substring(0, str.length - 3) : ''

export const addMsToTime = str => {
  if (!str) return
  if (str.match(timeWithoutMsRegex)) {
    return `${str}:00`
  }
  return str
}

export const replaceNullWithEmptyString = map(when(isNil, always('')))

export const formatFilterKey = filterName => `filter[${snakeCase(filterName)}]`

export const joinLocationsIdsWithRange = arr =>
  arr.map(location =>
    location.range
      ? join('|', pipe(pickAll(['id', 'range']), values)(location))
      : location.id
  )

export const parseFiltersToQuery = filters =>
  queryString.stringify(filters, {
    arrayFormat: 'comma',
    skipNull: true
  })

export const checkDocumentStatusByDate = date =>
  (moment(date).isSameOrBefore(moment()) && DOCUMENTS_STATES.EXPIRED) ||
  (moment(date).isSameOrBefore(moment().add(30, 'day')) &&
    DOCUMENTS_STATES.EXPIRING) ||
  DOCUMENTS_STATES.ADDED

export const formatHomepageUrl = url => {
  if (!url || isEmpty(url)) return url
  return url.match(/^(http|https):\/\//) ? url : `http://${url}`
}

export const toggleArrayItem = (arr, item) =>
  arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item]

export const getDateFromIsoString = date =>
  isNil(date) ? null : moment(date).format(DATE_API_FORMAT)

export const getTimeFromIsoString = date =>
  isNil(date) ? null : moment.utc(date).format(TIME_FORMAT)

/**
 * create API compatible date ISO string from date(YYYY-MM-DD) and time(gg:mm)
 * @param {string} date - YYYY-MM-DD
 * @param {string} time - gg:mm
 * @returns {string} - API compatible date ISO string
 */
export const getIsoStringFromDateAndTime = (date, time) =>
  moment(`${date} ${time}Z`)
    .utc()
    .toISOString()

export const formatPhoneNumber = pipe(splitEvery(3), join(' '))

export const formatMessagesGroupTimestamp = str =>
  moment(str).calendar(null, {
    sameDay: 'HH:mm',
    nextDay: 'ddd., HH:mm',
    lastDay: 'ddd., HH:mm',
    lastWeek: 'DD.MM, HH:mm',
    sameElse: 'DD.MM, HH:mm'
  })

export const bytesToKB = bytes => `${(bytes * 0.001).toFixed()}KB`

export const ellipsisInTheMiddle = (text, maxLength = 100) =>
  text.length > maxLength
    ? `${text.substr(0, maxLength / 2)}...${text.substr(
        text.length - maxLength / 2,
        text.length
      )}`
    : text

export const generateTableWidths = arr =>
  arr.map(header => `${header.width}fr `).join('')

export const generateTableNames = arr =>
  `'${arr.map(header => `${header.name} `).join('')}'`

export const formatTimeDuration = time => {
  if (!time) return null
  const duration = moment.duration(time, 'minutes')
  const hours = duration.hours()
  const minutes = duration.minutes()
  return hours > 0 ? `${hours}h ${minutes}min` : `${minutes}min`
}

export const parseGoogleAutocompleteResponse = pipe(
  map(
    pipe(
      evolve({
        place_id: identity,
        description: identity,
        structured_formatting: path(['main_text'])
      }),
      pick(['place_id', 'description', 'structured_formatting'])
    )
  ),
  map(
    renameKeys({
      place_id: 'placeId',
      description: 'name',
      structured_formatting: 'shortName'
    })
  )
)

export const parseGoogleGeocodedLocation = pipe(
  head,
  path(['geometry', 'location']),
  pick(['lat', 'lng']),
  evolve({ lat: call, lng: call })
)

export const getPostcodeFromGooglePredictions = pipe(
  head,
  prop('address_components'),
  filter(address => includes('postal_code', address.types)),
  pluck('long_name'),
  head
)

export const getValueFromGooglePredictions = (type, nameType) =>
  pipe(
    head,
    prop('address_components'),
    filter(address => includes(type, address.types)),
    pluck(nameType),
    head
  )

/**
 * Creates local storage key for last used values
 * @param {string} dictionaryName - required
 * @param {string} [locationType] - optional
 * @returns {string}
 */
export const createLastUsedLocalStorageKey = (dictionaryName, locationType) =>
  `dictionaries-last-used-${dictionaryName}${
    locationType ? `-${locationType}` : ''
  }`

/**
 * Returns order status based on rejectedAt and acceptedAt props.
 * @param {object} order
 * @returns {string} One of 'rejected', 'accepted', 'new'
 */
export const checkOrderStatus = order => {
  let status
  if (order.rejectedAt) {
    status = 'rejected'
  } else {
    status = order.acceptedAt ? 'accepted' : 'new'
  }
  return status
}

/**
 * Replaces slash with pipe character - required for api calls
 * @param {string} referenceNumber
 * @returns {string}
 */
export const formatOrderReferenceNumber = referenceNumber =>
  referenceNumber.replace(/\//g, '|')

/**
 * Return error message from error or default error message
 * @returns {string}
 */
export const getErrorMessageFromApiResponse = pathOr(
  translate().formatMessage({
    id: 'common.errorMessage'
  }),
  ['data', 'message']
)

/**
 * Displays api error message in snackbar
 * @param error {object} - required
 * @param type {string} One of 'info', 'success', 'warning', 'alert', 'white'
 * @param withDescription {boolean} display all errors descriptions
 * @returns {Generator<*, void, ?>}
 */
export function* displayResponseErrorMessage(
  error,
  type = 'alert',
  withDescription = false
) {
  const message = pathOr(
    translate().formatMessage({
      id: 'common.errorMessage'
    }),
    ['data', 'message'],
    error
  )

  const messageDescription =
    pipe(path(['data', 'errors']), values, flatten, join(' \n'))(error) || ''

  yield call(setSnackbarValues, {
    payload: {
      title: withDescription ? message : '',
      message: withDescription ? messageDescription : message,
      type
    }
  })
}

export const getOriginQueryParams = query =>
  qs.stringify(query, { addQueryPrefix: true })

export const addPostalCodeToName = location => {
  if (!location) return
  return LOCATION_TYPES.CITY.includes(location.type)
    ? {
        ...location,
        name: `${location.name} (${location.postalCode})`
      }
    : location
}
